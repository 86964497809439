import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import i18n from "../../../../utils/i18n";
import { useSnackbar, withSnackbar } from "notistack";
import {
  FullSizeContainer,
  Content,
  TitleContainer,
  Title,
  StyledTableCell,
  StyledTableCellRight,
  NoDataContainer,
} from "../../../Share/General";

import {
  Grid,
  Table,
  TableRow,
  TableBody,
  TableHead,
  TableContainer,
} from "@material-ui/core";
import { AffiliateService } from "../../../../services/AffiliateService";

import moment from "moment";
import addDays from "date-fns/addDays";
import CustomPagination from "../../../Share/CustomPagination";
import ReportDatepicker from "../../Report/ReportDatepicker";
import Common from "../../../../utils/Common";

const TitleAndAction = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  @media (max-width: 600px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const CustomSelect = styled.select`
  height: 40px;
  border: solid 1px #b6b6b6;
  border-radius: 4px;
  max-width: 100px;
  margin-right: 8px;
  display: flex;
  align-items: center;
  padding: 0px 8px;
  @media (max-width: 600px) {
    width: 100%;
    max-width: 100%;
    margin-right: 0px;
    margin-bottom: 8px;
  }
`;

const CustomButton = styled.div`
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  border: solid 1px #b6b6b6;
  padding: 0 8px;

  background-image: linear-gradient(
    to top,
    ${(props) => props.theme.primaryGradient},
    ${(props) => props.theme.primary}
  );
  color: ${(props) => props.theme.primaryText};
  margin-left: 8px;
  min-width: 75px;
  cursor: pointer;
  user-select: none;
  @media (max-width: 600px) {
    width: 100%;
    max-width: 100%;
    margin-left: 0px;
    margin-top: 8px;
  }
`;
const ActionZone = styled.div`
  display: flex;
  align-items: center;

  flex: 1;
  width: 100%;
  justify-content: flex-end;
  @media (max-width: 600px) {
    flex-direction: column;
    margin-top: 8px;
  }
`;
const ChangesReport = (props) => {
  const [category, setCategory] = useState([]);
  const [reportCategory, setReportCategory] = useState("");
  const [myDownlineChangesReport, setMyDownlineChangesReport] = useState([]);
  const [dateFrom, setDateFrom] = useState(
    moment(addDays(new Date(), -3)).format("YYYY/MM/DD")
  );
  const [dateTo, setDateTo] = useState(moment(new Date()).format("YYYY/MM/DD"));
  const [page, setPage] = useState(1);

  function handleDateFrom(newDate) {
    setDateFrom(newDate);
  }
  function handleDateTo(newDate) {
    setDateTo(newDate);
  }

  function handleNextPage() {
    if (Math.ceil(myDownlineChangesReport.totalcount / 10) > page) {
      setPage(page + 1);
    } else {
    }
  }

  function handlePrevPage() {
    if (page <= 1) {
    } else {
      setPage(page - 1);
    }
  }

  const { enqueueSnackbar } = useSnackbar();

  function performFastGetAccountChangesReport(from, to, category, page, uid) {
    if (Common.maxDayRangeDetector(from, to)) {
      enqueueSnackbar(i18n.t("common.cannotExceed31day"), {
        variant: "warning",
      });
    } else {
      performGetAccountChangesReport(
        moment.utc(new Date(from)).toISOString(),
        moment(new Date(to)).add(1, "days").toISOString(),
        category,
        page,
        10,
        false,
        "recordedOn",
        false,
        uid
      );
    }
  }

  function performGetAccountChangesReport(
    from,
    to,
    transactiontype,
    page,
    rows,
    desc,
    sort,
    all,
    id
  ) {
    AffiliateService.getAccountChangesReport(
      from,
      to,
      transactiontype,
      page,
      rows,
      desc,
      sort,
      all,
      id
    ).then((e) => {
      if (e.IsSuccess) {
        setMyDownlineChangesReport(e.data);
      }
    });
  }

  useEffect(() => {
    if (category.length === 0) {
      AffiliateService.getTransactionType().then((e) => {
        if (e.IsSuccess) {
          setCategory(e.data);
        }
      });
    }
  }, []);

  useEffect(() => {
    if (
      myDownlineChangesReport.length === 0 &&
      props.account.info.member_id !== 0
    ) {
      performFastGetAccountChangesReport(
        dateFrom,
        dateTo,
        "",
        1,
        props.account.info.member_id
      );
    }
  }, [props.account.info.member_id]);

  useEffect(() => {
    if (props.account.info.member_id !== 0) {
      performFastGetAccountChangesReport(
        dateFrom,
        dateTo,
        reportCategory,
        page,
        props.account.info.member_id
      );
    }
  }, [page]);

  useEffect(() => {
    if (moment(dateTo).isBefore(dateFrom)) {
      setDateTo(dateFrom);
    }
  });

  const checkDataType = (data) => {
    switch (data) {
      case 6100:
        return i18n.t("transferout");
      case 6200:
        return data;
      case 6300:
        return i18n.t("transferoutfailed");
      case 6500:
        return i18n.t("transferin");
      case 6600:
        return i18n.t("transferinfailed");
      case 4200:
        return i18n.t("account.agentInfo.transactionRecord.type.Dividend");
      case 2100:
        return i18n.t("account.agentInfo.transactionRecord.type.Withdrawal");
      default:
        return "";
    }
  };
  return (
    <FullSizeContainer>
      <Content>
        <TitleContainer>
          <Grid container alignItems="flex-end">
            <TitleAndAction>
              <Title>
                {i18n.t("account.agentInfo.transactionRecord.title")}
              </Title>
              <ActionZone>
                <CustomSelect
                  onChange={(e) => {
                    setReportCategory(e.target.value);
                  }}
                >
                  <option value="">
                    {i18n.t(
                      "account.agentInfo.transactionRecord.transactionType"
                    )}
                  </option>
                  {Object.keys(category).map(function (key, index) {
                    return (
                      <option value={key}>
                        {i18n.t(
                          `account.agentInfo.transactionRecord.type.${category[key]}`
                        )}
                      </option>
                    );
                  })}
                </CustomSelect>

                <ReportDatepicker
                  selectedDateFrom={dateFrom}
                  selectedDateTo={dateTo}
                  handleDateTo={handleDateTo}
                  handleDateFrom={handleDateFrom}
                />
                <CustomButton
                  onClick={() => {
                    performFastGetAccountChangesReport(
                      dateFrom,
                      dateTo,
                      reportCategory,
                      1,
                      props.account.info.member_id
                    );
                  }}
                >
                  {i18n.t("report.search")}
                </CustomButton>
              </ActionZone>
            </TitleAndAction>
          </Grid>
        </TitleContainer>
        <TableContainer>
          <Table aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell>
                  {i18n.t("account.agentInfo.transactionRecord.recordedOn")}
                </StyledTableCell>
                <StyledTableCellRight>
                  {i18n.t(
                    "account.agentInfo.transactionRecord.transactionType"
                  )}
                </StyledTableCellRight>
                <StyledTableCellRight>
                  {i18n.t("account.agentInfo.transactionRecord.amount")}
                </StyledTableCellRight>
                <StyledTableCellRight>
                  {i18n.t("account.agentInfo.transactionRecord.balance")}
                </StyledTableCellRight>
              </TableRow>
            </TableHead>
            <TableBody style={{ backgroundColor: "#fff" }}>
              {myDownlineChangesReport.items &&
                myDownlineChangesReport.items
                  .sort((a, b) => {
                    var dateA = new Date(a.recordedOn),
                      dateB = new Date(b.recordedOn);
                    return dateB - dateA;
                  })
                  .map((data, index) => {
                    return (
                      <TableRow>
                        <StyledTableCell>
                          {moment(data.recordedOn).format(
                            "YYYY-MM-DD HH:mm:ss"
                          )}
                        </StyledTableCell>
                        <StyledTableCellRight>
                          {i18n.t(
                            `account.agentInfo.transactionRecord.type.${
                              category[data.transactionType]
                            }`
                          )}
                        </StyledTableCellRight>
                        <StyledTableCellRight>
                          {data.amount}
                        </StyledTableCellRight>
                        <StyledTableCellRight>
                          {data.balance}
                        </StyledTableCellRight>
                      </TableRow>
                    );
                  })}
              {myDownlineChangesReport.totalcount === 0 && (
                <TableRow>
                  <NoDataContainer colSpan={4}>
                    {i18n.t("common.nodata")}
                  </NoDataContainer>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        {myDownlineChangesReport.totalcount >= 10 && (
          <CustomPagination
            total={myDownlineChangesReport.totalcount}
            page={page}
            next={handleNextPage}
            prev={handlePrevPage}
          />
        )}
      </Content>
    </FullSizeContainer>
  );
};

const mapStateToProps = (state) => {
  return {
    account: state.account,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withSnackbar(ChangesReport));
