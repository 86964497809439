import React, { Component } from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import { SHOWING_DIALOG } from "../../../../constant/actionType";

import { Row, Col } from "react-grid-system";
import i18n from "../../../../utils/i18n";

import { withSnackbar } from "notistack";

import {
  FullSizeContainer,
  Content,
  TitleContainer,
  Title,
  TitleContainerBackgroundShadow,
  CustomHyperlink,
  CustomTextField,
} from "../../../Share/General";

import CustomButton from "../../../Share/Button";

import { affiliateAction } from "../../../../actions/affiliateAction";
import { Grid } from "@material-ui/core";

const CustomInputRow = styled(Row)`
  margin-bottom: 10px;
`;

const CustomInputCol = styled(Col)`
  display: flex;
  align-items: center;
  color: #9b9b9b;
`;

class Profile extends Component {
  constructor(props) {
    super(props);

    this.state = {
      token: this.props.token,
      qq: this.props.qq,
      skype: this.props.skype,
      promote_methods: this.props.promote_methods,
      biography: this.props.biography,
    };
  }
  componentDidUpdate(preProps, preState) {
    if (preProps.qq !== this.props.qq || preProps.skype !== this.props.skype) {
      this.setState({
        ...this.state,
        qq: this.props.qq,
        skype: this.props.skype,
        promote_methods: this.props.promote_methods,
        biography: this.props.biography,
      });
    }
  }
  handleSubmitChangeAffiliateInfo = async () => {
    const result = await this.props.editAffiliateInfo(
      this.props.token,
      this.state.qq,
      this.state.skype,
      this.state.promote_methods,
      this.state.biography
    );
    if (result.IsSuccess) {
      this.props.enqueueSnackbar(result.msg, {
        variant: "success",
      });
    }
  };

  handleTextChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  render() {
    return (
      <FullSizeContainer>
        <Content>
          <TitleContainer>
            <Grid container alignItems="flex-end">
              <Grid item>
                <Title> {i18n.t("account.profile.title")}</Title>
              </Grid>
              <Grid item>
                <TitleContainerBackgroundShadow>
                  {i18n.t("account.profile.desc")}
                </TitleContainerBackgroundShadow>
              </Grid>
            </Grid>
          </TitleContainer>

          <CustomInputRow>
            <Grid
              container
              alignItems="center"
              style={{ paddingLeft: 15, paddingRight: 15 }}
            >
              <Grid item xs={12} sm={6} md={5} lg={5} xl={5}>
                <CustomTextField
                  placeholder={i18n.t("account.profile.qqPlaceholder")}
                  variant="outlined"
                  fullWidth
                  value={this.state.qq}
                  name="qq"
                  onChange={this.handleTextChange}
                />
              </Grid>
              <Grid item>
                <CustomInputCol>
                  {i18n.t("account.profile.dontHaveQQ")}{" "}
                  <CustomHyperlink target="_blank" to={"/account/registerqq"}>
                    {i18n.t("account.profile.register")}
                  </CustomHyperlink>
                </CustomInputCol>
              </Grid>
            </Grid>
          </CustomInputRow>

          <CustomInputRow>
            <Grid
              container
              alignItems="center"
              style={{ paddingLeft: 15, paddingRight: 15 }}
            >
              <Grid item xs={12} sm={6} md={5} lg={5} xl={5}>
                <CustomTextField
                  fullWidth
                  placeholder={i18n.t("account.profile.skypePlaceholder")}
                  variant="outlined"
                  value={this.state.skype}
                  name="skype"
                  onChange={this.handleTextChange}
                />
              </Grid>
              <Grid item>
                <CustomInputCol>
                  {i18n.t("account.profile.dontHaveSkype")}{" "}
                  <CustomHyperlink
                    target="_blank"
                    to={"/account/registerskype"}
                  >
                    {i18n.t("account.profile.register")}
                  </CustomHyperlink>
                </CustomInputCol>
              </Grid>
            </Grid>
          </CustomInputRow>
          <CustomInputRow>
            <Grid
              container
              alignItems="center"
              style={{ paddingLeft: 15, paddingRight: 15 }}
            >
              <Grid item xs={12} sm={6} md={5} lg={5} xl={5}>
                <CustomTextField
                  placeholder={i18n.t("account.profile.promotePlaceholder")}
                  variant="outlined"
                  value={this.state.promote_methods}
                  name="promote_methods"
                  onChange={this.handleTextChange}
                />
              </Grid>
            </Grid>
          </CustomInputRow>
          <CustomInputRow>
            <Grid
              container
              alignItems="center"
              style={{ paddingLeft: 15, paddingRight: 15 }}
            >
              <Grid item xs={12} sm={6} md={5} lg={5} xl={5}>
                <CustomTextField
                  placeholder={i18n.t("account.profile.biographyPlaceholder")}
                  variant="outlined"
                  value={this.state.biography}
                  name="biography"
                  onChange={this.handleTextChange}
                  style={{ height: "auto" }}
                  multiline
                  rows="4"
                />
              </Grid>
            </Grid>
          </CustomInputRow>
          <CustomInputRow>
            <Grid
              container
              alignItems="center"
              style={{ paddingLeft: 15, paddingRight: 15 }}
            >
              <Grid item xs={12} sm={6} md={5} lg={5} xl={5}>
                <CustomButton
                  name={i18n.t("common.submit")}
                  handleClick={this.handleSubmitChangeAffiliateInfo.bind(this)}
                />
              </Grid>
            </Grid>
          </CustomInputRow>
        </Content>
      </FullSizeContainer>
    );
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    editAffiliateInfo: (token, qq, skype, promote_methods, biography) =>
      dispatch(
        affiliateAction.changeAffiliateInformation(
          token,
          qq,
          skype,
          promote_methods,
          biography
        )
      ),
    showDialog: (payload) => {
      dispatch({ type: SHOWING_DIALOG, payload });
    },
  };
};

const mapStateToProps = (state) => {
  return {
    token: state.account.token,
    qq: state.account.info.member_qq,
    skype: state.account.info.member_skype,
    promote_methods: state.account.info.promote_methods,
    biography: state.account.info.biography,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withSnackbar(Profile));
