import Common from "./Common";
var translation = {};

const ErrorSwitcher = {
  translation,
  setTranslation: (data) => {
    translation = data;
  },

  getTranslation: (msgid, msg) => {
    if (Common.IsJsonString(msg)) {
      var json = JSON.parse(msg);
      var key = Object.keys(json);
      return (translation[msgid] ? translation[msgid] : msgid).replace(
        "{" + key + "}",
        json[key]
      );
    } else {
      return translation[msgid] ? translation[msgid] : msgid;
    }
  },
};
export default ErrorSwitcher;
