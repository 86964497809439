import React from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import i18n from "../../../utils/i18n";

import { withRouter } from "react-router-dom";

import Red from "../../../assets/images/affiliate/red.png";
import Yellow from "../../../assets/images/affiliate/yellow.png";
import Blue from "../../../assets/images/affiliate/blue.png";
import Cyan from "../../../assets/images/affiliate/cyan.png";

import RedGlowPNG from "../../../assets/images/affiliate/red-glow.png";
import YellowGlowPNG from "../../../assets/images/affiliate/yellow-glow.png";
import BlueGlowPNG from "../../../assets/images/affiliate/blue-glow.png";
import CyanGlowPNG from "../../../assets/images/affiliate/cyan-glow.png";

import YellowUnderGlow from "../../../assets/images/affiliate/yellow-underglow.png";
import RedUnderGlow from "../../../assets/images/affiliate/red-underglow.png";
import BlueUnderGlow from "../../../assets/images/affiliate/blue-underglow.png";
import CyanUnderGlow from "../../../assets/images/affiliate/cyan-underglow.png";

import { SHOW_MODAL, SHOW_LOGIN } from "../../../constant/actionType";
import { Grid, Container } from "@material-ui/core";

const ContentHover = styled.div`
  height: 600px;
  background: #000;
  opacity: 0;
  z-index: 999;
  display: none;
  &:hover {
    background: black;
    opacity: 0.4;
    z-index: 999;
  }
`;

const AffiliateContainerContent = styled.div`
  width: 100%;

  background: url(${({ image }) => {
      return image;
    }})
    center no-repeat;
  background-size: cover;
  border-radius: 12px;
  position: relative;
  display: inline;
  float: left;
  z-index: 999;
  cursor: pointer;

  height: 600px;

  @media (max-width: 600px) {
    height: 300px;
  }

  &:hover {
    border: solid 2px #00b4ff;
    box-shadow: inset 0 0 10px #00b4ff;
    display: block;
  }

  &:hover ${ContentHover} {
    display: block;
  }
`;

const GlowPart = styled.img`
  position: absolute;
  bottom: 0;
  height: auto;
  width: 100%;
  z-index: -1;
  &:hover {
    display: block;
  }
`;

const UnderGlow = styled.img`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
`;

const AffiliateContentBottom = styled.div`
  color: white;
  text-align: center;
  position: absolute;
  bottom: 60px;
  width: 100%;

  font-size: 18px;
  font-weight: bold;
  text-shadow: 0px 0px 5px black;

  :hover {
    ${ContentHover} {
      display: block;
    }
  }
`;

const ContentWrap = styled(Grid)`
  padding: 16px;
`;

const OuterWrapper = styled.div`
  position: relative;
  height: 670px;

  @media (max-width: 600px) {
    height: 300px;
  }
`;

const Home = () => {
  const homepageContent = [
    {
      image: Yellow,
      glow: YellowGlowPNG,
      underglow: YellowUnderGlow,
      text: i18n.t("common.banner1"),
    },
    {
      image: Red,
      glow: RedGlowPNG,
      underglow: RedUnderGlow,
      text: i18n.t("common.banner2"),
    },
    {
      image: Blue,
      glow: BlueGlowPNG,
      underglow: BlueUnderGlow,
      text: i18n.t("common.banner3"),
    },
    {
      image: Cyan,
      glow: CyanGlowPNG,
      underglow: CyanUnderGlow,
      text: i18n.t("common.banner4"),
    },
  ];

  return (
    <div>
      <Container maxWidth="lg">
        <Grid container>
          {homepageContent.map((data, index) => {
            return (
              <ContentWrap item xs={12} sm={6} md={3} lg={3} xl={3}>
                <OuterWrapper>
                  <AffiliateContainerContent image={data.image}>
                    <ContentHover></ContentHover>
                    <GlowPart src={data.glow}></GlowPart>
                    <AffiliateContentBottom>{data.text}</AffiliateContentBottom>
                  </AffiliateContainerContent>
                  <UnderGlow src={data.underglow}></UnderGlow>
                </OuterWrapper>
              </ContentWrap>
            );
          })}
        </Grid>
      </Container>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    showModal: (pageName) => {
      dispatch({ type: SHOW_MODAL, pageName });
    },
    showLogin: (payload) => {
      dispatch({ type: SHOW_LOGIN, payload });
    },
  };
};
const mapStateToProps = (state) => {
  return {
    token: state.account.token,
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Home));
