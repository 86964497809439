import React from "react";

import styled from "styled-components";
import { withRouter } from "react-router-dom";
import i18n from "../../utils/i18n";
import { Grid } from "@material-ui/core";
import config from "../../config/config";
import Provider1PNG from "../../assets/images/provider1.png";
import Provider2PNG from "../../assets/images/provider2.png";
import Provider3PNG from "../../assets/images/provider3.png";
import Provider4PNG from "../../assets/images/provider4.png";
import Provider5PNG from "../../assets/images/provider5.png";
import Provider6PNG from "../../assets/images/provider6.png";
import Provider7PNG from "../../assets/images/provider7.png";
import Provider8PNG from "../../assets/images/provider8.png";
import Provider9PNG from "../../assets/images/provider9.png";
import Provider10PNG from "../../assets/images/provider10.png";
import VersionNumber from "../../utils/versionnumber";

const MiddleContainer = styled.div`
  width: 100%;
  border-bottom: solid 1px ${(props) => props.theme.primaryGradient};
  border-top: solid 1px ${(props) => props.theme.primaryGradient};

  ${({ theme }) =>
    theme.alternatePrimary &&
    "border-bottom: solid 1px " + theme.alternatePrimary + ";"}"};
    ${({ theme }) =>
      theme.alternatePrimary &&
      "border-top: solid 1px " + theme.alternatePrimary + ";"}"};
  padding-top: 23px;
  padding-bottom: 23px;
  @media (max-width: 530px) {
  }
`;
const ProductUL = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0px auto;
  display: table;
  @media (max-width: 530px) {
    text-align: center;
  }
`;

const ProductLI = styled.li`
  list-style: none;
  padding: 0;
  margin: 0;
  display: inline-block;
`;
const ProductImg = styled.img`
  margin: 0 16px;
  @media (max-width: 530px) {
    height: 8vh;
  }
`;
const BottomContainer = styled.div`
  padding: 16px 8px;
  margin: 0 auto;
  color: #f6f6f6;
  text-align: center;
`;
const CopyRightLine1 = styled.div`
  font-size: 12px;
  margin: 0 auto;

  text-align: center;
`;
const CopyRightLine2 = styled.div`
  font-size: 12px;

  margin: 0 auto;

  text-align: center;
`;
const Version = styled.div`
  font-size: 12px;

  margin: 0 auto;

  text-align: center;
`;

const Container = styled.div`
  width: 100%;

  background-image: linear-gradient(
    to top,
    ${(props) => props.theme.primaryGradient},
    ${(props) => props.theme.primary}
  );
`;

const CustomizedContainer = styled(Container)`
  background: ${({ theme }) => theme.alternatePrimary};
`;
function Footer(props) {
  return (
    <CustomizedContainer maxWidth="lg">
      {/* <InnerWrap>
        <TopContainer>
          <TopLeftContainer>
            <TopLeftContent>
              <TopLeftContentTopContainer>
                <RestricContainer>
                  <RestricImage src={FooterHandIconPNG}></RestricImage>
                  <RestricImage src={FooterArrowIconPNG}></RestricImage>
                  <RestricImage src={Footer18plusIconPNG}></RestricImage>
                </RestricContainer>
              </TopLeftContentTopContainer>
              <TopLeftContentBottomContainer>
                {i18n.t("footer.warning")}
              </TopLeftContentBottomContainer>
            </TopLeftContent>
          </TopLeftContainer>
          <TopCenterContainer>
            <FooterATag
              onClick={() => {
                AppHistory.push("/download");
                window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
              }}
            >
              <FooterColumn>
                <FooterIcon1></FooterIcon1>
                <FooterLabelCotanier>
                  APP下载
                  <br />
                  随时随地 最佳体验
                </FooterLabelCotanier>
              </FooterColumn>

              <QRContainerVisit>
                <QRImg src={QRCode1}></QRImg>
                <QRRedTitle>手机免下载访问</QRRedTitle>
              </QRContainerVisit>

              <QRContainerVisit>
                <QRDownloadImg src={QRCode2}>尽情期待</QRDownloadImg>
                <QRRedTitle>APP扫码下载</QRRedTitle>
              </QRContainerVisit>
            </FooterATag>
          </TopCenterContainer>
          <TopRightContainer>
            <AffContainer onClick={() => alert("敬请期待")}>
              <AffImg src={FooterAffIconPNG}></AffImg>
              <AffCSRightContent>
                <AffRightContentLine1>
                  {i18n.t("footer.affiliate.line1")}
                </AffRightContentLine1>
                <AffRightContentLine2>
                  {i18n.t("footer.affiliate.line2")}
                </AffRightContentLine2>
              </AffCSRightContent>
            </AffContainer>
            <CSContainer>
              <CSImg src={Footer24CSIconPNG}></CSImg>
              <AffCSRightContent>
                <AffCSRightContentLine1>000-000-0000</AffCSRightContentLine1>
                <AffCSRightContentLine2>
                  {i18n.t("footer.support")}
                </AffCSRightContentLine2>
              </AffCSRightContent>
            </CSContainer>
          </TopRightContainer>
        </TopContainer>
      </InnerWrap> */}

      <MiddleContainer>
        <Grid container justify="center" alignItems="center">
          <ProductUL>
            <ProductLI>
              <ProductImg src={Provider1PNG}></ProductImg>
            </ProductLI>
            <ProductLI>
              <ProductImg src={Provider2PNG}></ProductImg>
            </ProductLI>
            <ProductLI>
              <ProductImg src={Provider3PNG}></ProductImg>
            </ProductLI>
            <ProductLI>
              <ProductImg src={Provider4PNG}></ProductImg>
            </ProductLI>
            <ProductLI>
              <ProductImg src={Provider5PNG}></ProductImg>
            </ProductLI>
            <ProductLI>
              <ProductImg src={Provider6PNG}></ProductImg>
            </ProductLI>
            <ProductLI>
              <ProductImg src={Provider7PNG}></ProductImg>
            </ProductLI>
            <ProductLI>
              <ProductImg src={Provider8PNG}></ProductImg>
            </ProductLI>
            <ProductLI>
              <ProductImg src={Provider9PNG}></ProductImg>
            </ProductLI>
            <ProductLI>
              <ProductImg src={Provider10PNG}></ProductImg>
            </ProductLI>
          </ProductUL>
        </Grid>
      </MiddleContainer>
      <BottomContainer>
        <CopyRightLine1>
          本网站属于（PAGCOR）Philippine Amusement and Gaming
          Corporation授权和监管
        </CopyRightLine1>
        <CopyRightLine2>
          {i18n.exists(`brand.${config.brand}.customBrand`)
            ? i18n.t(`brand.${config.brand}.customBrand`)
            : config.brand}{" "}
          Copyright© 2020{" "}
          {i18n.exists(`brand.${config.brand}.customBrand`)
            ? i18n.t(`brand.${config.brand}.customBrand`)
            : config.brand}{" "}
          All Rights Reserved
        </CopyRightLine2>
        <Version>
          Version <VersionNumber />
        </Version>
      </BottomContainer>
    </CustomizedContainer>
  );
}

export default withRouter(Footer);
