import { ApiService } from "./ApiService";

export const WalletService = {
  GetWalletDetails,
  GetWithdrawalDetails,
  Transfer,
  GetDepositChannel,
  GetDepositBonusScheme,
  Deposit,
  CancelDeposit,
  CheckDeposit,
  GetBankCardList,
  AddBankCard,
  Withdrawal,
  DeleteBankCard,
  WithdrawAll,
  GetQRCode,
  GetListOfWithdrawChannel,
  DoWithdraw,
  GetWalletBalance,
};
function GetWithdrawalDetails(token) {
  const result = ApiService.post(
    "member/forWithdrawal",
    {
      token: token,
    },
    true
  );

  return result;
}
function GetWalletDetails(token) {
  const result = ApiService.post(
    "member/getTransferThirdpartys",
    {
      token: token,
    },
    true
  );

  return result;
}
function Transfer(token, transferfrom, transferto, amount) {
  const result = ApiService.post(
    "member/transfer",
    {
      token,
      transferfrom,
      transferto,
      amount,
    },
    true
  );

  return result;
}
function WithdrawAll(token) {
  const result = ApiService.get(
    "member/oneKeyWithdrawalFromThirdparty?token=" + token
  );

  return result;
}
function GetDepositChannel(token) {
  const result = ApiService.get("member/getPayments?token=" + token);

  return result;
}
function Deposit(token, payment_agent_id, bank_id, amount, bosp_id) {
  const result = ApiService.post(
    "member/deposit",
    {
      token,
      payment_agent_id,
      bank_id,
      amount,
      bosp_id,
    },
    true
  );

  return result;
}
function CancelDeposit(token, id) {
  const result = ApiService.post(
    "member/depositCancel",
    {
      token,
      id,
    },
    true
  );

  return result;
}
function CheckDeposit(token, id) {
  const result = ApiService.post(
    "member/depositCheck",
    {
      token,
      id,
    },
    true
  );

  return result;
}
function GetDepositBonusScheme(token, amount) {
  const result = ApiService.post(
    "activity/getDepositActs",
    {
      token,
      amount,
    },
    true
  );

  return result;
}

function Withdrawal(token, bank_id, amount) {
  const result = ApiService.post(
    "member/withdrawal",
    {
      token,
      bank_id,
      amount,
    },
    true
  );

  return result;
}

function GetQRCode(url, cid, oid) {
  const result = ApiService.postExternal(
    url,
    {
      cid,
      oid,
    },
    true
  );

  return result;
}
function GetBankCardList() {
  const result = ApiService.get("bankinfo");

  return result;
}
function AddBankCard(channelid, bankType, detail) {
  const result = ApiService.post("bankinfo", {
    paymentchannelid: channelid,
    banktype: bankType,
    detail: detail,
  });

  return result;
}
function DeleteBankCard(bankInfoId) {
  const result = ApiService.del(
    "bankinfo",
    {
      bankInfoId,
    },
    true
  );

  return result;
}
//SWAGGER: Withdraw
function GetListOfWithdrawChannel() {
  const result = ApiService.get("withdraw");
  return result;
}
function DoWithdraw(amount, bankinfoid, paypassword) {
  const result = ApiService.post("withdraw", {
    amount,
    bankinfoid,
    paypassword,
  });

  return result;
}
function GetWalletBalance() {
  var result = ApiService.get("Wallet");
  return result;
} //SWAGGER: Deposit
