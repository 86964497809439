import { AffiliateService } from "../services/AffiliateService";

// These are the constants to represent each of three recommended actions for a single API fetch or post.
export const GET_AFFILIATE_BEGIN = "GET_AFFILIATE_BEGIN";
export const GET_AFFILIATE_SUCCESS = "GET_AFFILIATE_SUCCESS";
export const GET_AFFILIATE_FAILURE = "GET_AFFILIATE_FAILURE";

export const SET_AFFILIATE_BEGIN = "SET_AFFILIATE_BEGIN";
export const SET_AFFILIATE_SUCCESS = "SET_AFFILIATE_SUCCESS";
export const SET_AFFILIATE_FAILURE = "SET_AFFILIATE_FAILURE";

export const EDIT_AFFILIATE_BEGIN = "EDIT_AFFILIATE_BEGIN";
export const EDIT_AFFILIATE_SUCCESS = "EDIT_AFFILIATE_SUCCESS";
export const EDIT_AFFILIATE_FAILURE = "EDIT_AFFILIATE_FAILURE";

export const SUBMIT_MESSAGE_BEGIN = "SUBMIT_MESSAGE_BEGIN";
export const SUBMIT_MESSAGE_SUCCESS = "SUBMIT_MESSAGE_SUCCESS";
export const SUBMIT_MESSAGE_FAILURE = "SUBMIT_MESSAGE_FAILURE";

export const GET_CHANNEL_REPORT_BEGIN = "GET_CHANNEL_REPORT_BEGIN";
export const GET_CHANNEL_REPORT_SUCCESS = "GET_CHANNEL_REPORT_SUCCESS";
export const GET_CHANNEL_REPORT_FAILURE = "GET_CHANNEL_REPORT_FAILURE";

export const GET_OPERATIONAL_REPORT_BEGIN = "GET_OPERATIONAL_REPORT_BEGIN";
export const GET_OPERATIONAL_REPORT_SUCCESS = "GET_OPERATIONAL_REPORT_SUCCESS";
export const GET_OPERATIONAL_REPORT_FAILURE = "GET_OPERATIONAL_REPORT_FAILURE";

export const GET_PROMOTIONAL_REPORT_BEGIN = "GET_PROMOTIONAL_REPORT_BEGIN";
export const GET_PROMOTIONAL_REPORT_SUCCESS = "GET_PROMOTIONAL_REPORT_SUCCESS";
export const GET_PROMOTIONAL_REPORT_FAILURE = "GET_PROMOTIONAL_REPORT_FAILURE";

export const GET_DOWNLINE_REPORT_BEGIN = "GET_DOWNLINE_REPORT_BEGIN";
export const GET_DOWNLINE_REPORT_SUCCESS = "GET_DOWNLINE_REPORT_SUCCESS";
export const GET_DOWNLINE_REPORT_FAILURE = "GET_DOWNLINE_REPORT_FAILURE";

export const affiliateAction = {
  getAffiliateChannelList,
  setNewAffiliateChannel,
  changeAffiliateInformation,
  submitAffiliateMessage,
  getAffiliateChannelReport,
  getAffiliateOperationalReport,
  getAffiliatePromotionalReport,
  getAffiliateDownlineReport,
};
function getAffiliateChannelList(token, mainUrl) {
  return (dispatch) => {
    dispatch({
      type: GET_AFFILIATE_BEGIN,
      loading: true,
    });
    const result = AffiliateService.getChannelList(token, mainUrl)
      .then((response) => {
        if (response.IsSuccess) {
          localStorage.setItem("default", response.data[0].id);
          dispatch({
            type: GET_AFFILIATE_SUCCESS,
            payload: response,
            loading: false,
          });
        } else {
          dispatch({
            type: GET_AFFILIATE_FAILURE,
            payload: response,
            loading: false,
          });
        }
        return response;
      })
      .catch((error) => {
        dispatch({
          type: GET_AFFILIATE_FAILURE,
          payload: error,
          loading: false,
        });
      });
    return result;
  };
}

function setNewAffiliateChannel(token, channel) {
  return (dispatch) => {
    dispatch({
      type: EDIT_AFFILIATE_BEGIN,
      loading: true,
    });
    const result = AffiliateService.createNewChannel(token, channel)
      .then((response) => {
        if (response.IsSuccess) {
          dispatch({
            type: EDIT_AFFILIATE_SUCCESS,
            payload: response,
            loading: false,
          });
        } else {
          dispatch({
            type: EDIT_AFFILIATE_FAILURE,
            payload: response,
            loading: false,
          });
        }
        return response;
      })
      .catch((error) => {
        dispatch({
          type: EDIT_AFFILIATE_FAILURE,
          payload: error,
          loading: false,
        });
      });
    return result;
  };
}

function changeAffiliateInformation(
  token,
  qq,
  skype,
  promote_methods,
  biography
) {
  return (dispatch) => {
    dispatch({
      type: SET_AFFILIATE_BEGIN,
      loading: true,
    });
    const result = AffiliateService.changeAffiliateInfo(
      token,
      qq,
      skype,
      promote_methods,
      biography
    )
      .then((response) => {
        if (response.IsSuccess) {
          dispatch({
            type: SET_AFFILIATE_SUCCESS,
            payload: response,
            loading: false,
          });
        } else {
          dispatch({
            type: SET_AFFILIATE_FAILURE,
            payload: response,
            loading: false,
          });
        }
        return response;
      })
      .catch((error) => {
        dispatch({
          type: SET_AFFILIATE_FAILURE,
          payload: error,
          loading: false,
        });
      });
    return result;
  };
}

function submitAffiliateMessage(token, description) {
  return (dispatch) => {
    dispatch({
      type: SUBMIT_MESSAGE_BEGIN,
      loading: true,
    });
    const result = AffiliateService.submitMessage(token, description)
      .then((response) => {
        if (response.IsSuccess) {
          dispatch({
            type: SUBMIT_MESSAGE_SUCCESS,
            payload: response,
            loading: false,
          });
        } else {
          dispatch({
            type: SUBMIT_MESSAGE_FAILURE,
            payload: response,
            loading: false,
          });
        }
        return response;
      })
      .catch((error) => {
        dispatch({
          type: SUBMIT_MESSAGE_FAILURE,
          payload: error,
          loading: false,
        });
      });
    return result;
  };
}

function getAffiliateChannelReport(token, affiliate_id, start, end) {
  return (dispatch) => {
    dispatch({
      type: GET_CHANNEL_REPORT_BEGIN,
      loading: true,
    });
    const result = AffiliateService.getChannelReport(
      token,
      affiliate_id,
      start,
      end
    )
      .then((response) => {
        if (response.IsSuccess) {
          dispatch({
            type: GET_CHANNEL_REPORT_SUCCESS,
            payload: response,
            loading: false,
          });
        } else {
          dispatch({
            type: GET_CHANNEL_REPORT_FAILURE,
            payload: response,
            loading: false,
          });
        }
        return response;
      })
      .catch((error) => {
        dispatch({
          type: GET_CHANNEL_REPORT_FAILURE,
          payload: error,
          loading: false,
        });
      });
    return result;
  };
}

function getAffiliateOperationalReport(
  token,
  affiliate_id,
  channel_list,
  start,
  end,
  page,
  limit
) {
  return (dispatch) => {
    dispatch({
      type: GET_OPERATIONAL_REPORT_BEGIN,
      loading: true,
    });
    const result = AffiliateService.getOperationReport(
      token,
      affiliate_id,
      channel_list,
      start,
      end,
      page,
      limit
    )
      .then((response) => {
        if (response.IsSuccess) {
          dispatch({
            type: GET_OPERATIONAL_REPORT_SUCCESS,
            payload: response,
            loading: false,
          });
        } else {
          dispatch({
            type: GET_OPERATIONAL_REPORT_FAILURE,
            payload: response,
            loading: false,
          });
        }
        return response;
      })
      .catch((error) => {
        dispatch({
          type: GET_OPERATIONAL_REPORT_FAILURE,
          payload: error,
          loading: false,
        });
      });
    return result;
  };
}

function getAffiliatePromotionalReport(
  token,
  affiliate_id,
  channel_list,
  start,
  end,
  page,
  limit
) {
  return (dispatch) => {
    dispatch({
      type: GET_PROMOTIONAL_REPORT_BEGIN,
      loading: true,
    });
    const result = AffiliateService.getPromotionReport(
      token,
      affiliate_id,
      channel_list,
      start,
      end,
      page,
      limit
    )
      .then((response) => {
        if (response.IsSuccess) {
          dispatch({
            type: GET_PROMOTIONAL_REPORT_SUCCESS,
            payload: response,
            loading: false,
          });
        } else {
          dispatch({
            type: GET_PROMOTIONAL_REPORT_FAILURE,
            payload: response,
            loading: false,
          });
        }
        return response;
      })
      .catch((error) => {
        dispatch({
          type: GET_PROMOTIONAL_REPORT_FAILURE,
          payload: error,
          loading: false,
        });
      });
    return result;
  };
}

function getAffiliateDownlineReport(
  token,
  affiliate_id,
  channel_id,
  start,
  end,
  page,
  limit
) {
  return (dispatch) => {
    dispatch({
      type: GET_DOWNLINE_REPORT_BEGIN,
      loading: true,
    });
    const result = AffiliateService.getDownlineReport(
      token,
      affiliate_id,
      channel_id,
      start,
      end,
      page,
      limit
    )
      .then((response) => {
        if (response.IsSuccess) {
          dispatch({
            type: GET_DOWNLINE_REPORT_SUCCESS,
            payload: response,
            loading: false,
          });
        } else {
          dispatch({
            type: GET_DOWNLINE_REPORT_FAILURE,
            payload: response,
            loading: false,
          });
        }
        return response;
      })
      .catch((error) => {
        dispatch({
          type: GET_DOWNLINE_REPORT_FAILURE,
          payload: error,
          loading: false,
        });
      });
    return result;
  };
}
