import React, { Component } from "react";
import {
  CustomContainer,
  InnerContainer,
  ReportContainer,
  StyledTableCell,
  VerificationButton,
  Content,
} from "../../Share/General";
import Header from "../Account/Header";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import { withStyles } from "@material-ui/core";

import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { connect } from "react-redux";
import { AffiliateService } from "../../../services/AffiliateService";
import { withRouter } from "react-router-dom";
import styled from "styled-components";
import { Row, Col } from "react-grid-system";

const ContentWrap = styled.div`
  height: 38px;
  font-size: 14px;
  width: 100%;
  display: flex;
  margin-left: -15px;
  align-items: center;
`;
const ContentLabel = styled.div`
  color: #747474;
  float: left;
  width: 70px;
  margin-left: 35px;
`;

const CustomLabel = styled(ContentLabel)`
  margin-left: 0px;
  font-weight: bold;
`;
const ContentValue = styled.div`
  color: #16242a;
  float: left;
  width: auto;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const BackButon = styled(VerificationButton)`
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: 0px !important;
  background: #c0252d;
  color: white;
  font-size: 14px;
  &:hover {
    background: rgba(192, 37, 45, 0.8);
  }
  &:focus {
    background: rgba(192, 37, 45, 0.8);
  }
  &:active {
    background: rgba(192, 37, 45, 0.8);
  }
`;

const CustomFormControl = withStyles((theme) => ({
  root: {
    width: "140px",
    height: "38px",

    marginRight: "5px",
    float: "left",
    backgroundColor: "#fff",
    padding: 0,
    "&:before": {
      borderRight: "1px solid #b6b6b6",
      content: '"\\00a0"',
      position: "absolute",
      top: "1px",
      right: "36px",
      zIndex: " 1",
      display: "block",
      height: "100%",
    },
    "& label.Mui-focused": {
      color: "#b6b6b6",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#b6b6b6",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#b6b6b6",
        marginTop: "1px",
      },
      "&:hover fieldset": {
        borderColor: "#b6b6b6",
        marginTop: "1px",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#b6b6b6",
        marginTop: "1px",
      },
    },
  },
}))(FormControl);

const CustomSelect = withStyles((theme) => ({
  root: {
    fontSize: "14px",

    color: "#818181",
  },
}))(Select);

const Display = styled(Col)``;

const levelList = [
  { type: 1, name: "直属玩家" },
  { type: 2, name: "第二层" },
  { type: 3, name: "第三层" },
  { type: 4, name: "第四层" },
  { type: 5, name: "第五层" },
];

class PreDividendDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      list: [],
      type: this.props.match.params.type,
      level: this.props.match.params.level,
      month: this.props.match.params.month,
    };
  }

  changeLabelTextBasedOnType = (type) => {
    switch (type) {
      case "1":
        return "体育";
      case "2":
        return "彩票";
      case "3":
        return "真人";
      case "4":
        return "棋牌";
      case "5":
        return "小游戏";
      case "6":
        return "视频";
      default:
        return "体育";
    }
  };

  componentDidMount() {
    this.changeDividendListDetail(this.state.type, this.state.level);
  }

  // componentDidUpdate() {
  //   this.changeDividendListDetail(this.state.type, this.state.level);
  // }

  changeDividendListDetail = async (type, level) => {
    await AffiliateService.getPreDividendDetail(
      this.props.token,
      type,
      level
    ).then((response) => {
      if (response.IsSuccess) {
        this.setState({
          data: response.data,
          list: response.data.datas,
        });
      }
    });
  };

  changeLevel = (e) => {
    this.setState({
      level: e.target.value,
    });
    this.changeDividendListDetail(this.state.type, this.state.level);
    return this.props.history.push(
      "/report/dividend/" +
        this.state.month +
        "/" +
        this.state.type +
        "/" +
        e.target.value
    );
  };

  render() {
    return (
      <CustomContainer>
        <InnerContainer>
          <Header />
          <ReportContainer>
            <Content>
              <Row>
                <Col xl={9}>
                  <Row>
                    <Col>
                      <Display>
                        <ContentWrap>
                          <CustomLabel>代理 :</CustomLabel>
                          <ContentValue>
                            {this.state.data.member_username}
                          </ContentValue>
                        </ContentWrap>
                      </Display>
                      <Display>
                        <ContentWrap>
                          <CustomLabel>月活人数:</CustomLabel>
                          <ContentValue>{this.state.type}</ContentValue>
                        </ContentWrap>
                      </Display>
                    </Col>
                    <Col>
                      <Display>
                        <ContentWrap>
                          <CustomLabel>月份:</CustomLabel>
                          <ContentValue>{this.state.month}</ContentValue>
                        </ContentWrap>
                      </Display>
                      <Display>
                        <ContentWrap>
                          <CustomLabel>净盈利:</CustomLabel>
                          <ContentValue>{this.state.data.profit}</ContentValue>
                        </ContentWrap>
                      </Display>
                    </Col>
                    <Col>
                      <Display>
                        <ContentWrap>
                          <CustomLabel>类型:</CustomLabel>
                          <ContentValue>
                            {this.changeLabelTextBasedOnType(this.state.type)}
                          </ContentValue>
                        </ContentWrap>
                      </Display>
                      <Display>
                        <ContentWrap>
                          <CustomLabel>层级:</CustomLabel>
                          <ContentValue>
                            <CustomFormControl>
                              <CustomSelect
                                native
                                variant="outlined"
                                displayEmpty
                                value={this.state.level}
                                onChange={this.changeLevel}
                              >
                                {levelList.map((data, index) => {
                                  return (
                                    <option value={data.type}>
                                      {data.name}
                                    </option>
                                  );
                                })}
                              </CustomSelect>
                            </CustomFormControl>
                          </ContentValue>
                        </ContentWrap>
                      </Display>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <BackButon to={"/report/dividend"}>返回</BackButon>
              <Table aria-label="customized table">
                <TableHead>
                  <TableRow>
                    <StyledTableCell>用户名</StyledTableCell>
                    <StyledTableCell>产品盈亏</StyledTableCell>
                    <StyledTableCell>红利</StyledTableCell>
                    <StyledTableCell>反水</StyledTableCell>
                    <StyledTableCell>调账</StyledTableCell>
                    <StyledTableCell>存提款手续费</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {this.state.list.map((data, index) => {
                    return (
                      <TableRow key={index}>
                        <StyledTableCell>
                          {data.member_username}
                        </StyledTableCell>
                        <StyledTableCell>{data.contribution}</StyledTableCell>
                        <StyledTableCell>{data.bonus}</StyledTableCell>
                        <StyledTableCell>{data.rebate}</StyledTableCell>
                        <StyledTableCell>{data.adjustment}</StyledTableCell>
                        <StyledTableCell>{data.bank_fee}</StyledTableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </Content>
          </ReportContainer>
        </InnerContainer>
      </CustomContainer>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    token: state.account.token,
  };
};

export default withRouter(connect(mapStateToProps, null)(PreDividendDetail));
