import {
  LOGIN_BEGIN,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  LOGOUT_BEGIN,
  LOGOUT_SUCCESS,
  LOGOUT_FAILURE,
  GETACCOUNT_BEGIN,
  GETACCOUNT_SUCCESS,
  GETBALANCE_SUCCESS,
  GETACCOUNT_FAILURE,
  CHECKTOKEN_SUCCESS,
  GETVIPINFO_SUCCESS,
  GETVIPINFO_FAILURE,
  UPDATE_INFO,
  CHECKTOKEN_BEGIN,
  GETMESSAGE_SUCCESS,
  CREATEAGENT_BEGIN,
  CREATEAGENT_SUCCESS,
  CREATEAGENT_FAILURE,
  RESET_RESULT,
} from "../actions/accountAction";
import { isMobile } from "react-device-detect";
const initialState = {
  pending: false,
  info: {
    avatar: "",
    agentlevel: "",
    accumulatedprofitsharing: "",
    dividendpercentage: "",
    balance: 0,
    invitationCode: "",
    member_card_no: "",
    member_id: 0,
    member_level_id: 1,
    member_name: "",
    member_username: "",
    member_birthday: "",
    member_email: "",
    member_phone: "",
    member_safe_password: "",
    member_address: "",
    member_address_surename: "",
    member_address_phone: "",
    member_question: "",
    can_withdrawal: "",
    bonus_num: "",
    message_num: "",
    check_pass_account: "",
    check_pass_email: "",
    playernumber: 0,
    check_pass_phone: "",
    month_vip: {
      id: 0,
      curr_title: "",
      curr_owned_point: "",
      next_title: "",
      next_point: "",
      require_point: "",
      vip_progress_bar: 0,
    },
    year_vip: {
      id: 0,
      curr_title: "",
      curr_owned_point: "",
      next_title: "",
      next_point: "",
      require_point: "",
      vip_progress_bar: 0,
    },
    token: "",
  },
  vip: {
    member: "",
    next_vip_title: "",
    owned_point: "0",
    next_point: "0",
    require_point: "0",
    vip_progress_bar: 0,
    curr_vip: {
      id: 0,
      title: "",
      upgrade_gift_amount: "0.00",
      upgrade_gift_multiple: "0.00",
      birthday_gift_amount: "0.00",
      birthday_gift_multiple: "0.00",
      topped_gift_amount: "0.00",
      topped_gift_multiple: "0.00",
      deposit_bonus: "0.00",
      deposit_bonus_limit: "0.00",
      deposit_bonus_multiple: "0.00",
      rescue_amount: "0.00",
      rescue_amount_limit: "0.00",
      rescue_amount_multiple: "0.00",
      free_withdrawal_num: 0,
    },
    next_vip: {
      id: 0,
      title: "",
      upgrade_gift_amount: "0.00",
      upgrade_gift_multiple: "0.00",
      birthday_gift_amount: "0.00",
      birthday_gift_multiple: "0.00",
      topped_gift_amount: "0.00",
      topped_gift_multiple: "0.00",
      deposit_bonus: "0.00",
      deposit_bonus_limit: "0.00",
      deposit_bonus_multiple: "0.00",
      rescue_amount: "0.00",
      rescue_amount_limit: "0.00",
      rescue_amount_multiple: "0.00",
      free_withdrawal_num: 0,
    },
  },
  inbox: {
    list: [],
    count: 0,
  },
  createAgentResult: null,
  token: null,
  error: null,
  loading: false,
  dividendconditions: [],
  agentdomain: [],
  agentdividendtype: "",
};
function mapToAccountState(state, data) {
  return {
    ...state.info,
    avatar: data.avatar_url_text,
    balance: data.balance,
    member_last_login: "",
    member_id: data.id,
    member_level_id: data.agentlevel,
    member_name: data.realname,
    playernumber: data.playernumber,
    member_username: data.username,
    member_parent: data.parentusername,
    member_type: data.type,
    member_leveltype: data.leveltype,
    member_status: data.status,
    check_pass_phone: data.phone === null ? false : true,
    check_pass_email: data.email === null ? false : true,
    check_pass_account: data.realname === null ? false : true,
    member_phone: data.phone,
    member_email: data.email === null ? "" : data.email,
    member_safe_password: data.issetpaypassword === true ? "set" : "",
    member_qq: data.qq,
    member_wechat: data.wechat,
    invitationCode: data.invitationCode,
    agentlevel: data.agentlevel,
    accumulatedprofitsharing: data.accumulatedprofitsharing,
    dividendpercentage: data.dividendpercentage,
    createLevelCount: data.dividendconditions.length,
    dividendconditions: data.dividendconditions,
    agentdomain: data.agentdomains,
    registeredon: data.registeredon,
    agentdividendtype: data.agentdividendtype,
  };
}
const accountReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_INFO:
      return {
        ...state,
        info: { ...state.info, ...action.payload },
      };
    case LOGIN_SUCCESS:
      return {
        ...state,
        token: action.payload.data.token,
        info: {
          token: action.payload.data.token,
        },
        loading: false,
      };

    case CHECKTOKEN_SUCCESS:
      return {
        ...state,
        token: localStorage.getItem("token" + (isMobile ? 1 : 0)),
        info: {
          ...mapToAccountState(state, action.payload.data),
          token: localStorage.getItem("token" + (isMobile ? 1 : 0)),
        },
        loading: false,
      };
    case GETACCOUNT_SUCCESS:
      return {
        ...state,
        token: localStorage.getItem("token" + (isMobile ? 1 : 0)),
        info: {
          ...mapToAccountState(state, action.payload.data),
          token: localStorage.getItem("token" + (isMobile ? 1 : 0)),
        },
        loading: false,
      };
    case GETBALANCE_SUCCESS:
      return {
        ...state,
        info: {
          ...state.info,
          balance: action.payload.data.balance,
          can_withdrawal: action.payload.data.can_withdrawal,
          limit_withdrawal: action.payload.data.limit_withdrawal,
          member_level_id: action.payload.data.member_level_id,
          message_num: action.payload.data.message_num,
        },
        loading: action.loading,
      };
    case GETVIPINFO_SUCCESS:
      return {
        ...state,
        vip: { ...state.vip, ...action.payload.data },
      };
    case LOGOUT_FAILURE:
    case LOGIN_FAILURE:
    case GETACCOUNT_FAILURE:
    case GETVIPINFO_FAILURE:
      return {
        ...state,
        error: action.payload,
      };

    case LOGIN_BEGIN:
    case LOGOUT_BEGIN:
    case CHECKTOKEN_BEGIN:
    case GETACCOUNT_BEGIN:
      return { ...state, loading: action.loading };

    case LOGOUT_SUCCESS:
      return {
        ...state,
        token: null,
        info: initialState.info,
        loading: action.loading,
      };
    case "UNAUTHORIZE":
      return {
        ...state,
        token: null,
        info: initialState.info,
      };
    case GETMESSAGE_SUCCESS:
      return {
        ...state,
        inbox: {
          list: action.isNew
            ? action.payload.data.datas
            : state.inbox.list.concat(action.payload.data.datas),
          count: action.payload.data.total,
        },
      };
    case CREATEAGENT_BEGIN:
      return { ...state, loading: true, createAgentResult: null };
    case CREATEAGENT_SUCCESS:
    case CREATEAGENT_FAILURE:
      return { ...state, loading: false, createAgentResult: action.payload };
    case RESET_RESULT:
      return {
        ...state,
        createAgentResult: null,
      };
    default:
      return state;
  }
};
export default accountReducer;
// export const userLoginSuccess = state => state.user;
// export const userLoginPending = state => state.pending;
// export const userLoginError = state => state.error;
