import React from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import i18n from "../../../../utils/i18n";
import { withSnackbar } from "notistack";
import {
  FullSizeContainer,
  Content,
  TitleContainer,
  Title,
} from "../../../Share/General";

import { Grid } from "@material-ui/core";
import moment from "moment";
import Common from "../../../../utils/Common";

const TitleAndAction = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
`;

const CustomDataRow = styled.div`
  display: grid;
  grid-template-columns: 25% auto;
  padding: 7.5px 10px;
`;

const CustomDataTitle = styled.div`
  font-weight: bold;
  font-size: 12px;
  display: flex;
  height: 100%;
  align-items: flex-start;
`;

const CustomDataContent = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
`;

const AgentDetail = (props) => {
  const processedData = [
    {
      title: i18n.t("account.agentInfo.agentDetails.username"),
      data: props.account.info.member_username,
    },

    {
      title: i18n.t("account.agentInfo.agentDetails.realname"),
      data: props.account.info.member_name,
    },
    // {
    //   title: i18n.t("account.agentInfo.agentDetails.agentlevel"),
    //   data: props.account.info.member_level_id,
    // },
    {
      title: i18n.t("account.agentInfo.agentDetails.registeredon"),
      data: moment(props.account.info.registeredon).format(
        "YYYY-MM-DD HH:mm:ss"
      ),
    },
    {
      title: i18n.t("account.agentInfo.agentDetails.balance"),
      data: Common.formatNumberWithCommasNoRounding(props.account.info.balance),
    },

    {
      title: i18n.t("account.agentInfo.agentDetails.status"),
      data: i18n.t(
        `custom.affiliateDropdown.status.` + props.account.info.member_status
      ),
    },
    {
      title: i18n.t("account.agentInfo.agentDetails.category"),
      data: i18n.t(`custom.membertype.` + props.account.info.member_leveltype),
    },
    {
      title: i18n.t("account.agentInfo.agentDetails.type"),
      data: i18n.t(`custom.type.` + props.account.info.member_type),
    },
    {
      title: i18n.t("report.agentDividendPercentage"),
      data: props.account.info.dividendpercentage + "%",
    },
    //  {
    //    title: i18n.t("account.agentInfo.agentDetails.accumulatedprofitsharing"),
    //    data: Common.formatNumberWithCommasNoRounding(
    //      props.account.info.accumulatedprofitsharing
    //    ),
    //  },
    // {
    //   title: i18n.t("account.agentInfo.agentDetails.parentusername"),
    //   data: props.account.info.member_parent,
    // },
    {
      title: i18n.t("account.createAgent.email"),
      data: props.account.info.member_email,
    },
    {
      title: i18n.t("account.createAgent.phone"),
      data: props.account.info.member_phone,
    },

    { title: "QQ", data: props.account.info.member_qq },

    { title: "WeChat", data: props.account.info.member_wechat },
  ];

  return (
    <FullSizeContainer>
      <Content>
        <TitleContainer>
          <Grid container alignItems="flex-end">
            <TitleAndAction>
              <Title> {i18n.t("account.menu.agentDetail")}</Title>
            </TitleAndAction>
          </Grid>
        </TitleContainer>
        <div>
          {processedData.map((data, index) => {
            return (
              <CustomDataRow key={index}>
                <CustomDataTitle>{data.title}</CustomDataTitle>
                <CustomDataContent>{data.data}</CustomDataContent>
              </CustomDataRow>
            );
          })}
        </div>
      </Content>
    </FullSizeContainer>
  );
};

const mapStateToProps = (state) => {
  return {
    account: state.account,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withSnackbar(AgentDetail));
