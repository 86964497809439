import React, { useEffect, useState, useContext } from "react";

import Header from "../Account/Header";
import {
  CustomContainer,
  ReportContainer,
  StyledTableCell,
  FilterContainer,
  TitleContainer,
  Title,
  Content,
  CustomQuickButton,
} from "../../Share/General";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";

import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TablePagination from "@material-ui/core/TablePagination";
import ReportDatepicker from "./ReportDatepicker";
import { Container } from "@material-ui/core";
import { affiliateAction } from "../../../actions/affiliateAction";
import { connect } from "react-redux";
import { FunctionContext } from "../../Context/FunctionContext";

const ChannelReport = (props) => {
  const [page, setPage] = useState(0);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  useEffect(() => {
    getChannelReportList(selectedDateFrom, selectedDateTo, 1);
  }, []);

  const { selectedDateFrom, selectedDateTo } = useContext(FunctionContext);

  const [result, setResult] = useState();
  const getChannelReportList = async (dateFrom, dateTo) => {
    const result = await props.getAffiliateChannelReport(
      props.token,
      //TODO: remove hardcoded stuffs
      props.data.member_id,
      dateFrom,
      dateTo
    );
    if (result.IsSuccess) {
      setResult(result.data);
    }
  };

  const getUserSelectedQuery = (dateFrom, dateTo) => {
    getChannelReportList(dateFrom, dateTo);
  };

  return (
    <CustomContainer>
      <Container maxWidth="lg">
        <Header />
        <ReportContainer>
          <Content>
            <TitleContainer>
              <Title>渠道报表</Title>
            </TitleContainer>
            <FilterContainer>
              <ReportDatepicker></ReportDatepicker>
              {
                //   <CustomFormControl>
                //   <Select native variant="outlined">
                //     <option value={0}>全部</option>
                //     <option value={3}>红利</option>
                //  {
                //    //   <option value={4}>转入</option>
                //  }
                //     <option value={5}>转出</option>
                //     <option value={6}>调账</option>
                //
                //     <option value={99}>提款</option>
                //     <option value={100}>存款</option>
                //   </Select>
                // </CustomFormControl>
              }
              <CustomQuickButton
                onClick={() =>
                  getUserSelectedQuery(selectedDateFrom, selectedDateTo)
                }
              >
                查询
              </CustomQuickButton>
            </FilterContainer>
            <Table aria-label="customized table">
              <TableHead>
                <TableRow>
                  <StyledTableCell>序号</StyledTableCell>
                  <StyledTableCell>渠道名称</StyledTableCell>
                  <StyledTableCell>存款总额</StyledTableCell>
                  <StyledTableCell>提款总额</StyledTableCell>
                  <StyledTableCell>红利总额</StyledTableCell>
                  <StyledTableCell>客户调账</StyledTableCell>
                  <StyledTableCell>客户流水</StyledTableCell>
                  <StyledTableCell>提款客户数</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {result
                  ? result.datas.map((data, index) => {
                      return (
                        <TableRow>
                          <StyledTableCell>{index + 1}</StyledTableCell>
                          <StyledTableCell>{data.channel_name}</StyledTableCell>
                          <StyledTableCell>{data.deposit}</StyledTableCell>
                          <StyledTableCell>{data.withdraw}</StyledTableCell>
                          <StyledTableCell>{data.bonus}</StyledTableCell>
                          <StyledTableCell>{data.adjustment}</StyledTableCell>
                          <StyledTableCell>{data.turnover}</StyledTableCell>
                          <StyledTableCell>{data.withdraw_num}</StyledTableCell>
                        </TableRow>
                      );
                    })
                  : ""}
              </TableBody>
            </Table>
            <TablePagination
              rowsPerPageOptions={[10]}
              component="div"
              count={result ? result.total : ""}
              rowsPerPage={10}
              page={page}
              backIconButtonProps={{
                "aria-label": "previous page",
              }}
              nextIconButtonProps={{
                "aria-label": "next page",
              }}
              onChangePage={handleChangePage}
            />
          </Content>
        </ReportContainer>
      </Container>
    </CustomContainer>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    getAffiliateChannelReport: (token, affiliate_id, start, end) =>
      dispatch(
        affiliateAction.getAffiliateChannelReport(
          token,
          affiliate_id,
          start,
          end
        )
      ),
  };
};
const mapStateToProps = (state) => {
  return {
    token: state.account.token,
    data: state.account.info,
    state,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ChannelReport);
