import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import i18n from "../../../../utils/i18n";
import { useSnackbar, withSnackbar } from "notistack";
import {
  FullSizeContainer,
  Content,
  TitleContainer,
  Title,
  StyledTableCell,
  StyledTableCellRight,
  NoDataContainer,
} from "../../../Share/General";

import {
  Grid,
  Table,
  TableRow,
  TableBody,
  TableHead,
  TableContainer,
  Dialog,
} from "@material-ui/core";
import { AffiliateService } from "../../../../services/AffiliateService";

import moment from "moment";

import CustomPagination from "../../../Share/CustomPagination";
import { addDays, isFirstDayOfMonth } from "date-fns";
import Common from "../../../../utils/Common";
const CustomSelect = styled.select`
  height: 40px;
  border: solid 1px #b6b6b6;
  border-radius: 4px;
  display: flex;
  align-items: center;
  padding: 0px 8px;
  width: 110px;
  @media (max-width: 600px) {
    width: 100%;
    margin-right: 0px;
    margin-bottom: 4px;
  }
`;

const CustomTableRow = styled(TableRow)`
  :hover {
    background: rgba(0, 0, 0, 0.05);
    cursor: pointer;
  }
`;

const TitleAndAction = styled.div`
  display: flex;
  align-items: flex-start;
  width: 100%;
  justify-content: space-between;
  @media (max-width: 600px) {
    flex-direction: column;

    max-width: 100%;
  }
`;

const ContainerForInput = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-top: 10px;

  @media (max-width: 600px) {
    margin-top: 8px;
    padding-top: 0px;
    width: 100%;
    justify-content: flex-start;
    flex-direction: column;
  }
`;

const FlexRow = styled.div`
  display: flex;

  @media (max-width: 600px) {
    flex-direction: column;
    width: 100%;
  }
`;

const CustomButton = styled.div`
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  border: solid 1px #b6b6b6;
  padding: 0 8px;
  background-image: linear-gradient(
    to top,
    ${(props) => props.theme.primaryGradient},
    ${(props) => props.theme.primary}
  );
  color: ${(props) => props.theme.primaryText};
  margin-left: 5px;

  min-width: 75px;
  cursor: pointer;
  user-select: none;

  @media (max-width: 600px) {
    width: 100%;
    margin: 4px 0px;
  }
`;

const CustomTextField = styled.input`
  height: 38px;
  border: solid 1px #b6b6b6;
  border-radius: 4px;
  margin-right: 8px;
  display: flex;
  align-items: center;
  padding: 0px 8px;
  margin-left: 5px;
  max-width: 110px;
  @media (max-width: 600px) {
    max-width: 100%;
    padding: 0 8px;
    margin: 4px 0px;
  }

  ::placeholder {
    color: #000;
    opacity: 0.25;
  }
`;

const PlayerBalanceContainer = styled.div`
  padding: 2px;
`;

const PlayerBalanceButton = styled.div`
  border: solid 1px #b6b6b6;
  border-radius: 4px;
`;

const CustomDialog = styled(Dialog)``;

const DialogContainer = styled.div`
  min-width: 300px;
`;

const DialogTitle = styled.div`
  font-size: 24px;
  font-weight: 600;
  padding: 8px 16px;
`;

const DialogContent = styled.div`
  padding: 8px 16px 16px 16px;
  display: grid;
  grid-template-columns: auto auto;
  gap: 10px;
`;

const DialogAction = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 16px;
  background: #f5f5f5;
`;

const CloseButton = styled.div`
  border-radius: 4px;
  padding: 10px 20px;
  background-image: linear-gradient(
    to top,
    ${(props) => props.theme.primaryGradient},
    ${(props) => props.theme.primary}
  );
  color: ${(props) => props.theme.primaryText};

  min-width: 75px;
  cursor: pointer;
  user-select: none;
  border: solid 1px #b6b6b6;
`;

const PlayerList = () => {
  const [sUsername, setSUsername] = useState("");
  const [sDomain, setSDomain] = useState("");
  const [sVIP, setSVIP] = useState("");
  const [playerList, setplayerList] = useState([]);
  const [vipLevel, setvipLevel] = useState([]);
  const [status, setStatus] = useState([]);
  const [selectedType, setSelected] = useState("");

  const [currentType, setCurrentType] = useState("");

  var MAX = 10;

  const [page, setPage] = useState(1);

  const [dateFrom, setDateFrom] = useState(
    moment(addDays(new Date(), -3)).format("YYYY/MM/DD")
  );
  const [dateTo, setDateTo] = useState(moment(new Date()).format("YYYY/MM/DD"));

  function handleNextPage() {
    if (Math.ceil(playerList.totalcount / 10) > page) {
      setPage(page + 1);
    } else {
    }
  }

  function dateTimeFormatter(date) {
    return moment(date).format("YYYY-MM-DD HH:mm:ss").toString();
  }

  function handlePrevPage() {
    if (page <= 1) {
    } else {
      setPage(page - 1);
    }
  }

  useEffect(() => {
    if (playerList.length !== 0) {
      performSearch();
    }
  }, [page]);

  useEffect(() => {
    AffiliateService.getPlayerStatus().then((e) => {
      if (e.IsSuccess) {
        setStatus(e.data);
      }
    });
    AffiliateService.getVipLevels().then((e) => {
      if (e.IsSuccess) {
        setvipLevel(e.data);
      }
    });
  }, []);

  useEffect(() => {
    if (playerList.length === 0) {
      //default state, get everything.
      performGetPlayerList(
        dateFrom,
        dateTo,
        sDomain,
        sUsername,
        selectedType,
        sVIP,
        page
      );
    }
  }, [playerList]);

  function performGetPlayerList(
    dateFrom,
    dateTo,
    domain,
    status,
    username,
    vip,
    page
  ) {
    AffiliateService.getPlayerList(
      "",
      "",
      domain,
      status,
      username,
      vip,
      page,
      MAX,
      true,
      "",
      false
    ).then((e) => {
      if (e.IsSuccess) {
        setplayerList(e.data);
      }
    });
  }

  const { enqueueSnackbar } = useSnackbar();

  function performSearch() {
    var newDateTo = dateTo;
    if (isFirstDayOfMonth(new Date(dateTo))) {
      newDateTo = moment(new Date(dateTo))
        .subtract(1, "days")
        .format("YYYY/MM/DD");
    }
    var addDateTo = moment(new Date(dateTo))
      .add(1, "days")
      .format("YYYY/MM/DD");
    if (Common.maxDayRangeDetector(dateFrom, addDateTo)) {
      enqueueSnackbar(i18n.t("common.cannotExceed31day"), {
        variant: "warning",
      });
    } else {
      performGetPlayerList(
        dateFrom,
        dateTo,
        sDomain,
        selectedType,
        sUsername,
        sVIP,
        page
      );
    }
  }

  const GetBalance = ({ data }) => {
    const [show, setShow] = useState(false);
    const [balance, setBalance] = useState("");
    const [withdrawAmount, setWithdrawAmount] = useState("");

    const triggerGetBalance = () => {
      AffiliateService.getPlayerBalance(data.playercode).then((e) => {
        if (e.IsSuccess) {
          if (typeof e.data === "object") {
            setBalance(e.data.data);
          } else {
            setBalance(e.data);
          }
        }
      });

      AffiliateService.getPlayerWithdrawAmount(data.playercode).then((e) => {
        if (e.IsSuccess) {
          if (typeof e.data === "object") {
            setWithdrawAmount(e.data.data);
          } else {
            setWithdrawAmount(e.data);
          }
        }
      });
    };

    return (
      <>
        <PlayerBalanceContainer
          onClick={() => {
            setShow(true);
            balance === "" && triggerGetBalance();
          }}
        >
          <PlayerBalanceButton>
            {i18n.t("account.agentInfo.agentDetails.balance")}
          </PlayerBalanceButton>
        </PlayerBalanceContainer>

        <CustomDialog
          open={show}
          onClose={() => {
            setShow(false);
          }}
        >
          <DialogContainer>
            <DialogTitle>
              {i18n.t("account.agentInfo.agentDetails.balance")}
            </DialogTitle>
            <DialogContent>
              <div>{i18n.t("common.username")}:</div>
              <div>{data.username}</div>
              <div>{i18n.t("common.balance")}:</div>
              <div>{balance}</div>
              <div>{i18n.t("common.turnover")}:</div>
              {<div>{withdrawAmount}</div>}
            </DialogContent>
            <DialogAction>
              <CloseButton
                primary
                onClick={() => {
                  setShow(false);
                }}
              >
                {i18n.t("common.close")}
              </CloseButton>
            </DialogAction>
          </DialogContainer>
        </CustomDialog>
      </>
    );
  };

  return (
    <FullSizeContainer>
      <Content>
        <TitleContainer>
          <Grid container alignItems="flex-end">
            <TitleAndAction>
              <Title> {i18n.t("player.title")}</Title>
              <ContainerForInput>
                <FlexRow>
                  <CustomSelect
                    onChange={(e) => {
                      setSelected(e.target.value);
                    }}
                  >
                    <option selected disabled value="">
                      {i18n.t("player.status.title")}
                    </option>
                    {Object.keys(status).map(function (key, index) {
                      return (
                        <option value={key}>
                          {i18n.t(`player.status.` + status[key])}
                        </option>
                      );
                    })}
                  </CustomSelect>
                </FlexRow>
                <FlexRow>
                  <CustomTextField
                    type="text"
                    value={sUsername}
                    placeholder={i18n.t("player.username")}
                    onChange={(e) => {
                      setSUsername(e.target.value);
                    }}
                  />
                </FlexRow>
                <FlexRow>
                  <CustomButton
                    onClick={() => {
                      setCurrentType(selectedType);
                      if (selectedType !== currentType) {
                        performSearch();
                        setPage(1);
                      }
                    }}
                  >
                    {i18n.t("report.search")}
                  </CustomButton>
                </FlexRow>
              </ContainerForInput>
            </TitleAndAction>

            <ContainerForInput>
              {
                //     <CustomTextField
                //       type="text"
                //       value={sDomain}
                //       placeholder={i18n.t("player.registeredDomain")}
                //       onChange={(e) => {
                //         setSDomain(e.target.value);
                //       }}
                //     />
                //   </FlexRow>
                //   <FlexRow>
                //     <CustomSelect
                //       onChange={(e) => {
                //         setSVIP(e.target.value);
                //       }}
                //     >
                //       <option selected disabled value="">
                //         {i18n.t("player.vipLevel")}
                //       </option>
                //       {vipLevel.map((data, index) => {
                //         return (
                //           <option key={index} value={data.id}>
                //             VIP {data.level}
                //           </option>
                //         );
                //       })}
                //     </CustomSelect>
                //     <CustomSelect
                //       onChange={(e) => {
                //         setSelected(e.target.value);
                //       }}
                //     >
                //       <option selected disabled value="">
                //         {i18n.t("player.status.title")}
                //       </option>
                //       {Object.keys(status).map(function (key, index) {
                //         return (
                //           <option value={key}>
                //             {i18n.t(`player.status.` + status[key])}
                //           </option>
                //         );
                //       })}
                //     </CustomSelect>
                //   </FlexRow>  <FlexRow>
                //      <ReportDatepicker
                //      selectedDateFrom={dateFrom}
                //      selectedDateTo={dateTo}
                //      handleDateTo={handleDateTo}
                //      handleDateFrom={handleDateFrom}
                //    />
                //  </FlexRow>
                //  <FlexRow>
                //    <CustomButton
                //      onClick={() => {
                //        performSearch();
                //      }}
                //    >
                //      {i18n.t("report.search")}
                //    </CustomButton>
                //  </FlexRow>
              }
            </ContainerForInput>
          </Grid>
        </TitleContainer>
        <TableContainer>
          <Table aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell>{i18n.t("player.username")}</StyledTableCell>
                <StyledTableCellRight>
                  {i18n.t("player.vipLevel")}
                </StyledTableCellRight>
                <StyledTableCellRight>
                  {i18n.t("player.registeredOn")}
                </StyledTableCellRight>

                <StyledTableCellRight>
                  {i18n.t("player.walletBalance")}
                </StyledTableCellRight>
                <StyledTableCellRight>
                  {i18n.t("player.status.title")}
                </StyledTableCellRight>
              </TableRow>
            </TableHead>
            <TableBody style={{ backgroundColor: "#fff" }}>
              {playerList.length !== 0 ? (
                playerList.items
                  .sort((a, b) => {
                    var dateA = new Date(a.registeredtime),
                      dateB = new Date(b.registeredtime);
                    return dateB - dateA;
                  })
                  .map((data, index) => {
                    return (
                      <CustomTableRow key={index} onClick={() => {}}>
                        <StyledTableCell>{data.username}</StyledTableCell>
                        <StyledTableCellRight>
                          {data.viplevel}
                        </StyledTableCellRight>
                        <StyledTableCellRight>
                          {data.registeredtime &&
                            dateTimeFormatter(data.registeredtime)}
                        </StyledTableCellRight>

                        <StyledTableCellRight>
                          <GetBalance data={data} />
                        </StyledTableCellRight>
                        <StyledTableCellRight>
                          {Object.keys(status)
                            .filter((a, index) => {
                              return data.status === index;
                            })
                            .map(function (key, index) {
                              return i18n.t(`player.status.` + status[key]);
                            })}
                        </StyledTableCellRight>
                      </CustomTableRow>
                    );
                  })
              ) : (
                <TableRow>
                  <NoDataContainer colSpan={6}>
                    {i18n.t("common.nodata")}
                  </NoDataContainer>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        {playerList.totalcount > MAX && (
          <CustomPagination
            total={playerList.totalcount}
            page={page}
            next={handleNextPage}
            prev={handlePrevPage}
          />
        )}
      </Content>
    </FullSizeContainer>
  );
};

const mapStateToProps = (state) => {
  return {
    account: state.account,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withSnackbar(PlayerList));
