import React from "react";
import styled from "styled-components";
import i18n from "../../../../../../utils/i18n";

const CustomDataRow = styled.div`
  display: grid;
  grid-template-columns: 30% auto;
  padding: 7.5px 10px;
`;

const CustomContainer = styled.div`
  padding: 10px;
`;

const CustomDataTitle = styled.div`
  font-weight: bold;
  font-size: 12px;
  display: flex;
  height: 100%;
  align-items: center;
`;

const CustomDataContent = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
`;

const TabUserContact = ({ data }) => {
  const processedData = [
    {
      title: i18n.t("account.createAgent.email"),
      data: data.email,
    },
    {
      title: i18n.t("account.createAgent.phone"),
      data: data.phone,
    },
    { title: "QQ", data: data.qq },

    { title: "WeChat", data: data.wechat },
  ];

  return (
    <CustomContainer>
      {processedData.map((data, index) => {
        return (
          <CustomDataRow key={index}>
            <CustomDataTitle>{data.title}</CustomDataTitle>
            <CustomDataContent>{data.data}</CustomDataContent>
          </CustomDataRow>
        );
      })}
    </CustomContainer>
  );
};

export default TabUserContact;
