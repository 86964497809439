import React, { useState } from "react";
import styled, { keyframes } from "styled-components";
import ChannelLink from "../../App/Account/Channel/ChannelLink";
import SafetyGreen from "../../../assets/images/safety-rating-green.png";
import SafetyRed from "../../../assets/images/safety-rating-red.png";
import { connect } from "react-redux";
import i18n from "../../../utils/i18n";
import { useSnackbar } from "notistack";
import { Grid } from "@material-ui/core";
import config from "../../../config/config";
import QRCode from "qrcode.react";
import GenerateQRCode from "./QRCodeGenerate";
import Common from "../../../utils/Common";
export const rotateKeyFrame = keyframes`
  0% {
    transform:rotate(0deg)
  }
  100% {
    transform:rotate(-359deg)
  }
`;
const CustomContainer = styled.div`
  margin-top: 20px;
  margin-bottom: 16px;
  border-radius: 10px;
`;

const LeftContent = styled.div`
  background-color: rgb(217, 217, 217);
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
`;

const SafetyRating = styled.div`
  justify-content: center;
  align-items: center;
  background-image: url(${(props) => {
    return props.score >= 75 ? SafetyGreen : SafetyRed;
  }});
  background-size: 100%;
  background-repeat: no-repeat;
  background-size: 175px 150px;
  background-position: center;
  height: 175px;
  width: inherit;
`;

const SafetyRatingScore = styled.div`
  font-size: 28px;
  text-align: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  align-items: center;

  color: white;
`;
const SafetyRatingLabel = styled.div`
  font-size: 10px;
  text-align: center;

  color: white;
`;

const InformationLabel = styled.div`
  min-width: 100px;
  font-size: 14px;

  color: #9b9b9b;
  &:after {
    content: ":";
  }
`;

const InformationData = styled.div`
  color: #505050;
  font-size: 14px;
`;
const InformationDataTh = styled.th``;

const InformationDataTr = styled.tr``;
const InformationDataTd = styled.td``;
const InformationDataTable = styled.table`
  color: #505050;
  font-size: 12px;
  border-collapse: collapse;
  border: 1px solid #9b9b9b;
  ${InformationDataTh},
  ${InformationDataTd} {
    padding: 4px 15px;
    text-align: right;
    border: 1px solid #9b9b9b;
  }
`;

const NewHeader = styled.div`
  display: grid;
  min-height: 300px;
  @media (min-width: 960px) {
    grid-template-columns: 25% auto;
  }
  @media (min-width: 1920px) {
    grid-template-columns: 16.666667% 83.333333%;
  }
`;

const RightContent = styled.div`
  background: #eaeaea;
  padding: 20px;
  display: flex;
  justify-content: space-between;

  @media (max-width: 600px) {
    display: block;
  }
`;

const QRCodeWrapper = styled.div`
  @media (max-width: 600px) {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
  }
`;

function Header(props) {
  const [score, setScore] = useState(0);

  var URL = `${config.site}a/${props.accountInfo.invitationCode}?do=register`;

  // useEffect(() => {
  //   if (props.check_pass_account !== false) {
  //     verificationScore = verificationScore + 25;
  //   }
  //   if (props.phone.length && props.phone !== 0) {
  //     verificationScore = verificationScore + 25;
  //   }
  //   if (props.email.length && props.name !== "") {
  //     verificationScore = verificationScore + 25;
  //   }
  //   if (
  //     props.question !== 0 &&
  //     props.question !== "" &&
  //     props.question !== null
  //   ) {
  //     verificationScore = verificationScore + 25;
  //   }
  //   setScore(verificationScore);
  // });

  const headerContent = [
    {
      title: i18n.t("account.header.name"),
      data: [props.accountInfo.member_username],
    },
    {
      title: i18n.t("account.header.balance"),
      data: [Common.formatNumberWithCommasNoRounding(props.wallet.mainBalance)],
    },
    {
      title: i18n.t("account.header.dividendpercentage"),
      type: "dividendpercentage",
      data:
        props.accountInfo.agentdividendtype === "MultipleLayer"
          ? props.accountInfo.dividendconditions
          : props.accountInfo.dividendpercentage,
    },
    //   {
    //     title: i18n.t("report.dividend"),
    //     data: [
    //       props.accountInfo.accumulatedprofitsharing
    //         ? props.accountInfo.accumulatedprofitsharing.toFixed(2)
    //         : 0,
    //     ],
    //   },
    {
      title: i18n.t("account.agentInfo.agentDetails.accumulatedprofitsharing"),
      data: [
        props.accountInfo.accumulatedprofitsharing
          ? Common.formatNumberWithCommasNoRounding(
              props.accountInfo.accumulatedprofitsharing
            )
          : 0,
      ],
    },
    {
      title: i18n.t("account.agentInfo.agentDetails.teamsize"),
      data: [props.accountInfo.playernumber],
    },
    {
      title: i18n.t("account.header.affiliateCode"),
      data: [props.accountInfo.invitationCode],
    },
    {
      title: i18n.t("account.header.affiliateLink"),
      data: (
        <ChannelLink
          id={"link"}
          member_id={"asdasd"}
          tile={"asdasd"}
          url={`${config.site}a/${props.accountInfo.invitationCode}?do=register`}
        />
      ),
    },
  ];

  return (
    <CustomContainer>
      <NewHeader>
        <div style={{ background: "#d9d9d9" }}>
          <LeftContent>
            <SafetyRating score={score}>
              <SafetyRatingScore>
                {100}%
                <SafetyRatingLabel>
                  {i18n.t("account.header.safety")}
                </SafetyRatingLabel>
              </SafetyRatingScore>
            </SafetyRating>
          </LeftContent>
        </div>
        <RightContent>
          <Grid item xs={8} md={8}>
            {headerContent
              .filter((e) => {
                if (!config.features.Header_PlayerCount) {
                  return (
                    e.title !==
                    i18n.t("account.agentInfo.agentDetails.teamsize")
                  );
                }
                return e;
              })
              .map((data, index) => {
                return (
                  <Grid container alignItems="flex-start" spacing={4}>
                    <Grid item xs={12} md={4}>
                      <InformationLabel>{data.title}</InformationLabel>
                    </Grid>
                    <Grid item>
                      {(() => {
                        if (data.type === "dividendpercentage") {
                          if (typeof data.data !== "object") {
                            return (
                              <InformationData>{data.data}%</InformationData>
                            );
                          } else {
                            return (
                              <InformationDataTable>
                                <InformationDataTr>
                                  <InformationDataTh>净盈利</InformationDataTh>
                                  <InformationDataTh>
                                    活跃人数
                                  </InformationDataTh>
                                  <InformationDataTh>
                                    分红比例
                                  </InformationDataTh>
                                </InformationDataTr>
                                {data.data.map((item, index) => {
                                  return (
                                    <InformationDataTr>
                                      <InformationDataTd>
                                        {item.netprofit}
                                      </InformationDataTd>
                                      <InformationDataTd>
                                        {item.activenumber}
                                      </InformationDataTd>
                                      <InformationDataTd>
                                        {item.dividendpercentage}%
                                      </InformationDataTd>
                                    </InformationDataTr>
                                  );
                                })}
                              </InformationDataTable>
                            );
                          }
                        } else {
                          return <InformationData>{data.data}</InformationData>;
                        }
                      })()}
                    </Grid>
                  </Grid>
                );
              })}
          </Grid>
          <QRCodeWrapper>
            <GenerateQRCode link={URL} />
          </QRCodeWrapper>
        </RightContent>
      </NewHeader>
    </CustomContainer>
  );
}

const mapStateToProps = (state) => {
  return {
    accountInfo: state.account.info,
    wallet: state.wallet,
  };
};
export default connect(mapStateToProps, null)(Header);
