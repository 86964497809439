import { Button } from "@material-ui/core";
import React from "react";
import styled from "styled-components";

const PaginationContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
  align-items: center;
  padding: 10px;
`;

const PaginationButtonContainer = styled.div`
  display: flex;
  align-items: center;
`;

const CustomPagination = ({ page, total, prev, next }) => {
  var pagecount = total <= 10 ? 1 : Math.ceil(total / 10);
  return (
    <PaginationContainer>
      <PaginationButtonContainer>
        {page} / {pagecount}
        <Button size="small" onClick={prev}>
          ❮
        </Button>
        <Button size="small" onClick={next}>
          ❯
        </Button>
      </PaginationButtonContainer>
    </PaginationContainer>
  );
};

export default CustomPagination;
