import React, { Component } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import { Router, Route, Switch } from "react-router";
import { withRouter } from "react-router-dom";

import { AccountHistory, AppHistory } from "../../../history/History";
import BlueBgJPG from "../../../assets/images/bg.jpg";

import Header from "./Header";
import Profile from "./Profile/index";
import Inbox from "./Inbox/index";
import UnderConstruction from "./UnderConstruction";
import { accountAction } from "../../../actions/accountAction";
import Withdrawal from "./Withdrawal/index";
import Safety from "./Safety";
import Channel from "./Channel";
import Card from "./Card";
import { isMobile } from "react-device-detect";
import CreateAgent from "./CreateAgent";
import i18n from "../../../utils/i18n";
import { Container, Grid, Tabs, Hidden, Tab } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

import AgentList from "./AgentList";
import AgentDetail from "./AgentDetail";
import AgentDomain from "./AgentDomain";
import ChangesReport from "./ChangesReport";

import config from "../../../config/config";
import PlayerList from "./PlayerList";

const AntTabs = withStyles({
  indicator: {
    backgroundColor: "#dc4048",
  },
})(Tabs);

const ContentWrapper = styled.div`
  width: 100%;
  padding-bottom: 16px;
  background: url(${BlueBgJPG});
`;

const Main = styled.div`
  border-radius: 10px;
  height: 100%;
  background-color: #e9e9e9;
`;
const LeftMenu = styled.div`
  background-color: rgb(217, 217, 217);
  padding-top: 20px;
  width: 100%;
  height: 100%;
  float: left;
`;
const RightContent = styled.div`
  height: 100%;
  background: rgb(234, 234, 234);
  @media (max-width: 600px) {
    padding: 10px;
  }
`;

const MenuButtonDiv = styled.div`
  width: 100%;
  padding: 2.5px 5px;
  display: flex;
  align-items: center;
  justify-content: center;

  font-size: 16px;
  &:hover {
    cursor: pointer;
  }
  &:after {
    border-left: solid 1px red;
  }
  ${(props) =>
    props.active
      ? ` background-color: #fff;
      color: #dc4048;
    
      border-left:solid 3px #dc4048;
      `
      : " background-color: rgb(217,217,217);color: #000; "};
`;

const CustomButton = styled.div`
  color: ${({ active }) => (active ? "rgb(247, 247, 247)" : "rgb(64, 64, 64)")};
  padding: 10px 40px;
  width: 100%;
  border: 1px solid rgb(127, 127, 127);
  border-radius: 3px;
  font-size: 14px;
  background-color: ${({ theme, active }) =>
    active ? theme.primary : "rgb(237, 237, 237)"};
`;

class Account extends Component {
  componentDidMount() {
    const localStorageToken = localStorage.getItem(
      "token" + (isMobile ? 1 : 0)
    );
    if (localStorageToken === null) {
      this.props.history.replace("/");
    }
    if (this.props.token != null) {
      this.didCallApi = true;
      // this.props.getAccountProfile(this.props.token);
      // this.props.getVIPInfo(this.props.token);
    } else {
      //this.props.history.push("/");
    }
  }

  constructor(props) {
    super(props);
    this.state = {
      selected: 0,
    };
  }
  handleRefreshClick = () => {
    this.props.getAccountProfile(this.props.token);
    // this.props.getVIPInfo(this.props.token);
  };

  componentDidUpdate(prevProps, prevState) {
    this.activePage = this.props.match.params.tab;
    if (this.props.account.token != null && this.didCallApi === false) {
      this.didCallApi = true;
      //  this.props.getAccountProfile(this.props.account.token);
      // this.props.getVIPInfo(this.props.account.token);
    }
    if (prevProps.account.token != null && this.props.account.token == null) {
      this.props.history.push("/");
    }
  }
  didCallApi = false;

  activePage = this.props.match.params.tab;
  handleClickTab = (link, index = 0) => {
    if (
      config.brand !== "HK28" &&
      this.props.account.info.member_safe_password !== "set" &&
      (link === "card" || link === "withdrawal")
    ) {
      this.props.history.push("/account/safety/member_safe_password");
    } else {
      this.activePage = link;
      this.setState({ ...this.state, activePage: link, selected: index });
      this.props.history.push("/account/" + link);
    }
  };

  genMenuButtonDiv = (text, link) => {
    return (
      <MenuButtonDiv onClick={() => this.handleClickTab(link)}>
        <CustomButton active={this.activePage === link}>{text}</CustomButton>
      </MenuButtonDiv>
    );
  };

  generateTabs = (text, link, index) => {
    return (
      <Tab
        label={text}
        onClick={() => {
          this.handleClickTab(link, index);
          //  this.setState({ ...this.state, selected: index });
        }}
      />
    );
  };

  handleChange = (value) => {
    this.setState({
      ...this.state,
      selected: value,
    });
  };

  a11yProps(index) {
    return {
      id: `scrollable-auto-tab-${index}`,
      "aria-controls": `scrollable-auto-tabpanel-${index}`,
    };
  }

  render() {
    return (
      <ContentWrapper>
        <Container maxWidth="lg">
          <Header
            info={this.props.account.info}
            vip={this.props.account.vip}
            onhandleClickTab={this.handleClickTab}
            onhandleRefreshClick={this.handleRefreshClick}
            onhandleMoreBenefitClick={this.handleMoreBenefitClick}
            onIDVerifyClick={this.handleIDVerifyClick}
            onPhoneVerifyClick={this.handlePhoneVerifyClick}
            onEmailVerifyClick={this.handleEmailVerifyClick}
          ></Header>

          <Main>
            <Grid container>
              <Grid item xs={12} sm={12} md={3} lg={2} xl={2}>
                <Hidden smDown>
                  <LeftMenu>
                    {this.genMenuButtonDiv(
                      i18n.t("account.menu.agentDetail"),
                      "agentDetail"
                    )}
                    {this.genMenuButtonDiv(
                      i18n.t("account.createAgent.agentdomain"),
                      "agentDomain"
                    )}
                    {this.genMenuButtonDiv(
                      i18n.t("account.agentInfo.transactionRecord.title"),
                      "changesReport"
                    )}
                    {
                      //  this.genMenuButtonDiv(
                      //    i18n.t("account.agentInfo.teamInfo.title"),
                      //    "teamInfo"
                      //  )
                    }
                    {config.features.VIPPage_PlayerList &&
                      this.genMenuButtonDiv(i18n.t("player.title"), "player")}
                    {this.genMenuButtonDiv(
                      i18n.t("account.menu.agentList"),
                      "agentlist"
                    )}
                    {this.genMenuButtonDiv(
                      i18n.t("account.menu.safety"),
                      "safety"
                    )}
                    {/* {this.genMenuButtonDiv(
                      i18n.t("account.menu.personal"),
                      "profile"
                    )}
                    {this.props.account.info.check_pass_account === false
                      ? ""
                      : this.genMenuButtonDiv(
                          i18n.t("account.menu.affiliate"),
                          "channel"
                        )}
                    {this.genMenuButtonDiv(
                      i18n.t("account.menu.inbox"),
                      "inbox"
                    )} */}
                    {this.genMenuButtonDiv(
                      i18n.t("account.menu.withdrawal"),
                      "withdrawal"
                    )}
                    {this.genMenuButtonDiv(i18n.t("account.menu.card"), "card")}
                    {this.genMenuButtonDiv(
                      i18n.t("account.menu.createAgent"),
                      "createAgent"
                    )}
                  </LeftMenu>
                </Hidden>
                <Hidden mdUp>
                  <AntTabs
                    variant="scrollable"
                    scrollButtons="on"
                    indicatorColor="primary"
                    value={this.state.selected}
                    onChange={this.handleChange}
                  >
                    {this.generateTabs(
                      i18n.t("account.menu.agentDetail"),
                      "agentDetail",
                      0
                    )}
                    {this.generateTabs(
                      i18n.t("account.createAgent.agentdomain"),
                      "agentDomain",
                      1
                    )}
                    {this.generateTabs(
                      i18n.t("account.agentInfo.transactionRecord.title"),
                      "changesReport",
                      2
                    )}
                    {this.generateTabs(
                      i18n.t("account.menu.agentList"),
                      "agentList",
                      3
                    )}
                    {config.features.VIPPage_PlayerList &&
                      this.generateTabs(
                        i18n.t("player.title"),
                        "player",
                        config.features.VIPPage_PlayerList && 4
                      )}
                    {this.generateTabs(
                      i18n.t("account.menu.safety"),
                      "safety",
                      config.features.VIPPage_PlayerList ? 5 : 4
                    )}
                    {this.generateTabs(
                      i18n.t("account.menu.withdrawal"),
                      "withdrawal",
                      config.features.VIPPage_PlayerList ? 6 : 5
                    )}
                    {this.generateTabs(
                      i18n.t("account.menu.card"),
                      "card",
                      config.features.VIPPage_PlayerList ? 7 : 6
                    )}
                    {this.generateTabs(
                      i18n.t("account.menu.createAgent"),
                      "createAgent",
                      config.features.VIPPage_PlayerList ? 8 : 7
                    )}
                  </AntTabs>
                </Hidden>
              </Grid>
              <Grid item xs={12} sm={12} md={9} lg={10} xl={10}>
                <RightContent>
                  <Router history={AppHistory}>
                    <Switch>
                      <Route
                        exact
                        path={"/account/safety/:popup?"}
                        render={(props) => (
                          <Safety
                            account={this.props.account}
                            popup={this.props.match.params.popup}
                            history={this.props.history}
                          />
                        )}
                      />
                      <Route
                        exact
                        token={this.props.account.token}
                        path={"/account/profile/:popup?"}
                        render={(props) => (
                          <Profile
                            account={this.props.account}
                            onhandleCSClick={this.props.onhandleCSClick}
                            popup={this.props.match.params.popup}
                          />
                        )}
                      />
                      <Route
                        exact
                        path={"/account/channel"}
                        render={(props) => (
                          <Channel token={this.props.account.token} />
                        )}
                      />
                      <Route
                        path={"/account/createAgent"}
                        render={(props) => (
                          <CreateAgent token={this.props.account.token} />
                        )}
                      />
                      <Route
                        path={"/account/agentlist"}
                        render={(props) => (
                          <AgentList token={this.props.account.token} />
                        )}
                      />
                      {config.features.VIPPage_PlayerList && (
                        <Route
                          path={"/account/player"}
                          render={(props) => (
                            <PlayerList token={this.props.account.token} />
                          )}
                        />
                      )}

                      <Route
                        path={"/account/agentDetail"}
                        render={(props) => (
                          <AgentDetail token={this.props.account.token} />
                        )}
                      />
                      <Route
                        path={"/account/agentDomain"}
                        render={(props) => (
                          <AgentDomain token={this.props.account.token} />
                        )}
                      />
                      {
                        //    <Route
                        //    path={"/account/teamInfo"}
                        //    render={(props) => (
                        //      <TeamInfo token={this.props.account.token} />
                        //    )}
                        //  />
                      }
                      <Route
                        path={"/account/changesReport"}
                        render={(props) => (
                          <ChangesReport token={this.props.account.token} />
                        )}
                      />
                      <Route
                        exact
                        path={"/account/withdrawal"}
                        render={(props) => (
                          <Withdrawal
                            token={this.props.account.token}
                            onhandleClickTab={this.handleClickTab}
                            onhandleCSClick={this.props.onhandleCSClick}
                            account={this.props.account}
                          />
                        )}
                      />
                      <Route
                        exact
                        path={"/account/card"}
                        render={(props) => (
                          <Card
                            token={this.props.account.token}
                            onhandleClickTab={this.handleClickTab}
                            onhandleCSClick={this.props.onhandleCSClick}
                            account={this.props.account}
                          />
                        )}
                      />
                      <Route
                        exact
                        path={"/account/inbox"}
                        render={(props) => (
                          <Inbox token={this.props.account.token} />
                        )}
                      />
                      <Route
                        exact
                        path={"/account/underconstruction"}
                        render={(props) => (
                          <UnderConstruction token={this.props.account.token} />
                        )}
                      />
                      <Route
                        path={"/account/registerqq"}
                        component={() => {
                          window.location.href =
                            "https://ssl.zc.qq.com/v3/index-chs.html";
                          return null;
                        }}
                      />
                      <Route
                        path={"/account/registerskype"}
                        component={() => {
                          window.location.href = " http://skype.gmw.cn/";
                          return null;
                        }}
                      />
                    </Switch>
                  </Router>
                </RightContent>
              </Grid>
            </Grid>
          </Main>
        </Container>
      </ContentWrapper>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    // screenWidth: state.ui.screenWidth,
    // screenHeight: state.ui.screenHeight,
    account: state.account,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getAccountProfile: (token) =>
      dispatch(accountAction.getAccountProfile(token, true)),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Account)
);
