import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";

import React, { useEffect } from "react";
import ReactDOM from "react-dom";
import "./index.css";
import { Router, Route, Switch, Redirect } from "react-router";
import {
  createMuiTheme,
  MuiThemeProvider,
  StylesProvider,
} from "@material-ui/core/styles";
import Web from "./components/App/index";
import { ThemeProvider } from "styled-components";
import { themeList } from "./theme";
import * as serviceWorker from "./utils/serviceWorker";
import { isMobile } from "react-device-detect";
import { Provider } from "react-redux";
//import store from "./store/index";
import { createStore, applyMiddleware } from "redux";
import rootReducer from "./reducers/rootReducer";
import thunk from "redux-thunk";
import Interceptor from "./services/Interceptor";
import JoinGame from "./components/Share/JoinGame";
import IPRestrict from "./components/App/IPRestrict";
import CS from "./components/App/CS";
import AboutUs from "./components/AboutUs/index";
import GameRules from "./components/GameRules/index";
import { AppHistory } from "./history/History";
import { SnackbarProvider } from "notistack";
import styled from "styled-components";
import Config from "./config/config";
import i18n from "./utils/i18n";
import DangerPNG from "../src/assets/images/warning.png";
import InfoPNG from "../src/assets/images/info.png";
import SuccessPNG from "../src/assets/images/success.png";
import { makeStyles } from "@material-ui/core";
import FunctionContextProvider from "./components/Context/FunctionContext";
import Common from "./utils/Common";
import { GeneralService } from "./services/GeneralService";
import ErrorSwitcher from "./utils/ErrorSwitcher";
import featureList from "./config/features";
import FeatureGetter from "./components/Share/feature-getter";
import config from "./config/config";

require("es6-promise").polyfill();
const store = createStore(rootReducer, applyMiddleware(thunk));
Interceptor.setupInterceptors(store);
const notistackRef = React.createRef();
const onClickDismiss = (key) => () => {
  notistackRef.current.closeSnackbar(key);
};

const useStyles = makeStyles((theme) => ({
  success: { backgroundColor: "#fff", color: "#000" },
  error: { backgroundColor: "#fff", color: "#000" },
  warning: { backgroundColor: "#fff", color: "#000" },
  info: { backgroundColor: "#fff", color: "#000" },
}));

const Dismiss = styled.a`
  margin-right: 12px;
  font-weight: bold;
  &:hover {
    cursor: pointer;
  }
`;

const Icon = styled.img`
  width: 24px;
  margin-right: 12px;
`;

//Config.from = isMobile ? "m" : "pc";
Config.from = isMobile ? 2 : 1;
Config.isPC = isMobile ? 2 : 1;

var defaultFeature = featureList["default"];
var brandFeature = featureList[Config.brand] || featureList["default"];
for (var k in brandFeature) {
  defaultFeature[k] = brandFeature[k];
}
Config.features = defaultFeature;
// if (process.env.REACT_APP_CUSTOM_NODE_ENV !== "development") {
//   let url = window.location.hostname;
//   let new_sub = "service";
//   let baseUrl = "";
//   if (url.split(/[\/\.]+/).length >= 3) {
//     baseUrl = url.replace(url.split(/[\/\.]+/)[0], new_sub);
//   } else {
//     baseUrl = new_sub + "." + url;
//   }
//   baseUrl = window.location.protocol + "//" + baseUrl + "/";
//   Config.baseUrl = baseUrl;
// }

document.getElementById("favicon").href =
  process.env.PUBLIC_URL + `/assets/images/logo/${Config.brand}.ico`;
var title = i18n.exists(`brand.${Config.brand}.title`)
  ? i18n.t(`brand.${Config.brand}.title`)
  : i18n.t("common.title").replace(/{brand}/g, Config.brand);

var description = i18n.exists(`brand.${Config.brand}.description`)
  ? i18n.t(`brand.${Config.brand}.description`)
  : title;
document.getElementById("title").innerHTML = title;
document.getElementById("description").setAttribute("content", description);

const muiThemeMode = themeList[Config.theme];
const muiTheme = createMuiTheme({
  palette: {
    primary: {
      main: muiThemeMode.primary,
      mainRgb: muiThemeMode.primaryRgb,
      contrastText: muiThemeMode.primaryText,
      mainGradient: muiThemeMode.primaryGradient,
      mainGradientRgb: muiThemeMode.primaryGradientRgb,
    },
    secondary: {
      main: muiThemeMode.secondary,
      contrastText: muiThemeMode.secondaryText,
    },
    tertiary: {
      main: muiThemeMode.tertiary,
      contrastText: muiThemeMode.tertiaryText,
    },
    type: "light",
  },
  typography: {
    fontFamily: "PingFangTC-Medium",

    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
  },
  overrides: {
    MuiSelect: {
      root: {
        color: muiThemeMode.secondary,
      },
    },
    MuiTabs: {
      // Name of the rule
      root: {
        backgroundColor: muiThemeMode.secondary,
        height: "24px",
        overflow: "visible",
        "& .MuiTabs-indicator": {
          backgroundColor: "#FDE740",
          height: "5px",
        },
        "& .MuiTabs-indicator:before": {
          content: "'\\A'",
          borderStyle: "solid",
          borderWidth: "0 10px 10px 10px",
          borderColor: "transparent transparent #FDE740 transparent",
          position: "absolute",
          left: "50%",
          top: "-6px",
          transform: "translateX(-50%)",
        },
        "& .MuiTabs-flexContainer": {
          overflow: "visible",
        },
        "& .MuiTabs-scrollButtons": {
          display: "none",
        },
      },
    },
    MuiTab: {
      root: {
        fontSize: "14px",
        maxWidth: "375px",
        color: "#ffffff !important",
        minWidth: "125px",
        height: "24px",
        "&.Mui-selected": {
          color: "#FDE740  !important",
        },
      },
    },
  },
});
const AppWrapper = () => {
  const classes = useStyles();

  useEffect(() => {
    GeneralService.GetLocalization().then((response) => {
      if (response.IsSuccess) {
        ErrorSwitcher.setTranslation(response.data);
      }
    });
  });

  return (
    <Provider store={store}>
      <FeatureGetter />
      <StylesProvider injectFirst>
        <MuiThemeProvider theme={muiTheme}>
          <ThemeProvider theme={muiThemeMode}>
            <SnackbarProvider
              classes={{
                variantSuccess: classes.success,
                variantError: classes.error,
                variantWarning: classes.warning,
                variantInfo: classes.info,
              }}
              iconVariant={{
                success: <Icon src={SuccessPNG}></Icon>,
                error: <Icon src={DangerPNG}></Icon>,
                warning: <Icon src={DangerPNG}></Icon>,
                info: <Icon src={InfoPNG}></Icon>,
              }}
              action={(key) => (
                <Dismiss onClick={onClickDismiss(key)}>x</Dismiss>
              )}
              maxSnack={3}
              autoHideDuration={3000}
              anchorOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
              ref={notistackRef}
            >
              <Router history={AppHistory}>
                <Switch>
                  <Route
                    exact
                    path={"/aboutus/:page/:action?/:value?"}
                    component={AboutUs}
                  />
                  <Route exact path={"/ip-restrict"} component={IPRestrict} />
                  <Route exact path={"/cs/:value?"} component={CS} />
                  {/*<Route path="/" render={props => <Web />} />*/}
                  <Route
                    path={"/"}
                    render={(props) => (isMobile ? <Web /> : <Web />)}
                  />
                  <Redirect to={`/`} />
                </Switch>
              </Router>
            </SnackbarProvider>
          </ThemeProvider>
        </MuiThemeProvider>
      </StylesProvider>
    </Provider>
  );
};

const ContextWrapper = (props) => {
  return <>{props.children}</>;
};

ReactDOM.render(
  <ContextWrapper>
    <AppWrapper />
  </ContextWrapper>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
