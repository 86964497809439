import { SlotGameParentId } from "../utils/Enum";
import ABCPNG from "../assets/images/wap/bank/abc.png";
import BOBPNG from "../assets/images/wap/bank/bob.png";
import BOCPNG from "../assets/images/wap/bank/boc.png";
import BOSPNG from "../assets/images/wap/bank/bos.png";
import CCBPNG from "../assets/images/wap/bank/ccb.png";
import CEBPNG from "../assets/images/wap/bank/ceb.png";
import CGDPNG from "../assets/images/wap/bank/cgd.png";
import CIBPNG from "../assets/images/wap/bank/cib.png";
import CMBPNG from "../assets/images/wap/bank/cmb.png";
import CMBCPNG from "../assets/images/wap/bank/cmbc.png";
import ECITICPNG from "../assets/images/wap/bank/ecitic.png";
import GFPNG from "../assets/images/wap/bank/gf.png";
import HXBPNG from "../assets/images/wap/bank/hxb.png";
import ICBCPNG from "../assets/images/wap/bank/icbc.png";
import PABPNG from "../assets/images/wap/bank/pab.png";
import PSBCPNG from "../assets/images/wap/bank/psbc.png";
import SPDBPNG from "../assets/images/wap/bank/spdb.png";
import DefaultPNG from "../assets/images/wap/bank/default.png";
import config from "../config/config";
import moment from "moment";
function ConvertSlotGameParentIdToString(id) {
  for (let key in SlotGameParentId) {
    if (SlotGameParentId[key] === id) {
      return key;
    }
  }
}
function GetBankImageByTitle(title) {
  let bank = null;

  for (let k in this.bankImageMapping) {
    if (this.bankImageMapping[k].title === title) {
      bank = this.bankImageMapping[k];
      break;
    }
  }
  if (bank != null) {
    return bank.image;
  } else {
    return DefaultPNG;
  }
}
function GetBankImageByCode(code) {
  let mapp = this.bankImageMapping[code];
  if (mapp) {
    return mapp;
  } else {
    return {
      id: 0,
      title: code,
      image: DefaultPNG,
    };
  }
}
const bankImageMapping = {
  ab: {
    id: 0,
    title: "农业银行",
    image: ABCPNG,
  },
  abc: {
    id: 0,
    title: "农业银行",
    image: ABCPNG,
  },
  bob: {
    id: 0,
    title: "北京银行",
    image: BOBPNG,
  },
  boc: {
    id: 0,
    title: "中国银行",
    image: BOCPNG,
  },
  bos: {
    id: 0,
    title: "上海银行",
    image: BOSPNG,
  },
  ccb: {
    id: 0,
    title: "建设银行",
    image: CCBPNG,
  },
  ecitic: {
    id: 0,
    title: "中信银行",
    image: ECITICPNG,
  },
  ceb: {
    id: 0,
    title: "中国光大银行",
    image: CEBPNG,
  },

  cgd: {
    id: 0,
    title: "广发银行",
    image: CGDPNG,
  },
  cib: {
    id: 0,
    title: "兴业银行",
    image: CIBPNG,
  },
  cmb: {
    id: 0,
    title: "招商银行",
    image: CMBPNG,
  },
  cmbc: {
    id: 0,
    title: "民生银行",
    image: CMBCPNG,
  },
  hxb: {
    id: 0,
    title: "华夏银行",
    image: HXBPNG,
  },
  icbc: {
    id: 0,
    title: "工商银行",
    image: ICBCPNG,
  },
  pab: {
    id: 0,
    title: "平安银行",
    image: PABPNG,
  },
  psbc: {
    id: 0,
    title: "中国邮政储蓄银行",
    image: PSBCPNG,
  },
  spdb: {
    id: 0,
    title: "浦东发展银行",
    image: SPDBPNG,
  },
  gf: {
    id: 0,
    title: "广发银行",
    image: GFPNG,
  },
  pingan: {
    id: 0,
    title: "平安银行",
    image: GFPNG,
  },
};
function PopupCenter(url, title, w, h) {
  // Fixes dual-screen position                         Most browsers      Firefox
  var dualScreenLeft =
    window.screenLeft !== undefined ? window.screenLeft : window.screenX;
  var dualScreenTop =
    window.screenTop !== undefined ? window.screenTop : window.screenY;

  var width = window.innerWidth
    ? window.innerWidth
    : document.documentElement.clientWidth
    ? document.documentElement.clientWidth
    : window.screen.width;
  var height = window.innerHeight
    ? window.innerHeight
    : document.documentElement.clientHeight
    ? document.documentElement.clientHeight
    : window.screen.height;

  var systemZoom = 1;
  var left = (width - w) / 2 / systemZoom + dualScreenLeft;
  var top = (height - h) / 2 / systemZoom + dualScreenTop;
  top = top < 0 ? 0 : top;
  left = left < 0 ? 0 : left;
  var newWindow = window.open(
    url,
    title,
    "scrollbars=yes, width=" +
      w / systemZoom +
      ", height=" +
      h / systemZoom +
      ", top=" +
      top +
      ", left=" +
      left
  );

  if (
    !newWindow ||
    newWindow.closed ||
    typeof newWindow.closed == "undefined"
  ) {
    //POPUP BLOCKED
    return false;
  } else {
    // Puts focus on the newWindow
    if (window.focus) newWindow.focus();
    return true;
  }
}
function GetBonusCategory(type_id) {
  switch (type_id) {
    case 1:
      return "身份验证";

    case 2:
      return "邮件验证";

    case 3:
      return "手机验证";
    case 4:
      return "注册红利";
    case 5:
      return "VIP红利";
    case 10:
      return "流水红利";

    case 11:
      return "单次存款红利";

    case 12:
      return "累计存款红利";

    case 21:
      return "累计流水";

    case 31:
      return "特殊红利";

    default:
      return "";
  }
}
function formatNumberWithCommas(x) {
  if (isNaN(x)) return x;
  return parseFloat(x)
    .toFixed(2)
    .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
}
function formatNumberWithThousand(x) {
  if (isNaN(x)) return x;
  return x.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
}
function formatNumberWithCommasNoRounding(x) {
  if (isNaN(x)) return x;
  return parseFloat(x)
    .toFixed(6)
    .slice(0, -4)
    .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
}
function loadScript(url, cb) {
  var scr = document.createElement("script");
  scr.type = "text/javascript";

  if (scr.readyState) {
    // IE
    scr.onreadystatechange = function () {
      if (scr.readyState === "loaded" || scr.readyState === "complete") {
        scr.onreadystatechange = null;
        cb();
      }
    };
  } else {
    // Others
    scr.onload = cb;
  }

  scr.src = url;
  document.getElementsByTagName("head")[0].appendChild(scr);
}

function IsJsonString(str) {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
}

const maxDayRangeDetector = (datefrom, dateTo) => {
  var start = moment(new Date(datefrom)).format("MM/DD/YYYY");
  var end = moment(new Date(dateTo)).format("MM/DD/YYYY");
  if (
    moment
      .duration(moment(end).diff(moment(new Date(start)).format("MM/DD/YYYY")))
      .asDays() > 31
  ) {
    return true;
  } else {
    return false;
  }
};

const baseRouteUrl = `/:locale(${config.languages
  .map((item) => item.name)
  .join("|")})`;
const Common = {
  ConvertSlotGameParentIdToString,
  GetBonusCategory,
  formatNumberWithCommas,
  formatNumberWithThousand,
  formatNumberWithCommasNoRounding,
  PopupCenter,
  bankImageMapping,
  GetBankImageByTitle,
  GetBankImageByCode,
  loadScript,
  baseRouteUrl,
  IsJsonString,
  maxDayRangeDetector,
};
export default Common;
