import axios from "axios";
import { UNAUTHORIZE } from "../constant/actionType";
import { AppHistory } from "../history/History";
import i18n from "../utils/i18n";
import ErrorSwitcher from "../utils/ErrorSwitcher";
import Promise from "promise-polyfill";
import { isMobile } from "react-device-detect";
export default {
  setupInterceptors: (store) => {
    // Add a response interceptor
    axios.interceptors.request.use(
      function (config) {
        const client = localStorage.getItem("client-token");
        if (client != null && client != "") {
          config.headers["Authorization"] = "bearer " + client;
        }

        const token = localStorage.getItem("token" + (isMobile ? "1" : "0"));
        if (token != null && token != "") {
          config.headers["Token"] = token;
        }
        config.headers["Content-Type"] = "application/json";

        config.headers["Accept-Language"] = localStorage.getItem("i18nextLng");

        return config;
      },
      function (error) {
        // Do something with request error
        return Promise.reject(error);
      }
    );
    axios.interceptors.response.use(
      function (response) {
        var newResponse = {
          IsSuccess: false,
          info: ErrorSwitcher.getTranslation(
            response.data.errmsgid,
            response.data.errmsg
          ),
          code: response.data.err,
          data: response.data.data ? response.data.data : response.data,
        };
        if (response.data.url) {
          newResponse.data.url = response.data.url;
        }
        if (response.data.items) {
          newResponse.data = response.data;
          newResponse.IsSuccess = true;
        }
        if (response.data.token) {
          newResponse.data.token = response.data.token;
        }
        if (
          response.config.url.toUpperCase().indexOf("/API/AGENT/LOGIN") >= 0
        ) {
          localStorage.setItem(
            "token" + (isMobile ? "1" : "0"),
            response.data.token
          );
        }
        if (
          response.config.url.toUpperCase().indexOf("/API/AGENT/LOGOUT") >= 0
        ) {
          localStorage.removeItem("token" + (isMobile ? "1" : "0"));
        }
        if (
          response.config.url.toUpperCase().indexOf("/API/AGENT/CREATE") >= 0
        ) {
          if (response.data.data.isNew === true) {
            newResponse = {
              IsSuccess: true,
              info: "",
              code: 0,
              data: response.agentId,
            };
          } else {
            newResponse = {
              IsSuccess: false,
              info: response.data.message,
              code: 1,
            };
          }
        }
        if (response.config.url.toUpperCase().indexOf("BRANDLIVECHAT") >= 0) {
          newResponse = {
            IsSuccess: true,
            info: "",
            code: 0,
            data: response.data,
          };
        }
        if (newResponse.code === 0) {
          newResponse.IsSuccess = true;
        } else if (
          newResponse.code === 102 ||
          newResponse.code === 101 ||
          newResponse.code === 210
        ) {
          localStorage.removeItem("token" + (isMobile ? "1" : "0"));
          store.dispatch({ type: UNAUTHORIZE });

          AppHistory.replace("/");
        }

        return newResponse;
      },
      function (error) {
        if (
          error.response.data.err == 102 ||
          error.response.data.err == 101 ||
          error.response.data.err == 210 ||
          error.response.data.errmsg ===
            "request platform is not matched with player login platform."
        ) {
          if (error.response.config.url.indexOf("agent/login") < 0) {
            localStorage.removeItem("token" + (isMobile ? "1" : "0"));
            store.dispatch({ type: UNAUTHORIZE });

            AppHistory.replace("/");
          }
        }
        return {
          IsSuccess: false,
          info: ErrorSwitcher.getTranslation(
            error.response.data.errmsgid,
            error.response.data.errmsg
          ),
          code: error.response.data.err,
          data: {},
        };
      }
    );
  },
};
