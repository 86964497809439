import { AccountService } from "../services/AccountService";
import { InboxService } from "../services/InboxService";
import config from "../config/config";

// These are the constants to represent each of three recommended actions for a single API fetch or post.
export const LOGIN_BEGIN = "LOGIN_BEGIN";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILURE = "LOGIN_FAILURE";

export const CREATEAGENT_BEGIN = "CREATEAGENT_BEGIN";
export const CREATEAGENT_SUCCESS = "CREATEAGENT_SUCCESS";
export const CREATEAGENT_FAILURE = "CREATEAGENT_FAILURE";

export const GETACCOUNT_SUCCESS = "GETACCOUNT_SUCCESS";
export const GETBALANCE_SUCCESS = "GETBALANCE_SUCCESS";
export const GETACCOUNT_FAILURE = "GETACCOUNT_FAILURE";
export const GETACCOUNT_BEGIN = "GETACCOUNT_BEGIN";

export const CHECKTOKEN_BEGIN = "CHECKTOKEN_BEGIN";
export const CHECKTOKEN_SUCCESS = "CHECKTOKEN_SUCCESS";
export const CHECKTOKEN_FAILURE = "CHECKTOKEN_FAILURE";
export const LOGOUT_BEGIN = "LOGOUT_BEGIN";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const LOGOUT_FAILURE = "LOGOUT_FAILURE";
export const GETVIPINFO_SUCCESS = "GETVIPINFO_SUCCESS";
export const GETVIPINFO_FAILURE = "GETVIPINFO_FAILURE";
export const UPDATE_INFO = "UPDATE_INFO";
export const GETMESSAGE_SUCCESS = "GETMESSAGE_SUCCESS";
export const RESET_RESULT = "RESET_RESULT";
export const CREATEPLAYER_SUCCESS = "CREATEPLAYER_SUCCESS";
export const NEWPLAYERCONTAINPHONE_SUCCESS = "NEWPLAYERCONTAINPHONE_SUCCESS";
export const NEWPLAYERCONTAINEMAIL_SUCCESS = "NEWPLAYERCONTAINEMAIL_SUCCESS";
export const CHECK_REGISTER_CONDITIONS = "CHECK_REGISTER_CONDITIONS";
export const accountAction = {
  accountLogin,
  accountLogout,
  getAccountInfo,
  getAccountProfile,
  getVIPInfo,
  checkTokenIsValid,
  createAccount,
  getMessageList,
  addWithdrawalPassword,
  createNewPlayer,
  newPlayerContainPhone,
  newPlayerContainEmail,
  checkRegisterCondition,
};

function accountLogin(username, password) {
  return async (dispatch) => {
    dispatch({
      type: LOGIN_BEGIN,
      loading: true,
    });
    const response = await AccountService.Login(username, password);

    if (response.IsSuccess) {
      dispatch({
        type: LOGIN_SUCCESS,
        payload: response,
        loading: false,
      });
      // if (config.isPC === 1) {
      //   document
      //     .getElementById("ioBB")
      //     .parentNode.removeChild(document.getElementById("ioBB"));
      // }
    } else {
      dispatch({
        type: LOGIN_FAILURE,
        payload: response,
        loading: false,
      });
    }
    return response;
  };
}
function accountLogout(token) {
  return async (dispatch) => {
    dispatch({
      type: LOGOUT_BEGIN,
      loading: true,
    });
    const response = await AccountService.Logout(token);

    if (response.IsSuccess) {
      dispatch({
        type: LOGOUT_SUCCESS,
        payload: response,
        loading: false,
      });
    } else {
      dispatch({
        type: LOGOUT_FAILURE,
        payload: response,
        loading: false,
      });
    }
    // if (document.getElementById("ioBB") == null) {
    //   var node = document.createElement("input"); // Create a <li> node
    //   node.id = "ioBB";
    //   node.name = "ioBB";
    //   node.type = "hidden";
    //   // Append the text to <li>
    //   document.getElementsByTagName("body")[0].appendChild(node); // Append <li> to <ul> with id="myList"
    // }

    return response;
  };
}
function checkTokenIsValid(token) {
  return async (dispatch) => {
    dispatch({
      type: CHECKTOKEN_BEGIN,
      loading: true,
    });
    const response = await AccountService.GetMemberInfo(token);

    if (response.IsSuccess) {
      dispatch({
        type: CHECKTOKEN_SUCCESS,
        payload: response,
        loading: true,
      });
    } else {
      dispatch({
        type: CHECKTOKEN_FAILURE,
        payload: response,
        loading: false,
      });
    }
    return response;
  };
}
function getAccountInfo(token) {
  return (dispatch) => {
    dispatch({
      type: GETACCOUNT_BEGIN,
      loading: true,
    });
    const result = AccountService.GetMemberInfo(token)
      .then((response) => {
        if (response.IsSuccess) {
          dispatch({
            type: GETACCOUNT_SUCCESS,
            payload: response,
            loading: true,
          });

          // AppHistory.push("/");
        } else {
          dispatch({
            type: GETACCOUNT_FAILURE,
            payload: response,
            loading: false,
          });
        }
        return response;
      })
      .catch((error) => {
        dispatch({
          type: GETACCOUNT_FAILURE,
          payload: error,
          loading: false,
        });
      });
    return result;
  };
}
function getAccountProfile(token, showVip) {
  return (dispatch) => {
    const result = AccountService.GetMemberProfile(
      token,
      showVip === true ? 1 : 2
    )
      .then((response) => {
        if (response.IsSuccess) {
          dispatch({
            type: showVip === true ? GETACCOUNT_SUCCESS : GETBALANCE_SUCCESS,
            payload: response,
            loading: false,
          });

          // AppHistory.push("/");
        } else {
          dispatch({
            type: GETACCOUNT_FAILURE,
            payload: response,
            loading: false,
          });
        }
        return response;
      })
      .catch((error) => {
        dispatch({
          type: GETACCOUNT_FAILURE,
          payload: error,
          loading: false,
        });
      });
    return result;
  };
}
function getVIPInfo(token) {
  return (dispatch) => {
    const result = AccountService.GetVIPInfo(token)
      .then((response) => {
        if (response.IsSuccess) {
          dispatch({
            type: GETVIPINFO_SUCCESS,
            payload: response,
          });

          // AppHistory.push("/");
        } else {
          dispatch({
            type: GETVIPINFO_FAILURE,
            payload: response,
          });
        }
        return response;
      })
      .catch((error) => {
        dispatch({
          type: GETVIPINFO_FAILURE,
          payload: error,
        });
      });
    return result;
  };
}

function addWithdrawalPassword(password) {
  return (dispatch) => {
    const result = AccountService.AddWithdrawalPassword(password)
      .then((response) => {
        if (response.IsSuccess) {
          dispatch({
            type: UPDATE_INFO,
            payload: {
              member_safe_password: "set",
            },
          });
        }
        return response;
      })
      .catch((error) => {
        console.log(error);
      });
    return result;
  };
}

function createAccount(
  username,
  password,
  realname,
  email,
  phone,
  dividendPercentage,
  liveDealerRebatePercentage,
  slotRebatePercentage,
  lotteryRebatePercentage,
  cardGameRebatePercentage,
  sportsRebatePercentage,
  virtualSportsRebatePercentage,
  remarks,
  agentDividendConditions
) {
  return (dispatch) => {
    dispatch({
      type: CREATEAGENT_BEGIN,
      loading: true,
    });
    const result = AccountService.CreateAccount(
      username,
      password,
      realname,
      email,
      phone,
      dividendPercentage,
      liveDealerRebatePercentage,
      slotRebatePercentage,
      lotteryRebatePercentage,
      cardGameRebatePercentage,
      sportsRebatePercentage,
      virtualSportsRebatePercentage,
      remarks,
      agentDividendConditions
    )
      .then((response) => {
        if (response.IsSuccess) {
          dispatch({
            type: CREATEAGENT_SUCCESS,
            payload: response,
            loading: false,
          });
          // if (config.isPC === 1) {
          //   document
          //     .getElementById("ioBB")
          //     .parentNode.removeChild(document.getElementById("ioBB"));
          // }
        } else {
          dispatch({
            type: CREATEAGENT_FAILURE,
            payload: response,
            loading: false,
          });
        }
        return response;
      })
      .catch((error) => {
        dispatch({
          type: CREATEAGENT_FAILURE,
          payload: error,
          loading: false,
        });
      });
    return result;
  };
}
function getMessageList(token, limit, page) {
  return (dispatch) => {
    var result = InboxService.InboxList(token, page, limit);
    result.then((response) => {
      if (response.IsSuccess) {
        dispatch({
          type: GETMESSAGE_SUCCESS,
          payload: response,
          isNew: page === 0,
        });
      }
    });
    return result;
  };
}

function createNewPlayer(username, password) {
  return (dispatch) => {
    var result = AccountService.CreatePlayer(username, password);
    result.then((response) => {
      if (response.IsSuccess) {
        dispatch({
          type: CREATEPLAYER_SUCCESS,
          payload: response,
        });
      }
    });
    return result;
  };
}

function checkRegisterCondition(username, data) {
  return (dispatch) => {
    var result = AccountService.CheckRegistrationCondition(username, data);
    result.then((response) => {
      if (response.IsSuccess) {
        dispatch({
          type: CHECK_REGISTER_CONDITIONS,
          payload: response,
        });
      }
    });
    return result;
  };
}

function newPlayerContainPhone(playercode, areacode, phone) {
  return (dispatch) => {
    var result = AccountService.NewPlayerContainPhone(
      playercode,
      areacode,
      phone
    );
    result.then((response) => {
      if (response.IsSuccess) {
        dispatch({
          type: NEWPLAYERCONTAINPHONE_SUCCESS,
          payload: response,
        });
      }
    });
    return result;
  };
}

function newPlayerContainEmail(playercode, email) {
  return (dispatch) => {
    var result = AccountService.NewPlayerContainEmail(playercode, email);
    result.then((response) => {
      if (response.IsSuccess) {
        dispatch({
          type: NEWPLAYERCONTAINEMAIL_SUCCESS,
          payload: response,
        });
      }
    });
    return result;
  };
}
