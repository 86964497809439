import React, { Component } from "react";
import { connect } from "react-redux";
import {
  FullSizeContainer,
  Content,
  TitleContainer,
  Title,
  TitleContainerBackgroundShadow,
  CustomInputCol,
  CustomInputRow,
} from "../../../Share/General";
import styled from "styled-components";
import { Row, Col } from "react-grid-system";
import { Grid } from "@material-ui/core";

import { affiliateAction } from "../../../../actions/affiliateAction";
import { CustomTextField } from "../../../Share/General";
import ChannelLink from "./ChannelLink";
import { withSnackbar } from "notistack";
import Loading from "../../Loading";
import i18n from "../../../../utils/i18n";

const UpperContainer = styled.div`
  padding-bottom: 50px;
`;

const LowerContainer = styled.div`
  padding-bottom: 50px;
`;

const GenerateButton = styled.div`
  width: 105px;
  float: left;
  text-align: center;
  border-radius: 5px;
  font-size: 14px;
  margin-left: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid rgb(255, 255, 255);
  color: #fff;
  background: linear-gradient(
    to top,
    ${(props) => props.theme.primaryGradient},
    ${(props) => props.theme.primary}
  );
  margin-top: 10px;
  height: 46px;

  &:hover {
    cursor: pointer;
    box-shadow: rgb(255 255 255 / 75%) 1px 1px 9px 0px inset;
  }
`;

const OverrideCustomTextField = styled(CustomTextField)`
  min-width: 75% !important;
`;

class Channel extends Component {
  constructor(props) {
    super(props);

    this.state = {
      token: this.props.token,
      result: [],
      customChannel: "",
      isResultReady: false,
      isLoading: false,
    };
  }

  base_url = window.location.origin;

  async componentDidMount() {
    const result = await this.props.getAffiliateChannelList(
      this.state.token,
      this.base_url
    );
    if (result.IsSuccess) {
      this.setState({
        result: result,
        isResultReady: true,
      });
    }
  }

  async getLatestList() {
    const result = await this.props.getAffiliateChannelList(
      this.state.token,
      this.base_url
    );
    if (result.IsSuccess) {
      this.setState({
        result: result,
        isResultReady: true,
      });
    }
  }

  handleChange(e) {
    this.setState({
      customChannel: e.target.value,
    });
  }

  handleCreateNewAffiliate = async () => {
    if (this.state.customChannel === "") {
      this.props.enqueueSnackbar(i18n.t("toast.channelCantEmpty"), {
        variant: "warning",
      });
    } else {
      this.setState({
        isLoading: true,
      });
      await this.props
        .addAffiliateChannel(this.state.token, this.state.customChannel)
        .then((result) => {
          if (result.IsSuccess) {
            this.setState({
              isLoading: false,
            });
            this.getLatestList();
            this.props.enqueueSnackbar(result.msg, {
              variant: "success",
            });
          } else {
            this.props.enqueueSnackbar(result.msg, {
              variant: "warning",
            });
            this.setState({
              isLoading: false,
            });
          }
        });
    }
  };

  render() {
    return (
      <FullSizeContainer>
        <Content>
          <UpperContainer>
            <TitleContainer>
              <Grid container alignItems="flex-end">
                <Grid item>
                  <Title>{i18n.t("account.channel.title")}</Title>
                </Grid>
                <Grid item>
                  <TitleContainerBackgroundShadow>
                    {i18n.t("account.channel.tips")}
                  </TitleContainerBackgroundShadow>
                </Grid>
              </Grid>
            </TitleContainer>

            {this.state.isResultReady ? (
              this.state.result.data.map((data, index) => {
                return (
                  <ChannelLink
                    key={index}
                    id={data.id}
                    member_id={data.member_id}
                    tile={data.title}
                    url={data.url}
                  />
                );
              })
            ) : (
              <Row>
                <Col> {i18n.t("account.channel.fetching")}</Col>
              </Row>
            )}
          </UpperContainer>
          <LowerContainer>
            <TitleContainer>
              <Title>{i18n.t("account.channel.newAffiliateTitle")}</Title>
            </TitleContainer>
            <CustomInputRow>
              <CustomInputCol>
                <OverrideCustomTextField
                  variant="outlined"
                  placeholder={i18n.t("account.channel.channelName")}
                  value={this.state.customChannel}
                  onChange={this.handleChange.bind(this)}
                />

                <GenerateButton onClick={this.handleCreateNewAffiliate}>
                  {i18n.t("account.channel.generate")}
                </GenerateButton>
              </CustomInputCol>
            </CustomInputRow>
          </LowerContainer>
        </Content>
        <Loading show={this.state.isLoading}></Loading>
      </FullSizeContainer>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getAffiliateChannelList: (token, mainUrl) =>
      dispatch(affiliateAction.getAffiliateChannelList(token, mainUrl)),
    addAffiliateChannel: (token, title) =>
      dispatch(affiliateAction.setNewAffiliateChannel(token, title)),
  };
};
const mapStateToProps = (state) => {
  return {
    token: state.account.token,
    state,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withSnackbar(Channel));
