import styled from "styled-components";
import { FormControl, Select, TextField } from "@material-ui/core";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import { withStyles } from "@material-ui/core/styles";
import { Row, Col } from "react-grid-system";
import BlueBgJPG from "../../assets/images/bg.jpg";
import { Link } from "react-router-dom";
import React from "react";

export const Container = styled.div`
  margin: 0 auto;
  max-width: 1280px;
  width: 100%;
  font-size: 14px;
`;

export const TitleContainer = styled.div`
  position: relative;
  margin: 16px 0px;

  @media (max-width: 600px) {
    margin: 0px 0px 8px 0px;
  }
`;

export const Title = styled.div`
  color: #fff;
  padding: 10px 15px;
  font-weight: bold;
  font-size: 18px;

  display: flex;
  position: static;
  float: left;
  justify-content: center;
  background: ${(props) => props.theme.primary};
  position: relative;
  top: 0;
  z-index: 2;
`;

export const TitleContainerBackgroundShadow = styled.div`
  padding: 5px 20px;
  position: static;

  bottom: 0px;
  font-size: 14px;
  color: ${(props) => props.theme.primary};
  background: rgb(0, 0, 0);
  background: linear-gradient(
    90deg,
    rgba(217, 217, 217, 0.4738270308123249) 0%,
    rgba(217, 217, 217, 0) 54%
  );

  z-index: 1;
`;

export const FullSizeContainer = styled.div`
  height: 100%;
  width: 100%;
  min-height: 625px;
  padding: 16px;

  @media (max-width: 530px) {
    padding: 0px;
  }
`;

export const Content = styled.div`
  padding: 0px 16px;
  position: relative;
  overflow: auto;

  @media (max-width: 600px) {
    padding: 0px;
  }
`;

export const Label = styled.div`
  color: #9b9b9b;
  margin-bottom: 16px;
  text-align: right;
`;

export const VerificationContainer = styled.div`
  width: 100%;
  padding: 0px 32px;
  margin-bottom: 16px;
  display: flex;
  align-items: center;
`;

export const VerificationTypeIcon = styled.img`
  padding: 0px 12px;
  width: 12px;
  position: absolute;
`;

export const VerificationType = styled.div`
  float: left;
  color: #9b9b9b;
`;

export const VerificationPercentage = styled.span`
  color: #505050;
  margin-left: 16px;
`;

export const VerificationButton = styled(Link)`
  float: left;
  border: 1px solid rgb(255, 255, 255);
  color: #fff;
  background: linear-gradient(
    to top,
    ${(props) => props.theme.primaryGradient},
    ${(props) => props.theme.primary}
  );
  padding: 6px 16px;
  border-radius: 4px;
  font-size: 12px;

  font-weight: bold;
  cursor: pointer;
  outline: none;
  &:hover {
    box-shadow: rgb(255 255 255 / 75%) 1px 1px 9px 0px inset;
  }
  &:active {
    box-shadow: rgb(255 255 255 / 75%) 1px 1px 9px 0px inset;
  }
  &:focus {
    box-shadow: rgb(255 255 255 / 75%) 1px 1px 9px 0px inset;
  }
  @media (max-width: 600px) {
    min-width: 30px;
  }
`;

export const CustomTextField = withStyles({
  root: {
    width: "100%",
    minHeight: "46px",
    minWidth: "100%",
    maxWidth: "350px",
    margin: 0,
    marginTop: "13px",
    marginBottom: "0px",
    float: "left",
    overflow: "visible",
    "& .MuiOutlinedInput-input": {
      fontSize: "14px",
    },
    "& label.Mui-focused": {
      color: "#b6b6b6",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#b6b6b6",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#b6b6b6",
      },
      "&:hover fieldset": {
        borderColor: "#b6b6b6",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#b6b6b6",
      },
    },
  },
})(TextField);

export const CustomHyperlink = styled(Link)`
  color: rgb(253, 231, 63);
  text-shadow: 1px 1px 2px #000;
  text-decoration: none;
  font-weight: bold;
  margin-left: 5px;
  font-size: 14px;
`;

export const CustomInputCol = styled(Col)`
  display: flex;
  align-items: center;
  color: #9b9b9b;
  font-size: 14px;
`;

export const CustomInputRow = styled(Row)`
  display: flex;
  align-items: center;
`;

export const CustomFormControl = withStyles((theme) => ({
  root: {
    width: "193px",
    height: "41px",
    marginLeft: "5px",
    marginRight: "5px",
    float: "left",
    backgroundColor: "#fff",
    padding: 0,
    "&:before": {
      borderRight: "1px solid #b6b6b6",
      content: '"\\00a0"',
      position: "absolute",
      top: "1px",
      right: "36px",
      zIndex: " 1",
      display: "block",
      height: "100%",
    },
    "& label.Mui-focused": {
      color: "#b6b6b6",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#b6b6b6",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#b6b6b6",
        marginTop: "1px",
      },
      "&:hover fieldset": {
        borderColor: "#b6b6b6",
        marginTop: "1px",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#b6b6b6",
        marginTop: "1px",
      },
    },
  },
}))(FormControl);

export const CustomSelect = withStyles((theme) => ({
  root: {
    fontSize: "14px",

    color: "#818181",
  },
}))(Select);

export const QuickTransferButton = styled.div`
  width: 105px;
  height: 46px;
  float: left;
  text-align: center;
  border-radius: 5px;
  font-size: 14px;
  margin-left: 12px;
  display: flex;
  justify-content: center;
  align-items: center;

  border: 1px solid rgb(255, 255, 255);
  color: #fff;
  background: linear-gradient(
    to top,
    ${(props) => props.theme.primaryGradient},
    ${(props) => props.theme.primary}
  );
  @media (max-width: 600px) {
    margin-left: 0px;
    margin-top: 5px;
    width: 100%;
  }

  &:hover {
    cursor: pointer;
    box-shadow: rgb(255 255 255 / 75%) 1px 1px 9px 0px inset;
  }
`;

export const CustomContainer = styled.div`
  width: 100%;
  background: url(${BlueBgJPG});
`;
export const InnerContainer = styled.div`
  margin: 35px auto;
  width: 1230px;
  overflow: auto;
  /* background-color: rgba(21, 35, 38, 0.8); */
`;

export const ReportContainer = styled.div`
  width: 100%;

  border-radius: 10px;
  height: 100%;
  min-height: 600px;
  background-color: rgb(234, 234, 234);
  padding: 16px;
  margin-bottom: 16px;
`;
export const StyledTableCellRight = withStyles((theme) => ({
  head: {
    backgroundColor: "#f7f7f7",
    color: "#747474",
    padding: "5px 10px 5px 10px",
    textAlign: "center",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  body: {
    fontSize: 14,
    padding: "5px 10px 5px 10px",
    textAlign: "center",
    color: "#7b7b7b",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
}))(TableCell);
export const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#f7f7f7",
    color: "#747474",
    padding: "12px",
    minWidth: 110,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    position: "sticky",
  },
  body: {
    fontSize: 14,

    padding: "5px 0 5px 10px",

    color: "#7b7b7b",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
}))(TableCell);

export const StyledTableRow = withStyles((theme) => ({
  root: {
    backgroundColor: "#FFFFFF",
    "&:hover": {
      backgroundColor: "#fdeced !important",
      cursor: "pointer",
    },
  },
}))(TableRow);

export const FilterContainer = styled.div`
  height: auto;
  width: 100%;
  display: flex;
  float: left;
  margin-bottom: 16px;

  @media (max-width: 600px) {
    flex-direction: column;
  }
`;

export const CustomQuickButton = styled(QuickTransferButton)`
  height: 41px;
`;

export const NoDataContainer = styled.td`
  height: 200px;
  text-align: center;
`;

export const CustomTable = styled.div`
  width: 100%;
  overflow-x: scroll;
  margin-bottom: 10px;
  font-size: 0.875rem;
  background: #fff;

  & table {
    border-collapse: collapse;
  }

  & table thead tr div {
    color: #747474;
    overflow: hidden;
    text-align: center;
    white-space: nowrap;
    font-size: 0.875rem;
    text-overflow: ellipsis;
    display: flex;

    align-items: center;
    font-weight: normal;
  }

  & table tbody tr,
  table thead tr {
    border-bottom: 1px solid rgba(224, 224, 224, 1);
  }

  & table thead tr div.lock,
  table tbody tr div.lock {
    position: sticky;
    left: 0;
    background: #fff;
  }

  & table thead tr th,
  table tbody td {
    padding: 10px 10px 10px 20px;
    min-width: 120px;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  & table tbody td {
    cursor: pointer;
  }

  & table thead th:first-child {
    position: sticky;
    left: 0;
    z-index: 2;
  }
  & table thead tr th div.align-right {
    justify-content: right;
  }
  & table thead tr th div.align-center {
    justify-content: center;
  }
  & table tbody th {
    position: sticky;
    left: 0;
    background: white;
    z-index: 1;
  }

  & table tbody td.align-right {
    text-align: right;
  }
  & table tbody td.align-center {
    text-align: center;
  }
  & table th {
    background: #f7f7f7;
  }

  & table thead tr {
    background-color: #f7f7f7;
    :first-child {
      min-width: 150px;
    }
  }

  & table tbody tr th {
    font-weight: normal;
    text-align: left;
    padding: 10px 10px 10px 20px;
  }

  ::-webkit-scrollbar {
    height: 5px;
  }

  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  ::-webkit-scrollbar-thumb {
    background: #888;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
`;

export const CustomTableHeader = (props) => {
  return (
    <th>
      <div class={props.class}>{props.children}</div>
    </th>
  );
};
