import moment from "moment";
import React from "react";
import styled from "styled-components";
import Common from "../../../../../../utils/Common";
import i18n from "../../../../../../utils/i18n";
import Loading from "../loading";
const CustomDataRow = styled.div`
  display: grid;
  grid-template-columns: 30% auto;
  padding: 7.5px 10px;
`;

const CustomContainer = styled.div`
  padding: 10px;
`;

const CustomDataTitle = styled.div`
  font-weight: bold;
  font-size: 12px;
  display: flex;
  height: 100%;
  align-items: center;
`;

const CustomDataContent = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
`;

const TabUserDetail = ({ data, teamsize }) => {
  const processedData = [
    {
      title: i18n.t("account.agentInfo.agentDetails.username"),
      data: data.username,
    },
    {
      title: i18n.t("account.agentInfo.agentDetails.realname"),
      data: data.realname,
    },
    // {
    //   title: i18n.t("account.agentInfo.agentDetails.agentlevel"),
    //   data: data.agentlevel,
    // },

    {
      title: i18n.t("account.agentInfo.agentDetails.registeredon"),
      data: moment(data.registeredon).format("YYYY-MM-DD HH:mm:ss"),
    },
    {
      title: i18n.t("account.agentInfo.agentDetails.balance"),
      data: data.balance,
    },
    {
      title: i18n.t("account.agentInfo.agentDetails.status"),
      data: i18n.t(`custom.affiliateDropdown.status.` + data.status),
    },
    {
      title: i18n.t("account.agentInfo.agentDetails.category"),
      data: i18n.t(`custom.type.` + data.type),
    },
    {
      title: i18n.t("account.agentInfo.agentDetails.type"),
      data: i18n.t(`custom.membertype.` + data.leveltype),
    },
    {
      title: i18n.t("report.agentDividendPercentage"),
      data: data.dividendpercentage + "%",
    },
    {
      title: i18n.t("account.agentInfo.agentDetails.accumulatedprofitsharing"),
      data: Common.formatNumberWithCommasNoRounding(
        data.accumulatedprofitsharing
      ),
    },
  ];

  if (data === "") {
    return <Loading></Loading>;
  } else {
    return (
      <CustomContainer>
        {processedData.map((data, index) => {
          return (
            <CustomDataRow key={index}>
              <CustomDataTitle>{data.title}</CustomDataTitle>
              <CustomDataContent>{data.data}</CustomDataContent>
            </CustomDataRow>
          );
        })}
      </CustomContainer>
    );
  }
};

export default TabUserDetail;
