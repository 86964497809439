import uiReducer from "./uiReducer";
import accountReducer from "./accountReducer";
import { bonusReducer } from "./bonusReducer";
import { generalReducer } from "./generalReducer";
import modalReducer from "./modalReducer";
import { combineReducers } from "redux";
import affiliateReducer from "./affiliateReducer";
import walletReducer from "./walletReducer";
import reportReducer from "./reportReducer";

const rootReducer = combineReducers({
  ui: uiReducer,
  account: accountReducer,
  bonus: bonusReducer,
  modal: modalReducer,
  general: generalReducer,
  affiliate: affiliateReducer,
  wallet: walletReducer,
  report: reportReducer,
});

export default rootReducer;
