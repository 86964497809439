import React, { useEffect, useState, useContext } from "react";

import Header from "../Account/Header";
import {
  CustomContainer,
  ReportContainer,
  StyledTableCell,
  FilterContainer,
  CustomFormControl,
  TitleContainer,
  Title,
  Content,
  CustomQuickButton,
} from "../../Share/General";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";

import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TablePagination from "@material-ui/core/TablePagination";
import ReportDatepicker from "./ReportDatepicker";
import Select from "@material-ui/core/Select";
import { Container } from "@material-ui/core";
import { affiliateAction } from "../../../actions/affiliateAction";
import { connect } from "react-redux";
import { FunctionContext } from "../../Context/FunctionContext";

const OperationalReport = (props) => {
  const [page, setPage] = useState(0);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    getOperationalReportList(selectedDateFrom, selectedDateTo, newPage + 1);
  };

  const [channel, setChannel] = useState(null);
  const [render, setRendered] = useState(false);
  const [assigned, setAssigned] = useState(false);

  useEffect(() => {
    setChannel(props.first);
    setRendered(true);

    if (channel !== "") {
      setAssigned(true);
    }
  }, []);
  const defaultChannel = localStorage.getItem("default");
  useEffect(() => {
    if (render === true && assigned === true) {
      getOperationalReportList(
        selectedDateFrom,
        selectedDateTo,
        channel ? channel : defaultChannel
      );
    }
  }, [render, assigned]);

  const [result, setResult] = useState();

  const getOperationalReportList = async (dateFrom, dateTo, channel, page) => {
    const result = await props.getAffiliateOperationalReport(
      props.token,
      props.data.member_id,
      channel,
      dateFrom,
      dateTo,
      page ? page : 1,
      10
    );
    if (result.IsSuccess) {
      setResult(result.data);
    }
  };

  const { selectedDateFrom, selectedDateTo } = useContext(FunctionContext);

  const getUserSelectedQuery = (dateFrom, dateTo, affiliateId) => {
    getOperationalReportList(dateFrom, dateTo, affiliateId);
  };

  return (
    <CustomContainer>
      <Container maxWidth="lg">
        <Header />
        <ReportContainer>
          <Content>
            <TitleContainer>
              <Title>运营报表</Title>
            </TitleContainer>
            <FilterContainer>
              <ReportDatepicker></ReportDatepicker>
              <CustomFormControl>
                <Select
                  native
                  variant="outlined"
                  defaultValue={props.first}
                  onChange={(e) => {
                    setChannel(e.target.value);
                  }}
                >
                  {props.lists.map((data, index) => {
                    return <option value={data.id}>{data.title}</option>;
                  })}
                </Select>
              </CustomFormControl>
              <CustomQuickButton
                onClick={() =>
                  getUserSelectedQuery(
                    selectedDateFrom,
                    selectedDateTo,
                    channel ? channel : defaultChannel
                  )
                }
              >
                查询
              </CustomQuickButton>
            </FilterContainer>
            <Table aria-label="customized table">
              <TableHead>
                <TableRow>
                  <StyledTableCell>日期</StyledTableCell>
                  <StyledTableCell>存款总额</StyledTableCell>
                  <StyledTableCell>提款总额</StyledTableCell>
                  <StyledTableCell>红利总额</StyledTableCell>
                  <StyledTableCell>客户调账</StyledTableCell>
                  <StyledTableCell>客户流水</StyledTableCell>
                  <StyledTableCell>产品盈亏</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {result
                  ? result.datas.map((data, index) => {
                      return (
                        <TableRow>
                          <StyledTableCell>{data.day}</StyledTableCell>
                          <StyledTableCell>{data.deposit}</StyledTableCell>
                          <StyledTableCell>{data.withdrawal}</StyledTableCell>
                          <StyledTableCell>{data.bonus}</StyledTableCell>
                          <StyledTableCell>{data.adjustment}</StyledTableCell>
                          <StyledTableCell>{data.turnover}</StyledTableCell>
                          <StyledTableCell>{data.contribution}</StyledTableCell>
                        </TableRow>
                      );
                    })
                  : ""}
              </TableBody>
            </Table>

            <TablePagination
              rowsPerPageOptions={[10]}
              component="div"
              count={result ? result.total : ""}
              rowsPerPage={10}
              page={page}
              backIconButtonProps={{
                "aria-label": "previous page",
              }}
              nextIconButtonProps={{
                "aria-label": "next page",
              }}
              onChangePage={handleChangePage}
            />
          </Content>
        </ReportContainer>
      </Container>
    </CustomContainer>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    getAffiliateChannelList: (token, mainUrl) =>
      dispatch(affiliateAction.getAffiliateChannelList(token, mainUrl)),
    getAffiliateOperationalReport: (
      token,
      affiliate_id,
      channel_list,
      start,
      end,
      page,
      limit
    ) =>
      dispatch(
        affiliateAction.getAffiliateOperationalReport(
          token,
          affiliate_id,
          channel_list,
          start,
          end,
          page,
          limit
        )
      ),
  };
};
const mapStateToProps = (state) => {
  return {
    token: state.account.token,
    data: state.account.info,
    lists: state.affiliate.lists,
    first: state.affiliate.lists[0].id,
    state,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(OperationalReport);
