import featureList from "./features";

const config = {
  baseUrl: window?.env ? "/api/" : "https://dev-agent.rpgp.cc/api/",
  client:
    window?.env?.client ||
    "I7nKiimrZQOqnJ6ryjUw74c0Hi64KC6Myunhob4cHh8VOUIuZh4p63czefxyyzbZ",
  currency: window?.env?.currency || "IDR",
  site: window?.env?.site || " https://dev-t1.rpgp.cc/",

  brand: window?.env?.brand || process.env.REACT_APP_BRAND || "TIGER",
  brandName: window?.env?.brandName
    ? window?.env?.brandName
    : window?.env?.brand || "PISO",
  theme: window?.env?.theme || "gray",
  languages: window?.env?.languages
    ? window?.env?.languages
    : [
        { name: "zh-CN", label: "简体" },
        { name: "zh-TW", label: "繁體" },
        { name: "en-US", label: "English" },
        { name: "th-TH", label: "Thai" },
      ],
  defaultLanguage: window?.env?.defaultLanguage || "zh-CN",
  liveChatUrl: window?.env?.liveChat || "",
  features: featureList["default"],
  version: window?.env?.version,
  configUrl: window?.env?.configUrl || "https://app12.srvptt.com/",
};

export default config;
