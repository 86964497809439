import React, { Component } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import { Redirect } from "react-router";

import { withRouter } from "react-router-dom";
import Loading from "../../Loading";
// import PassSetting from "./PassSetting";
import Success from "./Success";
import AddCard from "./AddCard";
import { isMobile } from "react-device-detect";
import Main from "./Main";
import config from "../../../../config/config";
import {
  FullSizeContainer,
  Content,
  TitleContainer,
  Title,
} from "../../../Share/General";
import { walletAction } from "../../../../actions/walletActions";
import { RESET_WALLETRESULT } from "../../../../constant/actionType";
import { withSnackbar } from "notistack";
import i18n from "../../../../utils/i18n";

const InnerWrap = styled.div`
  width: 935px;
  min-height: 532px;
  margin-top: 18px;
  box-sizing: border-box;
  background-color: #f7f7f7;
  padding: 25px;
  position: relative;
  border-radius: 10px;

  @media (max-width: 530px) {
    width: auto;
  }
`;

class Withdrawal extends Component {
  state = {
    disableButton: false,
    loading: false,
    bankCardList: [],
    defaultCard: 0,
    addCard: {
      number: "",
      password: "",
    },
    isAddCardSuccess: false,
    displayView: 1,
    showMessagebox: false,
    messageboxText: "",
    withdrawalInfo: {
      bonus_limit_list: [],
    },
  };
  async componentDidMount() {
    const localStorageToken = localStorage.getItem(
      "token" + (isMobile ? 1 : 0)
    );
    if (localStorageToken === null) {
      this.props.history.push("/");
    }
    if (
      this.props.account.info.check_pass_email === false &&
      config.brand !== "HK28" &&
      config.currency !== "IDR"
    ) {
      this.props.onhandleClickTab("safety");
    }

    if (this.props.wallet.bankCardList.length === 0)
      this.props.getBankCardList();
    if (this.props.wallet.withdrawalList.length === 0)
      this.props.getWithdrawalChannel();
  }

  componentDidUpdate(preProps) {
    if (
      preProps.wallet.deleteBankCardResult !==
        this.props.wallet.deleteBankCardResult &&
      this.props.wallet.deleteBankCardResult !== null
    ) {
      if (this.props.wallet.deleteBankCardResult.IsSuccess) {
        this.props.getBankCardList();
      } else {
        this.props.enqueueSnackbar(
          this.props.wallet.deleteBankCardResult.info,
          {
            variant: "error",
          }
        );
      }
      this.props.resetResult();
    }
    if (
      preProps.wallet.addBankCardResult !==
        this.props.wallet.addBankCardResult &&
      this.props.wallet.addBankCardResult !== null
    ) {
      if (this.props.wallet.addBankCardResult.IsSuccess) {
        this.props.resetResult();
        this.props.getBankCardList();
        this.setState({
          ...this.state,
          loading: false,
          isAddCardSuccess: true,
        });
      } else {
        this.props.enqueueSnackbar(this.props.wallet.addBankCardResult.info, {
          variant: "error",
        });
      }
    }

    if (
      preProps.wallet.withdrawalResult !== this.props.wallet.withdrawalResult &&
      this.props.wallet.withdrawalResult !== null
    ) {
      if (this.props.wallet.withdrawalResult.IsSuccess) {
        this.props.resetResult();
        this.props.getBalanceList();
        this.setState({ ...this.state, displayView: 4 });
      } else {
        this.props.enqueueSnackbar(this.props.wallet.withdrawalResult.info, {
          variant: "error",
        });
      }
    }
  }

  handleAddCardNextClick = (channelid, bankType, detail) => {
    this.props.addBankCard(channelid, bankType, detail);
  };

  handleBindCardClick = () => {
    this.setState({ ...this.state, isAddCardSuccess: false, displayView: 2 });
  };
  handleGoWithdrawClick = () => {
    this.setState({ ...this.state, isAddCardSuccess: false, displayView: 1 });
  };
  handleCSClick = () => {
    this.props.onhandleCSClick(
      this.props.account.info.member_id,
      this.props.account.info.member_name,
      ""
    );
  };
  handleManageCardClick = () => {
    this.props.onhandleClickTab("card");
    //this.setState({ ...this.state, displayView: 3 });
  };
  handleAddCardClick = () => {
    this.setState({ ...this.state, displayView: 2 });
  };
  handleCardManageBack = () => {
    this.setState({ ...this.state, displayView: 1 });
  };
  handleAddCardBack = () => {
    this.setState({ ...this.state, displayView: 3 });
  };
  handleDeleteCardClick = (id) => {
    this.props.deleteBankCard(id);
  };
  handleReportClick = () => {
    this.props.onhandleClickTab("report");
  };
  handleSuccessConfirmClick = () => {
    this.setState({ ...this.state, displayView: 1, disableButton: false });
  };
  handleWithdrawalClick = (bankid, amount, password) => {
    const formatedamount = amount.toString().replace(/,/g, "");
    if (bankid == 0) {
      this.props.enqueueSnackbar(i18n.t("account.withdrawal.msgEnterBank"), {
        variant: "warning",
      });
      return;
    }
    if (parseFloat(formatedamount) <= 0) {
      this.props.enqueueSnackbar(i18n.t("account.withdrawal.msgInvalidAmt"), {
        variant: "warning",
      });
      return;
    }

    // if (
    //   parseFloat(formatedamount) > parseFloat(this.props.wallet.mainBalance)
    // ) {
    //   this.props.enqueueSnackbar(i18n.t("account.withdrawal.msgInsufficient"), {
    //     variant: "warning",
    //   });
    //   return;
    // }
    if (config.features.WithdrawalPassword) {
      if (password.length <= 0) {
        this.props.enqueueSnackbar(
          i18n.t("account.withdrawal.msgEnterWithdrawalPass"),
          {
            variant: "warning",
          }
        );
        return;
      }
    }

    this.setState({
      ...this.state,
      disableButton: true,
    });
    if (this.state.disableButton === true) {
    } else {
      this.props.doWithdraw(formatedamount, password, bankid);
    }
  };

  render() {
    return (
      <FullSizeContainer>
        <Content>
          <TitleContainer>
            <Title>{i18n.t("account.withdrawal.title")}</Title>
          </TitleContainer>
          <InnerWrap>
            {(() => {
              if (this.props.account.loading === false) {
                if (config.currency === "IDR") {
                } else {
                  if (config.brand !== "HK28") {
                    if (this.props.account.info.check_pass_email === false) {
                      return (
                        <Redirect
                          to={`/account/safety/member_email`}
                        ></Redirect>
                      );
                    } else if (
                      this.props.account.info.member_safe_password !== "set"
                    ) {
                      return <Redirect to={`/account/safety`}></Redirect>;
                    }
                  }
                }

                if (
                  this.props.wallet.bankCardList != null &&
                  this.props.wallet.bankCardList.length === 0
                ) {
                  return (
                    <AddCard
                      name={this.props.account.info.member_name}
                      onhandleNextClick={this.handleAddCardNextClick}
                      withdrawalList={this.props.wallet.withdrawalList}
                      showBack={false}
                    ></AddCard>
                  );
                } else {
                  if (this.state.displayView === 4)
                    return (
                      <Success
                        handleConfirmClick={this.handleSuccessConfirmClick}
                      ></Success>
                    );
                  else
                    return (
                      <Main
                        defaultCard={this.state.defaultCard}
                        cardList={this.props.wallet.bankCardList}
                        withdrawalList={this.props.wallet.withdrawalList}
                        withdrawalInfo={this.state.withdrawalInfo}
                        onhandleManageCardClick={this.handleManageCardClick}
                        onhandleWithdrawalClick={this.handleWithdrawalClick}
                        account={this.props.account}
                        disableButton={this.state.disableButton}
                      ></Main>
                    );
                }
              }
            })()}

            <Loading show={this.state.loading}></Loading>
          </InnerWrap>
        </Content>
      </FullSizeContainer>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    token: state.account.token,
    account: state.account,

    game: state.game,
    wallet: state.wallet,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getBalanceList: (token) => dispatch(walletAction.getBalanceList()),
    getBankCardList: () => dispatch(walletAction.getBankCardList()),
    deleteBankCard: (id) => dispatch(walletAction.deleteBankCard(id)),
    addBankCard: (channelid, bankType, detail) =>
      dispatch(walletAction.addBankCard(channelid, bankType, detail)),
    getWithdrawalChannel: () => dispatch(walletAction.getWithdrawalChannel()),
    resetResult: () =>
      dispatch({
        type: RESET_WALLETRESULT,
      }),
    doWithdraw: (amount, password, user_account_id) =>
      dispatch(walletAction.doWithdraw(amount, user_account_id, password)),
  };
};
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withSnackbar(Withdrawal))
);
