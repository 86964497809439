import React, { Component } from "react";
import styled from "styled-components";

import BGAppDownloadJPG from "../../../assets/images/bg-app-download.jpg";

import AppDownloadPhoneIconPNG from "../../../assets/images/appdownload-phone-icon-2.png";

import QRCode1 from "../../../assets/images/website.png";
import QRCode2 from "../../../assets/images/appdownload-qr-soon.png";
const Container = styled.div`
  width: 100%;
  height: 600px;
  position: relative;
  background: url(${BGAppDownloadJPG}) no-repeat center bottom;
`;
const Inner = styled.div`
  width: 880px;
  margin: 0 auto;
  padding-top: 90px;
`;
const LeftContainer = styled.div`
  width: 300px;
  height: 600px;
  float: left;
`;
const RightContainer = styled.div`
  width: 450px;
  height: 600px;
  float: right;
`;
const TitleContainer = styled.div`
  background: url(${AppDownloadPhoneIconPNG}) no-repeat center;

  font-weight: bold;
  color: #404040;

  height: 99px;
  width: 450px;
`;
const DescContainer = styled.div`
  font-size: 15px;
  padding: 20px;
  text-align: center;
  color: #404040;
`;
const QRContainer = styled.div`
  margin: 50px 0 0 60px;
  float: left;
  width: 160px;
`;
const QRRedTitle = styled.div`
  text-align: center;

  font-size: 22px;

  color: #404040;
`;
const QRMiniTitle = styled.div`
  text-align: center;

  margin: 8px;

  font-size: 14px;

  color: #404040;
`;
const QRContainerVisit = styled(QRContainer)`
  padding-top: 15px;
`;
const QRImg = styled.img`
  width: 103px;
  margin: 0 auto;
  display: block;
  margin: 20px auto 0 auto;
`;

const QRImgDiv = styled.div`
  width: 103px;
  height: 103px;
  margin: 0 auto;
  display: block;
  background: url(${(props) => props.src}) no-repeat;
  background-size: contain;
  text-align: center;
  color: #000;
  font-size: 18px;
  font-weight: bold;
  line-height: 103px;
`;

class Download extends Component {
  render() {
    return (
      <Container>
        <Inner>
          <LeftContainer></LeftContainer>
          <RightContainer>
            <TitleContainer></TitleContainer>
            <DescContainer>
              {" "}
              触手可及的信息，从指尖到脑海，存取转安全便捷。真人娱乐，体育投注，电子游艺等尽在一手掌握。凯旋娱乐城力求给每一位用户呈现别样、缤纷的极致体验。
            </DescContainer>
            <QRContainer>
              <QRRedTitle>APP扫码下载</QRRedTitle>
              <QRMiniTitle>(支持Android/iOS)</QRMiniTitle>
              <QRImgDiv src={QRCode2}>尽情期待</QRImgDiv>
            </QRContainer>
            <QRContainerVisit>
              <QRRedTitle>手机免下载访问</QRRedTitle>

              <QRImg src={QRCode1}></QRImg>
            </QRContainerVisit>
          </RightContainer>
        </Inner>
      </Container>
    );
  }
}

export default Download;
