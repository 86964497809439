import {
  GETROUNDSREPORTSTATUS_BEGIN,
  GETROUNDSREPORTSTATUS_FAILED,
  GETROUNDSREPORTSTATUS_SUCCESS,
} from "../constant/actionType";
import { ReportService } from "../services/ReportService";

export const reportAction = {
  getRoundsStatus,
};
function getRoundsStatus() {
  return (dispatch) => {
    dispatch({
      type: GETROUNDSREPORTSTATUS_BEGIN,
    });
    const result = ReportService.GetRoundStatus()
      .then((response) => {
        if (response.IsSuccess) {
          dispatch({
            type: GETROUNDSREPORTSTATUS_SUCCESS,
            payload: response,
          });
        } else {
          dispatch({
            type: GETROUNDSREPORTSTATUS_FAILED,
            payload: response,
          });
        }
        return response;
      })
      .catch((error) => {
        dispatch({
          type: GETROUNDSREPORTSTATUS_FAILED,
          payload: error,
        });
      });

    return result;
  };
}
