import React from "react";
import clsx from "clsx";
import styled from "styled-components";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import { makeStyles } from "@material-ui/core/styles";
import EmailSVG from "../../../assets/images/email.png";
import SkypeSVG from "../../../assets/images/skype.png";
import LiveCallSVG from "../../../assets/images/livecall.png";
import LiveChatSVG from "../../../assets/images/livechat.png";
import AboutBG from "../../../assets/images/aboutus-bg.png";
import Config from "../../../config/config";
import CustomButton from "../../Share/Button";
import CustomTextField from "../../Share/TextField";
import i18n from "../../../utils/i18n";
const Container = styled.div`
  width: 100%;
  background: url(${Config.sourceUrl + "/static/image/blue-bg.jpg"});
  position: relative;
  min-height: 800px;
`;
const InnerWrap = styled.div`
  width: 1000px;
  margin: 26px auto;
  background-color: rgba(21, 35, 38, 0.8);

  padding: 5px;
`;

const CustomCustomButton = styled(CustomButton)`
  margin-left: 10px;
`;

const ButtonWrapper = styled.div`
  margin-left: 8px;
`;
const LeftCard = styled.div`
  background-color: #243941;
  width: 207px;
  float: left;
  height: 767px;
`;

const LeftCardTitleContainer = styled.div`
  padding: 10px;
  background-image: url(${AboutBG});
  color: white;

  width: 207px;
  height: 113px;
`;

const LeftCardTitleUp = styled.div`
  font-size: 29px;
`;

const LeftCardTitleDown = styled.div`
  font-size: 21px;
`;

const LeftCardContent = styled.div`
  height: 100%;
  padding-left: 20px;
`;

const RightCard = styled.div`
  background-color: #fff;
  width: 777px;
  float: right;
`;

const RightCardTitleContainer = styled.div`
  width: 100%;
  height: 81px;
  font-size: 26px;
  font-weight: bold;
  background-color: #fdeced;
  padding: 25px 0 0 30px;
`;

const RightCardTitle = styled.div`
  font-size: 26px;
`;

const RightCardContent = styled.div`
  font-size: 14px;
  color: #747474;
  padding: 20px 30px;
  min-height: 686px;
`;

const ContactIcon = styled.img`
  margin: 40px auto 5px auto;
  fill: white !important;
  height: 15px;
`;

const ContactDesc = styled.div`
  text-align: left;
  font-size: 14px;
  color: rgb(191, 210, 191);
`;
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  margin: {
    margin: theme.spacing(1),
  },
  withoutLabel: {
    marginTop: theme.spacing(1),
  },
  textField: {
    width: "100%",
  },
  adornment: {
    height: "40px",
  },
  helper: {
    fontSize: "12px",
  },
}));
const Contact = () => {
  const classes = useStyles();
  return (
    <Container>
      <InnerWrap>
        <LeftCard>
          <LeftCardTitleContainer>
            <LeftCardTitleUp>联系我们</LeftCardTitleUp>
            <LeftCardTitleDown>Contact us</LeftCardTitleDown>
          </LeftCardTitleContainer>
          <LeftCardContent>
            <ContactIcon src={EmailSVG}></ContactIcon>
            <ContactDesc>
              {i18n.t("contact.collab")}: <br /> affiliate@633.com
            </ContactDesc>

            <ContactIcon src={SkypeSVG}></ContactIcon>
            <ContactDesc>
              {i18n.t("contact.collab")}: <br />
              affiliate@633.com
            </ContactDesc>

            <ContactIcon src={LiveChatSVG}></ContactIcon>
            <ContactDesc> {i18n.t("contact.onlineChat")}</ContactDesc>

            <ContactIcon src={LiveCallSVG}></ContactIcon>
            <ContactDesc>
              {i18n.t("contact.hotline")}: <br />
              4001208118
            </ContactDesc>
          </LeftCardContent>
        </LeftCard>
        <RightCard>
          <RightCardTitleContainer>
            <RightCardTitle>{i18n.t("contact.title")}</RightCardTitle>
          </RightCardTitleContainer>
          <RightCardContent>
            <FormControl
              className={clsx(
                classes.margin,
                classes.withoutLabel,
                classes.textField
              )}
            >
              <FormHelperText className={clsx(classes.helper)}>
                {i18n.t("contact.realname")}
              </FormHelperText>
              <CustomTextField fullWidth />
            </FormControl>
            <FormControl
              className={clsx(
                classes.margin,
                classes.withoutLabel,
                classes.textField
              )}
            >
              <FormHelperText className={clsx(classes.helper)}>
                {i18n.t("contact.email")}
              </FormHelperText>
              <CustomTextField fullWidth />
            </FormControl>
            <FormControl
              className={clsx(
                classes.margin,
                classes.withoutLabel,
                classes.textField
              )}
            >
              <FormHelperText className={clsx(classes.helper)}>
                {i18n.t("contact.problem")}
              </FormHelperText>
              <CustomTextField
                placeholder=""
                variant="outlined"
                name="biography"
                style={{ height: "auto" }}
                multiline
                rows="4"
              />
            </FormControl>

            <ButtonWrapper>
              <CustomCustomButton name={i18n.t("common.submit")} />
            </ButtonWrapper>
          </RightCardContent>
        </RightCard>
      </InnerWrap>
    </Container>
  );
};

export default Contact;
