import React, { Component } from "react";
import { connect } from "react-redux";
import styled, { keyframes } from "styled-components";

import { Link } from "react-router-dom";

import i18n from "../../utils/i18n";
export const rotateKeyFrame = keyframes`
  0% {
    transform:rotate(0deg)
  }
  100% {
    transform:rotate(-359deg)
  }
`;
const Container = styled.div`
  font-size: 14px;

  display: flex;
  align-items: center;
`;

const BtnLogout = styled.div`
  border: solid 1px #fff;
  height: 30px;
  margin-top: 3px;
  min-width: 55px;
  width: auto;
  padding: 0px 6px;
  border-radius: 3px;
  font-size: 14px;
  color: #fff;
  line-height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  float: right;
  &:hover {
    cursor: pointer;
    color: rgb(253, 231, 63);
    border: solid 1px rgb(253, 231, 63);
  }
`;

const BalanceContainer = styled.div`
  color: #ffffff;
  text-align: right;

  float: right;
  &:hover {
    cursor: pointer;
  }
`;

const MemberUserName = styled.span`
  color: rgb(253, 231, 63);
`;
const Spliter = styled.div`
  width: 2px;
  height: 16px;
  margin: 0px 4px;
  float: right;
  background-color: rgba(255, 255, 255, 0.2);
`;

const WelcomeContainer = styled(BalanceContainer)`
  color: #fff;
  float: right;
  margin-right: 0px;

  max-width: 300px;

  @media (max-width: 520px) {
    display: none;
    max-width: 125px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;
const CustomLink = styled(Link)`
  text-decoration: none;
  color: inherit;

  &:hover {
    cursor: pointer;
  }
`;
class MemberContainer extends Component {
  render() {
    return (
      <Container>
        <WelcomeContainer>
          <CustomLink>
            {i18n.t("header.welcomeBack")}
            <MemberUserName>
              {" "}
              {this.props.account.info.member_username}
            </MemberUserName>
          </CustomLink>
        </WelcomeContainer>
        <Spliter></Spliter>
        <BtnLogout onClick={this.props.onhandleLogout}>
          {i18n.t("header.logout")}
        </BtnLogout>
        <Spliter></Spliter>
      </Container>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    // screenWidth: state.ui.screenWidth,
    // screenHeight: state.ui.screenHeight,
    account: state.account,
  };
};
export default connect(mapStateToProps, null)(MemberContainer);
