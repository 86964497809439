import React from "react";
import styled from "styled-components";
import { withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TablePagination from "@material-ui/core/TablePagination";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import Checkbox from "@material-ui/core/Checkbox";
import IconInboxSelectedPNG from "../../../../assets/images/icon-inbox-selected.png";
import IconInboxCheckPNG from "../../../../assets/images/icon-inbox-check.png";
import i18next from "i18next";

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#fff",
    color: "#747474",
    padding: "12px",
    maxWidth: "600px",
  },
  body: {
    fontSize: 14,
    padding: "0px 0 0 10px",
    maxWidth: "600px",
    color: "#7b7b7b",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    backgroundColor: "#FFFFFF",
    "&:hover": {
      backgroundColor: "#fdeced !important",
      cursor: "pointer",
    },
  },
}))(TableRow);

// function stableSort(array, cmp) {
//   const stabilizedThis = array.map((el, index) => [el, index]);
//   stabilizedThis.sort((a, b) => {
//     const order = cmp(a[0], b[0]);
//     if (order !== 0) return order;
//     return a[1] - b[1];
//   });
//   return stabilizedThis.map(el => el[0]);
// }

// function getSorting(order, orderBy) {
//   return order === "desc"
//     ? (a, b) => desc(a, b, orderBy)
//     : (a, b) => -desc(a, b, orderBy);
// }
// function desc(a, b, orderBy) {
//   if (b[orderBy] < a[orderBy]) {
//     return -1;
//   }
//   if (b[orderBy] > a[orderBy]) {
//     return 1;
//   }
//   return 0;
// }
const useStyles = makeStyles({
  root: {
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  icon: {
    width: 20,
    height: 20,

    backgroundImage: "url(" + IconInboxCheckPNG + ")",
    backgroundRepeat: "no-repeat",
    "$root.Mui-focusVisible &": {},
    "input:hover ~ &": {},
    "input:disabled ~ &": {},
  },
  checkedIcon: {
    backgroundImage: "url(" + IconInboxSelectedPNG + ")",
    backgroundRepeat: "no-repeat",
    "&:before": {
      display: "block",
      width: 20,
      height: 20,
      backgroundImage: "url(" + IconInboxSelectedPNG + ")",
      backgroundRepeat: "no-repeat",
      content: '""',
    },
    "input:hover ~ &": {},
  },
});
const InnerWrap = styled.div`
  width: 100%;
  float: left;
  margin-top: 8px;
  box-sizing: border-box;
  background-color: #fff;
`;
function List(props) {
  const classes = useStyles();

  const handleClick = (event, id) => {
    const selectedIndex = props.selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(props.selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(props.selected.slice(1));
    } else if (selectedIndex === props.selected.length - 1) {
      newSelected = newSelected.concat(props.selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        props.selected.slice(0, selectedIndex),
        props.selected.slice(selectedIndex + 1)
      );
    }

    props.onhandleSelectedChange(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    props.onhandleChangePage(newPage + 1);
  };

  // const handleChangeRowsPerPage = event => {
  //   setRowsPerPage(parseInt(event.target.value, 10));
  //   setPage(0);
  // };

  const isSelected = (id) => props.selected.indexOf(id) !== -1;

  return (
    <InnerWrap>
      <Table aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell
              style={{ paddingLeft: "20px" }}
              align="right"
              width="30px"
            ></StyledTableCell>
            <StyledTableCell>
              {i18next.t("account.inbox.table.category")}
            </StyledTableCell>
            <StyledTableCell>
              {i18next.t("account.inbox.table.title")}
            </StyledTableCell>
            <StyledTableCell align="right" style={{ paddingRight: "20px" }}>
              {i18next.t("account.inbox.table.time")}
            </StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.rows.map((row, index) => {
            const isItemSelected = isSelected(row.id);
            const labelId = `enhanced-table-checkbox-${index}`;

            return (
              <StyledTableRow
                hover
                role="checkbox"
                aria-checked={isItemSelected}
                tabIndex={-1}
                key={row.id}
                selected={isItemSelected}
              >
                <StyledTableCell align="right">
                  <Checkbox
                    checked={isItemSelected}
                    inputProps={{ "aria-labelledby": labelId }}
                    className={classes.root}
                    onClick={(event) => handleClick(event, row.id)}
                    checkedIcon={
                      <span
                        className={clsx(classes.icon, classes.checkedIcon)}
                      />
                    }
                    icon={<span className={classes.icon} />}
                  />
                </StyledTableCell>
                <StyledTableCell
                  onClick={(event) => props.onhandleRowClick(event, row)}
                  id={labelId}
                  style={
                    row.is_read === 1
                      ? { fontWeight: "normal" }
                      : { fontWeight: "bold" }
                  }
                >
                  {row.is_admin_send ? "系统公告" : "其它"}
                </StyledTableCell>
                <StyledTableCell
                  onClick={(event) => props.onhandleRowClick(event, row)}
                  style={
                    row.is_read === 1
                      ? { fontWeight: "normal" }
                      : { fontWeight: "bold" }
                  }
                >
                  {row.title}
                </StyledTableCell>
                <StyledTableCell
                  align="right"
                  onClick={(event) => props.onhandleRowClick(event, row)}
                  style={
                    row.is_read === 1
                      ? { fontWeight: "normal", paddingRight: "20px" }
                      : { fontWeight: "bold", paddingRight: "20px" }
                  }
                >
                  {row.create_time}
                </StyledTableCell>
              </StyledTableRow>
            );
          })}
        </TableBody>
      </Table>
      <TablePagination
        rowsPerPageOptions={[20]}
        component="div"
        count={props.total}
        rowsPerPage={20}
        page={props.page - 1}
        labelRowsPerPage={("asd", "213123", "asdasdasda", "每頁行数")}
        backIconButtonProps={{
          "aria-label": "previous page",
        }}
        nextIconButtonProps={{
          "aria-label": "next page",
        }}
        onChangePage={handleChangePage}
      />
    </InnerWrap>
  );
}
export default List;
