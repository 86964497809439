import { ApiService } from "./ApiService";

export const ReportService = {
  GetAccountDetails,
  GetTransactionDetails,
  GetDailyDetails,
  GetContribution,
  GetDividend,
  GetRounds,
  GetRoundStatus,
};

function GetAccountDetails(
  token,
  start,
  end,
  page,
  limit,
  action_type = "",
  status = ""
) {
  const result = ApiService.get(
    "member/moneyHistory?token=" +
      token +
      "&start=" +
      start +
      "&end=" +
      end +
      "&page=" +
      page +
      "&limit=" +
      limit +
      "&action_type=" +
      action_type +
      "&status=" +
      status
  );

  return result;
}
function GetTransactionDetails(
  token,
  start,
  end,
  page,
  limit,
  thirdparty_id = 0,
  is_settle = "",
  thirdparty_type = 0
) {
  const result = ApiService.get(
    "member/betHistory?token=" +
      token +
      "&start=" +
      start +
      "&end=" +
      end +
      "&page=" +
      page +
      "&limit=" +
      limit +
      "&is_settle=" +
      is_settle +
      "&thirdparty_id=" +
      (thirdparty_type === 0 ? "" : thirdparty_id) +
      "&thirdparty_type=" +
      thirdparty_type +
      "&thirdparty_parent_id=" +
      (thirdparty_type === 0 ? thirdparty_id : "")
  );

  return result;
}
function GetDailyDetails(token, start, end) {
  const result = ApiService.get(
    "member/dayStatistics?token=" + token + "&start=" + start + "&end=" + end
  );

  return result;
}
function GetContribution(From, To, page, rows, desc, sort, all) {
  const result = ApiService.get(
    `agentreport/contributions?From=${From}&To=${To}&page=${page}&rows=${rows}&desc=${desc}&sort=${sort}&all=${all}`
  );

  return result;
}

function GetDividend(From, To, page, rows, desc, sort, all) {
  const result = ApiService.get(
    `agentreport/dividends?From=${From}&To=${To}&page=${page}&rows=${rows}&desc=${desc}&sort=${sort}&all=${all}`
  );

  return result;
}

function GetRounds(
  page,
  rows,
  desc,
  sort,
  all,
  Username,
  RoundId,
  ExternalRoundId,
  GameProviderId,
  Status,
  From,
  To,
  ClosedFrom,
  ClosedTo
) {
  const result = ApiService.get(
    `agentreport/rounds?page=${page}&rows=${rows}&desc=${desc}&sort=${sort}&all=${all}&Username=${Username}&RoundId=${RoundId}&ExternalRoundId=${ExternalRoundId}&GameProviderId=${GameProviderId}&Status=${Status}&From=${From}&To=${To}&ClosedFrom=${ClosedFrom}&ClosedTo=${ClosedTo}`
  );
  return result;
}

function GetRoundStatus() {
  const result = ApiService.get(`agentreport/rounds/status`);
  return result;
}
