import React, { Component } from "react";
import { connect } from "react-redux";
import styled from "styled-components";

import CustomButton from "../../../Share/Button";
import TextField from "@material-ui/core/TextField";
import IconInputVerifyCodePNG from "../../../../assets/images/icon-input-verifycode.png";
import { withStyles } from "@material-ui/core/styles";
import { AccountService } from "../../../../services/AccountService";
import Password1IconPNG from "../../../../assets/images/password1-icon.png";

import i18n from "../../../../utils/i18n";
import { Grid } from "@material-ui/core";
import { withSnackbar } from "notistack";
import ErrorSwitcher from "../../../../utils/ErrorSwitcher";
const Container = styled.div``;
const CustomText = withStyles({
  root: {
    marginTop: "10px",
    float: "left",
    backgroundColor: "#fff",
    "& .MuiOutlinedInput-input": {
      fontSize: "14px",
    },
    "& .MuiOutlinedInput-input[readonly]": {
      color: "#b6b6b6",
    },
    "& label.Mui-focused": {
      color: "#b6b6b6",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#b6b6b6",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#b6b6b6",
      },
      "&:hover fieldset": {
        borderColor: "#b6b6b6",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#b6b6b6",
      },
    },
  },
})(TextField);

const StyleButton = styled.div`
  width: 100%;
  height: 44px;
  border: solid 1px #b6b6b6;
  margin: 0;
  margin-top: 10px;

  float: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  ${(props) =>
    props.disable === true
      ? `  background-image: linear-gradient(90deg, rgba(89,89,89,1) 0%, rgba(126,126,126,1) 100%);`
      : `  background-image: linear-gradient(
    90deg,
    rgb(15, 123, 154) 0%,
    rgb(59, 171, 204) 100%
  );
`}

  border-radius: 5px;
  font-size: 16px;
  color: #fff;

  &:hover {
    cursor: pointer;

    ${(props) =>
      props.disable === true
        ? `  background-image: linear-gradient(90deg, rgba(89,89,89,1) 0%, rgba(126,126,126,1) 100%);`
        : `      background-blend-mode: screen, normal;
        background-image: linear-gradient(
            to bottom,
            rgba(59, 171, 204, 0.08),
            rgba(59, 171, 204, 0.08)
          ),
          linear-gradient(to top, #3babcc, #3babcc);
        }
  `}
  }
`;
const ButtonLabel = styled.span`
  text-align: center;
  display: block;
  margin-top: 10px;
`;
class ChangeWithdrawalPassword extends Component {
  constructor(props) {
    super(props);

    this.state = {
      step: "1",
      email: this.props.email ? this.props.email : "",
      securityKey: "",
      code: "",
      newpassword: "",
      confirmpassword: "",
      getCodeClicked: false,
      countdown: 0,
    };
  }
  onhandleEmailChange = (e) => {
    this.setState({ ...this.state, email: e.target.value });
  };
  onhandleCodeChange = (e) => {
    if (e.target.value.length <= 6) {
      if (e.target.value.match(/^[0-9]+$/) || e.target.value === "") {
        this.setState({ ...this.state, code: e.target.value });
      }
    }
  };
  onhandleNewPasswordChange = (e) => {
    this.setState({ ...this.state, newpassword: e.target.value });
  };
  onhandleConfirmPasswordChange = (e) => {
    this.setState({ ...this.state, confirmpassword: e.target.value });
  };
  onhandleGetCodeClick = () => {
    AccountService.GetChangeWithdrawalPasswordVerificationCode().then(
      (response) => {
        if (response.IsSuccess) {
          this.setState({ securityKey: response.data, getCodeClicked: true });
          this.props.enqueueSnackbar(i18n.t("common.msgTokenSent"), {
            variant: "info",
          });
        } else {
          this.props.enqueueSnackbar(
            ErrorSwitcher.getTranslation(ErrorSwitcher.info),
            {
              variant: "error",
            }
          );
        }
      }
    );
  };
  onhandleNextStep = () => {
    if (this.state.code !== "") {
      this.setState({
        step: "2",
      });
      this.props.handleChangeTitle(
        i18n.t("account.safety.dialog.withdrawalPassword.update")
      );
    } else {
      this.props.enqueueSnackbar(i18n.t("common.msgEnterCode"), {
        variant: "error",
      });
    }
  };
  onhandleBackClick = () => {
    this.setState({
      step: "1",
      code: "",
      newpassword: "",
      confirmpassword: "",
    });
    this.props.handleChangeTitle(i18n.t("account.safety.verify.emailVerify"));
  };
  onhandleSubmit = () => {
    var expr = /^.{6,20}$/;
    if (!expr.test(this.state.newpassword)) {
      this.props.enqueueSnackbar(i18n.t("common.msgRegisterPassInvalid"), {
        variant: "error",
      });
      return;
    }

    if (this.state.newpassword !== this.state.confirmpassword) {
      this.props.enqueueSnackbar(i18n.t("common.msgInvalidConfirmPass"), {
        variant: "error",
      });
    } else {
      AccountService.ChangeWithdrawalPassword(
        this.state.newpassword,
        this.state.code,
        this.state.securityKey
      ).then((response) => {
        if (response.IsSuccess) {
          this.props.enqueueSnackbar(i18n.t("common.msgSuccessUpdateProfile"), {
            variant: "success",
          });
          this.props.handleCloseDialog();
        } else {
          this.props.enqueueSnackbar(response.info, {
            variant: "warning",
          });
        }
      });
    }
  };

  render() {
    return (
      <>
        {this.state.step == "1" ? (
          <Container>
            <Grid container>
              <Grid item xs={12}>
                <CustomText
                  value={this.state.email}
                  onChange={this.onhandleEmailChange}
                  inputProps={{
                    maxLength: 20,
                  }}
                  style={{
                    width: "100%",
                    height: "46px",
                  }}
                  variant="outlined"
                  autoComplete="off"
                  disabled={true}
                ></CustomText>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={8} style={{ paddingRight: 8 }}>
                <CustomText
                  placeholder={i18n.t("common.msgEnterCode")}
                  adornment={IconInputVerifyCodePNG}
                  onChange={this.onhandleCodeChange}
                  variant="outlined"
                  autoComplete="off"
                  value={this.state.code}
                  type={"text"}
                  style={{
                    width: "208px",
                    height: "46px",
                  }}
                ></CustomText>
              </Grid>
              <Grid item xs={4}>
                <StyleButton
                  onClick={this.onhandleGetCodeClick}
                  disable={this.state.countdown > 0}
                  fullWidth
                >
                  <ButtonLabel>
                    {this.state.countdown <= 0
                      ? i18n.t("account.safety.dialog.emailVerify.requestCode")
                      : i18n.t(
                          "account.safety.dialog.emailVerify.requestAgain"
                        ) +
                        "(" +
                        this.state.countdown +
                        "s)"}
                  </ButtonLabel>
                </StyleButton>
              </Grid>
            </Grid>

            <CustomButton
              name={i18n.t("common.nextStep")}
              handleClick={this.onhandleNextStep}
            ></CustomButton>
          </Container>
        ) : (
          <>
            <Container>
              <Grid container>
                <Grid item xs={12}>
                  <CustomText
                    placeholder={i18n.t(
                      "account.withdrawal.setPassword.password"
                    )}
                    adornment={Password1IconPNG}
                    onChange={this.onhandleNewPasswordChange}
                    variant="outlined"
                    autoComplete="off"
                    value={this.state.newpassword}
                    type="password"
                    style={{
                      width: "100%",
                      height: "46px",
                    }}
                  ></CustomText>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={12}>
                  <CustomText
                    placeholder={i18n.t(
                      "account.withdrawal.setPassword.confirm"
                    )}
                    adornment={Password1IconPNG}
                    onChange={this.onhandleConfirmPasswordChange}
                    variant="outlined"
                    autoComplete="off"
                    value={this.state.confirmpassword}
                    type="password"
                    style={{
                      width: "100%",
                      height: "46px",
                    }}
                  ></CustomText>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={12} md={6}>
                  <CustomButton
                    float={"left"}
                    name={i18n.t("common.back")}
                    handleClick={this.onhandleBackClick}
                  ></CustomButton>
                </Grid>
                <Grid item xs={12} md={6}>
                  <CustomButton
                    name={i18n.t("common.submit")}
                    handleClick={this.onhandleSubmit}
                    float={"right"}
                  ></CustomButton>
                </Grid>
              </Grid>
            </Container>
          </>
        )}
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {};
};
export default connect(
  null,
  mapDispatchToProps
)(withSnackbar(ChangeWithdrawalPassword));
