import React, { Component } from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import { SHOWING_DIALOG } from "../../../../constant/actionType";
import Dialog from "@material-ui/core/Dialog";
import LoginBGPJPG from "../../../../assets/images/login-bg.jpg";
import IconCloseWhite from "../../../../assets/images/icon-close-white.png";
import { withStyles } from "@material-ui/core/styles";
import ChangePassword from "./ChangePassword";
import Email from "./Email";
import WithdrawalPassword from "./WithdrawalPassword";
import ChangeWithdrawalPassword from "./ChangeWithdrawalPassword";
import IDVerification from "./IDVerification";
import PhoneVerification from "./PhoneVerification";
import SecurityQuestion from "./SecurityQuestion";
import ResetSecurityQuestion from "./ResetSecurityQuestion";
import { isMobile } from "react-device-detect";
import i18n from "../../../../utils/i18n";

import userIcon from "../../../../../src/assets/images/account/safety/usericon.png";
import phoneIcon from "../../../../../src/assets/images/account/safety/phoneicon.png";
import emailIcon from "../../../../../src/assets/images/account/safety/emailicon.png";
import safetyIcon from "../../../../../src/assets/images/account/safety/safetyicon.png";
import cardIcon from "../../../../../src/assets/images/account/safety/cardicon.png";
import lockIcon from "../../../../../src/assets/images/account/safety/lockicon.png";

import {
  Content,
  FullSizeContainer,
  TitleContainerBackgroundShadow,
  TitleContainer,
  Title,
  VerificationTypeIcon,
  VerificationButton,
} from "../../../Share/General";
import { Grid } from "@material-ui/core";
import config from "../../../../config/config";

const ContentWrap = styled.div`
  height: 44px;
  font-size: 14px;
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  padding-right: 32px;

  @media (max-width: 530px) {
    padding-right: 0px;
  }
`;
const ContentLabel = styled.div`
  color: #000;
  float: left;
  width: 125px;
  margin-left: 35px;
`;

const ContentValue = styled.div`
  color: #16242a;
  float: left;
  width: 180px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
`;

function GetContentWrap(accountInfo, label, attr, func, disable) {
  let attrValue = accountInfo[attr];
  let buttonValue = i18n.t("account.safety.misc.verify");
  let icon = "";

  switch (attr) {
    case "check_pass_account":
      icon = userIcon;
      break;
    case "member_phone":
      icon = phoneIcon;
      break;
    case "member_email":
      icon = emailIcon;
      break;
    case "member_question":
      icon = safetyIcon;
      break;
    case "member_safe_password":
      icon = cardIcon;
      break;
    case "member_password":
      icon = lockIcon;
      break;
    default:
      return "";
  }

  if (attr === "check_pass_account") {
    if (attrValue === false || attrValue == null) {
      attrValue = "+25%";
    } else {
      attrValue = i18n.t("account.safety.misc.alreadySetup");
      buttonValue = i18n.t("account.safety.misc.update");
    }
  } else if (attr === "member_phone") {
    if (attrValue === "" || attrValue == null) {
      attrValue = "+25%";
    } else {
      attrValue = i18n.t("account.safety.misc.alreadySetup");
      buttonValue = i18n.t("account.safety.misc.update");
    }
  } else if (attr === "member_email") {
    if (attrValue === "" || attrValue == null) {
      attrValue = "";
    } else {
      //attrValue = i18n.t("account.safety.misc.alreadySetup");
      buttonValue = i18n.t("account.safety.misc.update");
    }
  } else if (attr === "member_question") {
    if (attrValue === 0) {
      attrValue = "+25%";
    } else {
      attrValue = i18n.t("account.safety.misc.alreadySetup");
      buttonValue = i18n.t("account.safety.misc.update");
    }
  } else if (attr === "member_password") {
    attrValue = "";
    buttonValue = i18n.t("account.safety.misc.update");
  } else if (attr === "member_safe_password") {
    if (attrValue === "set") {
      attrValue = i18n.t("account.safety.misc.alreadySetup");
      buttonValue = i18n.t("account.safety.misc.update");
    } else {
      buttonValue = i18n.t("account.safety.misc.setup");
    }
  }

  return (
    <ContentWrap>
      <VerificationTypeIcon src={icon} />
      <ContentLabel>{label}</ContentLabel>
      <ContentValue
        style={attrValue === "Already set up" ? { color: "#acacac" } : {}}
      >
        {attrValue}
      </ContentValue>

      {(() => {
        if (buttonValue !== "") {
          return (
            !disable && (
              <VerificationButton onClick={() => func(attr)}>
                {buttonValue}
              </VerificationButton>
            )
          );
        } else {
          return "";
        }
      })()}
    </ContentWrap>
  );
}
const StyledDialog = withStyles({
  root: {
    boxShadow: "0 3px 5px 2px rgba(255, 105, 135, .3)",
    "& .MuiDialog-paperWidthSm": {
      width: "420px",
    },
  },
})(Dialog);
const DialogContainer = styled.div`
  padding: 27px 32px;
  background: url(${LoginBGPJPG});
  display: table;
`;
const DialogCloseIconContainer = styled.div`
  background: url(${IconCloseWhite}) no-repeat center;
  width: 50px;
  height: 100%;

  position: absolute;
  top: 0;
  right: 0;
  &:hover {
    cursor: pointer;
  }
`;
const DialogHeader = styled.div`
  height: 50px;
  background-color: ${(props) => props.theme.secondary};
  position: relative;
`;
const DialogHeadertitle = styled.div`
  text-align: center;
  width: 75%;
  margin-left: 50px;
  padding-top: 12px;
  float: left;
  font-size: 18px;
  font-weight: bold;
  color: #ffffff;
`;

const LowerContainer = styled.div`
  padding-bottom: 50px;
  position: relative;
`;

var setLoad = true;

class Safety extends Component {
  state = {
    openDialog: false,
    dialogTitle: "",
    account: "",
  };

  componentDidMount() {
    const localStorageToken = localStorage.getItem(
      "token" + (isMobile ? 1 : 0)
    );
    if (localStorageToken === null) {
      this.props.history.push("/");
    }
    if (this.props.popup) {
      this.handleButtonClick(this.props.popup);
    }
    const urlSearchParams = new URLSearchParams(
      this.props.history.location.search
    );
    const params = Object.fromEntries(urlSearchParams.entries());
    if (params.do) {
      this.handleButtonClick(params.do);
    }
  }

  componentDidUpdate(preProps) {
    if (preProps.popup !== this.props.popup && this.props.popup) {
      this.handleButtonClick(this.props.popup);
    }
    if (preProps.account.info !== this.props.account.info) {
      setLoad = false;
    }
  }
  handleVerifyByQuestion = () => {
    this.setState({
      ...this.state,
      openDialog: true,
      dialogTitle: "安全问题验证",
      dialogComponent: (
        <SecurityQuestion
          handleCloseDialog={this.handleCloseDialog}
          token={this.props.account.token}
          memberQuestion={this.props.account.info.member_question}
          isVerify={true}
          onVerifySuccess={this.handleVerifySuccess}
        ></SecurityQuestion>
      ),
    });
  };
  handleChangeTitle = (title) => {
    this.setState({
      dialogTitle: title,
    });
  };
  handleEmailVerificationClick = () => {
    this.setState({
      ...this.state,
      openDialog: true,
      dialogTitle: "邮件验证",
      dialogComponent: (
        <Email
          handleCloseDialog={this.handleCloseDialog}
          email={this.props.account.info.member_email}
          token={this.props.account.token}
          onhandleVerifySuccess={this.handleVerifySuccess}
          isForSecurityQuestion={true}
        ></Email>
      ),
    });
  };
  handlePhoneVerificationClick = () => {
    this.setState({
      ...this.state,
      openDialog: true,
      dialogTitle: "手机验证",
      dialogComponent: (
        <PhoneVerification
          handleCloseDialog={this.handleCloseDialog}
          phone={this.props.account.info.member_phone}
          token={this.props.account.token}
          onhandleVerifySuccess={this.handleVerifySuccess}
          isForSecurityQuestion={true}
        ></PhoneVerification>
      ),
    });
  };

  handleVerifySuccess = (isForSecurityQuestion, isForWithdrawalPassword) => {
    if (isForSecurityQuestion) {
      this.setState({
        ...this.state,
        openDialog: true,
        dialogTitle: "设置安全问题",
        dialogComponent: (
          <SecurityQuestion
            handleCloseDialog={this.handleCloseDialog}
            token={this.props.account.token}
            memberQuestion={0}
            isVerify={false}
            isReset={true}
          ></SecurityQuestion>
        ),
      });
    } else if (isForWithdrawalPassword) {
      this.setState({
        ...this.state,
        openDialog: true,
        dialogTitle: "设置提款密码",
        dialogComponent: (
          <WithdrawalPassword
            value={this.props.account.info.member_safe_password}
            handleCloseDialog={this.handleCloseDialog}
            token={this.props.account.token}
          ></WithdrawalPassword>
        ),
      });
    }
  };
  handleButtonClick = (type) => {
    let title = "";
    let component = null;

    switch (type) {
      case "member_password":
        title = title = i18n.t("account.safety.verify.password");
        component = (
          <ChangePassword
            handleCloseDialog={this.handleCloseDialog}
            token={this.props.account.token}
          ></ChangePassword>
        );
        break;
      case "member_email":
        title = title = i18n.t("account.safety.verify.emailVerify");
        component = (
          <Email
            handleCloseDialog={this.handleCloseDialog}
            email={this.props.account.info.member_email}
            token={this.props.account.token}
            isForSecurityQuestion={false}
          ></Email>
        );
        break;
      case "member_safe_password":
        if (
          (this.props.account.info.member_email === "" ||
            this.props.account.info.member_email === null) &&
          config.currency !== "IDR"
        ) {
          title = title = i18n.t("account.safety.verify.emailVerify");
          component = (
            <Email
              handleCloseDialog={this.handleCloseDialog}
              email={this.props.account.info.member_email}
              token={this.props.account.token}
              isForSecurityQuestion={false}
            ></Email>
          );
        } else {
          if (this.props.account.info.member_safe_password !== "set") {
            // first time
            title = i18n.t("account.safety.dialog.withdrawalPassword.title");
            component = (
              <WithdrawalPassword
                value={this.props.account.info.member_safe_password}
                handleCloseDialog={this.handleCloseDialog}
                token={this.props.account.token}
              ></WithdrawalPassword>
            );
          } else {
            title = i18n.t("account.safety.verify.emailVerify");
            component = (
              <ChangeWithdrawalPassword
                email={this.props.account.info.member_email}
                handleCloseDialog={this.handleCloseDialog}
                handleChangeTitle={this.handleChangeTitle}
                token={this.props.account.token}
              ></ChangeWithdrawalPassword>
            );
          }
        }

        // else {
        //   // reset
        //   if (this.props.account.info.check_pass_phone) {
        //     title = i18n.t("account.safety.dialog.withdrawalPassword.update");
        //     component = (
        //       <PhoneVerification
        //         handleCloseDialog={this.handleCloseDialog}
        //         phone={this.props.account.info.member_phone}
        //         token={this.props.account.token}
        //         isForSecurityQuestion={false}
        //         isForWithdrawalPassword={true}
        //         onhandleVerifySuccess={this.handleVerifySuccess}
        //       ></PhoneVerification>
        //     );
        //   } else {
        //     title = i18n.t("account.safety.dialog.withdrawalPassword.bind");
        //     component = (
        //       <PhoneVerification
        //         handleCloseDialog={this.handleCloseDialog}
        //         phone={this.props.account.info.member_phone}
        //         token={this.props.account.token}
        //         isForSecurityQuestion={false}
        //       ></PhoneVerification>
        //     );
        //   }
        // }

        break;
      case "check_pass_account":
        title = i18n.t("account.safety.dialog.nameVerify.title");
        component = (
          <IDVerification
            handleCloseDialog={this.handleCloseDialog}
            account={this.props.account}
          ></IDVerification>
        );
        break;
      case "member_phone":
        title = title = i18n.t("account.safety.verify.phoneVerify");
        component = (
          <PhoneVerification
            handleCloseDialog={this.handleCloseDialog}
            phone={this.props.account.info.member_phone}
            token={this.props.account.token}
            isForSecurityQuestion={false}
          ></PhoneVerification>
        );
        break;
      case "member_question":
        component = null;

        if (this.props.account.info.member_question === 0) {
          // first time
          title = i18n.t("account.safety.dialog.securityQuestion.title");
          component = (
            <SecurityQuestion
              handleCloseDialog={this.handleCloseDialog}
              token={this.props.account.token}
              memberQuestion={this.props.account.info.member_question}
              isVerify={false}
              isReset={false}
            ></SecurityQuestion>
          );
        } else {
          // reset
          title = i18n.t("account.safety.dialog.securityQuestion.reset");
          component = (
            <ResetSecurityQuestion
              handleCloseDialog={this.handleCloseDialog}
              token={this.props.account.token}
              memberQuestion={this.props.account.info.member_question}
              passEmail={this.props.account.info.check_pass_email}
              passPhone={this.props.account.info.check_pass_phone}
              onhandleVerifyByQuestion={this.handleVerifyByQuestion}
              onhandlePhoneVerificationClick={this.handlePhoneVerificationClick}
              onhandleEmailVerificationClick={this.handleEmailVerificationClick}
              onhandleCSClick={this.props.onhandleCSClick}
            ></ResetSecurityQuestion>
          );
        }

        break;

      default:
        component = null;
        break;
    }
    this.setState({
      ...this.state,
      openDialog: true,
      dialogTitle: title,
      dialogComponent: component,
    });
  };
  handleCloseDialog = () => {
    this.props.history.push("/account/safety");
    this.setState({ ...this.state, openDialog: false });
  };
  render() {
    return (
      <FullSizeContainer>
        <Content>
          {/* <UpperContainer>
            <TitleContainer>
              <Grid container alignItems="flex-end">
                <Grid item>
                  <Title>{i18n.t("account.safety.title")}</Title>
                </Grid>
                <Grid item>
                  <TitleContainerBackgroundShadow>
                    {i18n.t("account.safety.personal.tips")}
                  </TitleContainerBackgroundShadow>
                </Grid>
              </Grid>
            </TitleContainer>
            <Row>
              <Col>
                {
                  //FillData(
                  // i18n.t("account.safety.personal.name"),
                  // this.props.account.info,
                  // "member_name"
                  //)
                }
                {FillData(
                  i18n.t("account.safety.personal.gender"),
                  this.props.account.info,
                  "member_gender"
                )}
                {
                  //FillData(
                  //  i18n.t("account.safety.personal.birthday"),
                  //  this.props.account.info,
                  //  "member_birthday"
                  //)
                }
                {FillData(
                  i18n.t("account.safety.personal.level"),
                  this.props.account.info,
                  "member_level"
                )}
              </Col>
              <Col>
                {FillData(
                  i18n.t("account.safety.personal.country"),
                  this.props.account.info,
                  "member_country"
                )}
                {FillData(
                  i18n.t("account.safety.personal.currency"),
                  this.props.account.info,
                  "currency"
                )}
              </Col>
            </Row>
          </UpperContainer> */}
          <LowerContainer>
            <TitleContainer>
              <Grid container alignItems="flex-end">
                <Grid item>
                  <Title>{i18n.t("account.safety.verify.title")}</Title>
                </Grid>
                <Grid item>
                  <TitleContainerBackgroundShadow>
                    {i18n.t("account.safety.verify.tips")}
                  </TitleContainerBackgroundShadow>
                </Grid>
              </Grid>
            </TitleContainer>
            <Grid container>
              {/* <Grid item>
                {GetContentWrap(
                  this.props.account.info,
                  i18n.t("account.safety.verify.nameVerify"),
                  "check_pass_account",
                  this.handleButtonClick
                )}
                {GetContentWrap(
                  this.props.account.info,
                  i18n.t("account.safety.verify.phoneVerify"),
                  "member_phone",
                  this.handleButtonClick
                )}
       
                {GetContentWrap(
                  this.props.account.info,
                  i18n.t("account.safety.verify.securityQuestion"),
                  "member_question",
                  this.handleButtonClick
                )}
              </Grid> */}
              {config.currency !== "IDR" &&
                GetContentWrap(
                  this.props.account.info,
                  i18n.t("account.safety.verify.emailVerify"),
                  "member_email",
                  this.handleButtonClick
                )}
              <Grid item>
                {GetContentWrap(
                  this.props.account.info,
                  i18n.t("account.safety.verify.withdrawalPassword"),
                  "member_safe_password",
                  this.handleButtonClick,
                  config.currency === "IDR" &&
                    this.props.account.info.member_safe_password === "set"
                )}
                {GetContentWrap(
                  this.props.account.info,
                  i18n.t("account.safety.verify.password"),
                  "member_password",
                  this.handleButtonClick
                )}
              </Grid>
            </Grid>

            {
              //<Loading show={setLoad} />
            }
          </LowerContainer>
        </Content>

        <StyledDialog
          open={this.state.openDialog}
          onClose={this.handleCloseDialog}
          onEntering={() => this.props.showDialog(true)}
          onExited={() => this.props.showDialog(false)}
        >
          <DialogHeader>
            <DialogHeadertitle>{this.state.dialogTitle}</DialogHeadertitle>
            <DialogCloseIconContainer
              onClick={this.handleCloseDialog}
            ></DialogCloseIconContainer>
          </DialogHeader>
          <DialogContainer>{this.state.dialogComponent}</DialogContainer>
        </StyledDialog>
      </FullSizeContainer>
    );
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    showDialog: (payload) => {
      dispatch({ type: SHOWING_DIALOG, payload });
    },
  };
};

const mapStateToProps = (state) => {
  return {
    account: state.account,
    load: false,
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Safety);
