import React, { Component } from "react";
import { connect } from "react-redux";
import { AppHistory } from "../../../history/History";
import Header from "../Account/Header";
import {
  CustomContainer,
  ReportContainer,
  FilterContainer,
  TitleContainer,
  Title,
  Content,
  CustomQuickButton,
  CustomTable,
  CustomTableHeader,
} from "../../Share/General";
import styled from "styled-components";
import { isMobile } from "react-device-detect";
import TablePagination from "@material-ui/core/TablePagination";
import ReportDatepicker from "./ReportDatepicker";
import { AffiliateService } from "../../../services/AffiliateService";
import i18n from "../../../utils/i18n";
import Common from "../../../utils/Common";
import { Container, Grid } from "@material-ui/core";
import addDays from "date-fns/addDays";
import TableContainer from "@material-ui/core/TableContainer";
import { withSnackbar } from "notistack";
import Checked from "../../../assets/images/icon-checked.png";
import Unchecked from "../../../assets/images/icon-unchecked.png";

import { addMonths, addWeeks } from "date-fns";
const moment = require("moment");

const Checkbox = styled.div`
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: url(${({ agentinfo }) => {
      return agentinfo === true ? Checked : Unchecked;
    }})
    no-repeat center;
  background-size: 100%;
  cursor: pointer;
  margin-left: 10px;
  border-radius: 4px;
`;

const MobileButtonPlacement = styled.div`
  display: flex;
  justify-content: space-between;

  align-items: stretch;
  flex-direction: column;
  flex: 1;

  @media (min-width: 600px) {
    display: none;
  }
`;

const AnotherWrapper = styled.div`
  display: flex;
  align-items: center;
  @media (max-width: 600px) {
    margin-top: 5px;
  }
`;

const Hidden = styled.div`
  @media (max-width: 600px) {
    display: none;
  }
`;

const PaginationWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const CustomSelect = styled.select`
  height: 30px;
  border: solid 1px #b6b6b6;
  border-radius: 4px;

  margin-right: 8px;
  display: flex;
  align-items: center;
  padding: 0px 8px;

  @media (max-width: 600px) {
    margin-right: 0px;
  }
`;

const CustomPageCounter = styled.div`
  font-size: 12px;
  margin-left: 12px;
`;

const GridContainer = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  box-sizing: border-box;
  align-items: center;
  justify-content: space-between;
  @media (max-width: 600px) {
    display: block;
  }
`;

class ContributeReport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      type: 1,
      selected: 1,
      data: {
        totalcount: 0,
        items: [],
        total: {
          agentAdjustAmount: 0,
          betAmount: 0,
          bonusAmount: 0,
          depositAmount: 0,
          depositHandlingFee: 0,
          netProfit: 0,
          turnover: 0,
          validBetAmount: 0,
          winLossAmount: 0,
          withdrawalAmount: 0,
          bonusAdjustAmount: 0,
        },
      },
      selectedDateFrom: moment().startOf("isoWeek").format("YYYY/MM/DD"),
      selectedDateTo: moment(new Date()).format("YYYY/MM/DD"),
      page: 1,
      rows: 10,
      desc: true,
      sort: "",
      all: false,
      agentinfo: true,
      dateRange: "thisWeek",
    };
  }
  componentDidMount() {
    const localStorageToken = localStorage.getItem(
      "token" + (isMobile ? 1 : 0)
    );
    if (localStorageToken === null) {
      AppHistory.replace("/");
    } else {
      this.changeContributeListView(this.state.page);
    }
  }
  componentDidUpdate(preProps, preState) {
    if (preState.page !== this.state.page) {
      this.changeContributeListView(this.state.page);
    }
    if (preState.rows !== this.state.rows) {
      this.setState({
        ...this.state,
        page: 1,
      });
      this.changeContributeListView(1);
    }

    if (preState.dateRange !== this.state.dateRange) {
      if (this.state.dateRange === "today") {
        this.setState({
          ...this.state,
          selectedDateFrom: moment(new Date()).format("YYYY/MM/DD"),
          selectedDateTo: moment(new Date()).format("YYYY/MM/DD"),
        });
      }
      if (this.state.dateRange === "yesterday") {
        this.setState({
          ...this.state,
          selectedDateFrom: moment(addDays(new Date(), -1)).format(
            "YYYY/MM/DD"
          ),
          selectedDateTo: moment(addDays(new Date(), -1)).format("YYYY/MM/DD"),
        });
      }
      if (this.state.dateRange === "thisWeek") {
        this.setState({
          ...this.state,
          selectedDateFrom: moment().startOf("isoWeek").format("YYYY/MM/DD"),
          selectedDateTo: moment(new Date()).format("YYYY/MM/DD"),
        });
      }
      if (this.state.dateRange === "lastWeek") {
        this.setState({
          ...this.state,
          selectedDateFrom: moment(addWeeks(new Date(), -1))
            .startOf("isoWeek")
            .format("YYYY/MM/DD"),
          selectedDateTo: moment(addWeeks(new Date(), -1))
            .endOf("isoWeek")
            .format("YYYY/MM/DD"),
        });
      }
      if (this.state.dateRange === "thisMonth") {
        this.setState({
          ...this.state,
          selectedDateFrom: moment(new Date())
            .startOf("months")
            .format("YYYY/MM/DD"),
          selectedDateTo: moment(new Date()).format("YYYY/MM/DD"),
        });
      }

      if (this.state.dateRange === "lastMonth") {
        this.setState({
          ...this.state,
          selectedDateFrom: moment(addMonths(new Date(), -1))
            .startOf("month")
            .format("YYYY/MM/DD"),
          selectedDateTo: moment(addMonths(new Date(), -1))
            .endOf("month")
            .format("YYYY/MM/DD"),
        });
      }
    }
  }

  changeContributeListView = async (page) => {
    if (
      Common.maxDayRangeDetector(
        this.state.selectedDateFrom,
        this.state.selectedDateTo
      )
    ) {
      this.props.enqueueSnackbar(i18n.t("common.cannotExceed31day"), {
        variant: "warning",
      });
    } else {
      await AffiliateService.getContributeList(
        moment.utc(new Date(this.state.selectedDateFrom)).toISOString(),
        moment
          .utc(new Date(this.state.selectedDateTo))
          .add(1, "days")
          .toISOString(),
        page,
        this.state.rows,
        this.state.desc,
        this.state.sort,
        this.state.all
      ).then((response) => {
        if (response.IsSuccess) {
          this.setState({ ...this.state, data: response.data });
        }
      });
    }
  };
  handleDateFrom = (newDate) => {
    if (moment(this.state.selectedDateTo).isBefore(newDate)) {
      this.setState({
        selectedDateTo: newDate,
        selectedDateFrom: newDate,
      });
    } else {
      this.setState({
        ...this.state,
        selectedDateFrom: moment(new Date(newDate)).format("YYYY/MM/DD"),
      });
    }
  };
  handleDateTo = (newDate) => {
    this.setState({
      ...this.state,
      selectedDateTo: moment(new Date(newDate)).format("YYYY/MM/DD"),
    });
  };
  handleChangePage = (event, newPage) => {
    this.setState({ ...this.state, page: newPage + 1 });
  };

  handleToggleAgentInfo = () => {
    this.setState({
      ...this.state,
      agentinfo: !this.state.agentinfo,
    });
  };

  handleDateRangeChange = (e) => {
    this.setState({
      ...this.state,
      dateRange: e.target.value,
    });
  };

  agentType = (type) => {
    switch (type) {
      case 1:
        return i18n.t("report.agent");
      case 2:
        return i18n.t("report.player");
      default:
        return "";
    }
  };

  checkIsSameDay = (from, to) => {
    return moment(from).isSame(to);
  };

  render() {
    var depositAmount = 0;
    var depositHandlingFee = 0;
    var withdrawalAmount = 0;
    var bonusAmount = 0;
    var agentAdjustAmount = 0;
    var betAmount = 0;
    var validBetAmount = 0;
    var turnover = 0;
    var winLossAmount = 0;
    var netProfit = 0;
    var bonusAdjustAmount = 0;
    return (
      <CustomContainer>
        <Container maxWidth="lg">
          <Header />
          <ReportContainer>
            <Content>
              <TitleContainer>
                <Title>{i18n.t("report.contributionReport")}</Title>
              </TitleContainer>
              <FilterContainer>
                <GridContainer>
                  <Grid item>
                    <Grid container>
                      <ReportDatepicker
                        selectedDateFrom={this.state.selectedDateFrom}
                        selectedDateTo={this.state.selectedDateTo}
                        handleDateTo={this.handleDateTo}
                        handleDateFrom={this.handleDateFrom}
                        withRangePicker={true}
                        dateRange={this.state.dateRange}
                        handleChange={this.handleDateRangeChange}
                        displayTwoRowWap={true}
                      ></ReportDatepicker>

                      <Hidden>
                        <CustomQuickButton
                          onClick={() => {
                            this.changeContributeListView(this.state.page);
                          }}
                        >
                          {i18n.t("report.search")}
                        </CustomQuickButton>
                      </Hidden>
                    </Grid>
                  </Grid>
                  <Hidden>
                    <Grid item>
                      <Grid container alignItems="center">
                        <div>{i18n.t("report.containagentinfo")}</div>
                        <Checkbox
                          agentinfo={this.state.agentinfo}
                          onClick={this.handleToggleAgentInfo}
                        />
                      </Grid>
                    </Grid>
                  </Hidden>

                  <MobileButtonPlacement>
                    <div style={{ marginLeft: -12 }}>
                      <CustomQuickButton
                        onClick={() => {
                          this.changeContributeListView(this.state.page);
                        }}
                      >
                        {i18n.t("report.search")}
                      </CustomQuickButton>
                    </div>

                    <AnotherWrapper>
                      <div>{i18n.t("report.containagentinfo")}</div>
                      <Checkbox
                        agentinfo={this.state.agentinfo}
                        onClick={this.handleToggleAgentInfo}
                      />
                    </AnotherWrapper>
                  </MobileButtonPlacement>
                </GridContainer>
              </FilterContainer>
              <TableContainer>
                <CustomTable lock={2}>
                  <table>
                    <thead>
                      <tr>
                        <CustomTableHeader>
                          {i18n.t("report.username")}
                        </CustomTableHeader>
                        <CustomTableHeader class="align-center">
                          {i18n.t("report.type")}
                        </CustomTableHeader>
                        <CustomTableHeader class="align-right">
                          {i18n.t("report.depositAmount")}
                        </CustomTableHeader>
                        <CustomTableHeader class="align-right">
                          {i18n.t("report.withdrawalAmount")}
                        </CustomTableHeader>
                        <CustomTableHeader class="align-right">
                          {i18n.t("report.bonusAmount")}
                        </CustomTableHeader>
                        <CustomTableHeader class="align-right">
                          {i18n.t("report.bonusAdjustAmount")}
                        </CustomTableHeader>
                        <CustomTableHeader class="align-right">
                          {i18n.t("report.agentAdjustmentAmount")}
                        </CustomTableHeader>
                        <CustomTableHeader class="align-right">
                          {i18n.t("report.betAmount")}
                        </CustomTableHeader>
                        <CustomTableHeader class="align-right">
                          {i18n.t("report.validBetAmount")}
                        </CustomTableHeader>
                        <CustomTableHeader class="align-right">
                          {i18n.t("report.turnover")}
                        </CustomTableHeader>
                        <CustomTableHeader class="align-right">
                          {i18n.t("report.winLossAmount")}
                        </CustomTableHeader>
                        <CustomTableHeader class="align-right">
                          {i18n.t("report.netProfit")}
                        </CustomTableHeader>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <th
                          style={{
                            textAlign: "right",
                            fontWeight: "bold",
                          }}
                        >
                          {i18n.t("report.total")}:
                        </th>
                        <td></td>
                        <td class="align-right">
                          {Common.formatNumberWithCommasNoRounding(
                            this.state.data.total.depositAmount -
                              (isNaN(this.state.data.total.depositHandlingFee)
                                ? 0
                                : this.state.data.total.depositHandlingFee)
                          )}
                        </td>
                        <td class="align-right">
                          {Common.formatNumberWithCommasNoRounding(
                            this.state.data.total.withdrawalAmount
                          )}
                        </td>
                        <td class="align-right">
                          {Common.formatNumberWithCommasNoRounding(
                            this.state.data.total.bonusAmount
                          )}
                        </td>
                        <td class="align-right">
                          {Common.formatNumberWithCommasNoRounding(
                            this.state.data.total.bonusAdjustAmount
                          )}
                        </td>
                        <td class="align-right">
                          {Common.formatNumberWithCommasNoRounding(
                            this.state.data.total.agentAdjustAmount
                          )}
                        </td>
                        <td class="align-right">
                          {Common.formatNumberWithCommasNoRounding(
                            this.state.data.total.betAmount
                          )}
                        </td>
                        <td class="align-right">
                          {Common.formatNumberWithCommasNoRounding(
                            this.state.data.total.validBetAmount
                          )}
                        </td>
                        <td class="align-right">
                          {Common.formatNumberWithCommasNoRounding(
                            this.state.data.total.turnover
                          )}
                        </td>
                        <td class="align-right">
                          {Common.formatNumberWithCommasNoRounding(
                            this.state.data.total.winLossAmount
                          )}
                        </td>
                        <td class="align-right">
                          {Common.formatNumberWithCommasNoRounding(
                            this.state.data.total.netProfit
                          )}
                        </td>
                      </tr>
                      {(this.state.data.items > 0
                        ? this.state.data.items
                        : this.state.data.items
                      )
                        .filter((a) => {
                          return this.state.agentinfo === true
                            ? a
                            : a.type === 2;
                        })
                        .map((data, index) => {
                          depositAmount = depositAmount + data.depositAmount;
                          depositHandlingFee =
                            depositHandlingFee +
                            (isNaN(data.depositHandlingFee)
                              ? 0
                              : data.depositHandlingFee);
                          withdrawalAmount =
                            withdrawalAmount + data.withdrawalAmount;
                          bonusAmount = bonusAmount + data.bonusAmount;
                          agentAdjustAmount =
                            agentAdjustAmount + data.agentAdjustAmount;
                          bonusAdjustAmount =
                            bonusAdjustAmount + data.bonusAdjustAmount;
                          betAmount = betAmount + data.betAmount;
                          validBetAmount = validBetAmount + data.validBetAmount;
                          turnover = turnover + data.turnover;
                          winLossAmount = winLossAmount + data.winLossAmount;
                          netProfit = netProfit + data.netProfit;

                          return (
                            <tr>
                              <th>{data.username}</th>{" "}
                              <td class="align-center">
                                {this.agentType(data.type)}
                              </td>
                              <td class="align-right">
                                {Common.formatNumberWithCommasNoRounding(
                                  data.depositAmount -
                                    (isNaN(data.depositHandlingFee)
                                      ? 0
                                      : data.depositHandlingFee)
                                )}
                              </td>
                              <td class="align-right">
                                {Common.formatNumberWithCommasNoRounding(
                                  data.withdrawalAmount
                                )}
                              </td>
                              <td class="align-right">
                                {Common.formatNumberWithCommasNoRounding(
                                  data.bonusAmount
                                )}
                              </td>
                              <td class="align-right">
                                {Common.formatNumberWithCommasNoRounding(
                                  data.bonusAdjustAmount
                                )}
                              </td>
                              <td class="align-right">
                                {Common.formatNumberWithCommasNoRounding(
                                  data.agentAdjustAmount
                                )}
                              </td>
                              <td class="align-right">
                                {Common.formatNumberWithCommasNoRounding(
                                  data.betAmount
                                )}
                              </td>
                              <td class="align-right">
                                {Common.formatNumberWithCommasNoRounding(
                                  data.validBetAmount
                                )}
                              </td>
                              <td class="align-right">
                                {Common.formatNumberWithCommasNoRounding(
                                  data.turnover
                                )}
                              </td>
                              <td class="align-right">
                                {Common.formatNumberWithCommasNoRounding(
                                  data.winLossAmount
                                )}
                              </td>
                              <td class="align-right">
                                {Common.formatNumberWithCommasNoRounding(
                                  data.netProfit
                                )}
                              </td>
                            </tr>
                          );
                        })}

                      <tr>
                        <th
                          style={{
                            textAlign: "right",
                            fontWeight: "bold",
                          }}
                        >
                          {i18n.t("report.pagetotal")}:
                        </th>
                        <td></td>
                        <td class="align-right">
                          {Common.formatNumberWithCommasNoRounding(
                            depositAmount -
                              (isNaN(depositHandlingFee)
                                ? 0
                                : depositHandlingFee)
                          )}
                        </td>
                        <td class="align-right">
                          {Common.formatNumberWithCommasNoRounding(
                            withdrawalAmount
                          )}
                        </td>
                        <td class="align-right">
                          {Common.formatNumberWithCommasNoRounding(bonusAmount)}
                        </td>
                        <td class="align-right">
                          {Common.formatNumberWithCommasNoRounding(
                            bonusAdjustAmount
                          )}
                        </td>
                        <td class="align-right">
                          {Common.formatNumberWithCommasNoRounding(
                            agentAdjustAmount
                          )}
                        </td>
                        <td class="align-right">
                          {Common.formatNumberWithCommasNoRounding(betAmount)}
                        </td>
                        <td class="align-right">
                          {Common.formatNumberWithCommasNoRounding(
                            validBetAmount
                          )}
                        </td>
                        <td class="align-right">
                          {Common.formatNumberWithCommasNoRounding(turnover)}
                        </td>
                        <td class="align-right">
                          {Common.formatNumberWithCommasNoRounding(
                            winLossAmount
                          )}
                        </td>
                        <td class="align-right">
                          {Common.formatNumberWithCommasNoRounding(netProfit)}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </CustomTable>
              </TableContainer>

              <PaginationWrapper>
                <CustomSelect
                  value={this.state.rows}
                  onChange={(e) => {
                    this.setState({
                      ...this.state,
                      rows: e.target.value,
                    });
                  }}
                >
                  <option value="10">10</option>
                  <option value="50">50</option>
                  <option value="200">200</option>
                </CustomSelect>
                <CustomPageCounter>
                  {this.state.page} /{" "}
                  {Math.ceil(this.state.data.totalcount / this.state.rows)}
                </CustomPageCounter>
                <TablePagination
                  rowsPerPageOptions={[]}
                  component="div"
                  count={this.state.data.totalcount}
                  rowsPerPage={this.state.rows}
                  page={this.state.page - 1}
                  labelRowsPerPage={("", "", "", "????")}
                  backIconButtonProps={{
                    "aria-label": "previous page",
                  }}
                  nextIconButtonProps={{
                    "aria-label": "next page",
                  }}
                  onChangePage={this.handleChangePage}
                />
              </PaginationWrapper>
            </Content>
          </ReportContainer>
        </Container>
      </CustomContainer>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    token: state.account.token,
    data: state.account.info,
  };
};

export default connect(mapStateToProps, null)(withSnackbar(ContributeReport));
