import React, { useEffect } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import i18n from "../../utils/i18n";
import { Grid } from "@material-ui/core";
import { connect } from "react-redux";
import config from "../../config/config";

const MouseOverInner = styled.div`
  height: 50px;
  display: flex;
  margin: 0px auto;
  align-items: center;
  justify-content: center;
`;
const ReportFrame = styled.div`
  padding: 0px 16px
  float: left;
`;

const ReportText = styled.div`
  font-weight: bold;
  color: white;
  &:hover {
    color: rgb(253, 231, 63);
  }
`;

const Atag = styled(Link)``;
function SubMenuReport(props) {
  const Report = ({ path, title }) => {
    return (
      <Grid item>
        <ReportFrame>
          <Atag to={`/report/${path}`}>
            <ReportText>{i18n.t(title)}</ReportText>
          </Atag>
        </ReportFrame>
      </Grid>
    );
  };

  return (
    <MouseOverInner>
      <Grid container style={{ width: "1280px" }} justify={"flex-end"}>
        <Report
          path="contribute"
          title="header.menu.report.submenu.contribution"
        />

        {!config.features.HideDividendReport && (
          <Report path="dividend" title="header.menu.report.submenu.dividend" />
        )}
        <Report path="round" title="roundreport.title" />
      </Grid>
    </MouseOverInner>
  );
}

const mapStateToProps = (state) => {
  return {
    check_pass_account: state.account.info.check_pass_account,
  };
};
export default connect(mapStateToProps, null)(SubMenuReport);
