import {
  SHOW_LOADING,
  HIDE_LOADING,
  SHOW_LOGIN,
  SHOWING_DIALOG,
  UPDATING_SCREEN_SIZE,
} from "../constant/actionType";

const initState = {
  showLogin: false,
  loading: false,
  message: "",
  showingDialog: false,
};

const uiReducer = (state = initState, action) => {
  switch (action.type) {
    case UPDATING_SCREEN_SIZE:
      return {
        ...state,
        screenWidth: action.width,
        screenHeight: action.height,

        scale: action.scale,
      };
    case SHOW_LOGIN:
      return {
        ...state,
        showLogin: action.payload,
      };

    case SHOW_LOADING:
      return {
        ...state,
        loading: true,
      };
    case HIDE_LOADING:
      return {
        ...state,
        message: "",
        loading: false,
      };
    case SHOWING_DIALOG:
      return {
        ...state,
        showingDialog: action.payload,
      };
    default:
      return state;
  }
};

export default uiReducer;
