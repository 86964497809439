import {
  IPRESTRICT,
  GETSITETOKEN_SUCCESS,
  GETSITETOKEN_FAIL,
  GETGAMEPROVIDER_SUCCESS,
} from "../constant/actionType";
import { GETLIVECHATURL_SUCCESS } from "../actions/generalAction";

const initialState = {
  isIPRestrict: false,
  token: "",
  livechat: "",
  gameprovider: [],
  isMaintenance: false,
};
export function generalReducer(state = initialState, action) {
  switch (action.type) {
    case IPRESTRICT:
      return {
        ...state,
        isIPRestrict: true,
      };
    case GETSITETOKEN_SUCCESS:
      return {
        ...state,
        token: action.payload.data.token,
      };
    case GETSITETOKEN_FAIL:
      return {
        ...state,
        token: action.payload.data.token,
        isMaintenance: true,
      }
    case GETLIVECHATURL_SUCCESS:
      return {
        ...state,
        livechat: action.payload.data,
        loading: false,
      };
    case GETGAMEPROVIDER_SUCCESS:
      return { ...state, gameprovider: action.payload.data };

    default:
      return state;
  }
}
