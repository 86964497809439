import React, { Component } from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Router, Route, Switch, Redirect } from "react-router";
import { AppHistory } from "../../history/History";
import { withSnackbar } from "notistack";
import { UPDATING_SCREEN_SIZE, SHOW_LOGIN } from "../../constant/actionType";
import { accountAction } from "../../actions/accountAction";
import { isMobile } from "react-device-detect";
import Home from "./Home/index";
import Maintenance from "./Maintenance";
import Account from "./Account/index";
import Header from "./Header";
import Common from "../../utils/Common";
import Footer from "./Footer";
import RegisterSuccess from "./RegisterSuccess";
import Download from "./Download/index";
import About from "./About";
import Contact from "./Contact";
import DownlineReport from "./Report/DownlineReport";
import PromotionalReport from "./Report/PromotionalReport";
import DividendReport from "./Report/DividendReport";
import OperationalReport from "./Report/OperationalReport";
import ChannelReport from "./Report/ChannelReport";
import ContributeReport from "./Report/ContributeReport";
import i18n from "../../utils/i18n";
import PreDividendDetail from "./Report/PreDividentDetail";
import { affiliateAction } from "../../actions/affiliateAction";
import { generalAction } from "../../actions/generalAction";
import { walletAction } from "../../actions/walletActions";
import RoundReport from "./Report/RoundReport";
import config from "../../config/config";

const Main = styled.div`
  min-height: 500px;
`;
const EmptyHeader = styled.div`
  height: 160px;
  background-image: linear-gradient(to top, #060d0f, #22353c, #243941);
`;
class App extends Component {
  state = {
    showCS: false,
    isIpRestrict: true,
    jwtToken: "",
  };
  async componentDidMount() {
    // Common.loadScript("/js/config.js", () => {
    //   Common.loadScript("/js/iovation.js", () => {});
    // });

    if (this.props.general.token == "") {
      this.props.getSiteToken();
    }
    const localStorageToken = localStorage.getItem(
      "token" + (isMobile ? 1 : 0)
    );
    if (
      localStorageToken != null &&
      this.props.account.token == null &&
      this.props.account.info.token === ""
    ) {
      const responseToken = await this.props.checkTokenIsValid(
        localStorageToken
      );
      if (responseToken.IsSuccess) {
        // document
        //   .getElementById("ioBB")
        //   .parentNode.removeChild(document.getElementById("ioBB"));
        this.props.getAccountProfile(responseToken.data.token, true);
        this.props.getBalanceList();
        // this.props.getAffiliateChannelList(
        //   responseToken.data.token,
        //   window.location.origin
        // );
      }
    }
    this.setState({ ...this.state, isIpRestrict: false });

    // const response = await GeneralService.CheckIPRestrict();
    // if (response.code === 50000) {
    //   AppHistory.push({
    //     pathname: "/ip-restrict",
    //     search: "?query=error",
    //     state: { ip: response.data.ip },
    //   });
    // } else {
    //   const localStorageToken = localStorage.getItem("token");
    //   if (
    //     localStorageToken != null &&
    //     this.props.account.token == null &&
    //     this.props.account.info.token === ""
    //   ) {
    //     const responseToken = await this.props.checkTokenIsValid(
    //       localStorageToken
    //     );
    //     if (responseToken.IsSuccess) {
    //       // document
    //       //   .getElementById("ioBB")
    //       //   .parentNode.removeChild(document.getElementById("ioBB"));
    //       this.props.getAccountProfile(responseToken.data.token, true);
    //       this.props.getAffiliateChannelList(
    //         responseToken.data.token,
    //         window.location.origin
    //       );
    //     }
    //   }
    //   this.setState({ ...this.state, isIpRestrict: false });
    // }

    // let GAScript = `<script  async  src="https://www.googletagmanager.com/gtag/js?id=UA-156359717-1"></script>
    // <script>
    //   window.dataLayer = window.dataLayer || [];
    //   function gtag() {
    //     dataLayer.push(arguments);
    //   }
    //   gtag("js", new Date());

    //   gtag("config", "UA-156359717-1");
    // </script>`;

    // if (process.env.REACT_APP_CUSTOM_NODE_ENV != "development") {
    //   var e = document.createElement("div");
    //   e.innerHTML = GAScript;
    //   document.body.appendChild(e);
    // }
  }
  componentDidUpdate(preProps) {
    if (preProps.general.token !== this.props.general.token) {
      if (this.props.general.token == null || this.props.general.token == "") {
        this.props.getSiteToken();
      } else {
        localStorage.setItem("client-token", this.props.general.token);
        this.props.getLiveChatURL();
      }
    }
    if (
      preProps.general.isMaintenance !== this.props.general.isMaintenance &&
      this.props.general.isMaintenance === true
    ) {
      this.props.history.push("/maintenance");
    }
  }
  GetSupportUrl = (userid, name, question, email) => {
    var newURL = this.props.general.livechat
      ? this.props.general.livechat.data.setting.url
          .replace("{name}", name)
          .replace("{email}", email || "")
          .replace("{lang}", i18n.language)
      : "";

    if (newURL.length) {
      Common.PopupCenter(newURL, "", 785, 750);
    } else {
    }
  };
  handleShowCSClick = () => {
    this.GetSupportUrl(
      this.props.account.info.member_id,
      this.props.account.info.member_username,
      "",
      this.props.account.info.member_email
    );
  };

  handleDepositClick = () => {
    if (this.props.account.token == null) {
      this.props.showLogin(true);
    } else {
      this.props.history.push("/account/deposit");
    }
  };

  render() {
    return (
      <Router history={AppHistory}>
        {(() => {
          // TODO: Temporary disable loading

          //   if (this.state.isIpRestrict) {
          //     return <Loading show={true} opacity={1}></Loading>;
          //   }
          //    else {
          return (
            <div>
              <Header onhandleShowCSClick={this.handleShowCSClick}></Header>
              <EmptyHeader></EmptyHeader>
              <Main>
                <Switch>
                  <Route
                    exact
                    path={"/"}
                    render={(props) => (
                      <Home
                        onhandleShowCSClick={this.handleShowCSClick}
                        onhandleDepositClick={this.handleDepositClick}
                      />
                    )}
                  />
                  <Route exact path={"/about/:page?"} component={About} />

                  <Route exact path={"/contact"} component={Contact} />

                  <Route
                    exact
                    path={"/report"}
                    component={DownlineReport}
                  ></Route>
                  <Route
                    exact
                    path={"/report/promo"}
                    component={PromotionalReport}
                  ></Route>
                  <Route
                    exact
                    path={"/report/operate"}
                    component={OperationalReport}
                  ></Route>
                  <Route
                    exact
                    path={"/report/channel"}
                    component={ChannelReport}
                  ></Route>
                  {!config.features.HideDividendReport && (
                    <Route
                      exact
                      path={"/report/dividend/"}
                      component={DividendReport}
                    ></Route>
                  )}

                  <Route
                    exact
                    path={"/report/round/"}
                    component={RoundReport}
                  ></Route>
                  <Route
                    exact
                    path={"/report/contribute"}
                    component={ContributeReport}
                  ></Route>
                  <Route
                    exact
                    path={"/report/dividend/:month/:type/:level"}
                    component={PreDividendDetail}
                  ></Route>

                  <Route
                    exact
                    path={"/promotion"}
                    component={() => {
                      window.location.href =
                        "https://www.633872.com/cn/promotion";
                      return null;
                    }}
                  />

                  <Route
                    exact
                    path={"/maintenance"}
                    render={(props) => <Maintenance />}
                  />
                  <Route
                    exact
                    path={"/registersuccess/:username"}
                    component={RegisterSuccess}
                  />
                  <Route
                    exact
                    path={"/account/:tab/:popup?"}
                    render={(props) => (
                      <Account
                        token={this.props.account.token}
                        onhandleCSClick={this.GetSupportUrl}
                      />
                    )}
                  />
                  <Route exact path={"/download"} component={Download} />
                  <Redirect to="/" />
                </Switch>
              </Main>
              <Footer target={"_blank"}></Footer>
            </div>
          );
          //    }
        })()}
      </Router>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    account: state.account,
    general: state.general,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getAffiliateChannelList: (token, mainUrl) =>
      dispatch(affiliateAction.getAffiliateChannelList(token, mainUrl)),
    updateScreenSize: (width, height) => {
      dispatch({ type: UPDATING_SCREEN_SIZE, width, height });
    },
    showLogin: (payload) => {
      dispatch({ type: SHOW_LOGIN, payload });
    },
    getSiteToken: () => dispatch(generalAction.getSiteToken()),
    getLiveChatURL: () => dispatch(generalAction.getLiveChatURL()),
    checkTokenIsValid: (token) =>
      dispatch(accountAction.checkTokenIsValid(token)),
    getAccountProfile: (token, showVip) =>
      dispatch(accountAction.getAccountProfile(token, showVip)),
    getBalanceList: () => dispatch(walletAction.getBalanceList()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withSnackbar(App)));
