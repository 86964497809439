import React, { Component } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import "react-sticky-header/styles.css";
import { withStyles } from "@material-ui/core/styles";

import { withRouter } from "react-router-dom";
import AppBar from "@material-ui/core/AppBar";
import { accountAction } from "../../actions/accountAction";
import TimerPNG from "../../assets/images/timer.png";
import BtnCSPNG from "../../assets/images/btn-cs.png";
import BtnCSHoverPNG from "../../assets/images/btn-cs-hover.png";
import TopMenuHome from "../../assets/images/top-menu-home.png";

import MenuCreatePlayer from "../../assets/images/create-agent.svg";
import TopMenuReport from "../../assets/images/top-menu-report.png";

import {
  SHOWING_DIALOG,
  SHOW_LOGIN,
  LOGOUT_SUCCESS,
} from "../../constant/actionType";
import { Link } from "react-router-dom";
import GuestContainer from "./GuestContainer";
import MemberContainer from "./MemberContainer";

import { withSnackbar } from "notistack";
import Common from "../../utils/Common";
import SubMenuReport from "./SubMenuReport";

import i18n from "../../utils/i18n";
import { Hidden, Grid } from "@material-ui/core";
import config from "../../config/config";
import LanguageChanger from "../Share/language-changer";
import CreatePlayer from "./CreatePlayer";

const moment = require("moment");

const InnerWrap = styled.div`
  margin: 0 auto;
  max-width: 1280px;
  width: 100%;
  font-size: 14px;
`;
const TopContainer = styled.div`
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #2d3e45;
`;
const TimerContainer = styled.div`
  font-family: arial;
  float: left;

  @media (max-width: 600px) {
    display: none;
  }
`;
const TimerLogo = styled.div`
  width: 11px;
  height: 11px;
  background: url(${TimerPNG}) no-repeat;
  float: left;
  margin-top: 3px;
`;
const TimerSpan = styled.span`
  color: #fff;
  float: left;
  margin-left: 5px;
  width: 190px;
`;
const LinkContainer = styled.div`
  float: left;
  min-width: 100px;
  @media (max-width: 600px) {
    padding-left: 20px;
  }
`;
const CustomLink = styled(Link)`
  color: #d1e0e5;

  &:hover {
    color: rgb(253, 231, 63);
    text-decoration: underline;
  }
`;

const LinkList = styled.ul`
  list-style: none;
  padding: 0;
`;

const ContentContainer = styled.div`
  display: flex;
  align-items: center;
`;
const LeftContainer = styled(ContentContainer)``;
const RightContainer = styled(ContentContainer)``;

const BtnAppsAndCS = styled.div`
  width: auto;
  min-width: 63px;
  height: 35px;
  float: right;
  color: #d1e0e5;
  &:hover {
    color: rgb(253, 231, 63);
    cursor: pointer;
  }
`;

const BtnCS = styled(BtnAppsAndCS)`
  margin-right: 8px;
  margin-top: 1px;
  width: 77px;
  background: url(${BtnCSPNG}) no-repeat center;
  color: #f6f6f6;
  &:hover {
    background: url(${BtnCSHoverPNG}) no-repeat center;
    color: rgb(253, 231, 63);
  }
  padding: 2px 0px 0px 34px;
  line-height: 30px;
`;

const BottomContainer = styled.div``;
const LogoContainer = styled.div`
  background-image: url(${process.env
    .PUBLIC_URL}/assets/images/logo/${config.brand}.png);
  background-repeat: no-repeat;
  background-size: auto 80px;
  background-position: left center;
  width: 270px;
  height: 110px;

  float: left;
  &:hover {
    cursor: pointer;
  }
  @media (max-width: 520px) {
    display: none;
  }
`;

const RightMenuContainer = styled.div`
  float: right;
  width:${(props) => (props.isMediumDevice ? "100%" : "")}}
`;
const RightMenuUL = styled.ul`
  float: right;
  list-style: none;
  padding: 0;
  margin: 0;
  height: 110px;
  width: 100%;
`;

const RightMenuTextSpan = styled.span`
  font-size: 16px;

  margin: 0 auto;
  display: block;

  padding-top: 6px;
  text-align: center;
`;

const MouseOverContainer = styled.div`
  width: 100%;
  height: 70px;
  display: none;

  position: fixed;
  left: 0;
  top: 160px;
  z-index: 1;
  cursor: default;
  transition: all 0.3s;
`;
const MouseOverBackground = styled.div`
  width: 100%;
  box-shadow: 4.5px 7.8px 13px 0 rgba(0, 0, 0, 0.75);
  background-color: rgba(0, 0, 0, 0.75);
`;
const RightMenuImg = styled.div`
  width: 50px;
  height: 44px;
  background-color: ${({ theme }) => theme.primary};
  mask: ${({ img }) => {
    return `url(${img}) no-repeat center /
contain;`;
  }};
  cursor: pointer;
  background: white;

  ${(props) =>
    props.active
      ? `
      background: rgb(253, 231, 63);
  `
      : `
  
  `}
`;
const RightMenuLI = styled.li`
  display: flex;
  flex-direction: column;
  align-items: center;

  width:${(props) => (props.isMobile ? "75px" : " 100px")}}
  height: 97%;
  color: #d1e0e5;
  padding: 0px;
  border-bottom: solid 4px transparent;

  &:hover {
    border-bottom: solid 4px rgb(253, 231, 63);
    color: rgb(253, 231, 63);
    background-color: none;

    cursor: pointer;
  }
  &:hover ${MouseOverContainer} {
    display: block;
  }
  &:hover ${RightMenuImg} {
  background:  rgb(253, 231, 63);
  }
  background-repeat: no-repeat;
  background-position: 15% center;
  ${(props) =>
    props.active
      ? `
  border-bottom: solid 4px rgb(253, 231, 63);
  color: rgb(253, 231, 63);
  background-color: none;

  `
      : `
  
  `}
`;

const HyperlinkList = styled(Link)`
  display: inline-block;
  width:${(props) => (props.isMobile ? "75px" : " 126px")}}
  height: 95%;
  color: #d1e0e5;
  padding: 0px;
  border-bottom: solid 4px transparent;

  &:hover {
    border-bottom: solid 4px rgb(253, 231, 63);
    color: rgb(253, 231, 63);
    background-color: none;

    cursor: pointer;
  }
  &:hover ${MouseOverContainer} {
    display: block;
  }
  &:hover ${RightMenuImg} {
    ${(props) => `background-image:url(${props.hoverimg});`}
  }
  background-repeat: no-repeat;
  background-position: 15% center;
  ${(props) =>
    props.active
      ? `
  border-bottom: solid 4px rgb(253, 231, 63);
  color: rgb(253, 231, 63);
  background-color: none;

  `
      : `
  
  `}
`;

const StyledAppBar = withStyles((theme) => ({
  root: {
    backgroundImage:
      "linear-gradient(to top, " +
      theme.palette.primary.mainGradient +
      ", " +
      theme.palette.primary.main +
      ")",
    "& .MuiToolbar-regular": {
      margin: "0 auto",
    },
  },
}))(AppBar);

const CustomizedAppBar = styled(StyledAppBar)`
  background: ${({ theme }) => theme.alternatePrimary};
`;

const styles = {
  root: {
    flexGrow: 1,
  },
  show: {
    transform: "translateY(0)",
    transition: "transform .5s",
  },
  hide: {
    transform: "translateY(-110%)",
    transition: "transform .5s",
  },
  minIcon: {
    width: "25px",
    height: "25px",
    margin: "20px 0 0 15px",
    transition: "all .3s",
  },
  maxIcon: {
    width: "100%",
    height: "40px",
    margin: "30px 0 0 0",
    transition: "all .3s",
  },
  maxLabel: {
    transition: "all .3s",
  },
  minLabel: {
    transition: "all .3s",
    transform: "translate(18px, -30px)",
  },
  maxMenu: {
    height: "110px",
    transition: "height .3s",
  },
  minMenu: {
    height: "64px",
    transition: "height .3s",
  },
};

class Header extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selected: "/",
      hovermenu: "",
      shouldShow: null,
      isHoverMenu: false,
      isHoverSubMenuContainer: false,
      open: false,
      isMobile: null,
      isMediumDevice: null,
      dialog: "",
      systemDateTime: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
      createPlayer: false,
    };
    this.lastScroll = null;

    this.handleScroll = this.handleScroll.bind(this);
    // this.refStickyHeader = React.createRef();
  }

  updateInterval = null;
  tickCount = 0;

  async componentDidMount() {
    // const localStorageToken = localStorage.getItem("token");
    // if (
    //   localStorageToken != null &&
    //   this.props.account.token == null &&
    //   this.props.account.info.token === ""
    // ) {
    //   const response = await this.props.checkTokenIsValid(localStorageToken);
    //   if (response.IsSuccess) {
    //     document.getElementById("ioBB").remove();
    //     this.props.getAccountProfile(response.data.token, true);
    //   }
    // }
    this.setState({ selected: this.props.location.pathname });

    this.FireTimerToUpdateBalance();
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }
  //componentDidUpdate(preProps) {
  //if (preProps.ui.showingDialog !== this.props.ui.showingDialog) {
  // const paddingRight = getComputedStyle(
  //   document.getElementsByTagName("body")[0]
  // ).getPropertyValue("padding-right");
  // this.refStickyHeader.current._root.children[0].setAttribute(
  //   "style",
  //   "padding-right:" + paddingRight
  // );
  //}
  //}
  handleScroll(evt) {
    const lastScroll = window.scrollY;
    if (lastScroll > 160) {
      this.setState({ ...this.state, shouldShow: false });
    } else {
      this.setState({ ...this.state, shouldShow: true });
    }
    return;
  }

  FireTimerToUpdateBalance = () => {
    this.updateInterval = setInterval(() => {
      this.setState({
        ...this.state,
        systemDateTime: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
      });
      if (this.props.account.token != null) {
        this.tickCount++;
        if (this.tickCount >= 5) {
          this.tickCount = 0;
          //this.props.getAccountProfile(this.props.account.token, false);
        }
      }
    }, 1000);
  };

  onhandleLogout = () => {
    this.props.logout(this.props.account.token);
    localStorage.removeItem("token0");
    localStorage.removeItem("token1");
    this.props.history.push("/");
  };
  onhandleLogoClick = () => {
    this.setState({ selected: "/" });
    this.props.history.push("/");
  };
  onhandleTransfer = () => {
    this.props.history.push("/account/transfer");
  };
  onhandleDeposit = () => {
    this.props.history.push("/account/deposit");
  };
  handleInboxClick = () => {
    this.props.history.push("/account/inbox");
  };
  handleRefreshBalanceClick = () => {
    this.props.getAccountProfile(this.props.account.token, true);
  };
  handleDownloadAppsClick = () => {
    // this.setState({ selected: "/download" });
    // this.props.history.push("/download");
    alert("敬请期待");
  };
  handleContactUsClick = () => {
    this.props.history.push("/aboutus/contactus");
  };
  HighlightLink = (link) => {};
  handleMenuMouseOver = (to) => {
    this.setState({ ...this.state, hovermenu: to, isHoverMenu: true });
  };
  handleMenuMouseLeave = () => {
    this.setState({ ...this.state, isHoverMenu: false });
  };
  handleClickMenu = (to) => {
    this.setState({ ...this.state, selected: to });
    this.props.history.push(to);
  };

  handleOpenCreatePlayerModal = () => {
    this.setState({ ...this.state, createPlayer: true });
  };

  RightMenuItem = (to, image, label, subMenuImg) => {
    const { classes } = this.props;
    return (
      <RightMenuLI
        isMobile={this.state.isMobile}
        active={this.state.selected === `${to}`}
        onClick={() =>
          to === "create-player"
            ? this.handleOpenCreatePlayerModal()
            : this.handleClickMenu(to)
        }
        onMouseOver={() => this.handleMenuMouseOver(to)}
        onMouseLeave={this.handleMenuMouseLeave}
      >
        <RightMenuImg
          img={image}
          active={this.state.selected === `${to}`}
          className={`${
            this.state.shouldShow === null
              ? classes.maxIcon
              : this.state.shouldShow
              ? classes.maxIcon
              : this.state.isMobile
              ? classes.minIconNoText
              : classes.minIcon
          }`}
        ></RightMenuImg>
        <RightMenuTextSpan
          className={`${
            this.state.shouldShow === null
              ? classes.maxLabel
              : this.state.shouldShow
              ? classes.maxLabel
              : classes.minLabel
          }`}
        >
          {label}
        </RightMenuTextSpan>
        {(() => {
          if (subMenuImg != null) {
            return (
              <MouseOverContainer
                onClick={(e) => e.stopPropagation()}
                style={
                  this.state.shouldShow === null
                    ? { top: "160px" }
                    : this.state.shouldShow
                    ? this.state.isMediumDevice || this.state.isMobile
                      ? { top: "230px" }
                      : { top: "160px" }
                    : { top: "114px" }
                }
              >
                <MouseOverBackground>{subMenuImg}</MouseOverBackground>
              </MouseOverContainer>
            );
          } else {
            return null;
          }
        })()}
      </RightMenuLI>
    );
  };

  HyperlinkMenuItem = (to, image, label, imageHover, subMenuImg) => {
    const { classes } = this.props;
    return (
      <HyperlinkList
        isMobile={this.state.isMobile}
        to={to}
        target="_blank"
        hoverimg={imageHover}
      >
        <RightMenuImg
          img={image}
          hoverimg={imageHover}
          active={this.state.selected === `${to}`}
          className={`${
            this.state.shouldShow === null
              ? classes.maxIcon
              : this.state.shouldShow
              ? classes.maxIcon
              : this.state.isMobile
              ? classes.minIconNoText
              : classes.minIcon
          }`}
        ></RightMenuImg>
        <Hidden xsDown>
          <RightMenuTextSpan
            className={`${
              this.state.shouldShow === null
                ? classes.maxLabel
                : this.state.shouldShow
                ? classes.maxLabel
                : classes.minLabel
            }`}
          >
            {label}
          </RightMenuTextSpan>
        </Hidden>
        {(() => {
          if (subMenuImg != null) {
            return (
              <MouseOverContainer
                onClick={(e) => e.stopPropagation()}
                style={
                  this.state.shouldShow === null
                    ? { top: "160px" }
                    : this.state.shouldShow
                    ? { top: "160px" }
                    : { top: "114px" }
                }
              >
                <MouseOverBackground>{subMenuImg}</MouseOverBackground>
              </MouseOverContainer>
            );
          } else {
            return null;
          }
        })()}
      </HyperlinkList>
    );
  };

  MouseOverItem = (to, image) => {
    return (
      <CustomLink to={to} onClick={() => this.setState({ selected: to })}>
        <img src={image} alt=""></img>
      </CustomLink>
    );
  };
  handleJoinGameClick = (url) => {
    if (this.props.account.token == null) {
      this.props.showLogin(true);
    } else {
      Common.PopupCenter(url, "", 1280, 720);
    }
  };
  handleBookmarkClick = () => {
    let pageTitle = document.title;
    let pageURL = document.location;
    try {
      // Internet Explorer solution
      eval(
        "window.external.AddFa-vorite(pageURL, pageTitle)".replace(/-/g, "")
      );
    } catch (e) {
      try {
        // Mozilla Firefox solution
        window.sidebar.addPanel(pageTitle, pageURL, "");
      } catch (e) {
        // Opera solution
        if (typeof window.opera == "object") {
          e.rel = "sidebar";
          e.title = pageTitle;
          e.url = pageURL;
          return true;
        } else {
          // The rest browsers (i.e Chrome, Safari)

          alert(
            "请按" +
              (navigator.userAgent.toLowerCase().indexOf("mac") !== -1
                ? "Cmd"
                : "Ctrl") +
              "+D收藏网站。"
          );
        }
      }
    }
    return false;
  };

  handleCloseCreatePlayerModal = () => {
    this.setState({ ...this.state, createPlayer: false });
  };

  render() {
    const { classes } = this.props;

    return (
      <div>
        <CustomizedAppBar>
          <InnerWrap>
            <TopContainer>
              <LeftContainer>
                <TimerContainer className={classes.hideOnSm}>
                  <TimerLogo></TimerLogo>
                  <TimerSpan>GMT+8 {this.state.systemDateTime}</TimerSpan>
                </TimerContainer>

                <LinkContainer>
                  <LinkList>
                    <LanguageChanger language={config.languages} />
                  </LinkList>
                </LinkContainer>
              </LeftContainer>

              <RightContainer isMediumDevice={this.state.isMediumDevice}>
                {(() => {
                  if (
                    this.props.account.token !== this.props.account.info.token
                  ) {
                    return (
                      <GuestContainer
                        onhandleShowCSClick={this.props.onhandleShowCSClick}
                        onhandleContactUsClick={this.handleContactUsClick}
                      ></GuestContainer>
                    );
                  } else {
                    return (
                      <MemberContainer
                        onhandleLogout={this.onhandleLogout}
                        onhandleTransfer={this.onhandleTransfer}
                        onhandleDeposit={this.onhandleDeposit}
                        onhandleInboxClick={this.handleInboxClick}
                        onhandleRefreshBalanceClick={
                          this.handleRefreshBalanceClick
                        }
                      ></MemberContainer>
                    );
                  }
                })()}
                {config.brand !== "HKJP" && (
                  <BtnCS onClick={this.props.onhandleShowCSClick}>
                    {i18n.t("header.cs")}
                  </BtnCS>
                )}
              </RightContainer>
            </TopContainer>
            <BottomContainer>
              <LogoContainer
                onClick={this.onhandleLogoClick}
                className={`${
                  this.state.shouldShow === null
                    ? classes.maxMenu && classes.maxLogo
                    : this.state.shouldShow
                    ? classes.maxMenu && classes.maxLogo
                    : classes.minMenu && classes.minLogo
                }`}
              ></LogoContainer>
              <RightMenuContainer isMediumDevice={this.state.isMediumDevice}>
                <RightMenuUL
                  className={` top-menu ${
                    this.state.shouldShow === null
                      ? classes.maxMenu
                      : this.state.shouldShow
                      ? classes.maxMenu
                      : classes.minMenu
                  }`}
                >
                  <Grid container justify="center" style={{ height: "100%" }}>
                    {(() => {
                      if (
                        this.props.account.token !==
                        this.props.account.info.token
                      ) {
                        return (
                          <>
                            {this.RightMenuItem(
                              "/",
                              TopMenuHome,
                              i18n.t("header.menu.home"),
                              null
                            )}
                            {/* <Grid item>
                            {this.RightMenuItem(
                              "/about/about",
                              TopMenuAbout,
                              i18n.t("header.menu.about.title"),
                              TopMenuAboutHover,
                              <SubMenuAbout></SubMenuAbout>
                            )}
                          </Grid>
                          <Grid item>
                            {this.RightMenuItem(
                              "/contact",
                              TopMenuContact,
                              i18n.t("header.menu.contact"),
                              TopMenuContactHover,
                              null
                            )}
                          </Grid>
                          <Grid item>
                            {this.HyperlinkMenuItem(
                              "/promotion",
                              TopMenuPromoSVG,
                              i18n.t("header.menu.promote"),
                              TopMenuPromoHoverSVG,
                              null
                            )}
                          </Grid> */}
                          </>
                        );
                      } else {
                        return (
                          <>
                            {config.features.Agent_CreatePlayer &&
                              this.RightMenuItem(
                                "create-player",
                                MenuCreatePlayer,
                                i18n.t("header.menu.createplayer.title"),
                                null
                              )}
                            {this.RightMenuItem(
                              "/account/agentDetail",
                              TopMenuHome,
                              i18n.t("header.menu.account.title"),
                              null
                            )}

                            {this.RightMenuItem(
                              !this.props.check_pass_account
                                ? "/"
                                : "/report/contribute",
                              TopMenuReport,
                              i18n.t("header.menu.report.title"),
                              <SubMenuReport />
                            )}
                          </>
                        );
                      }
                    })()}
                  </Grid>
                </RightMenuUL>
              </RightMenuContainer>
            </BottomContainer>
          </InnerWrap>
        </CustomizedAppBar>
        {config.features.Agent_CreatePlayer && (
          <CreatePlayer
            open={this.state.createPlayer}
            handleClose={this.handleCloseCreatePlayerModal}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    // screenWidth: state.ui.screenWidth,
    // screenHeight: state.ui.screenHeight,
    ui: state.ui,
    account: state.account,
    check_pass_account: state.account.info.check_pass_account,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    // updateScreenSize: (width, height) => {
    //   dispatch({ type: UPDATING_SCREEN_SIZE, width, height });
    // },
    // checkTokenIsValid: token =>
    //   dispatch(accountAction.checkTokenIsValid(token)),
    logout: (token) => dispatch({ type: LOGOUT_SUCCESS }),

    getAccountProfile: (token, showVip) =>
      dispatch(accountAction.getAccountProfile(token, showVip)),
    checkTokenIsValid: (token) =>
      dispatch(accountAction.checkTokenIsValid(token)),
    showLogin: (payload) => {
      dispatch({ type: SHOW_LOGIN, payload });
    },
    showDialog: (payload) => {
      dispatch({ type: SHOWING_DIALOG, payload });
    },
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withSnackbar(withStyles(styles)(Header)))
);
