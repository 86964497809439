import React from "react";
import styled from "styled-components";

const Textfieldx = styled.input`
  /*placeholder black text opacity 0.75*/
  ::placeholder {
    color: rgba(0, 0, 0, 0.75);
  }
  width: -webkit-fill-available;

  border: none;

  margin: 0;
  background: white;
  box-shadow: 0px 3px 8px -2px rgb(0 0 0 / 75%);
  padding: 15px 10px 15px
    ${({ gotIcon }) => {
      return gotIcon ? "60px" : "20px";
    }};

  border-radius: 6px;
`;
const Icon = styled.div`
  background: #000;
  opacity: 0.75;
  width: 30px;
  height: 30px;
  position: absolute;
  left: 22px;
  top: 12px;

  mask: url(${(props) => {
      return (
        process.env.PUBLIC_URL + "/assets/images/icon/" + props.icon + ".png"
      );
    }})
    no-repeat center / contain;
  mask-size: auto;
`;

const TextfieldWrapper = styled.div`
  display: flex;
  flex: auto;
  padding: 5px 5px 10px 5px;
  position: relative;
`;

const Textfield = React.memo(
  ({ name, placeholder, value, onChange, key, icon, type, maxlength }) => {
    //prevent component keep re-rendering

    return (
      <TextfieldWrapper>
        <Icon icon={icon} />
        <Textfieldx
          key={key}
          type={type}
          placeholder={placeholder}
          gotIcon={icon}
          maxLength={maxlength}
          value={value}
          onChange={onChange}
        />
      </TextfieldWrapper>
    );
  }
);

export default Textfield;
