import React, { Component } from "react";

import styled from "styled-components";

import LoginBGPJPG from "../../assets/images/login-bg.jpg";

import CloseIcon from "@material-ui/icons/Close";

const Container = styled.div`
  width: 420px;

  background: url(${LoginBGPJPG});
  display: table;
`;
const CloseIconContainer = styled(CloseIcon)`
  float: right;
  margin: 10px 10px 0 0;
  &:hover {
    cursor: pointer;
  }
`;
const TitleContainer = styled.div`
  font-size: 26.5px;
  text-align: center;
  color: #16242a;
`;

const FormContainer = styled.div`
  margin: 0 35px;
`;

const Button = styled.div`
  width: 347px;
  height: 46px;
  border: solid 1px #b6b6b6;
  margin-top: 15px;
  background-image: linear-gradient(to top, #c0252d, #d95a61);
  border-radius: 5px;
  font-size: 16px;
  color: #fff;

  &:hover {
    cursor: pointer;
    background-blend-mode: screen, normal;
    background-image: linear-gradient(
        to bottom,
        rgba(255, 255, 255, 0.08),
        rgba(255, 255, 255, 0.08)
      ),
      linear-gradient(to top, #c0252d, #d95a61);
  }
`;
const ButtonLabel = styled.span`
  text-align: center;
  display: block;
  margin-top: 10px;
`;
const LinkStyle = styled.a`
  color: #c0252d;
  font-size: 14px;
  margin-top: 5px;
  text-decoration: underline;
  &:hover {
    cursor: pointer;
  }
`;

const Description = styled.p`
  font-size: 13px;
  color: #686868;
  text-align: center;
`;

const ContactUsLink = styled(LinkStyle)`
  font-size: 13px;
`;

class ResetPasswordSuccess extends Component {
  state = {
    password: "",
    confirmpassword: "",
  };
  componentDidMount() {}
  handlePasswordChange = (e) => {
    this.setState({ ...this.state, password: e.target.value });
  };
  handleNewPasswordChange = (e) => {
    this.setState({ ...this.state, confirmpassword: e.target.value });
  };
  render() {
    return (
      <Container>
        <CloseIconContainer
          viewBox="4 4 16 16"
          onClick={this.props.onhandleClose}
        ></CloseIconContainer>
        <div className="clear"></div>
        <TitleContainer>重置密码成功</TitleContainer>

        <FormContainer>
          <Button onClick={this.props.onhandleSubmitClick}>
            <ButtonLabel>确定</ButtonLabel>
          </Button>

          <Description>
            如有问题，请及时
            <ContactUsLink onClick={this.props.onhandleOnlineSupport}>
              联系我们
            </ContactUsLink>
          </Description>
        </FormContainer>
      </Container>
    );
  }
}

export default ResetPasswordSuccess;
