import React from "react";
import styled from "styled-components";
const TextfieldWrapper = styled.div`
  display: flex;
  flex: auto;
  padding: 5px 5px 10px 5px;
  position: relative;

  width: ${({ width }) => {
    return width ? width + "px" : "";
  }};
`;
const Selectx = styled.select`
  border: none;
  width: -webkit-fill-available;
  background: white;
  box-shadow: 0px 3px 8px -2px rgb(0 0 0 / 75%);
  padding: 15px;
  border-radius: 6px;
`;

const Select = ({ width, children, onChange }) => {
  return (
    <TextfieldWrapper width={width}>
      <Selectx onChange={onChange}>{children}</Selectx>
    </TextfieldWrapper>
  );
};

export default Select;
