export const GameTypeEnum = {
  LIVE: "live",
  QIPAI: "chess",
  SPORTS: "sports",
  LOTTERY: "lottery",
  SLOT: "slot"
};
export const GameEnum = {
  AG: "ag",
  BBIN: "bbin",
  KY: "ky",
  AP: "ap",
  _633: "633",
  Sabah: "sabah",
  Pinnacle: "pinnacle"
};
export const LotteryGameTypeEnum = {
  K3: "k3",
  KENO: "keno",
  SSC: "ssc",
  PK10: "pk10",
  LOTTERY: "lotto"
};
export const AGGameTypeEnum = {
  Lobby: 0,
  Premium: 1,
  International: 2,
  Multitable: 3,
  VIP: 4,
  WebJ: 5,
  SlotLobby: 8,
  PremiumH5Baccarat: 12,
  InternationalH5Baccarat: 13,
  InternationalH5DragonTiger: 15,
  H5VideoLobby: 18,
  PremiumBaccarat: 21,
  InternationalBaccarat: 22,
  PremiumDragonTiger: 23,
  InternationalDragonTiger: 24,
  PremiumRoulette: 25,
  InternationalRoulette: 26,
  PremiumSicbo: 27,
  VIPBaccarat: 30,
  LEDVIPBaccarat: 31,
  NiuNiu: 32,
  Blackjack: 33,
  LiveStream: 34,
  ZhaJinHua: 36,
  DouNiu: 37,
  Euro: 38,
  NewWorld: 39
};

export const WalletType = {
  MainWallet: 0,
  SingleWallet: 1,
  TransferWallet: 2
};
export const SlotGameParentId = {
  BBIN: 140000,
  AG: 180000,
  MG: 40000,
  JDB: 110000
};
export const SlotGameCategory = {
  Live: { id: 1, title: "视频扑克" },
  Table: { id: 2, title: "桌面游戏" },
  Slot: { id: 3, title: "老虎机" },
  Jackpot: { id: 4, title: "刮刮卡" },
  LittleGame: { id: 5, title: "小游戏" },
  Lobby: { id: 6, title: "游戏厅" },
  Progressive: { id: 7, title: "累积大奖" },
  Fishing: { id: 8, title: "捕鱼游戏" },
  Qipai: { id: 8, title: "棋牌" }
};
export const SlotGameIsTest = {
  Yes: 1,
  No: 2
};
export const SlotGameWithTest = {
  No: 0,
  Yes: 1
};
