import React, { createContext, useState } from "react";

export const FunctionContext = createContext();

const FunctionContextProvider = (props) => {
  const moment = require("moment");
  const [selectedDateFrom, handleDateChangeFrom] = useState(
    moment().subtract(1, "months").format("YYYY/MM/DD")
  );
  const [selectedDateTo, handleDateChangeTo] = useState(
    moment().format("YYYY/MM/DD")
  );

  const handleDateFrom = (date) => {
    handleDateChangeFrom(date);
  };
  const handleDateTo = (date) => {
    if (moment(date).isBefore(selectedDateFrom)) {
    } else {
      handleDateChangeTo(date);
    }
  };

  return (
    <FunctionContext.Provider
      value={{ selectedDateFrom, selectedDateTo, handleDateFrom, handleDateTo }}
    >
      {props.children}
    </FunctionContext.Provider>
  );
};

export default FunctionContextProvider;
