import React, { useEffect, useState, useContext } from "react";

import Header from "../Account/Header";
import {
  CustomContainer,
  ReportContainer,
  StyledTableCell,
  FilterContainer,
  CustomFormControl,
  TitleContainer,
  Title,
  Content,
  CustomQuickButton,
} from "../../Share/General";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";

import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TablePagination from "@material-ui/core/TablePagination";
import ReportDatepicker from "./ReportDatepicker";
import Select from "@material-ui/core/Select";
import { Container } from "@material-ui/core";
import { connect } from "react-redux";
import { affiliateAction } from "../../../actions/affiliateAction";
import { FunctionContext } from "../../Context/FunctionContext";

const PromotionalReport = (props) => {
  const [page, setPage] = useState(0);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    getPromotionallReportList(
      selectedDateFrom,
      selectedDateTo,
      channel,
      newPage + 1
    );
  };

  const [result, setResult] = useState();

  const defaultChannel = localStorage.getItem("default");
  const [channel, setChannel] = useState(null);
  const [render, setRendered] = useState(false);
  const [assigned, setAssigned] = useState(false);

  useEffect(() => {
    setChannel(props.first);
    setRendered(true);
    if (channel !== "" || channel !== null) {
      setAssigned(true);
    }
  }, []);

  useEffect(() => {
    if (render === true && assigned === true) {
      getPromotionallReportList(
        selectedDateFrom,
        selectedDateTo,
        channel ? channel : defaultChannel
      );
    }
  }, [render, assigned]);

  const getPromotionallReportList = async (
    dateFrom,
    dateTo,
    channelId,
    page
  ) => {
    const result = await props.getAffiliatePromotionalReport(
      props.token,
      props.data.member_id,
      channelId,
      dateFrom,
      dateTo,
      page ? page : 1,
      10
    );
    if (result.IsSuccess) {
      setResult(result.data);
    }
  };

  const getUserSelectedQuery = (dateFrom, dateTo, channelId) => {
    getPromotionallReportList(dateFrom, dateTo, channelId);
  };

  const { selectedDateFrom, selectedDateTo } = useContext(FunctionContext);

  return (
    <CustomContainer>
      <Container maxWidth="lg">
        <Header />
        <ReportContainer>
          <Content>
            <TitleContainer>
              <Title>推广报表</Title>
            </TitleContainer>
            <FilterContainer>
              <ReportDatepicker onhandleDateChange></ReportDatepicker>

              <CustomFormControl>
                <Select
                  native
                  variant="outlined"
                  onChange={(e) => {
                    setChannel(e.target.value);
                  }}
                >
                  {props.lists.map((data, index) => {
                    return <option value={data.id}>{data.title}</option>;
                  })}
                </Select>
              </CustomFormControl>
              <CustomQuickButton
                onClick={() =>
                  getUserSelectedQuery(
                    selectedDateFrom,
                    selectedDateTo,
                    channel
                  )
                }
              >
                查询
              </CustomQuickButton>
            </FilterContainer>
            <Table aria-label="customized table">
              <TableHead>
                <TableRow>
                  <StyledTableCell>日期</StyledTableCell>
                  <StyledTableCell>点击数</StyledTableCell>
                  <StyledTableCell>注册数</StyledTableCell>
                  <StyledTableCell>首存数</StyledTableCell>
                  <StyledTableCell>活跃客户数</StyledTableCell>
                  <StyledTableCell>存款客户数</StyledTableCell>
                  <StyledTableCell>提款客户数</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {result
                  ? result.datas.map((data, index) => {
                      return (
                        <TableRow>
                          <StyledTableCell>{data.day}</StyledTableCell>
                          <StyledTableCell>{data.click_num}</StyledTableCell>
                          <StyledTableCell>{data.register_num}</StyledTableCell>
                          <StyledTableCell>
                            {data.first_deposit_num}
                          </StyledTableCell>
                          <StyledTableCell>{data.click_num}</StyledTableCell>
                          <StyledTableCell>{data.deposit_num}</StyledTableCell>
                          <StyledTableCell>{data.withdraw_num}</StyledTableCell>
                        </TableRow>
                      );
                    })
                  : ""}
              </TableBody>
            </Table>
            <TablePagination
              rowsPerPageOptions={[10]}
              component="div"
              count={result ? result.total : ""}
              rowsPerPage={10}
              page={page}
              backIconButtonProps={{
                "aria-label": "previous page",
              }}
              nextIconButtonProps={{
                "aria-label": "next page",
              }}
              onChangePage={handleChangePage}
            />
          </Content>
        </ReportContainer>
      </Container>
    </CustomContainer>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    getAffiliatePromotionalReport: (
      token,
      affiliate_id,
      channel_list,
      start,
      end,
      page,
      limit
    ) =>
      dispatch(
        affiliateAction.getAffiliatePromotionalReport(
          token,
          affiliate_id,
          channel_list,
          start,
          end,
          page,
          limit
        )
      ),
  };
};
const mapStateToProps = (state) => {
  return {
    token: state.account.token,
    data: state.account.info,
    lists: state.affiliate.lists,
    first: state.affiliate.lists[0].id,
    state,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PromotionalReport);
