const featureList = {
  default: {
    AddCard_DisplayFPS: false,
    AddCard_BankBranch: true,
    VIPPage_PlayerList: true,
    Header_PlayerCount: true,
    HideDividendReport: false,
    Agent_CreatePlayer: false,
    Register_ShowPhone: true,
  },
};
export default featureList;
