import React, { Component } from "react";
import Loading from "../App/Loading";
import { GameService } from "../../services/GameService";
import { GameTypeEnum, GameEnum } from "../../utils/Enum";
import Maintenance from "./Maintenance";
import { isMobile } from "react-device-detect";
class JoinGame extends Component {
  state = {
    loading: true,
    maintenance: false,
  };
  componentDidMount() {
    const token = localStorage.getItem("token" + (isMobile ? 1 : 0));
    const gameType = this.props.match.params.gametype;
    const game = this.props.match.params.game;
    const gameId = this.props.match.params.gameid;
    if (gameType === GameTypeEnum.LIVE) {
      let result = null;
      if (game === GameEnum.AG) {
        result = GameService.JoinAGLiveGame(token, gameId);
      } else if (game === GameEnum.BBIN) {
        result = GameService.JoinBBINLiveGame(token, gameId);
      }
      result.then(function (response) {
        if (response.IsSuccess) {
          window.location.href = response.url;
        } else {
          this.handleJoinGameMaintenance(response);
        }
      });
    } else if (gameType === GameTypeEnum.SLOT) {
      GameService.JoinSlotGame(token, game).then(function (response) {
        if (response.IsSuccess) {
          window.location.href = response.url;
        } else {
          this.handleJoinGameMaintenance(response);
        }
      });
    } else if (gameType === GameTypeEnum.LOTTERY) {
      const result = GameService.JoinLotteryGame(token, game);
      result.then(function (response) {
        if (response.IsSuccess) {
          window.location.href = response.url;
        } else {
          this.handleJoinGameMaintenance(response);
        }
      });
    } else if (gameType === GameTypeEnum.QIPAI) {
      const result = GameService.JoinQipaiGame(token, game, gameId);
      result.then(function (response) {
        if (response.IsSuccess) {
          window.location.href = response.url;
        } else {
          this.handleJoinGameMaintenance(response);
        }
      });
    } else if (gameType === GameTypeEnum.SPORTS) {
      const result = GameService.JoinSportsbook(token, game, gameId);
      result.then((response) => {
        if (response.IsSuccess) {
          window.location.href = response.url;
        } else {
          this.handleJoinGameMaintenance(response);
        }
      });
    }
  }
  handleJoinGameMaintenance = (response) => {
    this.setState({ ...this.state, maintenance: true, loading: false });
  };
  render() {
    if (this.state.maintenance) {
      return <Maintenance></Maintenance>;
    } else {
      return <Loading show={this.state.loading} opacity={1}></Loading>;
    }
  }
}

export default JoinGame;
