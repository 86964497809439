import React, { Component } from "react";
import {
  FullSizeContainer,
  Content,
  TitleContainer,
  Title,
} from "../../../Share/General";
import styled from "styled-components";
import { walletAction } from "../../../../actions/walletActions";
import { withRouter } from "react-router-dom";
import Loading from "../../Loading";
import { WalletService } from "../../../../services/WalletService";
import { accountAction } from "../../../../actions/accountAction";
import { RESET_WALLETRESULT } from "../../../../constant/actionType";
import AddCard from "../Withdrawal/AddCard";
import AddCardSuccess from "../Withdrawal/AddCardSuccess";
import CardManage from "../Withdrawal/CardManage";
import { AppHistory } from "../../../../history/History";
import { isMobile } from "react-device-detect";
import { withSnackbar } from "notistack";
import { connect } from "react-redux";
import i18n from "../../../../utils/i18n";
import config from "../../../../config/config";
const InnerWrap = styled.div`
  min-height: 532px;
  margin-top: 18px;
  box-sizing: border-box;
  background-color: #f7f7f7;
  padding: 25px;
  position: relative;
  border-radius: 10px;

  @media (max-width: 520px) {
    width: auto;
  }
`;

class Card extends Component {
  state = {
    loading: false,
    defaultCard: 0,
    addCard: {
      number: "",
      password: "",
    },
    isAddCardSuccess: false,
    displayView: 3,
    showMessagebox: false,
    messageboxText: "",
    withdrawalInfo: {
      bonus_limit_list: [],
    },
  };

  GetWithdrawalDetails() {
    WalletService.GetWithdrawalDetails(this.props.token).then((response) => {
      this.setState({ ...this.state, withdrawalInfo: response.data });
    });
  }
  componentDidMount() {
    const localStorageToken = localStorage.getItem(
      "token" + (isMobile ? 1 : 0)
    );
    if (localStorageToken === null) {
      AppHistory.push("/");
    }
    if (config.currency !== "IDR") {
      if (
        this.props.account.info.member_safe_password !== "set" &&
        config.brand !== "HK28"
      ) {
        this.props.onhandleClickTab("safety");
      }
    }
    if (this.props.token !== null) {
      if (this.props.wallet.bankCardList.length === 0)
        this.props.getBankCardList();
      if (this.props.wallet.withdrawalList.length === 0)
        this.props.getWithdrawalChannel();
    }
  }
  componentDidUpdate(preProps) {
    if (preProps.token !== this.props.token && this.props.token !== null) {
      if (this.props.wallet.bankCardList.length === 0)
        this.props.getBankCardList();
      if (this.props.wallet.withdrawalList.length === 0)
        this.props.getWithdrawalChannel();
    }
    if (
      preProps.wallet.deleteBankCardResult !==
        this.props.wallet.deleteBankCardResult &&
      this.props.wallet.deleteBankCardResult !== null
    ) {
      if (this.props.wallet.deleteBankCardResult.IsSuccess) {
        this.props.getBankCardList();
      } else {
        this.props.enqueueSnackbar(
          this.props.wallet.deleteBankCardResult.info,
          {
            variant: "error",
          }
        );
      }
      this.props.resetResult();
    }
    if (
      preProps.wallet.addBankCardResult !==
        this.props.wallet.addBankCardResult &&
      this.props.wallet.addBankCardResult !== null
    ) {
      if (this.props.wallet.addBankCardResult.IsSuccess) {
        this.props.resetResult();
        this.props.getBankCardList();
        this.setState({
          ...this.state,
          loading: false,
          isAddCardSuccess: true,
        });
      } else {
        this.props.enqueueSnackbar(this.props.wallet.addBankCardResult.info, {
          variant: "error",
        });
      }
    }
  }
  handleAddCardNextClick = (channelid, bankType, detail) => {
    this.props.addBankCard(channelid, bankType, detail);
  };

  handleBindCardClick = () => {
    this.setState({ ...this.state, isAddCardSuccess: false, displayView: 2 });
  };
  handleGoWithdrawClick = () => {
    this.props.onhandleClickTab("withdrawal");
  };
  handleCSClick = () => {
    this.props.onhandleCSClick(
      this.props.account.info.member_id,
      this.props.account.info.member_name,
      ""
    );
  };
  handleManageCardClick = () => {
    this.setState({ ...this.state, displayView: 3 });
  };
  handleAddCardClick = () => {
    this.setState({ ...this.state, displayView: 2 });
  };
  handleCardManageBack = () => {
    this.setState({ ...this.state, displayView: 1 });
  };
  handleAddCardBack = () => {
    this.setState({ ...this.state, isAddCardSuccess: false, displayView: 3 });
  };
  handleDeleteCardClick = (id) => {
    this.props.deleteBankCard(id);
  };
  handleReportClick = () => {
    this.props.onhandleClickTab("report/downline");
  };

  handleSetPasswordClick = (password1) => {
    this.props.addWithdrawalPassword(password1).then((response) => {
      if (response.IsSuccess) {
        this.props.enqueueSnackbar(response.msg, {
          variant: "success",
        });
      } else {
        this.props.enqueueSnackbar(response.msg, {
          variant: "error",
        });
      }
    });
  };

  render() {
    return (
      <FullSizeContainer>
        <Content>
          <TitleContainer>
            <Title>{i18n.t("title.cardManagement")}</Title>
          </TitleContainer>

          <InnerWrap>
            {(() => {
              if (
                this.props.account.info.member_safe_password !== "set" &&
                config.brand !== "HK28"
              ) {
                return this.props.history.push(
                  "/account/safety/member_safe_password"
                );
              } else {
                if (
                  this.props.wallet.bankCardList != null &&
                  this.props.wallet.bankCardList === 0
                ) {
                  return (
                    <AddCard
                      name={this.props.account.info.member_name}
                      onhandleNextClick={this.handleAddCardNextClick}
                      withdrawalList={this.props.wallet.withdrawalList}
                      showBack={false}
                    ></AddCard>
                  );
                } else {
                  if (this.state.isAddCardSuccess === true) {
                    return (
                      <AddCardSuccess
                        onhandleBindCardClick={this.handleBindCardClick}
                        onhandleGoWithdrawClick={this.handleGoWithdrawClick}
                        onhandleBackClick={this.handleAddCardBack}
                        onhandleCSClick={this.handleCSClick}
                      ></AddCardSuccess>
                    );
                  } else {
                    switch (this.state.displayView) {
                      case 2:
                        return (
                          <AddCard
                            name={this.props.account.info.member_name}
                            onhandleNextClick={this.handleAddCardNextClick}
                            withdrawalList={this.props.wallet.withdrawalList}
                            onhandleBackClick={this.handleAddCardBack}
                            showBack={true}
                          ></AddCard>
                        );

                      default:
                        return (
                          <CardManage
                            bankCardList={this.props.wallet.bankCardList}
                            onhandleAddCardClick={this.handleAddCardClick}
                            onhandleDeleteCardClick={this.handleDeleteCardClick}
                            onhandleReportClick={this.handleReportClick}
                          ></CardManage>
                        );
                    }
                  }
                }
              }
            })()}

            <Loading show={this.state.loading}></Loading>
          </InnerWrap>
        </Content>
      </FullSizeContainer>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    token: state.account.token,
    account: state.account,

    game: state.game,
    wallet: state.wallet,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getBalanceList: (token) => dispatch(walletAction.getBalanceList()),
    getBankCardList: () => dispatch(walletAction.getBankCardList()),
    deleteBankCard: (id) => dispatch(walletAction.deleteBankCard(id)),
    addBankCard: (channelid, bankType, detail) =>
      dispatch(walletAction.addBankCard(channelid, bankType, detail)),
    getWithdrawalChannel: () => dispatch(walletAction.getWithdrawalChannel()),
    resetResult: () =>
      dispatch({
        type: RESET_WALLETRESULT,
      }),
    doWithdraw: (amount, password, user_account_id) =>
      dispatch(walletAction.doWithdraw(amount, user_account_id, password)),
    addWithdrawalPassword: (password) => {
      dispatch(accountAction.addWithdrawalPassword(password));
    },
  };
};
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withSnackbar(Card))
);
