import React from "react";
import styled from "styled-components";
import { Dialog } from "@material-ui/core";
import LoginBGPNG from "../../../assets/images/login-bg.png";
import Textfield from "../component/Textfield";
import Select from "../component/select";
import i18n from "../../../utils/i18n";
import { accountAction } from "../../../actions/accountAction";
import { connect } from "react-redux";
import { useSnackbar } from "notistack";
import config from "../../../config/config";
import ErrorSwitcher from "../../../utils/ErrorSwitcher";
const CustomDialog = styled(Dialog)`
  .MuiPaper-rounded {
    border-radius: 20px;
  }
`;

const RegisterPlayerContainer = styled.div`
  width: 25vw;
  min-height: 50vh;

  background: url(${LoginBGPNG});
  padding: 20px;

  /*tablet size*/
  @media (max-width: 1280px) {
    width: 100%;
  }

  @media (max-width: 520px) {
    width: 80vw;
  }
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const TopPart = styled.div``;
const Title = styled.div`
  text-align: center;
  font-size: 50px;
  font-weight: 600;
  padding-bottom: 20px;
  @media (max-width: 520px) {
    padding: 5px;
  }
`;
const TheForm = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px 50px;
  @media (max-width: 520px) {
    padding: 5px;
  }
`;

const Input = styled.div`
  display: flex;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const Button = styled.div`
  margin-top: 35px;
  width: 350px;
  height: 51px;
  color: #fff;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  border-radius: 6px;
  border: solid 1px #ffffff;
  background-image: ${({ theme }) =>
    `linear-gradient(to top,${theme.primaryGradient}, rgba(${theme.primaryGradientRgb}, 0.9));`};

  ${(props) =>
    props.disabled
      ? " pointer-events: none; filter: grayscale(75%); cursor: not-allowed;"
      : " pointer-events: auto; "};
`;

const Checkbox = styled.div`
  background: ${({ toggle, theme }) => (toggle ? theme.primary : "#000")};
  opacity: ${({ toggle }) => (toggle ? "1" : "0.3")};
  width: 40px;
  height: 40px;
  cursor: pointer;
  margin-right: 10px;

  mask: url(${(props) => {
      return process.env.PUBLIC_URL + "/assets/images/misc/checkbox.png";
    }})
    no-repeat center / 110%;
`;

const CreatePlayer = React.memo(
  ({
    open,
    handleClose,
    createNewPlayer,
    newPlayerContainPhone,
    newPlayerContainEmail,
    checkRegisterCondition,
  }) => {
    const [username, setUsername] = React.useState("");
    const [password, setPassword] = React.useState("");
    const [confirmpass, setConfirmPass] = React.useState("");
    const [areacode, setareacode] = React.useState("+86");
    const [email, setEmail] = React.useState("");
    const [phone, setPhone] = React.useState("");
    const [accept, setAccept] = React.useState(false);

    const { enqueueSnackbar } = useSnackbar();

    const toggleAccept = () => {
      setAccept(!accept);
    };

    const handleRegisterSuccessful = () => {
      handleClose();
      setUsername("");
      setPassword("");
      setConfirmPass("");
      setareacode("+86");
      setEmail("");
      setPhone("");
      setAccept(false);
      enqueueSnackbar(i18n.t("createplayer.success"), {
        variant: "success",
      });
    };

    const handleRegister = () => {
      //check if username contain 6-16 character and contain alphanumeric
      if (!/^[a-zA-Z0-9]{6,16}$/.test(username)) {
        enqueueSnackbar(i18n.t("createplayer.invalidusername"), {
          variant: "error",
        });
        return;
      }

      if (!/^.{6,20}$/.test(password)) {
        enqueueSnackbar(i18n.t("common.msgRegisterPassInvalid"), {
          variant: "warning",
        });
        return;
      }
      if (password !== confirmpass) {
        enqueueSnackbar(i18n.t("common.msgRegisterPassMatchInvalid"), {
          variant: "warning",
        });
        return;
      }

      if (config.features.Register_ShowPhone) {
        //check if phone contain 8-10 character and contain number
        if (!/^[0-9]{8,10}$/.test(phone)) {
          enqueueSnackbar(
            i18n
              .t("common.noReachMin")
              .replace(/{maxlength}/g, config.features.MAX_PHONE_LENGTH || 10),
            {
              variant: "error",
            }
          );
          return;
        }
      }

      if (
        email &&
        !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)
      ) {
        enqueueSnackbar(i18n.t("common.msgInvalidEmail"), {
          variant: "warning",
        });
        return;
      }

      checkRegisterCondition(username, {
        email,
        areacode,
        phone,
      }).then((e) => {
        if (e.IsSuccess && e.data === true) {
          createNewPlayer(username, password).then((e) => {
            if (e.IsSuccess) {
              if (config.features.Register_ShowPhone) {
                newPlayerContainPhone(e.data, areacode, phone).then((f) => {
                  if (f.IsSuccess) {
                    if (email) {
                      newPlayerContainEmail(e.data, email).then((g) => {
                        if (g.IsSuccess) {
                          handleRegisterSuccessful();
                        } else {
                          enqueueSnackbar(
                            ErrorSwitcher.getTranslation(g.info),
                            {
                              variant: "warning",
                            }
                          );
                        }
                      });
                    } else {
                      handleRegisterSuccessful();
                    }
                  } else {
                    enqueueSnackbar(ErrorSwitcher.getTranslation(e.info), {
                      variant: "warning",
                    });
                  }
                });
              } else {
                if (email) {
                  newPlayerContainEmail(e.data, email).then((g) => {
                    if (g.IsSuccess) {
                      handleRegisterSuccessful();
                    } else {
                      enqueueSnackbar(ErrorSwitcher.getTranslation(g.info), {
                        variant: "warning",
                      });
                    }
                  });
                } else {
                  handleRegisterSuccessful();
                }
              }
            } else {
              enqueueSnackbar(ErrorSwitcher.getTranslation(e.info), {
                variant: "warning",
              });
            }
          });
        } else {
          enqueueSnackbar(ErrorSwitcher.getTranslation(e.info), {
            variant: "warning",
          });
        }
      });
    };

    return (
      <CustomDialog open={open} onClose={handleClose}>
        <RegisterPlayerContainer>
          <TopPart>
            <Title>{i18n.t("header.menu.createplayer.title")}</Title>
            <TheForm>
              <Input>
                <Textfield
                  type="text"
                  name="username"
                  icon="username"
                  value={username}
                  key="username"
                  placeholder={i18n.t("register.username")}
                  onChange={(e) => {
                    setUsername(e.target.value);
                  }}
                ></Textfield>
              </Input>
              <Input>
                <Textfield
                  type="password"
                  name="password"
                  icon="password"
                  value={password}
                  placeholder={i18n.t("register.password")}
                  onChange={(e) => {
                    setPassword(e.target.value);
                  }}
                ></Textfield>
              </Input>
              <Input>
                <Textfield
                  type="password"
                  name="confirmpass"
                  icon="confirmpass"
                  value={confirmpass}
                  placeholder={i18n.t("register.confirmPassword")}
                  onChange={(e) => {
                    setConfirmPass(e.target.value);
                  }}
                ></Textfield>
              </Input>
              {config.features.Register_ShowPhone && (
                <Input>
                  <Select
                    onChange={(e) => {
                      setareacode(e.target.value);
                    }}
                    width="140"
                  >
                    <option selectedvalue="+86">+86</option>
                    <option value="+852">+852</option>
                  </Select>
                  <Textfield
                    type="text"
                    name="phone"
                    icon="phone"
                    maxlength="10"
                    value={phone}
                    placeholder={i18n.t("createplayer.plsEnterPhoneNumber")}
                    onChange={(e) => {
                      setPhone(e.target.value);
                    }}
                  ></Textfield>
                </Input>
              )}
              <Input>
                <Textfield
                  name="password"
                  placeholder={i18n.t("createplayer.emailoptional")}
                  value={email}
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                ></Textfield>
              </Input>
              <Input>
                <Checkbox onClick={toggleAccept} toggle={accept} />
                {i18n.t("register.contract")}
              </Input>
              <ButtonContainer>
                <Button disabled={!accept} onClick={handleRegister}>
                  {i18n.t("register.btn")}
                </Button>
              </ButtonContainer>
            </TheForm>
          </TopPart>
        </RegisterPlayerContainer>
      </CustomDialog>
    );
  }
);

const mapStateToProps = (state) => {
  return {
    account: state.account,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    createNewPlayer: (username, password) =>
      dispatch(accountAction.createNewPlayer(username, password)),
    checkRegisterCondition: (username, data) =>
      dispatch(accountAction.checkRegisterCondition(username, data)),
    newPlayerContainPhone: (playercode, areacode, phone) =>
      dispatch(
        accountAction.newPlayerContainPhone(playercode, areacode, phone)
      ),
    newPlayerContainEmail: (playercode, email) =>
      dispatch(accountAction.newPlayerContainEmail(playercode, email)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CreatePlayer);
