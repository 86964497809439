import config from "../config/config";
import { ApiService } from "./ApiService";
import { isMobile } from "react-device-detect";
export const AccountService = {
  Login,
  LoginWithoutSetToken,
  Logout,
  CreateAccount,
  GetMemberInfo,
  GetMemberProfile,
  GetVIPInfo,
  UpdateAddress,
  UpdatePassword,
  UpdateEmail,
  GetEmailVerificationCode,
  UpdateWithdrawalPassword,
  UpdateMemberCardNo,
  UpdateMemberName,
  UpdateEmailStep1,
  UpdateEmailStep2,
  UpdatePhone,
  UpdatePhoneStep1,
  UpdatePhoneStep2,
  GetPhoneVerificationCode,
  GetSecurityQuestion,
  UpdateSecurityQuestion,
  VerifySecurityQuestion,
  VerifySecurityQuestionByEmail,
  VerifySecurityQuestionByPhone,
  ResetSecurityQuestion,
  GetVerifyCode,
  GetResetPasswordStep1,
  GetResetPasswordSecurityQuestionVerify,
  GetResetPasswordEmailVerify,
  GetResetPasswordPhoneVerify,
  ResetPassword,
  UpdateWechat,
  UpdateQQ,
  SelfControlVerify,
  SelfControlSetTime,
  VerifyWithdrawalPasswordByPhone,
  VerifyForgotPassword,
  AddWithdrawalPassword,
  VerifyEmail,
  GetChangeWithdrawalPasswordVerificationCode,
  ChangeWithdrawalPassword,

  CheckAgentExist,
  CreatePlayer,
  NewPlayerContainPhone,
  NewPlayerContainEmail,
  CheckRegistrationCondition,
};

function Login(username, password) {
  const result = ApiService.post(
    "agent/login", //change to passport/affiliate for testing actual API
    {
      username: username,
      password: password,
      platformtype: config.from,
    }
  );

  return result;
}
function LoginWithoutSetToken(username, password) {
  const result = ApiService.post(
    "passport/affiliate",
    {
      username: username,
      password: password,
      from: config.from,
      machine: config.machine,
    },
    true
  );
  return result;
}
function Logout(token) {
  const result = ApiService.post(
    "passport/logout",
    {
      token: token,
    },
    true
  );

  result.then((response) => {
    localStorage.removeItem("token" + (isMobile ? 1 : 0));
  });

  return result;
}
function GetMemberInfo(token) {
  const result = ApiService.get("agent/info");

  return result;
}
function VerifyForgotPassword(email) {
  var result = ApiService.post("agent/password/forgot", {
    email: email,
  });
  return result;
}

function GetMemberProfile(token, show_vip) {
  const result = ApiService.get("agent/info");

  return result;
}
function GetVIPInfo(token) {
  const result = ApiService.get("member/vip?token=" + token);

  return result;
}
function UpdateAddress(
  token,
  member_address,
  member_address_surename,
  member_address_phone
) {
  const result = ApiService.post(
    "member/address",
    {
      token,
      member_address,
      member_address_surename,
      member_address_phone,
    },
    true
  );

  return result;
}
function UpdateQQ(token, member_qq) {
  const result = ApiService.post(
    "member/qq",
    {
      token,
      member_qq,
    },
    true
  );

  return result;
}
function UpdateWechat(token, member_wechat) {
  const result = ApiService.post(
    "member/wechat",
    {
      token,
      member_wechat,
    },
    true
  );

  return result;
}
function UpdatePassword(oldpwd, newpwd) {
  const result = ApiService.put("agent/password", {
    oldpwd,
    newpwd,
  });

  return result;
}
function UpdateWithdrawalPassword(token, old_password, password, rpassword) {
  const result = ApiService.post(
    "member/safePassword",
    {
      token,
      old_password,
      password,
      rpassword,
    },
    true
  );

  return result;
}

function UpdateEmailStep1(token, code) {
  const result = ApiService.post(
    "member/resetBindEmailStepOne",
    {
      token,
      code,
    },
    true
  );

  return result;
}
function UpdateEmailStep2(token, email, code) {
  const result = ApiService.post(
    "member/resetBindEmailStepTwo",
    {
      token,
      email,
      code,
    },
    true
  );

  return result;
}
function GetEmailVerificationCode(token, email, event) {
  const result = ApiService.post(
    "passport/sendEmsCode",
    {
      email,
      event: event,
      token,
      from: config.from,
    },
    true
  );

  return result;
}

function UpdatePhone(token, phone, code) {
  const result = ApiService.post(
    "member/bindPhone",
    {
      token,
      phone,
      code,
    },
    true
  );

  return result;
}
function UpdatePhoneStep1(token, code) {
  const result = ApiService.post(
    "member/resetBindPhoneStepOne",
    {
      token,

      code,
    },
    true
  );

  return result;
}
function UpdatePhoneStep2(token, phone, code) {
  const result = ApiService.post(
    "member/resetBindPhoneStepTwo",
    {
      token,
      phone,
      code,
    },
    true
  );

  return result;
}
function GetPhoneVerificationCode(token, mobile, event) {
  const result = ApiService.post(
    "passport/sendSmsCode",
    {
      mobile,
      event: event,
      token,
      from: config.from,
    },
    true
  );

  return result;
}

function UpdateMemberCardNo(token, member_name, member_card_no) {
  const result = ApiService.post(
    "member/info",
    {
      member_card_no,
      member_name,
      token,
    },
    true
  );

  return result;
}
function UpdateMemberName(token, name) {
  const result = ApiService.post(
    "member/editSurename",
    {
      name,
      token,
    },
    true
  );

  return result;
}
function CreateAccount(
  username,
  password,
  realname,
  email,
  phone,
  dividendPercentage,
  liveDealerRebatePercentage,
  slotRebatePercentage,
  lotteryRebatePercentage,
  cardGameRebatePercentage,
  sportsRebatePercentage,
  virtualSportsRebatePercentage,
  remarks,
  agentDividendConditions
) {
  const result = ApiService.post("agent/create", {
    userName: username,
    password: password,
    realname: realname,
    email: email,
    phone: phone,
    dividendPercentage: dividendPercentage,
    liveDealerRebatePercentage: liveDealerRebatePercentage,
    slotRebatePercentage: slotRebatePercentage,
    lotteryRebatePercentage: lotteryRebatePercentage,
    cardGameRebatePercentage: cardGameRebatePercentage,
    sportsRebatePercentage: sportsRebatePercentage,
    virtualSportsRebatePercentage: virtualSportsRebatePercentage,
    remarks: remarks,
    agentDividendConditions: agentDividendConditions,
  });

  return result;
}
function GetSecurityQuestion(token) {
  const result = ApiService.post(
    "member/getQuestion",
    {
      token,
    },
    true
  );

  return result;
}
function ResetSecurityQuestion(token, question_id, answer) {
  const result = ApiService.post(
    "member/resetQuestion",
    {
      token,
      question_id,
      answer,
    },
    true
  );

  return result;
}
function UpdateSecurityQuestion(token, question_id, answer) {
  const result = ApiService.post(
    "member/setQuestion",
    {
      token,
      question_id,
      answer,
    },
    true
  );

  return result;
}
function VerifySecurityQuestion(token, answer) {
  const result = ApiService.post(
    "member/resetQuestionByAnswer",
    {
      token,
      answer,
    },
    true
  );

  return result;
}
function VerifySecurityQuestionByEmail(token, code) {
  const result = ApiService.post(
    "member/resetQuestionByEmail",
    {
      token,
      code,
    },
    true
  );

  return result;
}
function VerifySecurityQuestionByPhone(token, code) {
  const result = ApiService.post(
    "member/resetQuestionByPhone",
    {
      token,
      code,
      event: "resetQuestion",
    },
    true
  );

  return result;
}

function VerifyWithdrawalPasswordByPhone(token, code) {
  const result = ApiService.post(
    "member/resetWithdrawalPasswordByPhone",
    {
      token,
      code,
      event: "resetWithdrawalPassword",
    },
    true
  );

  return result;
}

function GetVerifyCode() {
  const result = ApiService.get("passport/verify");

  return result;
}
function GetResetPasswordStep1(account, verify_code, session_id) {
  const result = ApiService.post(
    "passport/resetPasswordStepOne",
    {
      account,
      verify_code,
      session_id,
      member_type: 2,
    },
    true
  );

  return result;
}
function GetResetPasswordSecurityQuestionVerify(forget_token, answer) {
  const result = ApiService.post(
    "passport/resetPasswordByAnswer",
    {
      forget_token,
      answer,
    },
    true
  );

  return result;
}
function GetResetPasswordEmailVerify(forget_token, code) {
  const result = ApiService.post(
    "passport/resetPasswordByEmail",
    {
      forget_token,
      code,
    },
    true
  );

  return result;
}

function GetResetPasswordPhoneVerify(forget_token, code) {
  const result = ApiService.post(
    "passport/resetPasswordByPhone",
    {
      forget_token,
      code,
    },
    true
  );

  return result;
}
function ResetPassword(securitykey, validatecode, newpwd) {
  var result = ApiService.post("agent/password/reset", {
    securitykey: securitykey,
    validatecode: validatecode,
    newpwd: newpwd,
  });
  return result;
}

function SelfControlVerify(token, code) {
  const result = ApiService.post(
    "member/selfControlOne",
    {
      token,
      code,
    },
    true
  );

  return result;
}
function SelfControlSetTime(token, day) {
  const result = ApiService.post(
    "member/selfControlTwo",
    {
      token,
      day,
    },
    true
  );

  return result;
}
function AddWithdrawalPassword(password) {
  var result = ApiService.post("pay/password", {
    pwd: password,
  });
  return result;
}
function VerifyEmail(email) {
  var result = ApiService.post("agent/email", {
    email: email,
  });
  return result;
}
function UpdateEmail(securitykey, validateCode) {
  var result = ApiService.put("agent/email", {
    securitykey: securitykey,
    validatecode: validateCode,
  });
  return result;
}
function GetChangeWithdrawalPasswordVerificationCode() {
  var result = ApiService.post("pay/password/validate-code", {});
  return result;
}
function ChangeWithdrawalPassword(newpwd, validateCode, securityKey) {
  var result = ApiService.patch("pay/password", {
    newpwd: newpwd,
    validatecode: validateCode,
    securitykey: securityKey,
  });
  return result;
}

function CheckAgentExist(username) {
  const result = ApiService.get(`agent/checkusername?username=${username}`);

  return result;
}

function CreatePlayer(username, password) {
  const result = ApiService.post("player/create", {
    username: username,
    password: password,
  });

  return result;
}

function CheckRegistrationCondition(username, data) {
  const result = ApiService.post("player/check/registrationconditions", {
    username: username,
    data: data,
  });

  return result;
}

function NewPlayerContainPhone(playercode, arecode, phone) {
  const result = ApiService.put("player/phone", {
    playercode: playercode,
    areaCode: arecode,
    phone: phone,
  });

  return result;
}

function NewPlayerContainEmail(playercode, email) {
  const result = ApiService.put("player/email", {
    playercode: playercode,
    email: email,
  });

  return result;
}
