import React, { useEffect, useState, useContext } from "react";
import Header from "../Account/Header";
import {
  CustomContainer,
  ReportContainer,
  StyledTableCell,
  FilterContainer,
  CustomFormControl,
  TitleContainer,
  Title,
  Content,
  CustomQuickButton,
} from "../../Share/General";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import { connect } from "react-redux";

import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TablePagination from "@material-ui/core/TablePagination";
import ReportDatepicker from "./ReportDatepicker";
import Select from "@material-ui/core/Select";
import { Container } from "@material-ui/core";
import { affiliateAction } from "../../../actions/affiliateAction";
import { FunctionContext } from "../../Context/FunctionContext";
const DownlineReport = (props) => {
  const [page, setPage] = useState(0);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    getDownlineReportList(
      selectedDateFrom,
      selectedDateTo,
      channel,
      newPage + 1
    );
  };

  const { selectedDateFrom, selectedDateTo } = useContext(FunctionContext);
  const [channel, setChannel] = useState("0");
  const [render, setRendered] = useState(false);

  useEffect(() => {
    setChannel(props.first);
    setRendered(true);
  }, []);

  useEffect(() => {
    if (render === true) {
      getDownlineReportList(selectedDateFrom, selectedDateTo, 0);
    }
  }, [render]);

  const [result, setResult] = useState();

  const getDownlineReportList = async (dateFrom, dateTo, affiliate, page) => {
    const result = await props.getAffiliateDownlineReport(
      props.token,
      props.data.member_id,
      affiliate ? affiliate : 0,
      dateFrom,
      dateTo,
      page ? page : 1,
      10
    );
    if (result.IsSuccess) {
      setResult(result.data);
    }
  };

  return (
    <CustomContainer>
      <Container maxWidth="lg">
        <Header />
        <ReportContainer>
          <Content>
            <TitleContainer>
              <Title>下线列表</Title>
            </TitleContainer>
            <FilterContainer>
              <ReportDatepicker></ReportDatepicker>
              <CustomFormControl>
                <Select
                  native
                  variant="outlined"
                  onChange={(e) => {
                    setChannel(e.target.value);
                  }}
                >
                  <option value={0}>全部</option>
                  {props.lists.map((data, index) => {
                    return (
                      <option key={index} value={data.id}>
                        {data.title}
                      </option>
                    );
                  })}
                </Select>
              </CustomFormControl>
              <CustomQuickButton
                onClick={() => {
                  getDownlineReportList(
                    selectedDateFrom,
                    selectedDateTo,
                    channel
                  );
                }}
              >
                查询
              </CustomQuickButton>
            </FilterContainer>
            <Table aria-label="customized table">
              <TableHead>
                <TableRow>
                  <StyledTableCell>用户名</StyledTableCell>
                  <StyledTableCell>类型</StyledTableCell>
                  <StyledTableCell>渠道</StyledTableCell>
                  <StyledTableCell>存款总额</StyledTableCell>
                  <StyledTableCell>红利总额</StyledTableCell>
                  <StyledTableCell>客户流水</StyledTableCell>
                  <StyledTableCell>客户盈亏</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {result
                  ? result.datas.map((data, index) => {
                      return (
                        <TableRow>
                          <StyledTableCell>
                            {data.member_username}
                          </StyledTableCell>
                          <StyledTableCell>{data.member_type}</StyledTableCell>
                          <StyledTableCell>
                            {data.promotion_channel}
                          </StyledTableCell>
                          <StyledTableCell>{data.deposit}</StyledTableCell>
                          <StyledTableCell>{data.bonus}</StyledTableCell>
                          <StyledTableCell>{data.turnover}</StyledTableCell>
                          <StyledTableCell>{data.contribution}</StyledTableCell>
                        </TableRow>
                      );
                    })
                  : ""}
              </TableBody>
            </Table>
            <TablePagination
              rowsPerPageOptions={[10]}
              component="div"
              count={result ? result.total : ""}
              rowsPerPage={10}
              page={page}
              backIconButtonProps={{
                "aria-label": "previous page",
              }}
              nextIconButtonProps={{
                "aria-label": "next page",
              }}
              onChangePage={handleChangePage}
            />
          </Content>
        </ReportContainer>
      </Container>
    </CustomContainer>
  );
};

const mapStateToProps = (state) => {
  return {
    token: state.account.token,
    data: state.account.info,
    lists: state.affiliate.lists,
    first: state.affiliate.lists[0].id,
    state,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getAffiliateDownlineReport: (
      token,
      affiliate_id,
      channel_list,
      start,
      end,
      page,
      limit
    ) =>
      dispatch(
        affiliateAction.getAffiliateDownlineReport(
          token,
          affiliate_id,
          channel_list,
          start,
          end,
          page,
          limit
        )
      ),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(DownlineReport);
