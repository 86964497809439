import React, { useState } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import CustomTextField from "../../../Share/TextField";
import CustomButton from "../../../Share/Button";
import IconInputReceivePNG from "../../../../assets/images/icon-input-receive.png";
import IconInputIDPNG from "../../../../assets/images/icon-input-id.png";
import { withSnackbar } from "notistack";
import { AccountService } from "../../../../services/AccountService";
import { UPDATE_INFO } from "../../../../actions/accountAction";
import Loading from "../../Loading";
import i18n from "../../../../utils/i18n";
const Container = styled.div``;

const CustomField = styled.div`
  margin: 8px 0px;
`;
let local_props = {
  member_name: "",
  member_card_no: "",
};

const onhandleNameChange = (e) => {
  local_props.member_name = e.target.value;
};
const onhandleIDChange = (e) => {
  local_props.member_card_no = e.target.value;
};

const IDVerification = (props) => {
  local_props = {
    member_name: props.account.info.member_name,
    member_card_no: props.account.info.member_card_no,
  };

  const [isClicked, setIsClicked] = useState(false);

  const onhandleClick = () => {
    if (
      local_props.member_card_no === "" ||
      local_props.member_name === "" ||
      local_props.member_card_no === null ||
      local_props.member_name === null
    ) {
      props.enqueueSnackbar(
        i18n.t("account.safety.dialog.nameVerify.warning"),
        {
          variant: "error",
        }
      );
    } else {
      setIsClicked(true);
      AccountService.UpdateMemberCardNo(
        props.account.token,
        local_props.member_name,
        local_props.member_card_no
      ).then((response) => {
        if (response.IsSuccess) {
          setIsClicked(false);
          props.dispatch({
            type: UPDATE_INFO,
            payload: {
              member_name: local_props.member_name,
              member_card_no: local_props.member_card_no,
              check_pass_account: true,
            },
          });
          props.handleCloseDialog();
        } else {
          setIsClicked(false);
          props.enqueueSnackbar(response.msg, {
            variant: "error",
          });
        }
      });
    }
  };
  return (
    <Container>
      <CustomField>
        <CustomTextField
          placeholder={i18n.t("account.safety.dialog.nameVerify.realName")}
          adornment={IconInputReceivePNG}
          handleTextFieldChange={onhandleNameChange}
          defaultValue={props.account.info.member_name}
          readOnly={props.account.info.member_name !== "" ? true : false}
          maxLength={10}
        ></CustomTextField>
      </CustomField>
      <CustomField>
        <CustomTextField
          placeholder={i18n.t("account.safety.dialog.nameVerify.id")}
          adornment={IconInputIDPNG}
          handleTextFieldChange={onhandleIDChange}
          defaultValue={props.account.info.member_card_no}
          readOnly={props.account.info.member_card_no !== "" ? true : false}
          maxLength={20}
        ></CustomTextField>
      </CustomField>
      {(() => {
        if (
          props.account.info.member_name === "" ||
          props.account.info.member_card_no === ""
        ) {
          return (
            <CustomButton
              name={i18n.t("account.safety.dialog.submit")}
              handleClick={onhandleClick}
            ></CustomButton>
          );
        } else return null;
      })()}
      <Loading show={isClicked} />
    </Container>
  );
};

export default connect(null, null)(withSnackbar(IDVerification));
