import React from "react";

import styled from "styled-components";
import { withStyles } from "@material-ui/core/styles";
import LoginBGPNG from "../../assets/images/login-bg.png";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import UserIconPNG from "../../assets/images/user-icon.png";
import Password1IconPNG from "../../assets/images/password1-icon.png";
import IconLoginClosePNG from "../../assets/images/icon-login-close.png";
import i18n from "../../utils/i18n";
import { Icon } from "@material-ui/core";

function Login(props) {
  const handleTextFieldChange = (e) => {
    local_props[e.target.id] = e.target.value;
  };

  const local_props = {
    rememberChecked: false,
    username: "",
    password: "",
  };
  const Container = styled.div`
    width: 506px;
    border-radius: 20px;
    background: url(${LoginBGPNG});
    background-attachment: fixed;
    background-position: center;
    display: table;

    @media (max-width: 530px) {
      width: 100%;
    }
  `;
  const CloseIconContainer = styled(Icon)`
    background: url(${IconLoginClosePNG}) no-repeat center;
    width: 28px;
    height: 28px;
    background-size: 100%;
    float: right;
    margin: 10px 10px 0 0;
    &:hover {
      cursor: pointer;
    }
  `;
  const TitleContainer = styled.div`
    text-align: center;

    font-size: 42px;

    color: #404040;
  `;
  const SubTitleContainer = styled.div`
    text-align: center;

    font-size: 18px;

    color: #404040;
  `;
  const FormContainer = styled.div`
    padding: 0 3px;
    margin: 10px 78px 0 78px;
    @media (max-width: 530px) {
      margin: 0px;
      padding: 20px;
    }
  `;
  const CustomText = withStyles({
    root: {
      width: "350px",
      height: "51px",
      borderRadius: "6px",
      border: "none",
      boxShadow: "0px 3px 8px -2px rgba(0,0,0,0.75)",
      margin: 0,
      marginTop: "13px",
      background: "white",

      "& .MuiOutlinedInput-input": {
        fontSize: "14px",
      },
      "& label.Mui-focused": {
        color: "#b6b6b6",
      },
      "& .MuiInput-underline:after": {
        borderBottomColor: "#b6b6b6",
      },
      "& .MuiOutlinedInput-root": {
        "& fieldset": {
          borderColor: "#b6b6b6",
        },
        "&:hover fieldset": {
          borderColor: "#b6b6b6",
        },
        "&.Mui-focused fieldset": {
          borderColor: "#b6b6b6",
        },
      },
    },
  })(TextField);

  const CustomCustomText = styled(CustomText)`
    @media (max-width: 530px) {
      width: 100%;
    }
  `;

  const ButtonLogin = styled.div`
    margin-top: 35px;
    width: 350px;
    height: 51px;
    color: #fff;
    font-size: 20px;

    border-radius: 6px;

    border: solid 1px #ffffff;
    background-image: linear-gradient(
      to top,
      ${(props) => props.theme.primaryGradient},
      ${(props) => props.theme.primary}
    );

    &:hover {
      cursor: pointer;

      box-shadow: inset 1px 1px 9px 0 rgba(255, 255, 255, 0.75);
    }

    @media (max-width: 530px) {
      width: 100%;
    }
  `;
  const ButtonLoginLabel = styled.span`
    text-align: center;
    display: block;
    margin-top: 10px;
  `;
  const LinkStyle = styled.a`
    margin-top: 5px;
    text-decoration: underline;

    font-size: 15px;

    color: #404040;

    &:hover {
      cursor: pointer;
    }
  `;

  const LinkForgotPassword = styled(LinkStyle)`
    float: right;
  `;
  const EmptyBlock = styled.div`
    clear: both;
    height: 30px;
  `;

  return (
    <Container>
      <CloseIconContainer
        viewBox="4 4 16 16"
        onClick={props.onhandleClose}
      ></CloseIconContainer>
      <div className="clear"></div>
      <TitleContainer>{i18n.t("login.logInto")}</TitleContainer>
      <SubTitleContainer>{i18n.t("login.asiaBest")}</SubTitleContainer>
      <FormContainer>
        <CustomCustomText
          onChange={handleTextFieldChange}
          id="username"
          placeholder={i18n.t("login.accountPlaceholder")}
          variant="outlined"
          inputProps={{
            maxLength: 16,
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment
                position="start"
                style={{ height: "24px", width: "30px" }}
              >
                <img src={UserIconPNG} alt=""></img>
              </InputAdornment>
            ),
          }}
        />
        <CustomCustomText
          onChange={handleTextFieldChange}
          id="password"
          placeholder={i18n.t("login.passwordPlaceholder")}
          variant="outlined"
          type="password"
          inputProps={{
            maxLength: 20,
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment
                position="start"
                style={{ height: "26px", width: "30px" }}
              >
                <img src={Password1IconPNG} alt=""></img>
              </InputAdornment>
            ),
          }}
        />
        <ButtonLogin
          onClick={() =>
            props.onhandleLoginClick(local_props.username, local_props.password)
          }
        >
          <ButtonLoginLabel>{i18n.t("common.login")}</ButtonLoginLabel>
        </ButtonLogin>
        {/* <LinkRegister onClick={props.onhandleRegister}>
          {i18n.t("common.register")}
        </LinkRegister> */}
        <LinkForgotPassword onClick={props.onhandleForgotPassword}>
          {i18n.t("login.forgotPassword")}
        </LinkForgotPassword>
        <EmptyBlock></EmptyBlock>
        {/* <Description>
          {i18n.t("login.tips.pleaseContact")}
          <OnlineSupportLink onClick={props.onhandleOnlineSupport}>
            {i18n.t("login.tips.online")}
          </OnlineSupportLink>
          {i18n.t("login.tips.or")}
          <ContactUsLink onClick={props.onhandleContactUs}>
            {i18n.t("login.tips.contact")}
          </ContactUsLink>
          <br></br>
          {i18n.t("login.tips.ourWeb")}
        </Description> */}
      </FormContainer>
    </Container>
  );
}

export default Login;
