import config from "../config/config";
import { ApiService } from "./ApiService";
const returnUrl = window.location.href;

export const AffiliateService = {
  getChannelList,
  createNewChannel,
  changePromotionalMethod,
  changeAboutMe,
  changeAffiliateInfo,
  submitMessage,
  getPreDividendList,
  getPreDividendDetail,
  getDownlineReport,
  getOperationReport,
  getChannelReport,
  getPromotionReport,
  getContributeList,
  getDividendDetails,
  //
  getAccountChangesReport,
  getTeamInfo,
  getTransactionType,
  getAgentList,
  getAgentInfo,
  getAgentListStatus,
  //
  getPlayerList,
  getVipLevels,
  getPlayerStatus,
  getPlayerBalance,
  getPlayerWithdrawAmount,
};

function getChannelList(token, main_url) {
  const result = ApiService.post(
    "affiliate_channel/getList",
    {
      token,
      main_url,
    },
    true
  );

  return result;
}

function createNewChannel(token, title) {
  const result = ApiService.post(
    "affiliate_channel/add",
    {
      token,
      title,
    },
    true
  );

  return result;
}

function changePromotionalMethod(token, promote_methods) {
  const result = ApiService.post(
    "member/promoteMethods",
    {
      token,
      promote_methods,
    },
    true
  );

  return result;
}

function changeAboutMe(token, biography) {
  const result = ApiService.post(
    "member/biography",
    {
      token,
      biography,
    },
    true
  );

  return result;
}

function changeAffiliateInfo(token, qq, skype, promote_methods, biography) {
  const result = ApiService.post(
    "affiliate/editAffiliateInfo",
    {
      token,
      qq,
      skype,
      promote_methods,
      biography,
    },
    true
  );

  return result;
}

function submitMessage(token, description) {
  const result = ApiService.post(
    "affiliate/submitMessage",
    {
      token,
      action_type: 2,
      description,
    },
    true
  );
  return result;
}

function getPreDividendList(From, To, page, rows, desc, sort, all) {
  const result = ApiService.get(
    `agentreport/agentdividends?From=${From}&To=${To}&page=${page}&rows=${rows}&desc=${desc}&sort=${sort}&all=${all}`
  );
  return result;
}
function getContributeList(From, To, page, rows, desc, sort, all) {
  const result = ApiService.get(
    `agentreport/contributions?From=${From}&To=${To}&page=${page}&rows=${rows}&desc=${desc}&sort=${sort}&all=${all}`
  );
  return result;
}

function getPreDividendDetail(token, type, level) {
  const result = ApiService.post(
    "affiliate/getPreDividendDetail",
    {
      token,
      type,
      level,
    },
    true
  );
  return result;
}

function getDownlineReport(
  token,
  affiliate_id,
  channel_id,
  start,
  end,
  page,
  limit
) {
  const result = ApiService.post(
    "affiliate_report/downlines",
    {
      token,
      affiliate_id,
      channel_id,
      start,
      end,
      page,
      limit,
    },
    true
  );
  return result;
}

function getPromotionReport(
  token,
  affiliate_id,
  channel_list,
  start,
  end,
  page,
  limit
) {
  const result = ApiService.post(
    "affiliate_report/promotion",
    {
      token,
      affiliate_id,
      channel_list,
      start,
      end,
      page,
      limit,
    },
    true
  );
  return result;
}

function getOperationReport(
  token,
  affiliate_id,
  channel_list,
  start,
  end,
  page,
  limit
) {
  const result = ApiService.post(
    "affiliate_report/operation",
    {
      token,
      affiliate_id,
      channel_list,
      start,
      end,
      page,
      limit,
    },
    true
  );
  return result;
}

function getChannelReport(
  token,
  affiliate_id,

  start,
  end
) {
  const result = ApiService.post(
    "affiliate_report/channel",
    {
      token,
      affiliate_id,
      start,
      end,
    },
    true
  );
  return result;
}

function getDividendDetails(
  AgentId,
  From,
  To,
  page,
  rows,
  desc,
  sort,
  all,
  agentDividendPercentage
) {
  const result = ApiService.get(
    `agentreport/dividenddetails?AgentId=${AgentId}&From=${From}&To=${To}&page=${page}&rows=${rows}&desc=${desc}&sort=${sort}&all=${all}&agentDividendPercentage=${agentDividendPercentage} `
  );
  return result;
}

function getAccountChangesReport(
  from,
  to,
  transactiontype,
  page,
  rows,
  desc,
  sort,
  all,
  id
) {
  const result = ApiService.get(
    `agentreport/accountchanges?from=${from}&to=${to}&transactiontype=${transactiontype}&page=${page}&rows=${rows}&desc=${desc}&sort=${sort}&all=${all}&id=${id}`
  );
  return result;
}

function getTeamInfo(page, rows, desc, sort, all, uid) {
  const result = ApiService.get(
    `agentreport/teaminfo?page=${page}&rows=${rows}&desc=${desc}&sort=${sort}&all=${all}&id=${uid}`
  );
  return result;
}

function getTransactionType() {
  const result = ApiService.get(`agentreport/accountchanges/transactiontypes`);
  return result;
}

function getAgentList(Status, Username, page, rows, desc, sort, all) {
  const result = ApiService.get(
    `agent/list?Status=${Status}&Username=${Username}&page=${page}&rows=${rows}&desc=${desc}&sort=${sort}&all=${all}`
  );
  return result;
}

function getAgentInfo(uid) {
  const result = ApiService.get(`agent/info/${uid}`);
  return result;
}

function getAgentListStatus() {
  const result = ApiService.get(`agent/list/statuses`);
  return result;
}

function getPlayerList(
  BeginRegisteredOn,
  EndRegisteredOn,
  RegisterHost,
  Status,
  Username,
  VipId,
  page,
  rows,
  desc,
  sort,
  all
) {
  const result = ApiService.get(
    `player/list?BeginRegisteredOn=${BeginRegisteredOn}&EndRegisteredOn=${EndRegisteredOn}&RegisterHost=${RegisterHost}&Status=${Status}&Username=${Username}&VipId=${VipId}&page=${page}&rows=${rows}&desc=${desc}&sort=${sort}&all=${all}`
  );
  return result;
}

function getVipLevels() {
  const result = ApiService.get(`player/list/viplevels`);
  return result;
}

function getPlayerStatus() {
  const result = ApiService.get(`player/list/statuses`);
  return result;
}

function getPlayerBalance(playercode) {
  const result = ApiService.get(`player/balance/${playercode}`);
  return result;
}

function getPlayerWithdrawAmount(playercode) {
  const result = ApiService.get(`player/withdraw/amount/${playercode}`);
  return result;
}
