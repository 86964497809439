export const APP_LOAD = "APP_LOAD";
export const REDIRECT = "REDIRECT";
export const ARTICLE_SUBMITTED = "ARTICLE_SUBMITTED";
export const SETTINGS_SAVED = "SETTINGS_SAVED";
export const DELETE_ARTICLE = "DELETE_ARTICLE";
export const SETTINGS_PAGE_UNLOADED = "SETTINGS_PAGE_UNLOADED";
export const HOME_PAGE_LOADED = "HOME_PAGE_LOADED";
export const HOME_PAGE_UNLOADED = "HOME_PAGE_UNLOADED";
export const ARTICLE_PAGE_LOADED = "ARTICLE_PAGE_LOADED";
export const ARTICLE_PAGE_UNLOADED = "ARTICLE_PAGE_UNLOADED";
export const ADD_COMMENT = "ADD_COMMENT";
export const DELETE_COMMENT = "DELETE_COMMENT";
export const ARTICLE_FAVORITED = "ARTICLE_FAVORITED";
export const ARTICLE_UNFAVORITED = "ARTICLE_UNFAVORITED";
export const SET_PAGE = "SET_PAGE";
export const APPLY_TAG_FILTER = "APPLY_TAG_FILTER";
export const CHANGE_TAB = "CHANGE_TAB";
export const PROFILE_PAGE_LOADED = "PROFILE_PAGE_LOADED";
export const PROFILE_PAGE_UNLOADED = "PROFILE_PAGE_UNLOADED";
export const LOGIN = "LOGIN";
export const LOGOUT = "LOGOUT";
export const REGISTER = "REGISTER";
export const LOGIN_PAGE_UNLOADED = "LOGIN_PAGE_UNLOADED";
export const REGISTER_PAGE_UNLOADED = "REGISTER_PAGE_UNLOADED";
export const ASYNC_START = "ASYNC_START";
export const ASYNC_END = "ASYNC_END";
export const EDITOR_PAGE_LOADED = "EDITOR_PAGE_LOADED";
export const EDITOR_PAGE_UNLOADED = "EDITOR_PAGE_UNLOADED";
export const ADD_TAG = "ADD_TAG";
export const REMOVE_TAG = "REMOVE_TAG";
export const UPDATE_FIELD_AUTH = "UPDATE_FIELD_AUTH";
export const UPDATE_FIELD_EDITOR = "UPDATE_FIELD_EDITOR";
export const FOLLOW_USER = "FOLLOW_USER";
export const UNFOLLOW_USER = "UNFOLLOW_USER";
export const PROFILE_FAVORITES_PAGE_UNLOADED =
  "PROFILE_FAVORITES_PAGE_UNLOADED";
export const PROFILE_FAVORITES_PAGE_LOADED = "PROFILE_FAVORITES_PAGE_LOADED";

export const UPDATING_SCREEN_SIZE = "UPDATING_SCREEN_SIZE";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const SHOW_MODAL = "SHOW_MODAL";
export const CLOSE_MODAL = "CLOSE_MODAL";
export const CHANGE_HOME_PAGE_GAME_TYPE = "CHANGE_HOME_PAGE_GAME_TYPE";
export const SHOW_MESSAGEBOX = "SHOW_MESSAGEBOX";
export const CLOSE_MESSAGEBOX = "CLOSE_MESSAGEBOX";
export const SHOW_LOADING = "SHOW_LOADING";
export const HIDE_LOADING = "HIDE_LOADING";
export const SHOW_LOGIN = "SHOW_LOGIN";
export const SHOWING_DIALOG = "SHOWING_DIALOG";
export const UNAUTHORIZE = "UNAUTHORIZE";
export const IPRESTRICT = "IPRESTRICT";

export const GETSITETOKEN_SUCCESS = "GETSITETOKEN_SUCCESS";
export const GETSITETOKEN_FAIL = "GETSITETOKEN_FAIL";
export const RESET_WALLETRESULT = "RESET_WALLETRESULT";
export const ADDBANKCARD_BEGIN = "ADDBANKCARD_BEGIN";
export const ADDBANKCARD_SUCCESS = "ADDBANKCARD_SUCCESS";
export const ADDBANKCARD_FAIL = "ADDBANKCARD_FAIL";
export const ADDBILLING_BEGIN = "ADDBILLING_BEGIN";
export const ADDBILLING_SUCCESS = "ADDBILLING_SUCCESS";
export const ADDBILLING_FAIL = "ADDBILLING_FAIL";
export const CANCELDEPOSIT_BEGIN = "CANCELDEPOSIT_BEGIN";
export const CANCELDEPOSIT_SUCCESS = "CALCENDEPOSIT_SUCCESS";
export const CANCELDEPOSIT_FAIL = "CANCELDEPOSIT_FAIL";
export const DELETEBANKCARD_BEGIN = "DELETEBANKCARD_BEGIN";
export const DELETEBANKCARD_SUCCESS = "DELETEBANKCARD_SUCCESS";
export const DELETEBANKCARD_FAIL = "DELETEBANKCARD_FAIL";
export const DOAPPLEPAYDEPOSIT_BEGIN = "DOAPPLEPAYDEPOSIT_BEGIN";
export const DOAPPLEPAYDEPOSIT_SUCCESS = "DOAPPLEPAYDEPOSIT_SUCCESS";
export const DOAPPLEPAYDEPOSIT_FAIL = "DOAPPLEPAYDEPOSIT_FAIL";
export const DODEPOSIT_BEGIN = "DODEPOSIT_BEGIN";
export const DODEPOSIT_SUCCESS = "DODEPOSIT_SUCCESS";
export const DODEPOSIT_FAIL = "DODEPOSIT_FAIL";
export const DOLEEPAYDEPOSIT_BEGIN = "DOLEEPAYDEPOSIT_BEGIN";
export const DOLEEPAYDEPOSIT_SUCCESS = "DOLEEPAYDEPOSIT_SUCCESS";
export const DOLEEPAYDEPOSIT_FAIL = "DOLEEPAYDEPOSIT_FAIL";
export const DOPRIMEPAYDEPOSIT_BEGIN = "DOPRIMEPAYDEPOSIT_BEGIN";
export const DOPRIMEPAYDEPOSIT_SUCCESS = "DOPRIMEPAYDEPOSIT_SUCCESS";
export const DOPRIMEPAYDEPOSIT_FAIL = "DOPRIMEPAYDEPOSIT_FAIL";
export const GETWITHDRAWALCHANNEL_BEGIN = "GETWITHDRAWALCHANNEL_BEGIN";
export const GETWITHDRAWALCHANNEL_SUCCESS = "GETWITHDRAWALCHANNEL_SUCCESS";
export const GETWITHDRAWALCHANNEL_FAILED = "GETWITHDRAWALCHANNEL_FAILED";
export const DOWITHDRAWALALL_BEGIN = "DOWITHDRAWALALL_BEGIN";
export const DOWITHDRAWALALL_SUCCESS = "DOWITHDRAWALALL_SUCCESS";
export const DOWITHDRAWALALL_FAIL = "DOWITHDRAWALALL_FAIL";
export const DOTRANSFER_BEGIN = "DOTRANSFER_BEGIN";
export const DOTRANSFER_SUCCESS = "DOTRANSFER_SUCCESS";
export const DOTRANSFER_FAIL = "DOTRANSFER_FAIL";
export const DOWITHDRAWAL_BEGIN = "DOWITHDRAWAL_BEGIN";
export const DOWITHDRAWAL_SUCCESS = "DOWITHDRAWAL_SUCCESS";
export const DOWITHDRAWAL_FAIL = "DOWITHDRAWAL_FAIL";
export const GETBANKLIST_BEGIN = "GETBANKLIST_BEGIN";
export const GETBANKLIST_SUCCESS = "GETBANKLIST_SUCCESS";
export const GETBANKLIST_FAIL = "GETBANKLIST_FAIL";
export const GETBANKCARDLIST_BEGIN = "GETBANKCARDLIST_BEGIN";
export const GETBANKCARDLIST_SUCCESS = "GETBANKCARDLIST_SUCCESS";
export const GETBANKCARDLIST_FAIL = "GETBANKCARDLIST_FAIL";
export const UPDATEBALANCELIST_BEGIN = "UPDATEBALANCELIST_BEGIN";
export const UPDATEBALANCELIST_SUCCESS = "UPDATEBALANCELIST_SUCCESS";
export const UPDATEBALANCELIST_FAIL = "UPDATEBALANCELIST_FAIL";
export const GETBALANCELIST_BEGIN = "GETBALANCELIST_BEGIN";
export const GETBALANCELIST_SUCCESS = "GETBALANCELIST_SUCCESS";
export const GETBALANCELIST_FAIL = "GETBALANCELIST_FAIL";
export const WALLET_HIDEMESSAGE = "WALLET_HIDEMESSAGE";
export const DOMANUALDEPOSITUPLOAD_BEGIN = "DOMANUALDEPOSITUPLOAD_BEGIN";
export const DOMANUALDEPOSITUPLOAD_SUCCESS = "DOMANUALDEPOSITUPLOAD_SUCCESS";
export const DOMANUALDEPOSITUPLOAD_FAIL = "DOMANUALDEPOSITUPLOAD_FAIL";
export const DOMANUALDEPOSIT_BEGIN = "DOMANUALDEPOSIT_BEGIN";
export const DOMANUALDEPOSIT_SUCCESS = "DOMANUALDEPOSIT_SUCCESS";
export const DOMANUALDEPOSIT_FAIL = "DOMANUALDEPOSIT_FAIL";
export const GETDEPOSITCHANNEL_BEGIN = "GETDEPOSITCHANNEL_BEGIN";
export const GETDEPOSITCHANNEL_SUCCESS = "GETDEPOSITCHANNEL_SUCCESS";
export const GETDEPOSITCHANNEL_FAIL = "GETDEPOSITCHANNEL_FAIL";

export const GETGAMEBALANCE_BEGIN = "GETGAMEBALANCE_BEGIN";
export const GETGAMEBALANCE_SUCCESS = "GETGAMEBALANCE_SUCCESS";
export const GETGAMEBALANCE_FAIL = "GETGAMEBALANCE_FAIL";

export const GETWALLETBALANCE_BEGIN = "GETWALLETBALANCE_BEGIN";
export const GETWALLETEBALANCE_SUCCESS = "GETWALLETEBALANCE_SUCCESS";
export const GETWALLETBALANCE_FAILED = "GETWALLETBALANCE_FAILED";

export const GETGAMEPROVIDER_BEGIN = "GETGAMEPROVIDER_BEGIN ";
export const GETGAMEPROVIDER_SUCCESS = "GETGAMEPROVIDER_SUCCESS ";
export const GETGAMEPROVIDER_FAILED = "GETGAMEPROVIDER_FAILED ";

export const GETROUNDSREPORTSTATUS_BEGIN = "GETROUNDSREPORTSTATUS_BEGIN";
export const GETROUNDSREPORTSTATUS_SUCCESS = "GETROUNDSREPORTSTATUS_SUCCESS";
export const GETROUNDSREPORTSTATUS_FAILED = "GETROUNDSREPORTSTATUS_FAILED";
