import React, { Component } from "react";
import styled from "styled-components";
import { connect } from "react-redux";

import IconInboxDeletePNG from "../../../../assets/images/icon-inbox-delete.png";
import IconInboxReadPNG from "../../../../assets/images/icon-inbox-read.png";
import IconInboxSelectedPNG from "../../../../assets/images/icon-inbox-selected.png";
import IconInboxCheckPNG from "../../../../assets/images/icon-inbox-check.png";
import { withSnackbar } from "notistack";
import { withStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import LoginBGPJPG from "../../../../assets/images/login-bg.jpg";
import IconCloseWhite from "../../../../assets/images/icon-close-white.png";
import Details from "./Details";
import List from "./List";
import { InboxService } from "../../../../services/InboxService";
import { AffiliateService } from "../../../../services/AffiliateService";
import { SHOWING_DIALOG } from "../../../../constant/actionType";
import {
  TitleContainer,
  Title,
  FullSizeContainer,
  Content,
  CustomFormControl,
  CustomSelect,
  QuickTransferButton,
} from "../../../Share/General";

import CustomTextField from "../../../Share/TextField";
import i18next from "i18next";

const ActionContainer = styled.div``;
const ActionButton = styled.div`
  height: 42px;
  font-size: 14px;
  background-color: #f7f7f7;
  text-align: center;
  padding: 10px 10px 0 35px;

  box-sizing: border-box;
  float: left;
  margin-right: 8px;
  margin-top: 18px;
  background-repeat: no-repeat;

  ${(props) =>
    props.active
      ? ` border: solid 1px #c0252d;
      color: #c0252d;`
      : ` border: solid 1px #e1dfdf;
      color: #747474;`};
  &:hover {
    cursor: pointer;
  }
`;

const DeleteButton = styled(ActionButton)`
  background-image: url(${IconInboxDeletePNG});
  background-position: 15px 10px;
`;
const ReadButton = styled(ActionButton)`
  background-image: url(${IconInboxReadPNG});
  background-position: 13px 14px;
`;
const SelectAllButton = styled(ActionButton)`
  background-position: 13px 12px;
  ${(props) =>
    props.active
      ? `  background-image: url(${IconInboxSelectedPNG});`
      : `  background-image: url(${IconInboxCheckPNG}); `};
`;
const StyledDialog = withStyles({
  root: {
    boxShadow: "0 3px 5px 2px rgba(255, 105, 135, .3)",
    "& .MuiDialog-paperWidthSm": {},
  },
})(Dialog);
const DialogContainer = styled.div`
  padding: 27px 32px;
  background: url(${LoginBGPJPG});
  display: table;
`;
const DialogCloseIconContainer = styled.div`
  background: url(${IconCloseWhite}) no-repeat center;
  width: 50px;
  height: 100%;
  float: right;
  &:hover {
    cursor: pointer;
  }
`;
const DialogHeader = styled.div`
  height: 50px;
  background-color: ${(props) => props.theme.secondary};
`;
const DialogHeadertitle = styled.div`
  text-align: center;
  margin-left: 50px;
  padding-top: 12px;
  float: left;
  font-size: 18px;
  font-weight: bold;
  color: #ffffff;
`;

const UpperContainer = styled.div`
  display: flex;
  align-items: center;
`;

class Inbox extends Component {
  state = {
    selectAll: false,
    openDialog: false,
    dialogTitle: "收件箱",
    message: "",
    selectedRecord: false,
    page: 1,
    selected: [],
    list: {
      datas: [],
      total: 0,
    },
  };
  handleChangePage = (newPage) => {
    this.GetInboxList(newPage);
  };
  GetInboxList = (page) => {
    this.setState({ ...this.state, page: page });
    InboxService.InboxList(this.props.token, page, 20).then((response) => {
      if (response.IsSuccess) {
        this.setState({ ...this.state, list: response.data });
      } else {
        this.props.enqueueSnackbar(response.msg, {
          variant: "error",
        });
      }
    });
  };
  DeleteSelectedInbox = (ids) => {
    if (ids === "") {
      this.props.enqueueSnackbar("尚未选取任何消息", {
        variant: "error",
      });
    } else {
      InboxService.DeleteMessage(this.props.token, ids).then((response) => {
        if (response.IsSuccess) {
          this.GetInboxList(1);
        } else {
          this.props.enqueueSnackbar(response.msg, {
            variant: "error",
          });
        }
      });
    }
  };
  ReadSelectedInbox = (ids) => {
    InboxService.UpdateReadMessage(this.props.token, ids).then((response) => {
      if (response.IsSuccess) {
        this.GetInboxList(1);
      } else {
        this.props.enqueueSnackbar(response.msg, {
          variant: "error",
        });
      }
    });
  };

  componentDidMount() {
    if (this.props.token != null) {
      this.GetInboxList(1);
    }
  }
  componentDidUpdate(preProps) {
    if (preProps.token == null && this.props.token != null) {
      this.GetInboxList(1);
    }
  }
  handleSelectAllClick = (event, newPage) => {
    if (!this.state.selectAll) {
      const newSelecteds = this.state.list.datas.map((n) => n.id);
      this.setState({
        ...this.state,
        selected: newSelecteds,
        selectAll: !this.state.selectAll,
      });

      return;
    }
    this.setState({
      ...this.state,
      selected: [],
      selectAll: !this.state.selectAll,
    });
  };
  handleSelectedChange = (newSelecteds) => {
    this.setState({
      ...this.state,
      selected: newSelecteds,
    });
  };
  handleCloseDialog = () => {
    this.setState({ ...this.state, openDialog: false });
  };

  handleDeleteSelected = () => {
    this.DeleteSelectedInbox(this.state.selected.join());
  };
  handleReadSelected = () => {
    this.ReadSelectedInbox(this.state.selected.join());
  };
  handleClickDelete = (id) => {
    this.DeleteSelectedInbox(id);
    this.setState({ ...this.state, openDialog: false });
  };
  handleRowClick = (event, row) => {
    this.ReadSelectedInbox([row.id]);
    this.setState({
      ...this.state,
      selectedRecord: row,
      openDialog: true,
    });
  };

  handleMessageChange = (e) => {
    this.setState({
      message: e.target.value,
    });
  };

  submitAffiliateMessage = () => {
    AffiliateService.submitMessage(this.props.token, this.state.message).then(
      (response) => {
        if (response.IsSuccess) {
        } else {
          this.props.enqueueSnackbar(response.msg, {
            variant: "error",
          });
        }
      }
    );
  };
  render() {
    return (
      <FullSizeContainer>
        <Content>
          <TitleContainer>
            <Title>{i18next.t("account.inbox.title")}</Title>
          </TitleContainer>
          <UpperContainer>
            <CustomFormControl style={{ height: 46 }}>
              <CustomSelect
                native
                variant="outlined"
                value={this.state.transferFrom}
                displayEmpty
                onChange={this.handleTransferFromChange}
              >
                <option value="" disabled></option>
                <option value="">
                  {i18next.t("account.inbox.options.suggestion")}
                </option>
              </CustomSelect>
            </CustomFormControl>
            <CustomTextField
              placeholder={i18next.t("account.inbox.enquiries")}
              style={{ marginTop: "0px !important" }}
              value={this.state.message}
              handleTextFieldChange={this.handleMessageChange}
            ></CustomTextField>
            <QuickTransferButton onClick={this.submitAffiliateMessage}>
              {i18next.t("common.submit")}
            </QuickTransferButton>
          </UpperContainer>

          <ActionContainer>
            <SelectAllButton
              active={this.state.selectAll}
              onClick={this.handleSelectAllClick}
            >
              {i18next.t("account.inbox.selectAll")}
            </SelectAllButton>
            <DeleteButton onClick={this.handleDeleteSelected}>
              {i18next.t("account.inbox.delete")}
            </DeleteButton>
            <ReadButton onClick={this.handleReadSelected}>
              {i18next.t("account.inbox.markAllAsRead")}
            </ReadButton>
          </ActionContainer>
          <List
            page={this.state.page}
            rows={this.state.list.datas}
            total={this.state.list.total}
            selected={this.state.selected}
            onhandleChangePage={this.handleChangePage}
            onhandleRowClick={this.handleRowClick}
            onhandleSelectedChange={this.handleSelectedChange}
          ></List>
          <StyledDialog
            open={this.state.openDialog}
            onClose={this.handleCloseDialog}
            onEntering={() => this.props.showDialog(true)}
            onExited={() => this.props.showDialog(false)}
          >
            <DialogHeader>
              <DialogHeadertitle>{this.state.dialogTitle}</DialogHeadertitle>
              <DialogCloseIconContainer
                onClick={this.handleCloseDialog}
              ></DialogCloseIconContainer>
            </DialogHeader>
            <DialogContainer>
              <Details
                inbox={this.state.selectedRecord}
                onhandleClickConfirm={this.handleCloseDialog}
                onhandleClickDelete={this.handleClickDelete}
              ></Details>
            </DialogContainer>
          </StyledDialog>
        </Content>
      </FullSizeContainer>
    );
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    showDialog: (payload) => {
      dispatch({ type: SHOWING_DIALOG, payload });
    },
  };
};
export default connect(null, mapDispatchToProps)(withSnackbar(Inbox));
