import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

import Header from "../Account/Header";
import {
  CustomContainer,
  ReportContainer,
  FilterContainer,
  TitleContainer,
  Title,
  Content,
  CustomQuickButton,
  NoDataContainer,
  CustomTable,
  CustomTableHeader,
} from "../../Share/General";

import TableContainer from "@material-ui/core/TableContainer";
import styled from "styled-components";
import TableRow from "@material-ui/core/TableRow";
import TablePagination from "@material-ui/core/TablePagination";
import ReportDatepicker from "./ReportDatepicker";
import i18n from "../../../utils/i18n";
import { Container } from "@material-ui/core";
import { ReportService } from "../../../services/ReportService";

import { generalAction } from "../../../actions/generalAction";
import { reportAction } from "../../../actions/reportAction";
import { useSnackbar, withSnackbar } from "notistack";

const moment = require("moment");

const CustomSelect = styled.select`
  height: 40px;
  border: solid 1px #b6b6b6;
  border-radius: 4px;

  margin-right: 8px;
  display: flex;
  align-items: center;
  padding: 0px 8px;
  min-width: 110px;
  @media (max-width: 600px) {
    width: 100%;
    margin-right: 0px;
    margin-bottom: 4px;
  }
`;

const CustomTextField = styled.input`
  height: 40px;
  border: solid 1px #b6b6b6;
  border-radius: 4px;
  margin-right: 8px;
  display: flex;
  align-items: center;
  padding: 0px 8px;
  width: 110px;
  @media (max-width: 600px) {
    width: 94%;
    margin-right: 0px;
    margin-bottom: 4px;
  }

  ::placeholder {
    color: #b6b6b6;
    opacity: 1;
  }
`;

const LeftDateRange = styled.div`
  margin-right: 8px;
  @media (max-width: 600px) {
    margin-right: 0px;
  }
`;

const PaginationWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const AnotherCustomSelect = styled.select`
  height: 30px;
  border: solid 1px #b6b6b6;
  border-radius: 4px;

  margin-right: 8px;
  display: flex;
  align-items: center;
  padding: 0px 8px;

  @media (max-width: 600px) {
    margin-right: 0px;
  }
`;

const CustomPageCounter = styled.div`
  font-size: 12px;
  margin-left: 12px;
`;

const RoundReport = (props) => {
  const [roundList, setRoundList] = useState([]);
  const [page, setPage] = useState(1);
  const [maxRow, setMaxRow] = useState(10);

  //search
  const [username, setUsername] = useState("");
  const [roundId, setRoundId] = useState("");
  const [externalRoundId, setExternalRoundId] = useState("");
  const [status, setStatus] = useState("");
  const [gameProvider, setGameProvider] = useState("");
  const [dateFrom, setDateFrom] = useState(null);
  const [dateTo, setDateTo] = useState(null);
  const [closedFrom, setClosedFrom] = useState(null);
  const [closedTo, setClosedTo] = useState(null);

  function handleClosedFrom(newDate) {
    setClosedFrom(newDate);
  }
  function handleClosedTo(newDate) {
    setClosedTo(newDate);
  }

  function resetClosedTime() {
    setClosedFrom(null);
    setClosedTo(null);
  }

  useEffect(() => {
    props.getGameProvider();
    props.getRoundStatus();
  }, []);

  function performSearch() {
    if (username === "") {
      props.enqueueSnackbar(i18n.t("roundreport.msgInsertPlayername"), {
        variant: "warning",
      });
      return;
    }
    if (!closedFrom || !closedTo) {
      props.enqueueSnackbar(i18n.t("roundreport.msgCloseTimeCannotEmpty"), {
        variant: "warning",
      });
      return;
    }
    getRounds(
      page,
      username,
      roundId,
      externalRoundId,
      gameProvider,
      status,
      dateFrom,
      dateTo,
      closedFrom,
      closedTo
    );
  }

  useEffect(() => {
    if (roundList.items) {
      performSearch();
    }
  }, [page]);

  useEffect(() => {
    if (roundList.items) {
      setPage(1);
      performSearch();
    }
  }, [maxRow]);

  function getRounds(
    page,
    Username,
    RoundId,
    ExternalRoundId,
    GameProviderId,
    Status,
    From,
    To,
    ClosedFrom,
    ClosedTo
  ) {
    ReportService.GetRounds(
      page,
      maxRow,
      true,
      "",
      false,
      Username,
      RoundId,
      ExternalRoundId,
      GameProviderId,
      Status,
      From === null ? "" : moment(new Date(From)).toISOString(),
      To === null ? "" : moment(new Date(To)).add(1, "days").toISOString(),
      ClosedFrom === null ? "" : moment(new Date(ClosedFrom)).toISOString(),
      ClosedTo === null
        ? ""
        : moment(new Date(ClosedTo)).add(1, "days").toISOString()
    ).then((result) => {
      if (result.IsSuccess) {
        setRoundList(result.data);
      }
    });
  }

  function dateTimeFormatter(date) {
    return moment(date).format("YYYY-MM-DD HH:mm:ss").toString();
  }
  function handleChangePage(event, newPage) {
    setPage(newPage + 1);
  }

  useEffect(() => {
    if (moment(dateTo).isBefore(dateFrom)) {
      setDateTo(dateFrom);
    }

    if (moment(closedTo).isBefore(closedFrom)) {
      setClosedTo(closedFrom);
    }
  });

  return (
    <CustomContainer>
      <Container maxWidth="lg">
        <Header />
        <ReportContainer>
          <Content>
            <TitleContainer>
              <Title>{i18n.t("roundreport.title")}</Title>
            </TitleContainer>
            {
              <FilterContainer style={{ marginBottom: 8 }}>
                <div>
                  <ReportDatepicker
                    selectedDateFrom={closedFrom}
                    selectedDateTo={closedTo}
                    handleDateFrom={handleClosedFrom}
                    handleDateTo={handleClosedTo}
                    maxMonth={6}
                    emptied
                    title={i18n.t("roundreport.closeTime")}
                    reset={resetClosedTime}
                  />
                </div>
              </FilterContainer>
            }
            <FilterContainer>
              <CustomTextField
                placeholder={i18n.t("roundreport.username")}
                value={username}
                onChange={(e) => {
                  setUsername(e.target.value);
                }}
              />
              <CustomTextField
                placeholder={i18n.t("roundreport.roundId")}
                value={roundId}
                onChange={(e) => {
                  setRoundId(e.target.value);
                }}
              />
              <CustomTextField
                placeholder={i18n.t("roundreport.externalRoundId")}
                value={externalRoundId}
                onChange={(e) => {
                  setExternalRoundId(e.target.value);
                }}
              />
              <CustomSelect
                value={status}
                onChange={(e) => {
                  setStatus(e.target.value);
                }}
              >
                <option selected disabled value="x">
                  {i18n.t("roundreport.status")}
                </option>
                <option value="">{i18n.t("common.all")}</option>
                {props.report.rounds.status &&
                  Object.keys(props.report.rounds.status).map(function (
                    key,
                    index
                  ) {
                    return (
                      <option value={key} key={index}>
                        {i18n.t(
                          `roundreport.reportStatus.` +
                            props.report.rounds.status[key]
                        )}
                      </option>
                    );
                  })}
              </CustomSelect>
              <CustomSelect
                value={gameProvider}
                onChange={(e) => {
                  setGameProvider(e.target.value);
                }}
              >
                <option selected disabled value="x">
                  {i18n.t("roundreport.brandProvider")}
                </option>
                <option value="">{i18n.t("common.all")}</option>
                {props.general.gameprovider
                  .sort(function (a, b) {
                    return a.name < b.name ? -1 : a.name > b.name ? 1 : 0;
                  })
                  .map((data, index) => {
                    return <option value={data.id}>{data.name}</option>;
                  })}
              </CustomSelect>
              <CustomQuickButton
                onClick={() => {
                  performSearch();
                }}
              >
                {i18n.t("report.search")}
              </CustomQuickButton>
            </FilterContainer>
            <TableContainer>
              <CustomTable lock={1}>
                <table>
                  <thead>
                    <tr>
                      <CustomTableHeader>
                        {i18n.t("roundreport.username")}
                      </CustomTableHeader>
                      <CustomTableHeader>
                        {i18n.t("roundreport.externalRoundId")}
                      </CustomTableHeader>
                      <CustomTableHeader>
                        {i18n.t("roundreport.roundId")}
                      </CustomTableHeader>
                      <CustomTableHeader>
                        {i18n.t("roundreport.betTime")}
                      </CustomTableHeader>
                      <CustomTableHeader>
                        {i18n.t("roundreport.closeTime")}
                      </CustomTableHeader>
                      <CustomTableHeader>
                        {i18n.t("roundreport.brandProvider")}
                      </CustomTableHeader>
                      <CustomTableHeader>
                        {i18n.t("roundreport.childBrandProvider")}
                      </CustomTableHeader>
                      <CustomTableHeader>
                        {i18n.t("roundreport.status")}
                      </CustomTableHeader>
                      <CustomTableHeader>
                        {i18n.t("roundreport.currency")}
                      </CustomTableHeader>
                      <CustomTableHeader class="align-right">
                        {i18n.t("roundreport.betAmount")}
                      </CustomTableHeader>
                      <CustomTableHeader class="align-right">
                        {i18n.t("roundreport.effectiveBet")}
                      </CustomTableHeader>
                      <CustomTableHeader class="align-right">
                        {i18n.t("roundreport.turnover")}
                      </CustomTableHeader>
                      <CustomTableHeader class="align-right">
                        {i18n.t("roundreport.payout")}
                      </CustomTableHeader>
                      <CustomTableHeader class="align-right">
                        {i18n.t("roundreport.winLose")}
                      </CustomTableHeader>
                    </tr>
                  </thead>
                  <tbody>
                    {roundList.items &&
                      roundList.items.map((data, index) => {
                        return (
                          <tr>
                            <th>{data.playerName}</th>
                            <td>{data.externalRoundId}</td>
                            <td>{data.id}</td>
                            <td>{dateTimeFormatter(data.createdOn)}</td>
                            <td>{dateTimeFormatter(data.closedOn)}</td>
                            <td>{data.gameProviderName}</td>
                            <td>{data.subGameProviderName}</td>
                            <td>
                              {i18n.t(
                                `roundreport.reportStatus.` +
                                  props.report.rounds.status[data.status]
                              )}
                            </td>
                            <td>{data.currencyCode}</td>
                            <td class="align-right">{data.betAmount}</td>
                            <td class="align-right">{data.validBetAmount}</td>
                            <td class="align-right">{data.turnover}</td>
                            <td class="align-right">{data.settleAmount}</td>
                            <td class="align-right">{data.winLoss}</td>
                          </tr>
                        );
                      })}
                    {(roundList.length === 0 || roundList.totalcount === 0) && (
                      <TableRow>
                        <NoDataContainer colSpan={15}>
                          {i18n.t("common.nodata")}
                        </NoDataContainer>
                      </TableRow>
                    )}
                  </tbody>
                </table>
              </CustomTable>
            </TableContainer>
            <PaginationWrapper>
              <AnotherCustomSelect
                value={maxRow}
                onChange={(e) => {
                  setMaxRow(e.target.value);
                }}
              >
                <option value="10">10</option>
                <option value="50">50</option>
                <option value="200">200</option>
              </AnotherCustomSelect>
              <CustomPageCounter>
                {page} /{" "}
                {Math.ceil(
                  (roundList.length !== 0 ? roundList.totalcount : 0) / maxRow
                )}
              </CustomPageCounter>
              <TablePagination
                rowsPerPageOptions={[]}
                component="div"
                count={roundList.length !== 0 ? roundList.totalcount : 0}
                rowsPerPage={maxRow}
                page={page - 1}
                labelRowsPerPage={("", "", "", "????")}
                backIconButtonProps={{
                  "aria-label": "previous page",
                }}
                nextIconButtonProps={{
                  "aria-label": "next page",
                }}
                onChangePage={handleChangePage}
              />
            </PaginationWrapper>
          </Content>
        </ReportContainer>
      </Container>
    </CustomContainer>
  );
};

const mapStateToProps = (state) => {
  return {
    token: state.account.token,
    data: state.account.info,
    general: state.general,
    report: state.report,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getGameProvider: () => dispatch(generalAction.getGameProvider()),
    getRoundStatus: () => dispatch(reportAction.getRoundsStatus()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withSnackbar(RoundReport));
