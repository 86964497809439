import React from "react";

import styled from "styled-components";

import Button from "../../../Share/Button";
import i18n from "../../../../utils/i18n";
import { Grid } from "@material-ui/core";
const Container = styled.div`
  width: 100%;
`;
const SubTitle = styled.div`
  font-size: 22px;
  font-weight: bold;
  text-align: center;
  color: #16242a;
  margin-top: 50px;
`;
const SubTitleDesc = styled.div`
  text-align: center;
  font-size: 14px;
  margin-top: 10px;
  margin-bottom: 40px;
  color: #818181;
  text-align: center;
`;

const CustomButton = styled(Button)`
  @media (max-width: 530px) {
    width: 100%;
  }
`;

function AddCardSuccess(props) {
  return (
    <Container>
      <SubTitle>{i18n.t("account.withdrawal.addCardSuccess.title")}</SubTitle>
      <SubTitleDesc>
        {i18n.t("account.withdrawal.addCardSuccess.titleDesc")}
        <br></br> {i18n.t("account.withdrawal.addCardSuccess.titleDesc2")}
      </SubTitleDesc>

      <Grid container justify="space-around" spacing={1}>
        <Grid item xs={12} md={4}>
          <CustomButton
            name={i18n.t("account.withdrawal.addCardSuccess.bindOtherCard")}
            handleClick={props.onhandleBindCardClick}
          ></CustomButton>
        </Grid>
        <Grid item xs={12} md={4}>
          <CustomButton
            name={i18n.t("account.withdrawal.addCardSuccess.proceedWithdrawal")}
            handleClick={props.onhandleGoWithdrawClick}
            float={"right"}
          ></CustomButton>
        </Grid>
      </Grid>
    </Container>
  );
}

export default AddCardSuccess;
