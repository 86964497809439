import React from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import i18n from "../../../../utils/i18n";
import { withSnackbar } from "notistack";
import {
  FullSizeContainer,
  Content,
  TitleContainer,
  Title,
} from "../../../Share/General";

import { Grid } from "@material-ui/core";
import ChannelLink from "../Channel/ChannelLink";

const TitleAndAction = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
`;

const CustomDataRow = styled.div`
  display: grid;

  padding: 7.5px 10px;
`;

const CustomDataContent = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
`;

const Domains = styled(CustomDataContent)`
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
`;

const AgentDomain = (props) => {
  return (
    <FullSizeContainer>
      <Content>
        <TitleContainer>
          <Grid container alignItems="flex-end">
            <TitleAndAction>
              <Title> {i18n.t("account.createAgent.agentdomain")}</Title>
            </TitleAndAction>
          </Grid>
        </TitleContainer>
        <CustomDataRow>
          <Domains>
            {props.account.info.agentdomain &&
              props.account.info.agentdomain.map((data, index) => {
                return <ChannelLink url={data} />;
              })}
          </Domains>
        </CustomDataRow>
      </Content>
    </FullSizeContainer>
  );
};

const mapStateToProps = (state) => {
  return {
    account: state.account,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withSnackbar(AgentDomain));
