import React, { Component } from "react";

import styled from "styled-components";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import TreeView from "@material-ui/lab/TreeView";
import TreeItem from "@material-ui/lab/TreeItem";
import Typography from "@material-ui/core/Typography";
import AboutUsBgPNG from "../../../assets/images/aboutus-bg.png";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";

import Config from "../../../config/config";

const Container = styled.div`
  width: 100%;
  background: url(${Config.sourceUrl + "/static/image/blue-bg.jpg"});
  position: relative;
  min-height: 800px;
`;
const InnerWrap = styled.div`
  margin: 26px auto;
  width: 1000px;
  background-color: #152326;
`;
const LeftContainer = styled.div`
  width: 207px;
  height: 767px;
  float: left;
  background-color: #243941;

  margin: 5px;
`;
const LeftContainerHeader = styled.div`
  background: url(${AboutUsBgPNG}) no-repeat;
  width: 207px;
  height: 113px;
  padding: 10px;
`;
const HeaderTitle = styled.div`
  font-size: 29px;
  color: #f5e3e0;
`;
const HeaderTitle2 = styled.div`
  font-family: Arial;
  font-size: 21px;

  color: #f5e3e0;
`;
const RightContainer = styled.div`
  width: 777px;

  float: left;
  background-color: #ffffff;
  margin: 5px 5px 5px 0;
  position: relative;
`;

const useTreeItemStyles = makeStyles((theme) => ({
  root: {
    color: "#bfd2bf",
    fontSize: "14px",
    "&:focus > $content": {
      backgroundColor: `var(--tree-view-bg-color, 'transparent')`,
      color: "#fbeba4",
    },
  },
  content: {
    color: "#bfd2bf",

    padding: "11px 0",

    "$expanded > &": {
      backgroundColor: `var(--tree-view-bg-color, 'transparent')`,
      color: "#fbeba4",
    },
    "&:hover ": {
      color: "#fbeba4",
    },
  },
  group: {
    backgroundColor: "#33404d",
    marginLeft: 0,
    "& $content": {
      paddingLeft: theme.spacing(2),
    },
  },
  expanded: {},
  label: {
    fontWeight: "inherit",
    color: "inherit",
  },
  labelRoot: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0.5, 0),
  },
  labelIcon: {
    width: "22px",
    height: "18px",
    marginRight: "10px",
  },
  labelIconEmpty: {
    marginRight: "10px",
  },
  labelText: {
    fontWeight: "inherit",
    flexGrow: 1,
  },
}));

const ItemDiv = styled.div``;
function StyledTreeItem(props) {
  const classes = useTreeItemStyles();
  const {
    isexpanded,
    labelText,
    labelIcon: LabelIcon,
    labelIconHover: LabelIconHover,
    labelInfo,
    color,
    bgColor,
    ...other
  } = props;

  return (
    <TreeItem
      label={
        <ItemDiv className={classes.labelRoot}>
          <ArrowRightIcon />
          <Typography variant="body2" className={classes.labelText}>
            {labelText}
          </Typography>
          <Typography variant="caption" color="inherit">
            {labelInfo}
          </Typography>
        </ItemDiv>
      }
      classes={{
        root: classes.root,
        content: classes.content,
        expanded: classes.expanded,
        group: classes.group,
        label: classes.label,
      }}
      {...other}
    />
  );
}

StyledTreeItem.propTypes = {
  bgColor: PropTypes.string,
  color: PropTypes.string,

  labelInfo: PropTypes.string,
  labelText: PropTypes.string.isRequired,
};

const Tabs = [
  {
    name: "品牌介绍",
    path: "about",
    icon: null,
    iconHover: null,
    child: [],
  },
  {
    name: "代理计划",
    path: "affiliate",
    icon: null,
    iconHover: null,
    child: [],
  },

  {
    name: "常见问题",
    path: "question",
    icon: null,
    iconHover: null,
    child: [],
  },
];
class AboutUs extends Component {
  handleTreeChange = (node, path) => {
    if (this.state.expanded.indexOf(node.toString()) >= 0) {
      this.setState({ expanded: [] });
    } else {
      this.setState({ expanded: [node.toString()] });
    }
  };
  handleTreeItemChange = (path) => {
    this.props.history.push("/about/" + path);
    fetch("/assets/help/cn/" + path + ".html")
      .then((res) => {
        return res.text();
      })
      .then((html) => {
        this.setState({ html: html });
      });
  };
  handleSelfControlClick = () => {
    this.setState({ ...this.state, showSelfControl: true });
  };
  componentDidMount() {
    let selectedPage = this.props.match.params.page;
    this.handleTreeItemChange(selectedPage);
  }
  componentWillReceiveProps(nextProps) {
    const oldpage = this.props.match.params.page;
    const newpage = nextProps.match.params.page;
    if (oldpage !== newpage) {
      this.handleTreeItemChange(newpage);
      this.setState({
        expanded: this.getSelectedParent(newpage),
      });
    }
  }
  componentDidUpdate(preProps, preStates) {
    if (preProps.match.params.action !== this.props.match.params.action) {
      if (this.props.match.params.action === "chat") {
        window.open("/CS", "", "width=420,height=470");
      }
    } else {
      if (preProps.match.params.value !== this.props.match.params.value) {
        if (this.props.match.params.action === "chat") {
          window.open("/CS", "", "width=420,height=470");
        }
      }
    }
  }
  getSelectedParent = (selectedPage) => {
    let parent = "";
    for (let item of Tabs) {
      if (item.path === selectedPage) {
        parent = item.name;
        break;
      } else {
        for (let child of item.child) {
          if (child.path === selectedPage) {
            parent = item.name;
            break;
          }
        }
        if (parent !== "") {
          break;
        }
      }
    }

    return [parent];
  };
  handleCloseSelfControl = () => {
    this.setState({ ...this.state, showSelfControl: false });
  };
  state = {
    expanded: this.getSelectedParent(this.props.match.params.page),
    showSelfControl: false,
  };
  render() {
    return (
      <Container>
        <InnerWrap>
          <LeftContainer>
            <LeftContainerHeader>
              <HeaderTitle>关于我们</HeaderTitle>
              <HeaderTitle2>About Us</HeaderTitle2>
            </LeftContainerHeader>
            <TreeView
              expanded={this.state.expanded}
              defaultCollapseIcon={<ArrowDropDownIcon />}
              defaultExpandIcon={<ArrowRightIcon />}
              defaultEndIcon={<div style={{ width: 24 }} />}
              children={<ArrowDropDownIcon />}
            >
              {Tabs.map((row, index) => {
                return (
                  <StyledTreeItem
                    key={row.name}
                    nodeId={row.name}
                    labelText={row.name}
                    labelIcon={row.icon}
                    labelIconHover={row.iconHover}
                    isexpanded={
                      this.state.expanded.indexOf(row.name) >= 0 ? true : false
                    }
                    onClick={() => this.handleTreeChange(row.name, row.path)}
                  >
                    {row.child.map((child, index) => {
                      return (
                        <StyledTreeItem
                          key={index}
                          nodeId={child.path}
                          labelText={child.name}
                          onClick={() => this.handleTreeItemChange(child.path)}
                        />
                      );
                    })}
                  </StyledTreeItem>
                );
              })}
            </TreeView>
          </LeftContainer>
          <RightContainer>
            <div dangerouslySetInnerHTML={{ __html: this.state.html }}></div>
          </RightContainer>
        </InnerWrap>
      </Container>
    );
  }
}

export default AboutUs;
