import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import i18n from "../../../../utils/i18n";
import { useSnackbar, withSnackbar } from "notistack";
import {
  FullSizeContainer,
  Content,
  TitleContainer,
  Title,
  StyledTableCell,
  StyledTableCellRight,
  NoDataContainer,
} from "../../../Share/General";

import {
  Grid,
  Table,
  TableRow,
  TableBody,
  TableHead,
  TableContainer,
  Dialog,
  DialogContent,
} from "@material-ui/core";
import { AffiliateService } from "../../../../services/AffiliateService";
import AgentDetailModal from "./modal";
import moment from "moment";
import addDays from "date-fns/addDays";
import CustomPagination from "../../../Share/CustomPagination";
import { addHours } from "date-fns";
import Common from "../../../../utils/Common";

const CustomTableRow = styled(TableRow)`
  :hover {
    background: rgba(0, 0, 0, 0.05);
    cursor: pointer;
  }
`;

const TitleAndAction = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  @media (max-width: 600px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const CustomSelect = styled.select`
  height: 40px;
  border: solid 1px #b6b6b6;
  border-radius: 4px;
  max-width: 100px;
  margin-right: 8px;
  display: flex;
  align-items: center;
  padding: 0px 8px;
  @media (max-width: 600px) {
    max-width: 100%;

    margin: 4px 0px;
  }
`;

const CustomTextField = styled.input`
  height: 40px;
  border: solid 1px #b6b6b6;
  border-radius: 4px;
  margin-right: 8px;
  display: flex;
  align-items: center;
  padding: 0px 8px;
  margin-left: 5px;
  max-width: 110px;
  @media (max-width: 600px) {
    max-width: 100%;
    padding: 0;
    margin: 4px 0px;
  }
`;

const ContainerForInput = styled.div`
  display: flex;
  align-items: center;

  @media (max-width: 600px) {
    margin-top: 8px;
    width: 100%;
    justify-content: flex-start;
    flex-direction: column;
    align-items: stretch;
  }
`;

const CustomButton = styled.div`
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  border: solid 1px #b6b6b6;
  padding: 0 8px;
  background-image: linear-gradient(
    to top,
    ${(props) => props.theme.primaryGradient},
    ${(props) => props.theme.primary}
  );
  color: ${(props) => props.theme.primaryText};
  margin-right: 8px;
  min-width: 75px;
  cursor: pointer;
  user-select: none;

  @media (max-width: 600px) {
    margin: 4px 0px;
  }
`;

const CustomTable = styled.table`
  width: 100%;
  border-radius: 4px;
  border: 1px solid #b6b6b6;
  padding: 2.5px;
  border-radius: 4px;
  border-spacing: 0;

  td {
    text-align: center;
  }

  thead td {
    background: ${({ theme }) => theme.primary};
    color: #fff;
    padding: 5px;
  }

  tbody {
    td {
      padding: 5px;
    }
  }
`;

const CustomDialogContent = styled(DialogContent)`
  &.MuiDialogContent-root {
    padding: 0px 12px 12px 12px !important;
  }
`;

const TableHeader = styled.div`
  width: 100%;
  height: 50px;
  font-size: 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: bold;
`;

const CloseButton = styled.div`
  padding-right: 8px;
  font-size: 18px;
  cursor: pointer;
`;

const CustomTinyButton = styled.div`
  border: solid 1px #b6b6b6;
  border-radius: 4px;
`;
const AgentList = (props) => {
  const [open, setOpen] = useState(false);
  const [search, setSearch] = useState("");
  const [category, setCategory] = useState([]);
  const [myDownlineList, setMyDownlineList] = useState([]);
  const [myDownlineData, setMyDownlineData] = useState("");
  const [myDownlineTeamInfo, setMyDownlineTeamInfo] = useState([]);
  const [status, setStatus] = useState([]);
  const [myDownlineChangesReport, setMyDownlineChangesReport] = useState(null);
  const [selectedType, setSelected] = useState(0);

  const [profitSharingModal, setProftSharingModal] = useState(false);

  const [dateFrom, setDateFrom] = useState(
    moment(addDays(new Date(), -3)).format("YYYY-MM-DD")
  );
  const [dateTo, setDateTo] = useState(moment(new Date()).format("YYYY-MM-DD"));
  const [page, setPage] = useState(1);
  function handleNextPage() {
    if (Math.ceil(myDownlineList.totalCount / 10) > page) {
      setPage(page + 1);
    } else {
    }
  }

  function handlePrevPage() {
    if (page <= 1) {
    } else {
      setPage(page - 1);
    }
  }
  useEffect(() => {
    performGetDownlineListWithUsernameAndStatus(search, selectedType);
  }, [page]);
  function performOpen() {
    setOpen(true);
  }

  function performClose() {
    setOpen(false);
    setMyDownlineTeamInfo([]);
    setMyDownlineData("");
    setMyDownlineChangesReport(null);
  }

  function performGetTeamInfo(page, uid) {
    AffiliateService.getTeamInfo(page, "10", true, "", false, uid).then((e) => {
      if (e.IsSuccess) {
        setMyDownlineTeamInfo(e.data);
      }
    });
  }

  function performGetUserInformation(uid) {
    performOpen();
    getAgentInfoOnly(uid);
    performGetTeamInfo(1, uid);
    performGetAccountChangesReport(
      moment.utc(addHours(new Date(dateFrom), -8)).toISOString(),
      moment.utc(addHours(new Date(dateTo), 16)).toISOString(),
      "",
      1,
      10,
      false,
      "recordedOn",
      false,
      uid
    );
  }

  function getAgentInfoOnly(uid) {
    AffiliateService.getAgentInfo(uid).then((e) => {
      if (e.IsSuccess) {
        setMyDownlineData(e.data);
      }
    });
  }

  function performGetDownlineListWithUsernameAndStatus(username, status) {
    AffiliateService.getAgentList(
      status,
      username,
      page,
      10,
      true,
      "",
      false
    ).then((e) => {
      if (e.IsSuccess) {
        setMyDownlineList(e.data);
      }
    });
  }

  const { enqueueSnackbar } = useSnackbar();

  function performFastGetAccountChangesReport(from, to, category, page, uid) {
    if (Common.maxDayRangeDetector(from, to)) {
      enqueueSnackbar(i18n.t("common.cannotExceed31day"), {
        variant: "warning",
      });
    } else {
      setMyDownlineChangesReport(null);
      performGetAccountChangesReport(
        moment.utc(addHours(new Date(from), -8)).toISOString(),
        moment.utc(addHours(new Date(to), 16)).toISOString(),
        category,
        page,
        10,
        false,
        "recordedOn",
        false,
        uid
      );
    }
  }

  function performGetAccountChangesReport(
    from,
    to,
    transactiontype,
    page,
    rows,
    desc,
    sort,
    all,
    id
  ) {
    AffiliateService.getAccountChangesReport(
      from,
      to,
      transactiontype,
      page,
      rows,
      desc,
      sort,
      all,
      id
    ).then((e) => {
      if (e.IsSuccess) {
        setMyDownlineChangesReport(e.data);
      }
    });
  }

  useEffect(() => {
    AffiliateService.getTransactionType().then((e) => {
      if (e.IsSuccess) {
        setCategory(e.data);
      }
    });
    AffiliateService.getAgentListStatus().then((e) => {
      if (e.IsSuccess) {
        setStatus(e.data);
      }
    });
    //performGetDownlineListWithStatus(0);
  }, []);

  useEffect(() => {
    console.log(myDownlineData);
  });

  return (
    <FullSizeContainer>
      <Content>
        <TitleContainer>
          <Grid container alignItems="flex-end">
            <TitleAndAction>
              <Title> {i18n.t("account.menu.agentList")}</Title>
              <ContainerForInput>
                <div>
                  {i18n.t("account.header.name")}:{"  "}
                </div>
                <CustomTextField
                  type="text"
                  value={search}
                  onChange={(e) => {
                    setSearch(e.target.value);
                  }}
                />
                <CustomSelect
                  onChange={(e) => {
                    setSelected(e.target.value);
                  }}
                >
                  {Object.keys(status).map(function (key, index) {
                    return (
                      <option value={key}>
                        {i18n.t(
                          `custom.affiliateDropdown.status.` + status[key]
                        )}
                      </option>
                    );
                  })}
                </CustomSelect>
                <CustomButton
                  onClick={() => {
                    performGetDownlineListWithUsernameAndStatus(
                      search,
                      selectedType
                    );
                  }}
                >
                  {i18n.t("report.search")}
                </CustomButton>
              </ContainerForInput>
            </TitleAndAction>
          </Grid>
        </TitleContainer>
        <TableContainer>
          <Table aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell>{i18n.t("report.username")}</StyledTableCell>
                <StyledTableCellRight>
                  {i18n.t("account.agentInfo.agentDetails.type")}
                </StyledTableCellRight>
                <StyledTableCellRight>
                  {i18n.t("account.agentInfo.agentDetails.dividendpercentage")}
                </StyledTableCellRight>
                {props.account.info.agentdividendtype === "MultipleLayer" && (
                  <StyledTableCellRight>
                    {i18n.t("account.header.dividendpercentage")}
                  </StyledTableCellRight>
                )}

                <StyledTableCellRight>
                  {i18n.t("account.agentInfo.agentDetails.teamsize")}
                </StyledTableCellRight>
                <StyledTableCellRight>
                  {i18n.t("account.agentInfo.agentDetails.balance")}
                </StyledTableCellRight>
                <StyledTableCellRight>
                  {i18n.t("report.status")}
                </StyledTableCellRight>
              </TableRow>
            </TableHead>
            <TableBody style={{ backgroundColor: "#fff" }}>
              {myDownlineList.length !== 0 ? (
                myDownlineList.items
                  .sort((a, b) => {
                    var dateA = new Date(a.recordedOn),
                      dateB = new Date(b.recordedOn);
                    return dateB - dateA;
                  })
                  .map((data, index) => {
                    return (
                      <>
                        <CustomTableRow
                          key={index}
                          onClick={() => {
                            performGetUserInformation(data.id);
                          }}
                        >
                          <StyledTableCell>{data.username}</StyledTableCell>{" "}
                          <StyledTableCellRight>
                            {i18n.t(`custom.type.` + data.type)}
                          </StyledTableCellRight>
                          <StyledTableCellRight>
                            {data.dividendpercentage}
                          </StyledTableCellRight>
                          {props.account.info.agentdividendtype ===
                            "MultipleLayer" && (
                            <StyledTableCellRight>
                              <CustomTinyButton
                                onClick={(e) => {
                                  e.stopPropagation();
                                  setProftSharingModal(data.username);
                                  getAgentInfoOnly(data.id);
                                }}
                              >
                                {i18n.t(
                                  "account.agentInfo.agentDetails.dividendSetup"
                                )}
                              </CustomTinyButton>
                            </StyledTableCellRight>
                          )}
                          <StyledTableCellRight>
                            {data.teamnumber}
                          </StyledTableCellRight>
                          <StyledTableCellRight>
                            {data.balance}
                          </StyledTableCellRight>
                          <StyledTableCellRight>
                            {i18n.t(`custom.agentStatus.` + data.agentstatus)}
                          </StyledTableCellRight>
                        </CustomTableRow>{" "}
                        <Dialog
                          open={data.username === profitSharingModal}
                          onClose={() => {
                            setProftSharingModal(false);
                          }}
                          fullWidth={true}
                          maxWidth="sm"
                        >
                          <CustomDialogContent>
                            <TableHeader>
                              <div>
                                {i18n.t("account.createAgent.profitSharing")}
                              </div>
                              <CloseButton
                                onClick={() => {
                                  setProftSharingModal(false);
                                }}
                              >
                                ✖
                              </CloseButton>
                            </TableHeader>
                            <CustomTable>
                              <thead>
                                <td>
                                  {i18n.t(
                                    "account.createAgent.profitSharingModal.netProfit"
                                  )}
                                </td>
                                <td>
                                  {i18n.t(
                                    "account.createAgent.profitSharingModal.activeNumber"
                                  )}
                                </td>
                                <td>
                                  {i18n.t(
                                    "account.createAgent.profitSharingModal.dividendPercentage"
                                  )}
                                </td>
                              </thead>
                              <tbody>
                                {myDownlineData?.dividendconditions?.map(
                                  (data) => {
                                    return (
                                      <tr>
                                        <td>{data.netprofit}</td>
                                        <td>{data.activenumber}</td>
                                        <td>{data.dividendpercentage}</td>
                                      </tr>
                                    );
                                  }
                                ) ?? <></>}
                              </tbody>
                            </CustomTable>
                          </CustomDialogContent>
                        </Dialog>
                      </>
                    );
                  })
              ) : (
                <TableRow>
                  <NoDataContainer colSpan={6}>
                    {i18n.t("common.nodata")}
                  </NoDataContainer>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        {myDownlineList.totalCount > 10 && (
          <CustomPagination
            total={myDownlineList.totalCount}
            page={page}
            next={handleNextPage}
            prev={handlePrevPage}
          />
        )}
      </Content>

      <AgentDetailModal
        open={open}
        close={performClose}
        category={category}
        status={status}
        myDownlineData={myDownlineData}
        myDownlineTeamInfo={myDownlineTeamInfo}
        myDownlineChangesReport={myDownlineChangesReport}
        performFastGetAccountChangesReport={performFastGetAccountChangesReport}
        performGetTeamInfo={performGetTeamInfo}
      />
    </FullSizeContainer>
  );
};

const mapStateToProps = (state) => {
  return {
    account: state.account,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withSnackbar(AgentList));
