import {
  GETGAMEPROVIDER_BEGIN,
  GETGAMEPROVIDER_FAILED,
  GETGAMEPROVIDER_SUCCESS,
} from "../constant/actionType";
import { GeneralService } from "../services/GeneralService";

export const generalAction = { getSiteToken, getLiveChatURL, getGameProvider };
export const GETSITETOKEN_BEGIN = "GETSITETOKEN_BEGIN";
export const GETSITETOKEN_SUCCESS = "GETSITETOKEN_SUCCESS";
export const GETSITETOKEN_FAIL = "GETSITETOKEN_FAIL";
export const GETLIVECHATURL_SUCCESS = "GETLIVECHATURL_SUCCESS";
export const GETLIVECHATURL_FAIL = "GETLIVECHATURL_FAIL";
export const GETLIVECHATURL_BEGIN = "GETLIVECHATURL_BEGIN";

function getSiteToken() {
  return (dispatch) => {
    dispatch({
      type: GETSITETOKEN_BEGIN,
      loading: true,
    });
    const result = GeneralService.GetSiteToken()
      .then((response) => {
        if (response.IsSuccess) {
          dispatch({
            type: GETSITETOKEN_SUCCESS,
            payload: response,
            loading: false,
          });
        } else {
          dispatch({
            type: GETSITETOKEN_FAIL,
            payload: response,
            loading: false,
          });
        }
        return response;
      })
      .catch((error) => {
        dispatch({
          type: GETSITETOKEN_FAIL,
          payload: error,
          loading: false,
        });
      });
    return result;
  };
}

function getLiveChatURL() {
  return (dispatch) => {
    dispatch({
      type: GETLIVECHATURL_BEGIN,
      loading: true,
    });
    const result = GeneralService.GetLiveChatURL()
      .then((response) => {
        if (response.IsSuccess) {
          dispatch({
            type: GETLIVECHATURL_SUCCESS,
            payload: response,
            loading: false,
          });

          // AppHistory.push("/");
        } else {
          dispatch({
            type: GETLIVECHATURL_FAIL,
            payload: response,
            loading: false,
          });
        }
        return response;
      })
      .catch((error) => {
        dispatch({
          type: GETLIVECHATURL_FAIL,
          payload: error,
          loading: false,
        });
      });
    return result;
  };
}

function getGameProvider() {
  return (dispatch) => {
    dispatch({
      type: GETGAMEPROVIDER_BEGIN,
    });
    const result = GeneralService.GetGameProvider()
      .then((response) => {
        if (response.IsSuccess) {
          dispatch({
            type: GETGAMEPROVIDER_SUCCESS,
            payload: response,
          });

          // AppHistory.push("/");
        } else {
          dispatch({
            type: GETGAMEPROVIDER_FAILED,
            payload: response,
          });
        }
        return response;
      })
      .catch((error) => {
        dispatch({
          type: GETGAMEPROVIDER_FAILED,
          payload: error,
        });
      });
    return result;
  };
}
