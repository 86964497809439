import React, { Component } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import CustomTextField from "../../../Share/TextField";
import CustomButton from "../../../Share/Button";
import Password1IconPNG from "../../../../assets/images/password1-icon.png";
import Password2IconPNG from "../../../../assets/images/password2-icon.png";
import IconInputNewpassPNG from "../../../../assets/images/icon-input-newpass.png";
import { AccountService } from "../../../../services/AccountService";
import { UPDATE_INFO } from "../../../../actions/accountAction";
import { withSnackbar } from "notistack";
import Loading from "../../Loading";
import i18n from "../../../../utils/i18n";
import ErrorSwitcher from "../../../../utils/ErrorSwitcher";
const Container = styled.div``;

const PasswordField = styled.div`
  margin: 8px 0px;
`;

const CustomCustomTextField = styled(CustomTextField)`
  width: 356px;
  @media (max-width: 530px) {
  }
`;

class WithdrawalPassword extends Component {
  constructor(props) {
    super(props);

    this.state = {
      value: this.props.value,
      isClicked: false,
      old_password: "",
      password: "",
      rpassword: "",
    };
  }

  onhandleOldPassChange = (e) => {
    this.setState({ ...this.state, old_password: e.target.value });
    // this.local_props.old_password = e.target.value;
  };
  onhandlePass1Change = (e) => {
    this.setState({ ...this.state, password: e.target.value });
    // this.local_props.password = e.target.value;
  };
  onhandlePass2Change = (e) => {
    this.setState({ ...this.state, rpassword: e.target.value });
    //this.local_props.rpassword = e.target.value;
  };
  onhandleClick = () => {
    var expr = /^.{6,20}$/;
    if (this.state.value !== "") {
      if (
        this.state.old_password === "" ||
        this.state.old_password === null ||
        this.state.password === "" ||
        this.state.password === null ||
        this.state.rpassword === "" ||
        this.state.rpassword === null
      ) {
        this.props.enqueueSnackbar(i18n.t("toast.passwordCantEmpty"), {
          variant: "warning",
        });
      } else {
        this.setState({
          isClicked: true,
        });
        if (this.state.password !== this.state.rpassword) {
          this.props.enqueueSnackbar(i18n.t("toast.passwordNotSame"), {
            variant: "warning",
          });
          this.setState({
            ...this.state,
            isClicked: false,
          });
        } else {
          AccountService.UpdateWithdrawalPassword(
            this.props.token,
            this.state.old_password,
            this.state.password,
            this.state.rpassword
          ).then((response) => {
            if (response.IsSuccess) {
              this.props.dispatch({
                type: UPDATE_INFO,
                payload: {
                  member_safe_password: "set",
                },
              });
              this.props.handleCloseDialog();
              this.props.enqueueSnackbar(response.msg, {
                variant: "success",
              });
              this.setState({
                ...this.state,
                isClicked: false,
              });
            } else {
              this.props.enqueueSnackbar(response.msg, {
                variant: "error",
              });
              this.setState({
                ...this.state,
                isClicked: false,
              });
            }
          });
        }
      }
    } else {
      if (!expr.test(this.state.password)) {
        this.props.enqueueSnackbar(i18n.t("common.msgRegisterPassInvalid"), {
          variant: "warning",
        });
        return;
      }

      if (this.state.password !== this.state.rpassword) {
        this.props.enqueueSnackbar(i18n.t("toast.passwordNotSame"), {
          variant: "warning",
        });
        return;
      }
      AccountService.AddWithdrawalPassword(this.state.password).then(
        (response) => {
          if (response.IsSuccess) {
            this.props.dispatch({
              type: UPDATE_INFO,
              payload: {
                member_safe_password: "set",
              },
            });
            this.props.handleCloseDialog();
            this.props.enqueueSnackbar(
              i18n.t("common.msgSuccessUpdateProfile"),
              {
                variant: "success",
              }
            );
            this.setState({
              ...this.state,
              isClicked: false,
            });
          } else {
            this.props.enqueueSnackbar(
              ErrorSwitcher.getTranslation(response.info),
              {
                variant: "error",
              }
            );
            this.setState({
              ...this.state,
              isClicked: false,
            });
          }
        }
      );
    }
  };
  render() {
    return (
      <Container>
        {(() => {
          if (this.state.value !== "") {
            return (
              <PasswordField>
                <CustomTextField
                  placeholder={i18n.t(
                    "account.safety.dialog.withdrawalPassword.oldPass"
                  )}
                  adornment={Password1IconPNG}
                  handleTextFieldChange={this.onhandleOldPassChange}
                  value={this.state.old_password}
                  type="password"
                ></CustomTextField>
              </PasswordField>
            );
          }
        })()}

        <PasswordField>
          <CustomCustomTextField
            placeholder={i18n.t(
              "account.safety.dialog.withdrawalPassword.newPass"
            )}
            adornment={IconInputNewpassPNG}
            handleTextFieldChange={this.onhandlePass1Change}
            value={this.state.password}
            type="password"
          ></CustomCustomTextField>
        </PasswordField>
        <PasswordField>
          <CustomCustomTextField
            placeholder={i18n.t(
              "account.safety.dialog.withdrawalPassword.confirmNewPass"
            )}
            adornment={Password2IconPNG}
            handleTextFieldChange={this.onhandlePass2Change}
            value={this.state.rpassword}
            type="password"
          ></CustomCustomTextField>
        </PasswordField>
        <CustomButton
          name={i18n.t("account.safety.dialog.submit")}
          handleClick={this.onhandleClick}
        ></CustomButton>
        <Loading show={this.state.isClicked} />
      </Container>
    );
  }
}

export default connect(null, null)(withSnackbar(WithdrawalPassword));
