import React from "react";
import addMonths from "date-fns/addMonths";
import DateFnsUtils from "@date-io/date-fns";
import { withStyles } from "@material-ui/core/styles";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";

function CustomDatePicker(props) {
  const moment = require("moment");
  const CustomPicker = withStyles({
    root: {
      "@media (min-width: 960px)": {
        width: "135px",
      },

      height: "40px",
      float: "left",
      backgroundColor: "#fff",
      "& .MuiInputBase-root": {
        paddingRight: 5,
      },
      "& input": {
        fontFamily: "SimHei",
        fontSize: "14px",
      },
      "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
        border: "solid 1px #b6b6b6",
      },
      "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
        border: "solid 1px rgb(182,182,182)",
      },
      "& .MuiInputAdornment-positionEnd": {
        width: "45px",
        height: "48px",
        marginRight: "5px",
        maxHeight: "40px",
        borderLeft: "solid 1px #b6b6b6",
        "& .MuiButtonBase-root": {
          padding: "5px",
          "&:hover": {
            backgroundColor: "transparent",
          },
          "&:active": {
            backgroundColor: "transparent",
          },
        },
      },
    },
  })(KeyboardDatePicker);
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <CustomPicker
        disableToolbar
        fullWidth
        InputAdornmentProps={{ position: "end" }}
        variant="inline"
        maxDate={new Date()}
        minDate={
          props.minDate
            ? props.minDate
            : moment(
                addMonths(new Date(), props.maxMonth ? -props.maxMonth : -6)
              )
        }
        inputVariant="outlined"
        format="yyyy/MM/dd"
        id={props.id}
        value={props.value ? props.value : props.emptied ? null : new Date()}
        InputProps={{
          readOnly: true,
        }}
        onChange={props.onChange}
        KeyboardButtonProps={{
          "aria-label": "change date",
        }}
      />
    </MuiPickersUtilsProvider>
  );
}

export default CustomDatePicker;
