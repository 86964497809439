import React, { Component } from "react";
import styled from "styled-components";

import LimitPopupBigPNG from "../../../../assets/images/limit-popup-big.png";

import IconEditPNG from "../../../../assets/images/icon-edit.png";
import IconBankDisablePNG from "../../../../assets/images/icon-bank-disable.png";
import config from "../../../../config/config";
import CustomButton from "../../../Share/Button";
import CustomTextField from "../../../Share/TextField";
import i18n from "../../../../utils/i18n";
import { Grid } from "@material-ui/core";
import Common from "../../../../utils/Common";
const TabButton = styled.div`
  height: 41px;
  font-size: 14px;
  background-color: #f7f7f7;
  color: #747474;

  text-align: center;

  float: left;
  margin-right: 8px;
  margin-top: 5px;
  ${(props) =>
    props.active
      ? ` border: solid 1px #151a34;
      `
      : ` border: solid 1px #e1dfdf;
     `};
  &:hover {
    cursor: pointer;
  }
`;
const SubHeaderContainer = styled.div``;
const NumberContainer = styled.div`
  width: 20px;
  height: 20px;
  border-radius: 3.2px;
  background-color: #141933;
  font-family: Arial;
  font-size: 14px;
  font-weight: bold;
  color: #ffffff;
  text-align: center;
  padding-top: 2px;
  float: left;
`;
const SubTitle = styled.div`
  font-size: 16px;
  float: left;
  color: #818181;
  margin: 0 0 0 10px;
`;
const SelectionContainer = styled.div`
  margin: 15px 0 0 30px;
`;
const BankCardButton = styled(TabButton)`
  width: 204px;
  text-align: left;
  padding: 10px 0 0 38px;
  background: url(${IconBankDisablePNG}) no-repeat 15px center;
`;
const BankCardButtonLabel = styled.div`
  width: 86px;
  display: inline-block;
`;
const BankCardButtonCardNo = styled.div`
  float: right;
  margin-right: 2px;
`;
const BankCardButtonCardNoHL = styled(BankCardButtonCardNo)`
  font-weight: bold;
  margin-right: 10px;
`;
const ManageCardButton = styled(TabButton)`
  width: 133px;
  background: url(${IconEditPNG}) no-repeat 15px center;
  padding: 10px 0 0 18px;
`;
const SplitLine = styled.div`
  width: 100%;
  height: 1px;
  background-color: #e1dfdf;
  margin: 20px 0;
`;

const LimitPopupContainer = styled.div`
  background: url(${LimitPopupBigPNG}) no-repeat,
    linear-gradient(90deg, rgba(2, 0, 36, 0) 9px, rgba(122, 145, 153, 1) 10px);
  font-size: 14px;
  color: #ffffff;
  padding: 10px 10px 10px 25px;
  border-radius: 4px;
  display: inline-block;
  line-height: 25px;
`;

const ContainerForButton = styled(Grid)``;

const CustomCustomButton = styled(CustomButton)`
  width: 255px;
  @media (max-width: 530px) {
    margin-left: 0px;
  }
`;

class Main extends Component {
  state = {
    selectedCardIndex: 0,
    showDetails: false,
    amount: "",
    password: "",
    min: 0,
    max: 0,
    disabled: false,
  };

  componentDidMount() {
    this.getTransferLimit(
      this.props.cardList[this.state.selectedCardIndex].paymentchannelid
    );
    // this.setState({ ...this.state, selectedCard: this.props.defaultCard });
  }

  componentDidUpdate(preProps) {
    if (
      preProps.cardList !== this.props.cardList ||
      preProps.withdrawalList !== this.props.withdrawalList
    ) {
      this.getTransferLimit(
        this.props.cardList[this.state.selectedCardIndex].paymentchannelid
      );
    }
  }

  handleSelectCardChange = (index, channelid) => {
    this.setState({ ...this.state, selectedCardIndex: index });
    setTimeout(() => {
      this.getTransferLimit(channelid);
    }, 100);
  };
  handleAmountChange = (e) => {
    var disableButton = true;
    if (this.state.amount !== "") {
      disableButton = false;
    }
    this.setState({
      ...this.state,
      amount: e.target.value,
      disabled: disableButton,
    });
  };
  handlePasswordChange = (e) => {
    var disableButton = true;
    if (this.state.amount !== "" && this.state.password !== "") {
      disableButton = false;
    }
    this.setState({
      ...this.state,
      password: e.target.value,
      disabled: disableButton,
    });
  };
  handleWithdrawalClick = (e) => {
    this.props.onhandleWithdrawalClick(
      this.props.cardList[this.state.selectedCardIndex].id,
      this.state.amount,
      this.state.password
    );
  };

  trimmer = (string) => {
    return string != "" && string != undefined
      ? string.toString().substring(string.length > 4 ? string.length - 4 : 0)
      : "";
  };
  handleTransferLimitChange = (min, max) => {
    this.setState({ ...this.state, min: min, max: max });
  };
  getTransferLimit = (channelId) => {
    //reset everything
    var minValue = [];
    var maxValue = [];
    var withdrawalList = this.props.withdrawalList;

    this.handleTransferLimitChange(0, 0);

    //update new data.
    for (var i = 0; i < withdrawalList.length; i++) {
      const methodList = withdrawalList[i].channel;
      if (methodList) {
        for (var inner = 0; inner < methodList.length; inner++) {
          const channelList = methodList[inner];
          if (channelList.channelid === channelId) {
            minValue.push(channelList.transferlimit.from);
            maxValue.push(channelList.transferlimit.to);
            this.handleTransferLimitChange(
              Math.min(...minValue),
              Math.max(...maxValue)
            );
          }
        }
      }
    }
  };
  render() {
    return (
      <>
        <SubHeaderContainer>
          <NumberContainer>1</NumberContainer>

          <SubTitle>{i18n.t("account.withdrawal.msgEnterBank")}</SubTitle>
        </SubHeaderContainer>
        <SelectionContainer>
          {this.props.cardList.map((item, index) => {
            return (
              <BankCardButton
                key={index}
                active={index === this.state.selectedCardIndex}
                onClick={() => {
                  this.handleSelectCardChange(index, item.paymentchannelid);
                }}
              >
                <BankCardButtonLabel>
                  {i18n.t("account.withdrawal.bankCard")}
                </BankCardButtonLabel>

                <BankCardButtonCardNoHL>
                  {item.detail.address
                    ? this.trimmer(item.detail.address)
                    : this.trimmer(item.detail.bankAccount)}
                </BankCardButtonCardNoHL>
                <BankCardButtonCardNo>
                  {i18n.t("account.withdrawal.tail")}
                </BankCardButtonCardNo>
              </BankCardButton>
            );
          })}
          <ManageCardButton onClick={this.props.onhandleManageCardClick}>
            {i18n.t("account.withdrawal.manageBank")}
          </ManageCardButton>
        </SelectionContainer>
        <SplitLine></SplitLine>

        <SubHeaderContainer>
          <NumberContainer>2</NumberContainer>

          <SubTitle>{i18n.t("account.withdrawal.enterAmount")}</SubTitle>
        </SubHeaderContainer>

        <SelectionContainer>
          <CustomTextField
            width={220}
            height={38}
            placeholder={i18n.t("account.withdrawal.enterAmount")}
            handleTextFieldChange={this.handleAmountChange}
            variant="outlined"
            autoComplete="off"
            value={this.state.amount}
            pattern="[0-9]*"
            type={"number"}
            maxLength={12}
          ></CustomTextField>
          <LimitPopupContainer>
            {Common.formatNumberWithThousand(this.state.min)} -{" "}
            {Common.formatNumberWithThousand(this.state.max)}
          </LimitPopupContainer>
          {/* <LimitPopupContainer>
            {i18n.t("withdrawal.msgWithdrawalAmount")}
          </LimitPopupContainer> */}
        </SelectionContainer>

        {(() => {
          if (config.features.WithdrawalPassword) {
            return (
              <React.Fragment>
                <SplitLine></SplitLine>
                <SubHeaderContainer>
                  <NumberContainer>3</NumberContainer>

                  <SubTitle>
                    {i18n.t("account.withdrawal.msgEnterWithdrawalPass")}
                  </SubTitle>
                </SubHeaderContainer>
                <SelectionContainer>
                  <CustomTextField
                    marginTop={10}
                    type={"password"}
                    placeholder={i18n.t(
                      "account.withdrawal.msgEnterWithdrawalPass"
                    )}
                    width={220}
                    height={38}
                    float={"none"}
                    value={this.state.password}
                    handleTextFieldChange={this.handlePasswordChange}
                  ></CustomTextField>
                </SelectionContainer>
              </React.Fragment>
            );
          }
        })()}

        <Grid container justify="center">
          <ContainerForButton item xs={12} md={3}>
            <CustomCustomButton
              disabled={
                parseFloat(this.state.amount) < this.state.min ||
                parseFloat(this.state.amount) > this.state.max ||
                this.state.disabled
              }
              grayMode={
                parseFloat(this.state.amount) < this.state.min ||
                parseFloat(this.state.amount) > this.state.max ||
                this.state.disabled
              }
              name={i18n.t("common.nextStep")}
              marginTop={0}
              handleClick={this.handleWithdrawalClick}
            ></CustomCustomButton>
          </ContainerForButton>
        </Grid>
      </>
    );
  }
}
export default Main;
