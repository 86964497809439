export const themeRed = {
  variant: "red",
  primary: "#c40511",
  primaryRgb: "196, 5, 17",
  primaryText: "#fff",
  primaryGradient: "#91030c",
  primaryGradientRgb: "145, 3, 12",
  primaryHightlight: "#fde73f",
  secondary: "#404040",
  secondaryText: "rgba(0,0,0,0.5)",
  tertiary: "rgba(255, 255, 255, 0.5)",
  tertiaryText: "rgba(255, 255, 255, 0.5)",

  alternatePrimary: "",
};

export const themeBlue = {
  variant: "blue",
  primary: "#1b86c7",
  primaryRgb: "27, 134, 199",
  primaryText: "#fff",
  primaryGradient: "#044BA3",
  primaryGradientRgb: "4, 75, 163",
  primaryHightlight: "#fde73f",
  secondary: "#404040",
  secondaryText: "rgba(0,0,0,0.5)",
  tertiary: "rgba(255, 255, 255, 0.5)",
  tertiaryText: "rgba(255, 255, 255, 0.5)",

  alternatePrimary: "",
};

export const themeGray = {
  variant: "gray",
  primary: "#3C3C3C",
  primaryRgb: "60, 60, 60",
  primaryText: "#fff",
  primaryGradient: "#3C3C3C",
  primaryGradientRgb: "60, 60, 60",
  primaryHightlight: "#fde73f",
  secondary: "#404040",
  secondaryText: "rgba(0,0,0,0.5)",
  tertiary: "rgba(255, 255, 255, 0.5)",
  tertiaryText: "rgba(255, 255, 255, 0.5)",

  alternatePrimary: "#3C3C3C",
};

export const themeList = {
  red: themeRed,
  blue: themeBlue,
  gray: themeGray,
};
