import QRCode from "qrcode.react";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import i18n from "../../../utils/i18n";
import { useSnackbar } from "notistack";
import {
  requestClipboardWritePermission,
  copyBlobToClipboard,
} from "copy-image-clipboard";

const QRCodeContainer = styled.div`
  background: ${({ theme }) => theme.primary};
  padding: 5px;
  border-radius: 5px;
`;

const ButtonPlacer = styled.div`
  display: flex;

  margin-top: -5px;
`;

const Button = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  padding: 5px;
  background: white;
  :hover {
    background: ${({ theme }) => theme.primary};
    color: ${({ theme }) => theme.primaryText};
  }
`;

const GenerateQRCode = ({ link }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [canCopy, setCanCopy] = useState(false);
  const [url, setUrl] = useState("");
  const downloadQR = () => {
    const canvas = document.getElementById("qrcode");
    if (canCopy) {
      canvas.toBlob(copyToClipboard, "image/png", 1);
    } else {
      const pngUrl = canvas.toDataURL("image/jpg");

      const w = window.open("about:blank", "image from canvas");
      w.document.write("<img src='" + pngUrl + "' alt='from canvas'/>");

      // let downloadLink = document.createElement("a");
      // downloadLink.href = pngUrl;
      // downloadLink.download = "qrcode.png";
      // document.body.appendChild(downloadLink);
      // downloadLink.click();
      // document.body.removeChild(downloadLink);
    }
  };
  useEffect(() => {
    requestClipboardWritePermission().then((hasPermission) => {
      setCanCopy(hasPermission);
      if (hasPermission === false) {
        const canvas = document.getElementById("qrcode");
        const pngUrl = canvas.toDataURL("image/png");

        setUrl(pngUrl);
      }
    });
  }, []);

  const copyToClipboard = (pngBlob) => {
    copyBlobToClipboard(pngBlob);
    enqueueSnackbar(i18n.t("account.header.copySuccess"), {
      variant: "success",
    });
  };

  function copyText(e) {
    var textField = document.createElement("textarea");
    textField.innerText = e;
    document.body.appendChild(textField);
    textField.select();
    document.execCommand("copy");
    textField.remove();
    enqueueSnackbar(i18n.t("account.header.copySuccess"), {
      variant: "success",
    });
  }
  return (
    <QRCodeContainer>
      <div>
        <QRCode
          id="qrcode"
          value={link}
          size={200}
          level={"H"}
          includeMargin={true}
        />
      </div>
      <ButtonPlacer>
        {(() => {
          return (
            <Button onClick={downloadQR}>
              {i18n.t("account.channel.copy")}
            </Button>
          );
        })()}
      </ButtonPlacer>
    </QRCodeContainer>
  );
};

export default GenerateQRCode;
