import React, { useState } from "react";

import styled from "styled-components";
import CustomTextField from "../../../Share/TextField";
import CustomButton from "../../../Share/Button";
import Password1IconPNG from "../../../../assets/images/password1-icon.png";
import Password2IconPNG from "../../../../assets/images/password2-icon.png";
import IconInputNewpassPNG from "../../../../assets/images/icon-input-newpass.png";
import { AccountService } from "../../../../services/AccountService";
import { withSnackbar, useSnackbar } from "notistack";
import Loading from "../../Loading";
import i18n from "../../../../utils/i18n";
import ErrorSwitcher from "../../../../utils/ErrorSwitcher";

const Container = styled.div``;

const PasswordField = styled.div`
  margin: 8px 0px;
`;

let local_props = {
  old_password: "",
  password: "",
  rpassword: "",
};

const onhandleOldPassChange = (e) => {
  local_props.old_password = e.target.value;
};
const onhandlePass1Change = (e) => {
  local_props.password = e.target.value;
};
const onhandlePass2Change = (e) => {
  local_props.rpassword = e.target.value;
};

const ChangePassword = (props) => {
  const [isClicked, setIsClicked] = useState(false);

  const { enqueueSnackbar } = useSnackbar();
  const onhandleClick = () => {
    if (
      local_props.password === null ||
      local_props.rpassword === null ||
      local_props.password === "" ||
      local_props.rpassword === "" ||
      local_props.old_password === null ||
      local_props.old_password === ""
    ) {
      props.enqueueSnackbar(i18n.t("toast.passwordCantEmpty"), {
        variant: "warning",
      });
    } else {
      setIsClicked(true);
      var expr = /^.{6,20}$/;
      if (!expr.test(local_props.password)) {
        setIsClicked(false);
        enqueueSnackbar(i18n.t("common.msgRegisterPassInvalid"), {
          variant: "warning",
        });
        return;
      }
      if (local_props.password !== local_props.rpassword) {
        setIsClicked(false);
        enqueueSnackbar(i18n.t("toast.passwordNotSame"), {
          variant: "warning",
        });
      } else {
        AccountService.UpdatePassword(
          local_props.old_password,
          local_props.password
        ).then((response) => {
          if (response.IsSuccess) {
            // props.dispatch({
            //   type: UPDATE_INFO,
            //   payload: {
            //     member_address: local_props.member_address,
            //     member_address_surename: local_props.member_address_surename,
            //     member_address_phone: local_props.member_address_phone
            //   }
            // });
            setIsClicked(false);
            props.enqueueSnackbar(
              i18n.t("account.safety.dialog.memberPassword.resetSuccess"),
              {
                variant: "success",
              }
            );
            props.handleCloseDialog();
          } else {
            setIsClicked(false);
            props.enqueueSnackbar(ErrorSwitcher.getTranslation(response.info), {
              variant: "error",
            });
          }
        });
      }
    }
  };
  return (
    <Container>
      <PasswordField>
        <CustomTextField
          placeholder={i18n.t("account.safety.dialog.memberPassword.oldPass")}
          adornment={Password1IconPNG}
          handleTextFieldChange={onhandleOldPassChange}
          type="password"
        ></CustomTextField>
      </PasswordField>
      <PasswordField>
        <CustomTextField
          placeholder={i18n.t("account.safety.dialog.memberPassword.newPass")}
          adornment={IconInputNewpassPNG}
          handleTextFieldChange={onhandlePass1Change}
          type="password"
        ></CustomTextField>
      </PasswordField>
      <PasswordField>
        <CustomTextField
          placeholder={i18n.t(
            "account.safety.dialog.memberPassword.confirmNewPass"
          )}
          adornment={Password2IconPNG}
          handleTextFieldChange={onhandlePass2Change}
          type="password"
        ></CustomTextField>
      </PasswordField>
      <CustomButton
        name={i18n.t("account.safety.dialog.submit")}
        handleClick={onhandleClick}
      ></CustomButton>
      <Loading show={isClicked} />
    </Container>
  );
};

export default withSnackbar(ChangePassword);
