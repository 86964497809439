import React, { Component } from "react";

import styled from "styled-components";
import IconDepositRedPNG from "../../../assets/images/icon-deposit-red.png";
import UnderConstructionPNG from "../../../assets/images/underconstruction.png";
const Container = styled.div`
  padding: 20px 28px;
  box-sizing: border-box
  background-color: #e9e9e9;
  width: 100%;
  min-height: 611px;
 

`;
const Title = styled.div`
  color: #c0252d;
`;
const TitleIcon = styled.div`
  margin-top: 4px;
  box-sizing: border-box;
  width: 24px;
  height: 16px;
  background: url(${IconDepositRedPNG}) no-repeat;

  float: left;
`;
const Wrap = styled.div`
  width: 935px;
  height: 100%;
  min-height: 611px;
  margin-top: 15px;
  background: url(${UnderConstructionPNG}) no-repeat center 50px #fff;
`;
const DescLabel = styled.div`
  margin: 330px auto 0 auto;
  text-align: center;
`;
class UnderConstruction extends Component {
  render() {
    return (
      <Container>
        <Title>
          <TitleIcon></TitleIcon>
          存款
        </Title>
        <Wrap>
          <DescLabel>存款系统维护中，请稍后再试...</DescLabel>
        </Wrap>
      </Container>
    );
  }
}
// const mapStateToProps = state => {
//   return {
//     // screenWidth: state.ui.screenWidth,
//     // screenHeight: state.ui.screenHeight,
//     account: state.account
//   };
// };
export default UnderConstruction;
