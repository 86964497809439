import React from "react";
import i18n from "../../../utils/i18n";
import styled from "styled-components";

const DateRangeSelectorContainer = styled.select`
  background: url("data:image/svg+xml,<svg height='10px' width='10px' viewBox='0 0 16 16' fill='%23000000' xmlns='http://www.w3.org/2000/svg'><path d='M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z'/></svg>")
    no-repeat;
  background-position: calc(100%) center;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  border: none;
  outline: none;
  height: 40px;
  min-width: 50px;
  padding: 10px;
`;

const SelectWrapper = styled.div`
  border-radius: 4px;
  border: solid 1px #b6b6b6;
  margin-left: 10px;
  height: 40px;
  background: #fff;
  padding: 0 10px 0 5px;

  @media (max-width: 600px) {
    width: 100%;
    margin-left: 0px;
  }
`;

const DateRangeSelector = ({ dateRange, handleChange }) => {
  return (
    <SelectWrapper>
      <DateRangeSelectorContainer value={dateRange} onChange={handleChange}>
        <option value="yesterday">
          {i18n.t("daterangeselector.yesterday")}
        </option>
        <option value="today">{i18n.t("daterangeselector.today")}</option>
        <option value="lastWeek">{i18n.t("daterangeselector.lastWeek")}</option>
        <option value="thisWeek">{i18n.t("daterangeselector.thisWeek")}</option>
        <option value="lastMonth">
          {i18n.t("daterangeselector.lastMonth")}
        </option>
        <option value="thisMonth">
          {i18n.t("daterangeselector.thisMonth")}
        </option>
      </DateRangeSelectorContainer>
    </SelectWrapper>
  );
};

export default DateRangeSelector;
