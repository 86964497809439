import { ApiService } from "./ApiService";

export const InboxService = {
  InboxList,
  UpdateReadMessage,
  DeleteMessage
};

function InboxList(token, page, limit) {
  const result = ApiService.post(
    "member/message",
    {
      token,
      page,
      limit
    },
    true
  );

  return result;
}

function UpdateReadMessage(token, id) {
  const result = ApiService.post(
    "member/messageRead",
    {
      token,
      id
    },
    true
  );

  return result;
}
function DeleteMessage(token, id) {
  const result = ApiService.post(
    "member/messageDelete",
    {
      token,
      id
    },
    true
  );

  return result;
}
