import React from "react";
import styled from "styled-components";

function CustomButton(props) {
  const StyleButton = styled.div`
    width: ${props.width ? props.width + "px" : "100%"};
    padding: 0px 8px;
    height: ${props.small ? "32" : "46"}px;
    border: solid 1px #b6b6b6;
    margin-top: ${props.marginTop ? props.marginTop : "12"}px;

    ${({ theme }) =>
      props.grayMode
        ? "    background-blend-mode: multiply, normal; background-image:  linear-gradient(to bottom, #bfbfbf, #bfbfbf), linear-gradient(to top, #797979, #c3c3c3);"
        : "  background-image: linear-gradient(to top, " +
          theme.primaryGradient +
          ", " +
          theme.primary +
          ");"}

    border-radius: 5px;
    font-size: ${props.small ? "14" : "16"}px;
    color: #fff;
    ${props.float ? "float: " + props.float + ";" : " float: left;"}
    ${props.marginLeft ? "margin-left: " + props.marginLeft + "px;" : ""}
    ${props.marginRight ? "margin-right: " + props.marginRight + "px;" : ""}
    ${props.disabled === true
      ? `
      pointer-events: none;
      cursor: not-allowed;`
      : `
    &:hover {
      cursor: pointer;
      background-blend-mode: screen, normal;
      ${
        props.grayMode
          ? "   background-image: linear-gradient(to top, #797979, #c3c3c3);"
          : "  box-shadow: rgb(255 255 255 / 75%) 1px 1px 9px 0px inset;"
      }
    }
    `}
  `;
  const ButtonLabel = styled.span`
    text-align: center;
    display: block;
    margin-top: ${props.small ? "5" : "10"}px;
  `;
  return (
    <StyleButton onClick={props.handleClick}>
      <ButtonLabel>{props.name}</ButtonLabel>
    </StyleButton>
  );
}
export default CustomButton;
