import {
    GET_AFFILIATE_BEGIN,
    GET_AFFILIATE_SUCCESS,
    GET_AFFILIATE_FAILURE
  } from "../actions/affiliateAction.js";
  
  const initState = {
    lists: [
        {id: "",}
    ]
  };
  
  const affiliateReducer = (state = initState, action) => {
    switch (action.type) {
      case GET_AFFILIATE_SUCCESS:
        return {
         
          ...state,
          lists: [
            ...action.payload.data
          ],
         
        };
      default:
        return state;
    }
  };
  
  export default affiliateReducer;
  