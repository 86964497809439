import React, { Component } from "react";
import { connect } from "react-redux";
import styled from "styled-components";

import CustomButton from "../../../Share/Button";
import TextField from "@material-ui/core/TextField";
import IconInputVerifyCodePNG from "../../../../assets/images/icon-input-verifycode.png";
import { withStyles } from "@material-ui/core/styles";
import { AccountService } from "../../../../services/AccountService";

import Select from "@material-ui/core/Select";

import FormControl from "@material-ui/core/FormControl";

import { accountAction } from "../../../../actions/accountAction";
import { withSnackbar } from "notistack";
import Loading from "../../Loading";
import i18n from "../../../../utils/i18n";
import { Grid } from "@material-ui/core";
const Container = styled.div``;
const CustomText = withStyles({
  root: {
    marginTop: "10px",
    float: "left",
    backgroundColor: "#fff",
    "& .MuiOutlinedInput-input": {
      fontSize: "14px",
    },
    "& .MuiOutlinedInput-input[readonly]": {
      color: "#b6b6b6",
    },
    "& label.Mui-focused": {
      color: "#b6b6b6",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#b6b6b6",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#b6b6b6",
      },
      "&:hover fieldset": {
        borderColor: "#b6b6b6",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#b6b6b6",
      },
    },
  },
})(TextField);
const StyleButton = styled.div`
 width:100%;
  height: 44px;
  border: solid 1px #b6b6b6;
  margin: 0;
  margin-top: 10px;
 
  float: left;

  ${(props) =>
    props.disable === true
      ? `  background-image: linear-gradient(90deg, rgba(89,89,89,1) 0%, rgba(126,126,126,1) 100%);`
      : `  background-image: linear-gradient(
    90deg,
    rgb(15, 123, 154) 0%,
    rgb(59, 171, 204) 100%
  );
`}
white-space: nowrap;
overflow: hidden;
text-overflow: ellipsis;
  border-radius: 5px;
  font-size: 16px;
  color: #fff;

  &:hover {
    cursor: pointer;
    ${(props) =>
      props.disable === true
        ? `  background-image: linear-gradient(90deg, rgba(89,89,89,1) 0%, rgba(126,126,126,1) 100%);`
        : `      background-blend-mode: screen, normal;
        background-image: linear-gradient(
            to bottom,
            rgba(59, 171, 204, 0.08),
            rgba(59, 171, 204, 0.08)
          ),
          linear-gradient(to top, #3babcc, #3babcc);
        }
  `}
`;
const ButtonLabel = styled.span`
  text-align: center;
  display: block;
  margin-top: 10px;
`;
const Message = styled.div`
  color: red;
  float: left;
  font-size: 13px;
  margin: 10px 0 0 0;
  width: 100%;
  text-align: left;
`;

class PhoneVerification extends Component {
  state = {
    phone: this.props.phone,
    code: "",
    step: 1,
    isNew: this.props.phone === "" ? true : false,
    message:
      this.props.phone === ""
        ? i18n.t("account.safety.dialog.phoneVerify.dontWorry")
        : "",
    prefix: "+86",
    countdown: 0,
    isClicked: false,
  };
  timer = null;
  componentWillUnmount() {
    if (this.timer != null) {
      clearTimeout(this.timer);
    }
  }
  onhandlePhoneChange = (e) => {
    this.setState({ ...this.state, phone: e.target.value });
  };
  handlePrefixChange = (e) => {
    this.setState({ ...this.state, prefix: e.target.value });
  };

  handleNumberChange = (e) => {
    this.setState({ ...this.state, number: e.target.value });
  };
  onhandleCodeChange = (e) => {
    this.setState({ ...this.state, code: e.target.value });
  };
  onhandleGetCodeClick = () => {
    this.setState({
      isClicked: true,
    });
    if (this.state.countdown <= 0) {
      let events = "";
      if (this.props.isForSecurityQuestion) {
        events = "resetQuestion";
      } else {
        if (this.props.isForWithdrawalPassword) {
          events = "resetWithdrawalPassword";
        } else {
          if (this.state.isNew) {
            events = "bind";
          } else {
            if (this.state.step === 1) {
              events = "check";
            } else if (this.state.step === 2) {
              events = "bind";
            }
          }
        }
      }

      AccountService.GetPhoneVerificationCode(
        this.props.token,
        this.state.phone,
        events
      ).then((response) => {
        // this.setState({ ...this.state, message: response.msg });
        if (response.IsSuccess) {
          this.props.enqueueSnackbar(response.msg, {
            variant: "info",
          });
          this.setState({
            isClicked: false,
          });
        } else {
          this.props.enqueueSnackbar(response.msg, {
            variant: "error",
          });
          this.setState({
            isClicked: false,
          });
        }
        this.setState({ ...this.state, countdown: response.data });
        this.checkCountdown();
      });
    }
  };
  checkCountdown = () => {
    if (this.state.countdown > 0) {
      this.timer = setTimeout(() => {
        this.setState({ ...this.state, countdown: this.state.countdown - 1 });
        this.checkCountdown();
      }, 1000);
    }
  };
  onhandleClick = () => {
    if (
      this.state.phone === "" ||
      this.state.phone === null ||
      this.state.code === "" ||
      this.state.code === null
    ) {
      this.props.enqueueSnackbar(i18n.t("toast.phoneCantEmpty"), {
        variant: "warning",
      });
    } else {
      this.setState({
        isClicked: true,
      });
      if (this.state.isNew) {
        AccountService.UpdatePhone(
          this.props.token,
          this.state.phone,
          this.state.code
        ).then((response) => {
          if (response.IsSuccess) {
            this.props.getAccountProfile(this.props.token);
            this.props.handleCloseDialog();
            this.props.enqueueSnackbar(response.msg, {
              variant: "info",
            });
            this.setState({
              isClicked: false,
            });
          } else {
            this.props.enqueueSnackbar(response.msg, {
              variant: "error",
            });
            this.setState({
              isClicked: false,
            });
          }
        });
      } else {
        if (this.props.isForSecurityQuestion) {
          AccountService.VerifySecurityQuestionByPhone(
            this.props.token,
            this.state.code
          ).then((response) => {
            if (response.IsSuccess) {
              this.props.onhandleVerifySuccess(
                this.props.isForSecurityQuestion,
                this.props.isForWithdrawalPassword
              );
              this.setState({
                isClicked: false,
              });
            } else {
              this.props.enqueueSnackbar(response.msg, {
                variant: "error",
              });
              this.setState({
                isClicked: false,
              });
              // this.setState({ ...this.state, message: response.msg });
            }
          });
        } else {
          if (this.props.isForWithdrawalPassword) {
            AccountService.VerifyWithdrawalPasswordByPhone(
              this.props.token,
              this.state.code
            ).then((response) => {
              if (response.IsSuccess) {
                this.props.onhandleVerifySuccess(
                  this.props.isForSecurityQuestion,
                  this.props.isForWithdrawalPassword
                );
                this.setState({
                  isClicked: false,
                });
              } else {
                this.props.enqueueSnackbar(response.msg, {
                  variant: "error",
                });
                this.setState({
                  isClicked: false,
                });
                // this.setState({ ...this.state, message: response.msg });
              }
            });
          } else {
            if (this.state.step === 1) {
              AccountService.UpdatePhoneStep1(
                this.props.token,
                this.state.code
              ).then((response) => {
                if (response.IsSuccess) {
                  this.props.enqueueSnackbar(response.msg, {
                    variant: "info",
                  });
                  this.setState({
                    ...this.state,
                    code: "",
                    phone: "",
                    step: 2,
                    countdown: 0,
                    isClicked: false,
                  });
                } else {
                  this.props.enqueueSnackbar(response.msg, {
                    variant: "error",
                  });
                  this.setState({
                    isClicked: false,
                  });
                  // this.setState({ ...this.state, message: response.msg });
                }
              });
            } else if (this.state.step === 2) {
              AccountService.UpdatePhoneStep2(
                this.props.token,
                this.state.phone,
                this.state.code
              ).then((response) => {
                if (response.IsSuccess) {
                  this.props.getAccountProfile(this.props.token);
                  this.props.handleCloseDialog();
                  this.props.enqueueSnackbar(response.msg, {
                    variant: "info",
                  });
                  this.setState({
                    isClicked: false,
                  });
                } else {
                  this.props.enqueueSnackbar(response.msg, {
                    variant: "error",
                  });
                  this.setState({
                    isClicked: false,
                  });
                }
              });
            }
          }
        }
      }
    }
  };

  render() {
    return (
      <Container>
        <Grid container justify="space-between">
          <Grid item xs={4}>
            <FormControl
              fullWidth
              style={{
                float: "left",
                height: "46px",
                marginTop: "10px",
                paddingRight: 8,
                marginRight: "5px",
              }}
            >
              <Select
                value={this.state.prefix}
                onChange={this.handlePrefixChange}
                variant="outlined"
                native
                style={{
                  backgroundColor: "#fff",
                }}
              >
                <option value={"+86"}>+86</option>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={8}>
            <CustomText
              placeholder={i18n.t(
                "account.safety.dialog.phoneVerify.phonePlaceholder"
              )}
              onChange={this.onhandlePhoneChange}
              value={this.state.phone}
              fullWidth
              inputProps={{
                maxLength: 20,
              }}
              style={{
                height: "46px",
              }}
              variant="outlined"
              autoComplete="off"
              disabled={this.state.isNew === false && this.state.step === 1}
            ></CustomText>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={8} style={{ paddingRight: 8 }}>
            <CustomText
              placeholder={i18n.t(
                "account.safety.dialog.phoneVerify.verificationCode"
              )}
              fullWidth
              adornment={IconInputVerifyCodePNG}
              onChange={this.onhandleCodeChange}
              onInput={(e) => {
                e.target.value = Math.max(0, parseInt(e.target.value))
                  .toString()
                  .slice(0, 6);
              }}
              variant="outlined"
              autoComplete="off"
              value={this.state.code}
              type={"number"}
              style={{
                height: "46px",
              }}
            ></CustomText>
          </Grid>
          <Grid item xs={4}>
            <StyleButton
              onClick={this.onhandleGetCodeClick}
              disable={this.state.countdown > 0}
              fullWidth
            >
              <ButtonLabel>
                {this.state.countdown <= 0
                  ? i18n.t("account.safety.dialog.phoneVerify.requestCode")
                  : i18n.t("account.safety.dialog.phoneVerify.requestAgain") +
                    "(" +
                    this.state.countdown +
                    "s)"}
              </ButtonLabel>
            </StyleButton>
          </Grid>
        </Grid>

        <Message>{this.state.message}</Message>
        <CustomButton
          name={i18n.t("account.safety.dialog.submit")}
          handleClick={this.onhandleClick}
          marginTop={10}
        ></CustomButton>
        <Loading show={this.state.isClicked} />
      </Container>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getAccountProfile: (token) =>
      dispatch(accountAction.getAccountProfile(token, true)),
    dispatchFunction: (json) => dispatch(json),
  };
};
export default connect(
  null,
  mapDispatchToProps
)(withSnackbar(PhoneVerification));
