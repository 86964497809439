import React, { useEffect, useState } from "react";
import styled from "styled-components";
import i18n from "../../../../../../utils/i18n";
import ReportDatepicker from "../../../../Report/ReportDatepicker";
import addDays from "date-fns/addDays";
import {
  NoDataContainer,
  StyledTableCell,
  StyledTableCellRight,
} from "../../../../../Share/General";

import {
  Table,
  TableRow,
  TableBody,
  TableHead,
  TableContainer,
} from "@material-ui/core";
import moment from "moment";
import CustomPagination from "../../../../../Share/CustomPagination";
import Common from "../../../../../../utils/Common";
const ActionZone = styled.div`
  display: flex;
  align-items: center;
  padding: 10px;
  flex: 1;
  width: 100%;
  @media (max-width: 600px) {
    flex-direction: column;
  }
`;

const CustomSelect = styled.select`
  height: 40px;
  border: solid 1px #b6b6b6;
  border-radius: 4px;
  margin-right: 8px;
  display: flex;
  align-items: center;
  padding: 0px 8px;
  @media (max-width: 600px) {
    width: 100%;
    margin-right: 0px;
    margin-bottom: 10px;
  }
`;

const CustomTableContainer = styled(TableContainer)``;

const CustomButton = styled.div`
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  border: solid 1px #b6b6b6;
  padding: 0 8px;
  background-image: linear-gradient(
    to top,
    ${(props) => props.theme.primaryGradient},
    ${(props) => props.theme.primary}
  );
  color: ${(props) => props.theme.primaryText};
  margin-left: 8px;
  min-width: 75px;
  cursor: pointer;
  user-select: none;
`;

const TabChangesRecord = ({
  uid,
  category,
  myDownlineChangesReport,
  performFastGetAccountChangesReport,
}) => {
  const [dateFrom, setDateFrom] = useState(
    moment(addDays(new Date(), -3)).format("YYYY-MM-DD")
  );
  const [dateTo, setDateTo] = useState(moment(new Date()).format("YYYY-MM-DD"));
  const [reportCategory, setReportCategory] = useState("");
  const [page, setPage] = useState(1);

  function handleDateFrom(newDate) {
    setDateFrom(newDate);
  }
  function handleDateTo(newDate) {
    setDateTo(newDate);
  }

  function handleNextPage() {
    if (Math.ceil(myDownlineChangesReport.totalcount / 10) > page) {
      setPage(page + 1);
    } else {
    }
  }

  function handlePrevPage() {
    if (page <= 1) {
    } else {
      setPage(page - 1);
    }
  }

  useEffect(() => {
    performFastGetAccountChangesReport(
      dateFrom,
      dateTo,
      reportCategory,
      page,
      uid
    );
  }, [page]);

  useEffect(() => {
    if (moment(dateTo).isBefore(dateFrom)) {
      setDateTo(dateFrom);
    }
  });

  const checkDataType = (data) => {
    switch (data) {
      case 6100:
        return i18n.t("transferout");
      case 6200:
        return data;
      case 6300:
        return i18n.t("transferoutfailed");
      case 6500:
        return i18n.t("transferin");
      case 6600:
        return i18n.t("transferinfailed");
      case 4200:
        return i18n.t("account.agentInfo.transactionRecord.type.Dividend");
      case 2100:
        return i18n.t("account.agentInfo.transactionRecord.type.Withdrawal");
      default:
        return "";
    }
  };

  return (
    <div>
      <ActionZone>
        <CustomSelect
          onChange={(e) => {
            setReportCategory(e.target.value);
          }}
        >
          <option value="">
            {i18n.t("account.agentInfo.transactionRecord.transactionType")}
          </option>
          {Object.keys(category).map(function (key, index) {
            return (
              <option value={key}>
                {i18n.t(
                  `account.agentInfo.transactionRecord.type.${category[key]}`
                )}
              </option>
            );
          })}
        </CustomSelect>

        <ReportDatepicker
          selectedDateFrom={dateFrom}
          selectedDateTo={dateTo}
          handleDateTo={handleDateTo}
          handleDateFrom={handleDateFrom}
        />
        <CustomButton
          onClick={() => {
            performFastGetAccountChangesReport(
              dateFrom,
              dateTo,
              reportCategory,
              1,
              uid
            );
          }}
        >
          {i18n.t("report.search")}
        </CustomButton>
      </ActionZone>
      <CustomTableContainer>
        <Table aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell>
                {i18n.t("account.agentInfo.transactionRecord.recordedOn")}
              </StyledTableCell>
              <StyledTableCellRight>
                {i18n.t("account.agentInfo.transactionRecord.transactionType")}
              </StyledTableCellRight>
              <StyledTableCellRight>
                {i18n.t("account.agentInfo.transactionRecord.amount")}
              </StyledTableCellRight>
              <StyledTableCellRight>
                {i18n.t("account.agentInfo.transactionRecord.balance")}
              </StyledTableCellRight>
            </TableRow>
          </TableHead>

          {(() => {
            if (myDownlineChangesReport !== null) {
              return (
                <TableBody style={{ backgroundColor: "#fff" }}>
                  {myDownlineChangesReport.items.length !== 0 ? (
                    myDownlineChangesReport.items
                      .sort((a, b) => {
                        var dateA = new Date(a.recordedOn),
                          dateB = new Date(b.recordedOn);
                        return dateB - dateA;
                      })
                      .map((data, index) => {
                        return (
                          <TableRow>
                            <StyledTableCell>
                              {moment(data.recordedOn).format(
                                "YYYY-MM-DD HH:mm:ss"
                              )}
                            </StyledTableCell>
                            <StyledTableCellRight>
                              {i18n.t(
                                `account.agentInfo.transactionRecord.type.${
                                  category[data.transactionType]
                                }`
                              )}
                            </StyledTableCellRight>
                            <StyledTableCellRight>
                              {Common.formatNumberWithCommasNoRounding(
                                data.amount
                              )}
                            </StyledTableCellRight>
                            <StyledTableCellRight>
                              {Common.formatNumberWithCommasNoRounding(
                                data.balance
                              )}
                            </StyledTableCellRight>
                          </TableRow>
                        );
                      })
                  ) : (
                    <TableRow>
                      <NoDataContainer colSpan={4}>
                        {i18n.t("common.nodata")}
                      </NoDataContainer>
                    </TableRow>
                  )}
                </TableBody>
              );
            }
          })()}
        </Table>
      </CustomTableContainer>
      {myDownlineChangesReport && myDownlineChangesReport.totalcount > 10 && (
        <CustomPagination
          total={myDownlineChangesReport.totalcount}
          page={page}
          next={handleNextPage}
          prev={handlePrevPage}
        />
      )}
    </div>
  );
};

export default TabChangesRecord;
