import React, { Component } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import CustomButton from "../../../Share/Button";
import Select from "@material-ui/core/Select";

import FormControl from "@material-ui/core/FormControl";
import { AccountService } from "../../../../services/AccountService";
import { UPDATE_INFO } from "../../../../actions/accountAction";
import { withSnackbar } from "notistack";
import { withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Loading from "../../Loading";
import i18n from "../../../../utils/i18n";
import { Grid } from "@material-ui/core";
const Container = styled.div``;
const Message = styled.div`
  color: red;
  float: left;
  font-size: 13px;
  margin: 10px 0 0 0;
  width: 100%;
  text-align: left;
`;
const CustomText = withStyles({
  root: {
    marginTop: "10px",
    float: "left",
    backgroundColor: "#fff",
    "& .MuiOutlinedInput-input": {
      fontSize: "14px",
    },
    "& .MuiOutlinedInput-input[readonly]": {
      color: "#6e6e6e",
    },
    "& label.Mui-focused": {
      color: "#b6b6b6",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#b6b6b6",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#b6b6b6",
      },
      "&:hover fieldset": {
        borderColor: "#b6b6b6",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#b6b6b6",
      },
    },
  },
})(TextField);
class SecurityQuestion extends Component {
  state = {
    answer: "",
    message: i18n.t("account.safety.dialog.securityQuestion.dontWorry"),
    questionList: [],
    question: this.props.memberQuestion,
    isClicked: false,
  };
  componentDidMount() {
    AccountService.GetSecurityQuestion(this.props.token).then((response) => {
      if (response.IsSuccess) {
        this.setState({ ...this.state, questionList: response.url });
      } else {
        this.props.enqueueSnackbar(response.msg, {
          variant: "error",
        });
      }
    });
  }

  handleAnswerChange = (e) => {
    this.setState({ ...this.state, answer: e.target.value });
  };
  handleQuestionChange = (e) => {
    this.setState({ ...this.state, question: e.target.value });
  };
  onhandleClick = () => {
    if (
      this.state.question === null ||
      this.state.question === "" ||
      this.state.answer === null ||
      this.state.answer === ""
    ) {
      this.props.enqueueSnackbar(i18n.t("toast.questionCAntEmpty"), {
        variant: "error",
      });
    } else {
      this.setState({
        isClicked: true,
      });
      if (this.props.isVerify) {
        AccountService.VerifySecurityQuestion(
          this.props.token,
          this.state.answer
        ).then((response) => {
          if (response.IsSuccess) {
            this.props.enqueueSnackbar(response.msg, {
              variant: "success",
            });
            this.setState({ ...this.state, answer: "" });
            this.props.onVerifySuccess();
            this.setState({
              isClicked: false,
            });
          } else {
            this.props.enqueueSnackbar(response.msg, {
              variant: "error",
            });
            this.setState({
              isClicked: false,
            });
          }
        });
      } else {
        if (this.state.question !== 0) {
          if (this.props.isReset === true) {
            AccountService.ResetSecurityQuestion(
              this.props.token,
              this.state.question,
              this.state.answer
            ).then((response) => {
              if (response.IsSuccess) {
                this.setState({
                  isClicked: false,
                });
                this.props.dispatch({
                  type: UPDATE_INFO,
                  payload: {
                    member_question: this.state.question,
                  },
                });
                this.props.enqueueSnackbar(response.msg, {
                  variant: "success",
                });
                this.props.handleCloseDialog();
              } else {
                this.setState({
                  isClicked: false,
                });
                this.props.enqueueSnackbar(response.msg, {
                  variant: "error",
                });
              }
            });
          } else {
            AccountService.UpdateSecurityQuestion(
              this.props.token,
              this.state.question,
              this.state.answer
            ).then((response) => {
              if (response.IsSuccess) {
                this.setState({
                  isClicked: false,
                });
                this.props.dispatch({
                  type: UPDATE_INFO,
                  payload: {
                    member_question: this.state.question,
                  },
                });
                this.props.enqueueSnackbar(response.msg, {
                  variant: "success",
                });
                this.props.handleCloseDialog();
              } else {
                this.setState({
                  isClicked: false,
                });
                this.props.enqueueSnackbar(response.msg, {
                  variant: "error",
                });
              }
            });
          }
        }
      }
    }
  };
  render() {
    return (
      <Container>
        <Grid container>
          <Grid item xs={12}>
            <FormControl
              fullWidth
              style={{
                float: "left",
                height: "46px",
                marginTop: "10px",
                backgroundColor: "#fff",
                marginRight: "5px",
              }}
            >
              <Select
                onChange={this.handleQuestionChange}
                variant="outlined"
                value={this.state.question}
                disabled={this.props.isVerify ? true : false}
                native
              >
                <option value={0} disabled>
                  {i18n.t(
                    "account.safety.dialog.securityQuestion.selectQuestion"
                  )}
                </option>
                {this.state.questionList.map((row) => {
                  return (
                    <option
                      key={row.id}
                      value={row.id}
                      disabled={this.props.isVerify ? true : false}
                    >
                      {row.title}
                    </option>
                  );
                })}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <CustomText
            fullWidth
            style={{
              height: "46px",
            }}
            onChange={this.handleAnswerChange}
            value={this.state.answer}
            placeholder={i18n.t(
              "account.safety.dialog.securityQuestion.answer"
            )}
            variant="outlined"
            autoComplete="off"
            type={"text"}
            inputProps={{
              maxLength: 50,
            }}
          />
        </Grid>

        <Message>{this.state.message}</Message>
        <CustomButton
          name={i18n.t("account.safety.dialog.submit")}
          handleClick={this.onhandleClick}
          marginTop={"10"}
        ></CustomButton>
        <Loading show={this.state.isClicked} />
      </Container>
    );
  }
}
export default connect(null, null)(withSnackbar(SecurityQuestion));
