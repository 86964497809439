import React, { Component } from "react";
import { connect } from "react-redux";
import { AppHistory } from "../../../history/History";
import Header from "../Account/Header";
import {
  CustomContainer,
  ReportContainer,
  FilterContainer,
  TitleContainer,
  Title,
  Content,
  CustomQuickButton,
  CustomTable,
  CustomTableHeader,
} from "../../Share/General";
import TableContainer from "@material-ui/core/TableContainer";
import styled from "styled-components";
import TablePagination from "@material-ui/core/TablePagination";
import ReportDatepicker from "./ReportDatepicker";
import { AffiliateService } from "../../../services/AffiliateService";
import i18n from "../../../utils/i18n";
import Common from "../../../utils/Common";
import { Container, Grid } from "@material-ui/core";
import addDays from "date-fns/addDays";
import { isMobile } from "react-device-detect";
import { withSnackbar } from "notistack";
import { addMonths, addWeeks } from "date-fns";
const moment = require("moment");

const AgentContainer = styled.div``;

const AgentData = styled.div`
  padding: 5px 0px;

  :last-child {
    padding-bottom: 10px;
  }
`;

const Back = styled(Grid)`
  span {
    color: #c40511;
    margin-right: 5px;
  }

  cursor: pointer;
`;

const PaginationWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const CustomSelect = styled.select`
  height: 30px;
  border: solid 1px #b6b6b6;
  border-radius: 4px;

  margin-right: 8px;
  display: flex;
  align-items: center;
  padding: 0px 8px;

  @media (max-width: 600px) {
    margin-right: 0px;
  }
`;

const CustomPageCounter = styled.div`
  font-size: 12px;
  margin-left: 12px;
`;

const Legends = styled.div`
  height: auto;
  cursor: pointer;
  padding: 20px;
  border-radius: 4px;
  align-items: center;
  display: flex;
  float: left;
  margin-bottom: 16px;

  ${({ expand }) => {
    return (
      expand &&
      `  
      border-left: solid 3px #1e67d5;
      background: #fff;
    `
    );
  }}
`;

const TheTinyI = styled.div`
  background: #1e67d5;
  color: #fff;
  font-size: 8px;
  min-width: 20px;
  min-height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100px;
`;

const TableHeader = styled.th`
  cursor: pointer;
`;

const CustomTableRow = styled.tr`
  th {
    font-weight: normal;
    text-align: left;
    padding: 5px 10px !important;
    padding-left: 20px !important;
  }
  height: 30px;

  border-bottom: 1px solid rgba(224, 224, 224, 1);
`;

class DividendReport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      type: 1,
      selected: 1,
      data: {
        totalcount: 0,
        items: [],
        total: {
          adjustmentAmount: 0,
          agentDividendAmount: 0,
          agentAdjustAmount: 0,
          companyAdjustAmount: 0,
          betAmount: 0,
          bonusAmount: 0,
          depositAmount: 0,
          fromPlayerAgentDividendAmount: 0,
          fromTeamAgentDividendAmount: 0,
          netProfit: 0,
          turnover: 0,
          validBetAmount: 0,
          winLossAmount: 0,
          withdrawalAmount: 0,
        },
      },
      selectedDateFrom: moment().startOf("isoWeek").format("YYYY/MM/DD"),
      selectedDateTo: moment(new Date()).format("YYYY/MM/DD"),
      page: 1,
      rows: 10,
      desc: true,
      sort: "",
      all: false,

      pageDetails: 1,
      rowsDetails: 10,
      descDetails: true,
      sortDetails: "",
      allDetails: false,
      selectedUserAgentDividendPercentage: "",

      data2: {
        totalcount: 0,
        items: [],
      },
      scene: "initial",
      selectedId: "",
      selectedUsername: "",
      selectedAmount: "",

      dateRange: "thisWeek",

      showLegends: false,
    };
  }

  componentDidMount() {
    const localStorageToken = localStorage.getItem(
      "token" + (isMobile ? 1 : 0)
    );
    if (localStorageToken === null) {
      AppHistory.replace("/");
    } else {
      this.changeDividendListView(this.state.page);
    }
  }
  componentDidUpdate(preProps, preState) {
    if (preState.page !== this.state.page) {
      this.changeDividendListView(this.state.page);
    }

    if (preState.data2.totalcount !== this.state.data2.totalcount) {
      this.setState({
        ...this.state,
        pageDetails: 1,
      });
    }

    if (
      preState.pageDetails !== this.state.pageDetails ||
      preState.rowsDetails !== this.state.rowsDetails
    ) {
      this.getDividendDetail(
        this.state.selectedId,
        this.state.selectedUserAgentDividendPercentage
      );
    }

    if (preState.rows !== this.state.rows) {
      this.setState({
        ...this.state,
        page: 1,
      });
      this.changeDividendListView(1);
    }

    if (preState.dateRange !== this.state.dateRange) {
      if (this.state.dateRange === "today") {
        this.setState({
          ...this.state,
          selectedDateFrom: moment(new Date()).format("YYYY/MM/DD"),
          selectedDateTo: moment(new Date()).format("YYYY/MM/DD"),
        });
      }
      if (this.state.dateRange === "yesterday") {
        this.setState({
          ...this.state,
          selectedDateFrom: moment(addDays(new Date(), -1)).format(
            "YYYY/MM/DD"
          ),
          selectedDateTo: moment(addDays(new Date(), -1)).format("YYYY/MM/DD"),
        });
      }
      if (this.state.dateRange === "thisWeek") {
        this.setState({
          ...this.state,
          selectedDateFrom: moment().startOf("isoWeek").format("YYYY/MM/DD"),
          selectedDateTo: moment(new Date()).format("YYYY/MM/DD"),
        });
      }
      if (this.state.dateRange === "lastWeek") {
        this.setState({
          ...this.state,
          selectedDateFrom: moment(addWeeks(new Date(), -1))
            .startOf("isoWeek")
            .format("YYYY/MM/DD"),
          selectedDateTo: moment(addWeeks(new Date(), -1))
            .endOf("isoWeek")
            .format("YYYY/MM/DD"),
        });
      }

      if (this.state.dateRange === "thisMonth") {
        this.setState({
          ...this.state,
          selectedDateFrom: moment(new Date())
            .startOf("months")
            .format("YYYY/MM/DD"),
          selectedDateTo: moment(new Date()).format("YYYY/MM/DD"),
        });
      }

      if (this.state.dateRange === "lastMonth") {
        this.setState({
          ...this.state,
          selectedDateFrom: moment(addMonths(new Date(), -1))
            .startOf("month")
            .format("YYYY/MM/DD"),
          selectedDateTo: moment(addMonths(new Date(), -1))
            .endOf("month")
            .format("YYYY/MM/DD"),
        });
      }
    }
  }

  changeDividendListView = async (page) => {
    if (
      Common.maxDayRangeDetector(
        this.state.selectedDateFrom,
        this.state.selectedDateTo
      )
    ) {
      this.props.enqueueSnackbar(i18n.t("common.cannotExceed31day"), {
        variant: "warning",
      });
    } else {
      await AffiliateService.getPreDividendList(
        moment.utc(new Date(this.state.selectedDateFrom)).toISOString(),
        moment
          .utc(new Date(this.state.selectedDateTo))
          .add(1, "days")
          .toISOString(),
        page,
        this.state.rows,
        this.state.desc,
        this.state.sort,
        this.state.all
      ).then((response) => {
        if (response.IsSuccess) {
          this.setState({ ...this.state, data: response.data });
        }
      });
    }
  };

  handleDateRangeChange = (e) => {
    this.setState({
      ...this.state,
      dateRange: e.target.value,
    });
  };

  getDividendDetail = async (id, dividendPercentage) => {
    if (
      Common.maxDayRangeDetector(
        this.state.selectedDateFrom,
        this.state.selectedDateTo
      )
    ) {
      this.props.enqueueSnackbar(i18n.t("common.cannotExceed31day"), {
        variant: "warning",
      });
    } else {
      await AffiliateService.getDividendDetails(
        id,
        moment.utc(new Date(this.state.selectedDateFrom)).toISOString(),
        moment
          .utc(new Date(this.state.selectedDateTo))
          .add(1, "days")
          .toISOString(),
        this.state.pageDetails,
        this.state.rowsDetails,
        this.state.descDetails,
        this.state.sortDetails,
        this.state.allDetails,
        dividendPercentage
      ).then((response) => {
        if (response.IsSuccess) {
          this.setState({
            ...this.state,
            data2: response.data,
          });
        }
      });
    }
  };

  handleDateFrom = (newDate) => {
    if (moment(this.state.selectedDateTo).isBefore(newDate)) {
      this.setState({
        selectedDateTo: newDate,
        selectedDateFrom: newDate,
      });
    } else {
      this.setState({
        ...this.state,
        selectedDateFrom: moment(new Date(newDate)).format("YYYY/MM/DD"),
      });
    }
  };
  handleDateTo = (newDate) => {
    this.setState({
      ...this.state,
      selectedDateTo: moment(new Date(newDate)).format("YYYY/MM/DD"),
    });
  };
  handleChangePage = (event, newPage) => {
    this.setState({ ...this.state, page: newPage + 1 });
  };
  handleChangePageDetails = (event, newPage) => {
    this.setState({ ...this.state, pageDetails: newPage + 1 });
  };

  checkIsSameDay = (from, to) => {
    return moment(from).isSame(to);
  };

  legends = [
    "rl_bonusAmount",
    "rl_agentCoveredBonus",
    "rl_agentCoveredBonusAdjust",
    "rl_netProfit",
    "rl_directPlayerContribution",
    "rl_teamContribution",
    "rl_profitSharingAmount",
  ];

  InitialScene = () => {
    var depositAmount = 0;
    var withdrawalAmount = 0;
    var bonusAmount = 0;
    var adjustmentAmount = 0;
    var companyAdjustAmount = 0;
    var agentAdjustAmount = 0;
    var betAmount = 0;
    var validBetAmount = 0;
    var winLossAmount = 0;
    var netProfit = 0;
    var agentDividendAmount = 0;
    var fromPlayerAgentDividendAmount = 0;
    var fromTeamAgentDividendAmount = 0;
    var bonusAdjustAmount = 0;
    var agentCoveredBonus = 0;
    var agentCoveredBonusAdjust = 0;

    const display = (data) => {
      return Common.formatNumberWithCommasNoRounding(
        this.state.data.subtotal && this.state.data.subtotal[data]
      );
    };
    return (
      <CustomContainer>
        <Container maxWidth="lg">
          <Header />
          <ReportContainer>
            <Content>
              <TitleContainer>
                <Title>{i18n.t("report.dividendReport")}</Title>
              </TitleContainer>
              <FilterContainer>
                <ReportDatepicker
                  selectedDateFrom={this.state.selectedDateFrom}
                  selectedDateTo={this.state.selectedDateTo}
                  handleDateTo={this.handleDateTo}
                  handleDateFrom={this.handleDateFrom}
                  withRangePicker={true}
                  dateRange={this.state.dateRange}
                  handleChange={this.handleDateRangeChange}
                  displayTwoRowWap={true}
                ></ReportDatepicker>

                <CustomQuickButton
                  onClick={() => {
                    this.changeDividendListView(this.state.page);
                  }}
                >
                  {i18n.t("report.search")}
                </CustomQuickButton>
              </FilterContainer>

              <Legends expand={this.state.showLegends}>
                <TheTinyI
                  onClick={() => {
                    this.setState({
                      ...this.state,
                      showLegends: !this.state.showLegends,
                    });
                  }}
                >
                  i
                </TheTinyI>
                {this.state.showLegends && (
                  <ul>
                    {this.legends.map((data, index) => {
                      return (
                        <li key={index}>{i18n.t("reportLegends." + data)}</li>
                      );
                    })}
                  </ul>
                )}
              </Legends>

              <TableContainer>
                <CustomTable lock={1}>
                  <table>
                    <thead>
                      <tr>
                        <CustomTableHeader>
                          {i18n.t("report.affliateUsername")}
                        </CustomTableHeader>
                        <CustomTableHeader class="align-right">
                          {i18n.t("report.agentDividendPercentage")}
                        </CustomTableHeader>
                        <CustomTableHeader class="align-right">
                          {i18n.t("report.bonusAmount")}
                        </CustomTableHeader>
                        <CustomTableHeader class="align-right">
                          {i18n.t("report.agentCoveredBonus")}
                        </CustomTableHeader>
                        <CustomTableHeader class="align-right">
                          {i18n.t("report.bonusAdjustAmount")}
                        </CustomTableHeader>
                        <CustomTableHeader class="align-right">
                          {i18n.t("report.agentCoveredBonusAdjust")}
                        </CustomTableHeader>
                        <CustomTableHeader class="align-right">
                          {i18n.t("report.betAmount")}
                        </CustomTableHeader>
                        <CustomTableHeader class="align-right">
                          {i18n.t("report.validBetAmount")}
                        </CustomTableHeader>
                        <CustomTableHeader class="align-right">
                          {i18n.t("report.winLossAmount")}
                        </CustomTableHeader>
                        <CustomTableHeader class="align-right">
                          {i18n.t("report.netProfit")}
                        </CustomTableHeader>
                        <CustomTableHeader class="align-right">
                          {i18n.t("report.playerContribute")}
                        </CustomTableHeader>
                        <CustomTableHeader class="align-right">
                          {i18n.t("report.teamContribute")}
                        </CustomTableHeader>
                        <CustomTableHeader class="align-right">
                          {i18n.t("report.agentAdjustmentAmount")}
                        </CustomTableHeader>
                        <CustomTableHeader class="align-right">
                          {i18n.t("report.dividend")}
                        </CustomTableHeader>
                      </tr>
                    </thead>

                    <tbody>
                      {this.state.data.mainitems &&
                        this.state.data.mainitems.map((data, index) => {
                          return (
                            <tr
                              key={index}
                              style={{
                                background: "rgb(235, 243, 254)",
                                fontWeight: "bold",
                              }}
                              onClick={() => {
                                this.getDividendDetail(
                                  data.id,
                                  data.agentDividendPercentage
                                );

                                this.setState({
                                  ...this.state,
                                  scene: "detailed",
                                  selectedId: data.id,
                                  selectedUsername: data.username,
                                  selectedUserAgentDividendPercentage:
                                    data.agentDividendPercentage,
                                });
                              }}
                            >
                              <TableHeader
                                style={{
                                  background: "rgb(235, 243, 254)",
                                  fontWeight: "bold",
                                }}
                              >
                                {data.username}
                              </TableHeader>
                              <td class="align-right">
                                {Common.formatNumberWithCommasNoRounding(
                                  data.agentDividendPercentage
                                )}
                                %
                              </td>
                              <td class="align-right">
                                {Common.formatNumberWithCommasNoRounding(
                                  data.bonusAmount
                                )}
                              </td>
                              <td class="align-right">
                                {Common.formatNumberWithCommasNoRounding(
                                  data.agentCoveredBonus
                                )}
                              </td>
                              <td class="align-right">
                                {Common.formatNumberWithCommasNoRounding(
                                  data.bonusAdjustAmount
                                )}
                              </td>
                              <td class="align-right">
                                {Common.formatNumberWithCommasNoRounding(
                                  data.agentCoveredBonusAdjust
                                )}
                              </td>
                              <td class="align-right">
                                {Common.formatNumberWithCommasNoRounding(
                                  data.betAmount
                                )}
                              </td>
                              <td class="align-right">
                                {Common.formatNumberWithCommasNoRounding(
                                  data.validBetAmount
                                )}
                              </td>
                              <td class="align-right">
                                {Common.formatNumberWithCommasNoRounding(
                                  data.winLossAmount
                                )}
                              </td>
                              <td class="align-right">
                                {Common.formatNumberWithCommasNoRounding(
                                  data.netProfit
                                )}
                              </td>
                              <td class="align-right">
                                {Common.formatNumberWithCommasNoRounding(
                                  data.fromPlayerAgentDividendAmount
                                )}
                              </td>
                              <td class="align-right">
                                {Common.formatNumberWithCommasNoRounding(
                                  data.fromTeamAgentDividendAmount
                                )}
                              </td>
                              <td class="align-right">
                                {Common.formatNumberWithCommasNoRounding(
                                  data.agentAdjustAmount
                                )}
                              </td>
                              <td class="align-right">
                                {Common.formatNumberWithCommasNoRounding(
                                  data.agentDividendAmount
                                )}
                              </td>
                            </tr>
                          );
                        })}
                      <CustomTableRow>
                        <th>{i18n.t("report.directAgent")}</th>
                      </CustomTableRow>
                      {this.state.data.subitems &&
                        this.state.data.subitems.map((data, index) => {
                          depositAmount = depositAmount + data.depositAmount;
                          withdrawalAmount =
                            withdrawalAmount + data.withdrawalAmount;
                          bonusAmount = bonusAmount + data.bonusAmount;
                          adjustmentAmount =
                            adjustmentAmount + data.adjustmentAmount;
                          companyAdjustAmount =
                            companyAdjustAmount + data.companyAdjustAmount;
                          agentAdjustAmount =
                            agentAdjustAmount + data.agentAdjustAmount;
                          betAmount = betAmount + data.betAmount;
                          validBetAmount = validBetAmount + data.validBetAmount;
                          winLossAmount = winLossAmount + data.winLossAmount;
                          netProfit = netProfit + data.netProfit;
                          agentDividendAmount =
                            agentDividendAmount + data.agentDividendAmount;
                          fromPlayerAgentDividendAmount =
                            fromPlayerAgentDividendAmount +
                            data.fromPlayerAgentDividendAmount;
                          fromTeamAgentDividendAmount =
                            fromTeamAgentDividendAmount +
                            data.fromTeamAgentDividendAmount;
                          bonusAdjustAmount =
                            bonusAdjustAmount + data.bonusAdjustAmount;
                          agentCoveredBonus =
                            agentCoveredBonus + data.agentCoveredBonus;
                          agentCoveredBonusAdjust =
                            agentCoveredBonusAdjust +
                            data.agentCoveredBonusAdjust;

                          return (
                            <tr
                              key={index}
                              onClick={() => {
                                this.getDividendDetail(
                                  data.id,
                                  data.agentDividendPercentage
                                );

                                this.setState({
                                  ...this.state,
                                  scene: "detailed",
                                  selectedId: data.id,
                                  selectedUsername: data.username,
                                  selectedAmount: data.agentDividendAmount,
                                  selectedUserAgentDividendPercentage:
                                    data.agentDividendPercentage,
                                });
                              }}
                            >
                              <TableHeader>{data.username}</TableHeader>
                              <td class="align-right">
                                {Common.formatNumberWithCommasNoRounding(
                                  data.agentDividendPercentage
                                )}
                                %
                              </td>
                              <td class="align-right">
                                {Common.formatNumberWithCommasNoRounding(
                                  data.bonusAmount
                                )}
                              </td>
                              <td class="align-right">
                                {Common.formatNumberWithCommasNoRounding(
                                  data.agentCoveredBonus
                                )}
                              </td>
                              <td class="align-right">
                                {Common.formatNumberWithCommasNoRounding(
                                  data.bonusAdjustAmount
                                )}
                              </td>
                              <td class="align-right">
                                {Common.formatNumberWithCommasNoRounding(
                                  data.agentCoveredBonusAdjust
                                )}
                              </td>
                              <td class="align-right">
                                {Common.formatNumberWithCommasNoRounding(
                                  data.betAmount
                                )}
                              </td>
                              <td class="align-right">
                                {Common.formatNumberWithCommasNoRounding(
                                  data.validBetAmount
                                )}
                              </td>
                              <td class="align-right">
                                {Common.formatNumberWithCommasNoRounding(
                                  data.winLossAmount
                                )}
                              </td>
                              <td class="align-right">
                                {Common.formatNumberWithCommasNoRounding(
                                  data.netProfit
                                )}
                              </td>
                              <td class="align-right">
                                {Common.formatNumberWithCommasNoRounding(
                                  data.fromPlayerAgentDividendAmount
                                )}
                              </td>
                              <td class="align-right">
                                {Common.formatNumberWithCommasNoRounding(
                                  data.fromTeamAgentDividendAmount
                                )}
                              </td>
                              <td class="align-right">
                                {Common.formatNumberWithCommasNoRounding(
                                  data.agentAdjustAmount
                                )}
                              </td>
                              <td class="align-right">
                                {Common.formatNumberWithCommasNoRounding(
                                  data.agentDividendAmount
                                )}
                              </td>
                            </tr>
                          );
                        })}{" "}
                      <tr
                        style={{
                          background: "rgb(247 247 247)",
                          fontWeight: "bold",
                        }}
                      >
                        <th
                          style={{
                            fontWeight: "bold",
                            textAlign: "right",
                            background: "rgb(247 247 247)",
                          }}
                        >
                          {i18n.t("report.total")}:
                        </th>{" "}
                        <td class="align-right">
                          {display("agentDividendPercentage") &&
                            display("agentDividendPercentage") + "%"}
                        </td>
                        <td class="align-right">{display("bonusAmount")}</td>
                        <td class="align-right">
                          {display("agentCoveredBonus")}
                        </td>
                        <td class="align-right">
                          {display("bonusAdjustAmount")}
                        </td>
                        <td class="align-right">
                          {display("agentCoveredBonusAdjust")}
                        </td>
                        <td class="align-right">{display("betAmount")}</td>
                        <td class="align-right">{display("validBetAmount")}</td>
                        <td class="align-right">{display("winLossAmount")}</td>
                        <td class="align-right">{display("netProfit")}</td>
                        <td class="align-right">
                          {display("fromPlayerAgentDividendAmount")}
                        </td>
                        <td class="align-right">
                          {display("fromTeamAgentDividendAmount")}
                        </td>
                        <td class="align-right">
                          {display("agentAdjustAmount")}
                        </td>
                        <td class="align-right">
                          {display("agentDividendAmount")}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </CustomTable>
              </TableContainer>
              <PaginationWrapper>
                <CustomSelect
                  value={this.state.rows}
                  onChange={(e) => {
                    this.setState({
                      ...this.state,
                      rows: e.target.value,
                    });
                  }}
                >
                  <option value="10">10</option>
                  <option value="50">50</option>
                  <option value="200">200</option>
                </CustomSelect>
                <CustomPageCounter>
                  {this.state.page} /{" "}
                  {Math.ceil(this.state.data.subtotalcount / this.state.rows) ||
                    1}
                </CustomPageCounter>

                <TablePagination
                  rowsPerPageOptions={[]}
                  component="div"
                  count={this.state.data.subtotalcount || 1}
                  rowsPerPage={this.state.rows}
                  page={this.state.page - 1}
                  labelRowsPerPage={("", "", "", "????")}
                  backIconButtonProps={{
                    "aria-label": "previous page",
                  }}
                  nextIconButtonProps={{
                    "aria-label": "next page",
                  }}
                  onChangePage={this.handleChangePage}
                />
              </PaginationWrapper>
            </Content>
          </ReportContainer>
        </Container>
      </CustomContainer>
    );
  };

  goBack = () => {
    //clear all the state.
    this.setState({
      ...this.state,
      scene: "initial",
      selectedId: "",
      selectedUsername: "",
      selectedAmount: "",
      selectedUserAgentDividendPercentage: "",
      data2: { subtotalcount: 0, items: [] },
      pageDetails: 1,
    });
    this.changeDividendListView(this.state.page);
  };

  DetailedScene = () => {
    return (
      <CustomContainer>
        <Container maxWidth="lg">
          <Header />
          <ReportContainer>
            <Content>
              <TitleContainer>
                <Title>{i18n.t("report.dividendDetail")}</Title>
              </TitleContainer>
              <AgentContainer>
                <AgentData>
                  {i18n.t("report.affliateUsername")}:{" "}
                  {this.state.selectedUsername}
                </AgentData>
                <AgentData>
                  {i18n.t("report.dividend")}: {this.state.selectedAmount}
                </AgentData>
              </AgentContainer>
              <FilterContainer>
                <Grid container justify="space-between" alignItems="center">
                  <Grid item>
                    <Grid container>
                      <ReportDatepicker
                        selectedDateFrom={this.state.selectedDateFrom}
                        selectedDateTo={this.state.selectedDateTo}
                        handleDateTo={this.handleDateTo}
                        handleDateFrom={this.handleDateFrom}
                        withRangePicker={true}
                        dateRange={this.state.dateRange}
                        handleChange={this.handleDateRangeChange}
                        displayTwoRowWap={true}
                      ></ReportDatepicker>
                      <CustomQuickButton
                        onClick={() => {
                          this.getDividendDetail(
                            this.state.selectedId,
                            this.state.selectedUserAgentDividendPercentage
                          );
                        }}
                      >
                        {i18n.t("report.search")}
                      </CustomQuickButton>
                    </Grid>
                  </Grid>
                  <Grid item>
                    <Back container onClick={this.goBack}>
                      <span>«</span> {i18n.t("common.back")}{" "}
                      {i18n.t("report.dividendReport")}
                    </Back>
                  </Grid>
                </Grid>
              </FilterContainer>
              <TableContainer>
                <CustomTable>
                  <table>
                    <thead>
                      <tr>
                        <CustomTableHeader>
                          {i18n.t("report.affliateUsername")}
                        </CustomTableHeader>

                        <CustomTableHeader class="align-center">
                          {i18n.t("report.settlementDate")}
                        </CustomTableHeader>
                        <CustomTableHeader class="align-center">
                          {i18n.t("report.settlementDateFromTo")}
                        </CustomTableHeader>

                        <CustomTableHeader class="align-right">
                          {i18n.t("report.agentDividendAmount")}
                        </CustomTableHeader>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.data2.items.map((data, index) => {
                        return (
                          <tr key={index}>
                            <th>{data.username}</th>
                            <td class="align-center">
                              {moment(data.settlementDate).format("YYYY-MM-DD")}
                            </td>
                            <td class="align-center">
                              {moment(data.settlementDateFrom).format(
                                "YYYY-MM-DD"
                              )}{" "}
                              -{" "}
                              {moment(data.settlementDateTo).format(
                                "YYYY-MM-DD"
                              )}
                            </td>

                            <td class="align-right">
                              {Common.formatNumberWithCommasNoRounding(
                                data.agentDividendAmount
                              )}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </CustomTable>
              </TableContainer>
              <PaginationWrapper>
                <CustomSelect
                  value={this.state.rowsDetails}
                  onChange={(e) => {
                    this.setState({
                      ...this.state,
                      rowsDetails: e.target.value,
                    });
                  }}
                >
                  <option value="10">10</option>
                  <option value="50">50</option>
                  <option value="200">200</option>
                </CustomSelect>
                <CustomPageCounter>
                  {this.state.pageDetails} /{" "}
                  {Math.ceil(
                    this.state.data2.totalcount / this.state.rowsDetails
                  ) || 1}
                </CustomPageCounter>

                <TablePagination
                  rowsPerPageOptions={[]}
                  component="div"
                  count={this.state.data2.totalcount}
                  rowsPerPage={this.state.rowsDetails}
                  page={this.state.pageDetails - 1}
                  labelRowsPerPage={("", "", "", "????")}
                  backIconButtonProps={{
                    "aria-label": "previous page",
                  }}
                  nextIconButtonProps={{
                    "aria-label": "next page",
                  }}
                  //handle on row change

                  onChangePage={this.handleChangePageDetails}
                />
              </PaginationWrapper>
            </Content>
          </ReportContainer>
        </Container>
      </CustomContainer>
    );
  };

  switchScene = (scene) => {
    switch (scene) {
      case "initial":
        return this.InitialScene();
      case "detailed":
        return this.DetailedScene();
      default:
        return;
    }
  };

  render() {
    return this.switchScene(this.state.scene);
  }
}

const mapStateToProps = (state) => {
  return {
    token: state.account.token,
    account: state.account.info,
  };
};

export default connect(mapStateToProps, null)(withSnackbar(DividendReport));
