import React, { Component } from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import { SHOWING_DIALOG } from "../../../../constant/actionType";

import i18n from "../../../../utils/i18n";

import { withSnackbar } from "notistack";

import {
  FullSizeContainer,
  Content,
  TitleContainer,
  Title,
  CustomTextField,
} from "../../../Share/General";

import { accountAction } from "../../../../actions/accountAction";

import { Dialog, Grid, DialogContent } from "@material-ui/core";
import { AccountService } from "../../../../services/AccountService";
const StyleButton = styled.div`
  width: ${(props) => (props.width ? props.width + "px" : "100%")};
  padding: 0px 8px;
  height: ${(props) => (props.small ? "32" : "46")}px;
  border: solid 1px #b6b6b6;
  margin-top: ${(props) => (props.marginTop ? props.marginTop : "12")}px;

  ${(props) =>
    props.grayMode
      ? "    background-blend-mode: multiply, normal; background-image:  linear-gradient(to bottom, #bfbfbf, #bfbfbf), linear-gradient(to top, #797979, #c3c3c3);"
      : "  background-image: linear-gradient(to top, " +
        props.theme.primaryGradient +
        ", " +
        props.theme.primary +
        ");"}

  border-radius: 5px;
  font-size: ${(props) => (props.small ? "14" : "16")}px;
  color: #fff;
  ${(props) => (props.float ? "float: " + props.float + ";" : " float: left;")}
  ${(props) =>
    props.marginLeft ? "margin-left: " + props.marginLeft + "px;" : ""}
    ${(props) =>
    props.marginRight ? "margin-right: " + props.marginRight + "px;" : ""}
    ${(props) =>
    props.disabled === true
      ? `
      pointer-event: none;`
      : `
    &:hover {
      cursor: pointer;
      background-blend-mode: screen, normal;
      ${(props) =>
        props.grayMode
          ? "   background-image: linear-gradient(to top, #797979, #c3c3c3);"
          : "  box-shadow: rgb(255 255 255 / 75%) 1px 1px 9px 0px inset;"}
    }
    `}
`;
const ButtonLabel = styled.span`
  text-align: center;
  display: block;
  margin-top: ${(props) => (props.small ? "5" : "10")}px;
`;

const ProfitSharingContainer = styled.div`
  height: 46px;
  width: 100%;
  display: flex;
  margin-top: 13px;
  justify-content: center;
  align-items: center;
`;

const ProfileSharingButton = styled.div`
  display: flex;
  background: #d9d9d9;
  color: rgb(64, 64, 64);
  border-radius: 4px;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: 1px solid #b6b6b6;

  :hover {
    background: rgba(217, 217, 217, 0.9);
  }
`;

const CustomTable = styled.div`
  width: 100%;
  border-radius: 4px;
  border: 1px solid #b6b6b6;
  padding: 2.5px;
`;

const CustomTableData = styled.div`
  padding: 1.25px 2.5px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const CustomTableRow = styled.div`
  display: grid;
  grid-template-columns: 30% 30% 30% 10%;
  padding: 2.5px 0px;
  border-radius: 4px;
  :first-child {
    background: ${({ theme }) => theme.primary};
    ${CustomTableData} {
      color: white;
      justify-content: center;
    }
  }
`;

const CustomInput = styled.input`
  border: 1px solid #b6b6b6;
  border-radius: 4px;
  width: calc(100% - 5px);
  height: 30px;
  padding: 0px 8px;
`;

const CustomActionButton = styled.div`
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50px;
  border: 1px solid #b6b6b6;
  cursor: pointer;
  :hover {
    background: #b6b6b6;
  }
`;

const CustomDialogContent = styled(DialogContent)`
  &.MuiDialogContent-root {
    padding: 0px 12px 12px 12px !important;
  }
`;

const Divider = styled.div`
  height: 1px;
  width: 100%;
  background: #b6b6b6;
`;

const TableHeader = styled.div`
  width: 100%;
  height: 50px;
  font-size: 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: bold;
`;

const CloseButton = styled.div`
  padding-right: 8px;
  font-size: 18px;
  cursor: pointer;
`;

class CreateAgent extends Component {
  initialState = {
    username: "",
    password: "",
    confirmPassword: "",
    divPercentage: 0,
    liveDealerRebatePercentage: 0,
    slotRebatePercentage: 0,
    lotteryRebatePercentage: 0,
    cardGameRebatePercentage: 0,
    sportsRebatePercentage: 0,
    virtualSportsRebatePercentage: 0,
    remarks: "",
    validUsername: false,
    disableButton: false,

    //TGS-4
    realname: "",
    phone: "",
    email: "",

    //profit sharing
    profitSharingModal: false,
    items: [],
    rank: "",
    netProfit: "",
    activeNumber: "",
    dividendPercentage: "",
  };
  state = this.initialState;
  componentDidMount() {}
  componentWillUnmount() {}
  componentDidUpdate(preProps, preState) {
    if (
      preProps.account.createAgentResult !==
        this.props.account.createAgentResult &&
      this.props.account.createAgentResult !== null
    ) {
      if (this.props.account.createAgentResult.IsSuccess) {
        this.props.enqueueSnackbar(
          i18n.t("account.createAgent.createSuccess"),
          {
            variant: "success",
          }
        );
        this.setState({
          ...this.initialState,
        });
      } else {
        this.props.enqueueSnackbar(this.props.account.createAgentResult.info, {
          variant: "error",
        });
      }
      this.props.resetResult();
    }
  }

  handleToggleProfitSharing = (state) => {
    this.setState({
      ...this.state,
      profitSharingModal: state,
    });
  };

  handleSubmitChangeAffiliateInfo = () => {
    var username = this.state.username;
    var password = this.state.password;
    var password2 = this.state.confirmPassword;
    var realname = this.state.realname;
    var phone = this.state.phone;
    var email = this.state.email;
    var expr = /^[a-zA-Z0-9]{6,32}$/;
    var items = this.state.items;
    if (!expr.test(username)) {
      this.props.enqueueSnackbar(i18n.t("common.msgRegisterUserNameInvalid"), {
        variant: "warning",
      });
      return;
    }
    var expr = /^.{6,20}$/;
    if (!expr.test(password)) {
      this.props.enqueueSnackbar(i18n.t("common.msgRegisterPassInvalid"), {
        variant: "warning",
      });
      return;
    }
    if (password !== password2) {
      this.props.enqueueSnackbar(i18n.t("common.msgRegisterPassMatchInvalid"), {
        variant: "warning",
      });
      return;
    }

    if (realname === "") {
      this.props.enqueueSnackbar(i18n.t("common.msgRealnameRequired"), {
        variant: "warning",
      });
      return;
    }
    var phoneRegex = /^[0-9]*$/;
    if (!phoneRegex.test(phone) || phone === "") {
      this.props.enqueueSnackbar(i18n.t("common.msgPhoneRequired"), {
        variant: "warning",
      });
      return;
    }

    var emailRegex =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    if (!emailRegex.test(email)) {
      this.props.enqueueSnackbar(i18n.t("common.msgInvalidEmail"), {
        variant: "warning",
      });
      return;
    }

    if (isNaN(this.state.divPercentage)) {
      this.props.enqueueSnackbar(
        i18n.t("account.createAgent.msgEnterDividendPercentage"),
        {
          variant: "warning",
        }
      );
      return;
    }
    if (isNaN(this.state.liveDealerRebatePercentage)) {
      this.props.enqueueSnackbar(
        i18n.t("account.createAgent.msgEnterLiveDealerRebatePercentage"),
        {
          variant: "warning",
        }
      );
      return;
    }
    if (isNaN(this.state.slotRebatePercentage)) {
      this.props.enqueueSnackbar(
        i18n.t("account.createAgent.msgEnterSlotRebatePercentage"),
        {
          variant: "warning",
        }
      );
      return;
    }
    if (isNaN(this.state.lotteryRebatePercentage)) {
      this.props.enqueueSnackbar(
        i18n.t("account.createAgent.msgEnterLotteryRebatePercentage"),
        {
          variant: "warning",
        }
      );
      return;
    }
    if (isNaN(this.state.cardGameRebatePercentage)) {
      this.props.enqueueSnackbar(
        i18n.t("account.createAgent.msgEnterCardGameRebatePercentage"),
        {
          variant: "warning",
        }
      );
      return;
    }
    if (isNaN(this.state.sportsRebatePercentage)) {
      this.props.enqueueSnackbar(
        i18n.t("account.createAgent.msgEnterSportsRebatePercentage"),
        {
          variant: "warning",
        }
      );
      return;
    }
    if (isNaN(this.state.virtualSportsRebatePercentage)) {
      this.props.enqueueSnackbar(
        i18n.t("account.createAgent.msgEnterVirtualSportsRebatePercentage"),
        {
          variant: "warning",
        }
      );
      return;
    }
    var newitem = items.map((a, index) => {
      a.rank = index + 1;
      return a;
    });
    this.props.createAccount(
      this.state.username,
      this.state.password,
      this.state.realname,
      this.state.email,
      this.state.phone,
      this.state.divPercentage,

      //  this.state.liveDealerRebatePercentage,
      0,
      //  this.state.slotRebatePercentage,
      0,
      //  this.state.lotteryRebatePercentage,
      0,
      //  this.state.cardGameRebatePercentage,
      0,
      //  this.state.sportsRebatePercentage,
      0,
      //   this.state.virtualSportsRebatePercentage,
      0,
      this.state.remarks,
      newitem
    );
  };

  updateItem(e) {
    this.setState({
      [e.target.id]: e.target.value,
    });
  }

  handleClick() {
    var items = this.state.items;
    if (items.length >= this.props.account.info.createLevelCount) {
      this.props.enqueueSnackbar(i18n.t("common.msgExceedCount"), {
        variant: "warning",
      });
    } else {
      items.push({
        rank: "",
        netProfit: this.state.netProfit,
        activeNumber: this.state.netProfit,
        dividendPercentage: this.state.dividendPercentage,
      });

      this.setState({
        items: items,
        rank: "",
        netProfit: "",
        activeNumber: "",
        dividendPercentage: "",
      });
    }
  }

  handleItemChanged(i, e) {
    var items = this.state.items;

    items[i] = {
      ...this.state.items[i],
      [e.target.id]:
        e.target.value == "" || isNaN(e.target.value)
          ? e.target.value
          : parseFloat(e.target.value),
    };

    this.setState({
      items: items,
    });
  }

  handleItemDeleted(i) {
    var items = this.state.items;
    items.splice(i, 1);
    this.setState({
      items: items,
    });
  }

  CheckAgentExist = async (username) => {
    const result = await AccountService.CheckAgentExist(username);
    if (result.IsSuccess) {
      if (result.data.isexist) {
        this.setState({
          ...this.state,
          validUsername: false,
        });
      } else {
        this.setState({
          ...this.state,
          validUsername: true,
        });
      }
    }
  };

  resetButtonAndMatchedUsername = () => {
    this.setState({
      ...this.state,
      disableButton: false,
      matchedUsername: "",
    });
  };

  render() {
    return (
      <FullSizeContainer>
        <Content>
          <TitleContainer>
            <Grid container alignItems="flex-end">
              <Grid item>
                <Title> {i18n.t("account.createAgent.title")}</Title>
              </Grid>
              {/* <Grid item>
                <TitleContainerBackgroundShadow>
                  {i18n.t("account.profile.desc")}
                </TitleContainerBackgroundShadow>
              </Grid> */}
            </Grid>
          </TitleContainer>
          <Grid container xs={12} sm={12} md={12} lg={12} spacing={10}>
            <Grid item xs={12} sm={6} md={6} lg={6}>
              <CustomTextField
                error={
                  this.state.validUsername === false &&
                  /^[a-zA-Z0-9]{6,32}$/.test(this.state.username)
                }
                helperText={
                  this.state.validUsername === false &&
                  /^[a-zA-Z0-9]{6,32}$/.test(this.state.username)
                    ? i18n.t("common.msgAlreadyExist")
                    : ""
                }
                placeholder={i18n.t("account.createAgent.username")}
                fullWidth
                variant="outlined"
                label={i18n.t("account.createAgent.username")}
                value={this.state.username}
                onChange={(e) => {
                  var expr = /^[a-zA-Z0-9]{6,32}$/;
                  if (expr.test(e.target.value)) {
                    this.CheckAgentExist(e.target.value);
                  }

                  this.setState({
                    ...this.state,
                    username: e.target.value,
                  });
                }}
              />

              <CustomTextField
                placeholder={i18n.t("account.createAgent.password")}
                label={i18n.t("account.createAgent.password")}
                fullWidth
                variant="outlined"
                type={"password"}
                value={this.state.password}
                onChange={(e) =>
                  this.setState({ ...this.state, password: e.target.value })
                }
              />
              <CustomTextField
                placeholder={i18n.t("account.createAgent.confirmPassword")}
                label={i18n.t("account.createAgent.confirmPassword")}
                fullWidth
                variant="outlined"
                type={"password"}
                value={this.state.confirmPassword}
                onChange={(e) =>
                  this.setState({
                    ...this.state,
                    confirmPassword: e.target.value,
                  })
                }
              />
              <CustomTextField
                placeholder={i18n.t("account.createAgent.realName")}
                label={i18n.t("account.createAgent.realName")}
                fullWidth
                variant="outlined"
                value={this.state.realname}
                onChange={(e) =>
                  this.setState({
                    ...this.state,
                    realname: e.target.value,
                  })
                }
                style={{ height: "auto" }}
              />
              <CustomTextField
                placeholder={i18n.t("account.createAgent.email")}
                label={i18n.t("account.createAgent.email")}
                fullWidth
                variant="outlined"
                value={this.state.email}
                onChange={(e) =>
                  this.setState({
                    ...this.state,
                    email: e.target.value,
                  })
                }
                style={{ height: "auto" }}
              />
              <CustomTextField
                placeholder={i18n.t("account.createAgent.phone")}
                label={i18n.t("account.createAgent.phone")}
                fullWidth
                variant="outlined"
                value={this.state.phone}
                onChange={(e) =>
                  this.setState({
                    ...this.state,
                    phone: e.target.value,
                  })
                }
                style={{ height: "auto" }}
              />

              <CustomTextField
                placeholder={i18n.t("account.createAgent.remarks")}
                label={i18n.t("account.createAgent.remarks")}
                fullWidth
                variant="outlined"
                value={this.state.remarks}
                onChange={(e) =>
                  this.setState({
                    ...this.state,
                    remarks: e.target.value,
                  })
                }
                style={{ height: "auto" }}
                multiline
                rows="4"
              />
            </Grid>

            <Grid item xs={12} sm={6} md={6} lg={6}>
              <Grid container>
                {this.props.account.info.agentdividendtype === "OneLayer" ? (
                  <CustomTextField
                    placeholder={i18n.t(
                      "account.createAgent.msgEnterDividendPercentage"
                    )}
                    label={i18n.t("account.createAgent.dividendPercentage")}
                    fullWidth
                    variant="outlined"
                    value={this.state.divPercentage}
                    onChange={(e) =>
                      this.setState({
                        ...this.state,
                        divPercentage: e.target.value,
                      })
                    }
                  />
                ) : (
                  <ProfitSharingContainer>
                    <ProfileSharingButton
                      onClick={() => {
                        this.handleToggleProfitSharing(true);
                      }}
                    >
                      {i18n.t("account.createAgent.profitSharing")}
                    </ProfileSharingButton>
                    <Dialog
                      open={this.state.profitSharingModal}
                      onClose={() => {
                        this.handleToggleProfitSharing(false);
                      }}
                      fullWidth={true}
                      maxWidth="sm"
                    >
                      <CustomDialogContent>
                        <TableHeader>
                          <div>
                            {i18n.t("account.createAgent.profitSharing")}
                          </div>
                          <CloseButton
                            onClick={() => {
                              this.handleToggleProfitSharing(false);
                            }}
                          >
                            ✖
                          </CloseButton>
                        </TableHeader>
                        <CustomTable>
                          <CustomTableRow>
                            {/* <CustomTableData>
                          {i18n.t(
                            "account.createAgent.profitSharingModal.rank"
                          )}
                        </CustomTableData> */}
                            <CustomTableData>
                              {i18n.t(
                                "account.createAgent.profitSharingModal.netProfit"
                              )}
                            </CustomTableData>
                            <CustomTableData>
                              {i18n.t(
                                "account.createAgent.profitSharingModal.activeNumber"
                              )}
                            </CustomTableData>
                            <CustomTableData>
                              {i18n.t(
                                "account.createAgent.profitSharingModal.dividendPercentage"
                              )}
                            </CustomTableData>
                            <div></div>
                          </CustomTableRow>
                          {this.state.items.map((data, index) => {
                            var context = this;
                            return (
                              <CustomTableRow key={"item-" + index}>
                                {/* <CustomTableData>
                              <CustomInput
                                type="text"
                                id="rank"
                                value={data.rank}
                                onChange={context.handleItemChanged.bind(
                                  context,
                                  index
                                )}
                              />
                            </CustomTableData> */}
                                <CustomTableData>
                                  <CustomInput
                                    type="text"
                                    id="netProfit"
                                    value={data.netProfit}
                                    onChange={context.handleItemChanged.bind(
                                      context,
                                      index
                                    )}
                                  />
                                </CustomTableData>
                                <CustomTableData>
                                  <CustomInput
                                    type="text"
                                    id="activeNumber"
                                    value={data.activeNumber}
                                    onChange={context.handleItemChanged.bind(
                                      context,
                                      index
                                    )}
                                  />
                                </CustomTableData>
                                <CustomTableData>
                                  <CustomInput
                                    type="text"
                                    id="dividendPercentage"
                                    value={data.dividendPercentage}
                                    onChange={context.handleItemChanged.bind(
                                      context,
                                      index
                                    )}
                                  />
                                </CustomTableData>
                                <CustomTableData>
                                  <CustomActionButton
                                    onClick={context.handleItemDeleted.bind(
                                      context,
                                      index
                                    )}
                                  >
                                    -
                                  </CustomActionButton>
                                </CustomTableData>
                              </CustomTableRow>
                            );
                          })}
                          {(() => {
                            if (
                              this.state.items.length <
                              this.props.account.info.createLevelCount
                            ) {
                              return (
                                <CustomTableRow>
                                  <CustomTableData></CustomTableData>
                                  <CustomTableData></CustomTableData>
                                  <CustomTableData></CustomTableData>
                                  <CustomTableData>
                                    <CustomActionButton
                                      onClick={this.handleClick.bind(this)}
                                    >
                                      +
                                    </CustomActionButton>
                                  </CustomTableData>
                                </CustomTableRow>
                              );
                            }
                          })()}
                        </CustomTable>
                      </CustomDialogContent>
                    </Dialog>
                  </ProfitSharingContainer>
                )}
              </Grid>
            </Grid>

            {
              //
              //      <CustomTextField
              //        placeholder={i18n.t(
              //          "account.createAgent.msgEnterLiveDealerRebatePercentage"
              //        )}
              //        label={i18n.t("account.createAgent.liveDealerRebatePercentage")}
              //        fullWidth
              //        variant="outlined"
              //        value={this.state.liveDealerRebatePercentage}
              //        onChange={(e) =>
              //          this.setState({
              //            ...this.state,
              //            liveDealerRebatePercentage: e.target.value,
              //          })
              //        }
              //      />
              //      <CustomTextField
              //        placeholder={i18n.t(
              //          "account.createAgent.msgEnterSlotRebatePercentage"
              //        )}
              //        label={i18n.t("account.createAgent.slotRebatePercentage")}
              //        fullWidth
              //        variant="outlined"
              //        value={this.state.slotRebatePercentage}
              //        onChange={(e) =>
              //          this.setState({
              //            ...this.state,
              //            slotRebatePercentage: e.target.value,
              //          })
              //        }
              //      />
              //      <CustomTextField
              //        placeholder={i18n.t(
              //          "account.createAgent.msgEnterLotteryRebatePercentage"
              //        )}
              //        label={i18n.t("account.createAgent.lotteryRebatePercentage")}
              //        fullWidth
              //        variant="outlined"
              //        value={this.state.lotteryRebatePercentage}
              //        onChange={(e) =>
              //          this.setState({
              //            ...this.state,
              //            lotteryRebatePercentage: e.target.value,
              //          })
              //        }
              //      />
              //      <CustomTextField
              //        placeholder={i18n.t(
              //          "account.createAgent.msgEnterCardGameRebatePercentage"
              //        )}
              //        label={i18n.t("account.createAgent.cardGameRebatePercentage")}
              //        fullWidth
              //        variant="outlined"
              //        value={this.state.cardGameRebatePercentage}
              //        onChange={(e) =>
              //          this.setState({
              //            ...this.state,
              //            cardGameRebatePercentage: e.target.value,
              //          })
              //        }
              //      />
              //      <CustomTextField
              //        placeholder={i18n.t(
              //          "account.createAgent.msgEnterSportsRebatePercentage"
              //        )}
              //        label={i18n.t("account.createAgent.sportsRebatePercentage")}
              //        fullWidth
              //        variant="outlined"
              //        value={this.state.sportsRebatePercentage}
              //        onChange={(e) =>
              //          this.setState({
              //            ...this.state,
              //            sportsRebatePercentage: e.target.value,
              //          })
              //        }
              //      />
              //      <CustomTextField
              //        placeholder={i18n.t(
              //          "account.createAgent.msgEnterVirtualSportsRebatePercentage"
              //        )}
              //        label={i18n.t(
              //          "account.createAgent.virtualSportsRebatePercentage"
              //        )}
              //        variant="outlined"
              //        fullWidth
              //        value={this.state.virtualSportsRebatePercentage}
              //        onChange={(e) =>
              //          this.setState({
              //            ...this.state,
              //            virtualSportsRebatePercentage: e.target.value,
              //          })
              //        }
              //      />
              //    </Grid>
            }
          </Grid>
          <Grid
            container
            xs={12}
            sm={12}
            md={12}
            lg={12}
            alignItems="center"
            justify="center"
            style={{ marginTop: "10px" }}
          >
            <Grid item xs={12} sm={6} md={4} lg={4}>
              <StyleButton
                onClick={
                  this.state.disableButton || !this.state.validUsername
                    ? ""
                    : this.handleSubmitChangeAffiliateInfo
                }
                grayMode={this.state.disableButton || !this.state.validUsername}
                disabled={this.state.disableButton || !this.state.validUsername}
              >
                <ButtonLabel>{i18n.t("common.submit")}</ButtonLabel>
              </StyleButton>
            </Grid>
          </Grid>
        </Content>
      </FullSizeContainer>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    account: state.account,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    createAccount: (
      username,
      password,
      realname,
      email,
      phone,
      divPercentage,
      liveDealerRebatePercentage,
      slotRebatePercentage,
      lotteryRebatePercentage,
      cardGameRebatePercentage,
      sportsRebatePercentage,
      virtualSportsRebatePercentage,
      remarks,
      agentDividendConditions
    ) =>
      dispatch(
        accountAction.createAccount(
          username,
          password,
          realname,
          email,
          phone,
          divPercentage,
          liveDealerRebatePercentage,
          slotRebatePercentage,
          lotteryRebatePercentage,
          cardGameRebatePercentage,
          sportsRebatePercentage,
          virtualSportsRebatePercentage,
          remarks,
          agentDividendConditions
        )
      ),
    resetResult: () => {
      dispatch({ type: "RESET_RESULT" });
    },
    showDialog: (payload) => {
      dispatch({ type: SHOWING_DIALOG, payload });
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withSnackbar(CreateAgent));
