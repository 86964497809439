import { ApiService } from "./ApiService";
import { GameEnum } from "../utils/Enum";
import config from "../config/config";
export const GameService = {
  JoinAGLiveGame,
  JoinBBINLiveGame,
  JoinLotteryGame,
  JoinQipaiGame,
  GetSlotGameList,
  BookmarkSlotGame,
  GetBookmarkGameList,
  JoinSlotGame,
  GetGameList,
  JoinSportsbook,
  GetSportsbookHomePage,
  GetKYGameList,
  GetHistoryGameList,
  GetJackpotPrizePool
};

const returnUrl = window.location.href;

function JoinAGLiveGame(token, game_type) {
  const result = ApiService.get(
    "casino/ag?token=" +
      token +
      "&game_type=" +
      game_type +
      "&return_url=" +
      returnUrl
  );

  return result;
}
function JoinBBINLiveGame(token, game_code) {
  const result = ApiService.get(
    "casino/bbin?token=" +
      token +
      "&game_code=" +
      game_code +
      "&return_url=" +
      returnUrl
  );

  return result;
}
function JoinLotteryGame(token, kind) {
  const result = ApiService.post(
    "Ticket/play",
    {
      token,
      kind,
      from: config.from,
      return_url: returnUrl
    },
    true
  );

  return result;
  // const result = ApiService.get("Ticket/play/" + game + "?token=" + token);

  // return result;
}
function JoinQipaiGame(token, game, kind = "") {
  const result = ApiService.get(
    "chess/" + game + "?token=" + token + (kind !== "" ? "&kind=" + kind : "")
  );

  return result;
}

function GetJackpotPrizePool() {
  const result = ApiService.get("game/jackpot");

  return result;
}

function JoinSportsbook(token, game_type) {
  let endpoint = "";
  if (game_type === GameEnum.Sabah) {
    endpoint = "oneWork";
  } else if (game_type === GameEnum._633) {
    endpoint = "gb";
  }
  const result = ApiService.get(
    "sport/" +
      endpoint +
      "?token=" +
      token +
      "&from=" +
      config.from +
      "&return_url=" +
      returnUrl
  );
  return result;
}
function GetSportsbookHomePage() {
  const result = ApiService.get("sport/index");
  return result;
}
function GetBookmarkGameList(token) {
  const result = ApiService.post(
    "game/favorite",
    {
      token,
      is_pc: config.isPC
    },
    true
  );

  return result;
}
function GetHistoryGameList(token) {
  const result = ApiService.post(
    "game/history",
    {
      token,
      is_pc: 1
    },
    true
  );

  return result;
}
function GetSlotGameList(
  page,
  limit,
  thirdparty_parent_id = "",
  title = "",
  is_hot = "",
  is_feature = "",
  is_new = "",
  category_id = "",
  is_recommended = "",
  order_by = "",
  order_sort = "",
  pic_w = "",
  pic_h = "",
  token = ""
) {
  const result = ApiService.post(
    "game/index",
    {
      page,
      limit,
      is_pc: config.isPC,
      thirdparty_parent_id,
      title,
      is_hot,
      is_feature,
      is_new,
      category_id,
      token
    },
    true
  );

  return result;
}
function BookmarkSlotGame(token, game_id) {
  const result = ApiService.post(
    "game/doFavorite",
    {
      token,
      game_id
    },
    true
  );

  return result;
}
function JoinSlotGame(token, gameId, test = 2) {
  const result = ApiService.get(
    "game/detail?token=" +
      token +
      "&game_id=" +
      gameId +
      "&test=" +
      test +
      "&is_pc=" +
      config.isPC +
      "&return_url=" +
      returnUrl
  );
  return result;
}
function GetGameList(token) {
  const result = ApiService.get("system/getThirdPartys?token=" + token);
  return result;
}
function GetKYGameList(token) {
  const result = ApiService
    .get
    // "chess/index?token=" + token + "&is_pc=" + config.isPC
    ();
  return result;
}
