import React, { Component } from "react";
import { connect } from "react-redux";
import styled from "styled-components";

import CustomButton from "../../../Share/Button";
import IconInputEmailPNG from "../../../../assets/images/icon-input-email.png";
import IconInputVerifyCodePNG from "../../../../assets/images/icon-input-verifycode.png";
import { withSnackbar } from "notistack";
import { AccountService } from "../../../../services/AccountService";

import { accountAction } from "../../../../actions/accountAction";
import TextField from "@material-ui/core/TextField";
import { withStyles } from "@material-ui/core/styles";
import Loading from "../../Loading";
import i18n from "../../../../utils/i18n";
import { Grid } from "@material-ui/core";
import ErrorSwitcher from "../../../../utils/ErrorSwitcher";
const CustomText = withStyles({
  root: {
    marginTop: "10px",
    float: "left",
    backgroundColor: "#fff",
    "& .MuiOutlinedInput-input": {
      fontSize: "14px",
    },
    "& .MuiOutlinedInput-input[readonly]": {
      color: "#b6b6b6",
    },
    "& label.Mui-focused": {
      color: "#b6b6b6",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#b6b6b6",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#b6b6b6",
      },
      "&:hover fieldset": {
        borderColor: "#b6b6b6",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#b6b6b6",
      },
    },
  },
})(TextField);
const Container = styled.div``;

const StyleButton = styled.div`
  width: 100%;
  height: 44px;
  border: solid 1px #b6b6b6;
  margin: 0;
  margin-top: 10px;

  float: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  ${(props) =>
    props.disable === true
      ? `  background-image: linear-gradient(90deg, rgba(89,89,89,1) 0%, rgba(126,126,126,1) 100%);`
      : `  background-image: linear-gradient(
    90deg,
    rgb(15, 123, 154) 0%,
    rgb(59, 171, 204) 100%
  );
`}

  border-radius: 5px;
  font-size: 16px;
  color: #fff;

  &:hover {
    cursor: pointer;

    ${(props) =>
      props.disable === true
        ? `  background-image: linear-gradient(90deg, rgba(89,89,89,1) 0%, rgba(126,126,126,1) 100%);`
        : `      background-blend-mode: screen, normal;
        background-image: linear-gradient(
            to bottom,
            rgba(59, 171, 204, 0.08),
            rgba(59, 171, 204, 0.08)
          ),
          linear-gradient(to top, #3babcc, #3babcc);
        }
  `}
  }
`;
const ButtonLabel = styled.span`
  text-align: center;
  display: block;
  margin-top: 10px;
`;
const Message = styled.div`
  color: red;
  float: left;
  font-size: 13px;
  margin: 10px 0 0 0;
  width: 100%;
  text-align: left;
`;
class Address extends Component {
  state = {
    isNew: this.props.email === "" || this.props.email === null ? true : false,
    step: 1,
    message: this.props.email === "" ? "" : "",
    email: this.props.email,
    code: "",
    countdown: 0,
    securityKey: "",
  };
  timer = null;
  componentWillUnmount() {
    if (this.timer != null) {
      clearTimeout(this.timer);
    }
  }
  onhandleEmailChange = (e) => {
    this.setState({ ...this.state, email: e.target.value });
  };

  onhandleCodeChange = (e) => {
    if (e.target.value.length <= 6) {
      if (e.target.value.match(/^[0-9]+$/) || e.target.value === "") {
        this.setState({ ...this.state, code: e.target.value });
      }
    }
  };
  onhandleGetCodeClick = () => {
    if (this.state.countdown <= 0) {
      if (
        /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
          this.state.email
        ) === false
      ) {
        this.props.enqueueSnackbar(i18n.t("common.msgInvalidEmail"), {
          variant: "error",
        });
        return;
      }
      AccountService.VerifyEmail(this.state.email).then((response) => {
        if (response.IsSuccess) {
          this.setState({
            securityKey: response.data,
          });
          this.props.enqueueSnackbar(i18n.t("common.msgTokenSent"), {
            variant: "info",
          });
          this.setState({ ...this.state, countdown: 60 });
        } else {
          this.props.enqueueSnackbar(
            ErrorSwitcher.getTranslation(response.info),
            {
              variant: "error",
            }
          );
        }

        this.checkCountdown();
      });
    }
  };
  checkCountdown = () => {
    if (this.state.countdown > 0) {
      this.timer = setTimeout(() => {
        this.setState({ ...this.state, countdown: this.state.countdown - 1 });
        this.checkCountdown();
      }, 1000);
    }
  };
  onhandleClick = () => {
    if (this.state.code.length < 6) {
      this.props.enqueueSnackbar(i18n.t("common.msgEnterCode"), {
        variant: "error",
      });
      return;
    }
    AccountService.UpdateEmail(this.state.securityKey, this.state.code).then(
      (response) => {
        if (response.IsSuccess) {
          this.props.enqueueSnackbar(i18n.t("common.msgTokenVerified"), {
            variant: "info",
          });
          if (this.state.isNew || this.state.step === 2) {
            this.props.getAccountInfo();
            this.props.handleCloseDialog();
          } else {
            this.setState({
              ...this.state,
              code: "",
              securityKey: "",
              email: "",
              step: 2,
              message: i18n.t("common.msgTokenVerified"),
              countdown: 0,
            });
          }
        } else {
          this.props.enqueueSnackbar(
            ErrorSwitcher.getTranslation(response.info),
            {
              variant: "error",
            }
          );
        }
      }
    );
  };
  render() {
    return (
      <Container>
        <Grid container>
          <Grid item xs={12}>
            <CustomText
              fullWidth
              placeholder={i18n.t(
                "account.safety.dialog.emailVerify.emailPlaceholder"
              )}
              adornment={IconInputEmailPNG}
              onChange={this.onhandleEmailChange}
              inputProps={{
                maxLength: 50,
              }}
              style={{
                height: "46px",
              }}
              value={this.state.email}
              variant="outlined"
              autoComplete="off"
              disabled={this.state.isNew === false && this.state.step === 1}
            ></CustomText>
          </Grid>
        </Grid>

        <Grid container>
          <Grid item xs={8} style={{ paddingRight: 8 }}>
            <CustomText
              fullWidth
              placeholder={i18n.t(
                "account.safety.dialog.emailVerify.verificationCode"
              )}
              adornment={IconInputVerifyCodePNG}
              onChange={this.onhandleCodeChange}
              value={this.state.code}
              //  onInput={(e) => {
              //    e.target.value = Math.max(0, parseInt(e.target.value))
              //      .toString()
              //      .slice(0, 6);
              //  }}
              style={{
                height: "46px",
              }}
              variant="outlined"
              autoComplete="off"
              type={"text"}
            ></CustomText>
          </Grid>
          <Grid item xs={4}>
            <StyleButton
              onClick={this.onhandleGetCodeClick}
              disable={this.state.countdown > 0}
              fullWidth
            >
              <ButtonLabel>
                {this.state.countdown <= 0
                  ? i18n.t("account.safety.dialog.emailVerify.requestCode")
                  : i18n.t("account.safety.dialog.emailVerify.requestAgain") +
                    "(" +
                    this.state.countdown +
                    "s)"}
              </ButtonLabel>
            </StyleButton>
          </Grid>
        </Grid>

        <Message>{this.state.message}</Message>
        <CustomButton
          name={i18n.t("account.safety.dialog.submit")}
          handleClick={this.onhandleClick}
          marginTop={15}
        ></CustomButton>
        <Loading show={this.state.isClicked} />
      </Container>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getAccountProfile: (token) =>
      dispatch(accountAction.getAccountProfile(token, true)),
    dispatchFunction: (json) => dispatch(json),
    getAccountInfo: (token) => dispatch(accountAction.getAccountInfo(token)),
  };
};
export default connect(null, mapDispatchToProps)(withSnackbar(Address));
