import { Dialog, Tab, Tabs } from "@material-ui/core";
import React, { useState } from "react";
import styled from "styled-components";
import i18n from "../../../../../utils/i18n";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import TabUserDetail from "./tab-userdetail";
import TabUserContact from "./tab-usercontact";
import TabChangesRecord from "./tab-changesrecord";

const Content = styled.div`
  min-width: 75vw;
  min-height: 45vh;
`;

const ActionBar = styled.div`
  height: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 20px;
  font-weight: bold;
  font-size: 18px;
  background: ${({ theme }) => theme.primary};
  color: ${({ theme }) => theme.primaryText};
  user-select: none;
`;

const CloseButton = styled.div`
  cursor: pointer;
`;
const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <div>{children}</div>}
    </div>
  );
};

const AgentDetailModal = ({
  open,
  close,
  category,
  status,
  myDownlineData,
  myDownlineTeamInfo,
  myDownlineChangesReport,

  performFastGetAccountChangesReport,
  performGetTeamInfo,
}) => {
  const [value, setValue] = useState(0);
  const smallDevice = useMediaQuery("(max-width:600px)");
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  var uid = myDownlineData.id;
  return (
    <Dialog open={open} onClose={close} maxWidth="xl" fullScreen={smallDevice}>
      <ActionBar>
        <div>{i18n.t("account.agentInfo.title")}</div>
        <CloseButton onClick={close}>✖</CloseButton>
      </ActionBar>
      <Content>
        <div sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs value={value} onChange={handleChange}>
            <Tab label={i18n.t("account.agentInfo.agentDetails.title")} />
            <Tab label={i18n.t("account.agentInfo.agentContact.title")} />
            <Tab label={i18n.t("account.agentInfo.transactionRecord.title")} />
          </Tabs>
        </div>
        <TabPanel value={value} index={0}>
          <TabUserDetail
            data={myDownlineData}
            teamsize={myDownlineTeamInfo.totalcount}
          />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <TabUserContact data={myDownlineData} />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <TabChangesRecord
            category={category}
            uid={uid}
            myDownlineChangesReport={myDownlineChangesReport}
            performFastGetAccountChangesReport={
              performFastGetAccountChangesReport
            }
          />
        </TabPanel>
      </Content>
    </Dialog>
  );
};

export default AgentDetailModal;
