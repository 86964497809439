import React from "react";
import styled from "styled-components";
import CustomDatePicker from "../Account/Report/CustomDatePicker";
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";

import i18n from "../../../utils/i18n";
import DateRangeSelector from "../../Share/date-range-selector";

const LabelDash = styled.span`
  float: left;
  margin: 10px 10px;

  @media (max-width: 530px) {
    ${(props) =>
      props.displayTwoRowWap
        ? `    min-width: 50px;
      text-align: center;`
        : ``}
  }
`;

const TimeBorder = styled.div``;

const TimeBorderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: solid 1px rgba(0, 0, 0, 0.2);
  margin-bottom: 6px;
  padding-bottom: 2px;
`;

const ResetButton = styled.div`
  cursor: pointer;
  user-select: none;
`;

const DateRangePickerStyle = styled.div`
  display: flex;
  flex-direction: column;
`;

const DatepickerWrapper = styled.div`
  display: flex;
  @media (max-width: 530px) {
    ${(props) =>
      props.displayTwoRowWap
        ? `   
        display: grid;
        grid-template-columns: 40% 60%;
        padding-right: 25px;
        gap: 10px;
        `
        : ``}
  }
`;
const DateTimeWrapper = styled.div`
  display: flex;
  @media (max-width: 530px) {
    display: block;
  }
`;
const HardcodedTime = styled.div`
  font-size: 14px;
  font-weight: 400;
  font-family: SimHei;
  color: rgba(0, 0, 0, 0.87);
  cursor: text;
  display: inline-flex;
  position: relative;
  box-sizing: border-box;
  align-items: center;
  line-height: 1.1875em;
  background-color: #fff;
  border: solid 1px rgb(182, 182, 182);
  padding: 7px;
  border-radius: 5px;
  margin-left: -3px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  height: 40px;
  @media (max-width: 530px) {
    width: 100%;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    justify-content: center;
    margin: 0;
    margin-top: 4px;
  }
`;

const NewLineMaker = styled.div`
  display: table;
  margin-right: 8px;
  @media (max-width: 530px) {
    ${(props) =>
      props.displayTwoRowWap
        ? `   
      text-align: center;
      `
        : ``}
  }
`;

const ReportDatepicker = ({
  selectedDateFrom,
  selectedDateTo,
  handleDateFrom,
  handleDateTo,
  maxMonth,
  emptied,
  title,
  reset,
  showHardcodeTime,
  withRangePicker = false,
  dateRange,
  handleChange,
  displayTwoRowWap,
}) => {
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <DateRangePickerStyle>
        {(reset || title) && (
          <TimeBorderContainer>
            {title && <TimeBorder>{title}</TimeBorder>}
            {reset && <ResetButton onClick={reset}>✖</ResetButton>}
          </TimeBorderContainer>
        )}
        <DatepickerWrapper displayTwoRowWap={displayTwoRowWap}>
          {withRangePicker === true && (
            <NewLineMaker displayTwoRowWap={displayTwoRowWap}>
              <DateRangeSelector
                dateRange={dateRange}
                handleChange={handleChange}
              />
            </NewLineMaker>
          )}
          <DateTimeWrapper>
            <CustomDatePicker
              value={selectedDateFrom}
              emptied={emptied}
              onChange={(e, value) => {
                handleDateFrom(value);
              }}
              maxMonth={maxMonth}
              id={"dateFrom"}
            ></CustomDatePicker>
            {showHardcodeTime && <HardcodedTime>00:00:00</HardcodedTime>}
          </DateTimeWrapper>
          <LabelDash displayTwoRowWap={displayTwoRowWap}>
            {i18n.t("common.to")}
          </LabelDash>
          <DateTimeWrapper>
            <CustomDatePicker
              value={selectedDateTo}
              minDate={selectedDateFrom}
              maxMonth={maxMonth}
              emptied={emptied}
              onChange={(e, value) => {
                handleDateTo(value);
              }}
              id={"dateTo"}
            ></CustomDatePicker>
            {showHardcodeTime && <HardcodedTime>23:59:59</HardcodedTime>}
          </DateTimeWrapper>
        </DatepickerWrapper>
      </DateRangePickerStyle>
    </MuiPickersUtilsProvider>
  );
};

export default ReportDatepicker;
