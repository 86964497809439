import { GETROUNDSREPORTSTATUS_SUCCESS } from "../constant/actionType";
const initState = {
  rounds: {
    status: [],
  },
};

const reportReducer = (state = initState, action) => {
  switch (action.type) {
    case GETROUNDSREPORTSTATUS_SUCCESS:
      return {
        ...state,
        rounds: {
          status: action.payload.data,
        },
      };

    default:
      return state;
  }
};

export default reportReducer;
