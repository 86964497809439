import React from "react";
import { useSnackbar } from "notistack";

import {
  CustomInputCol,
  CustomQuickButton,
  CustomTextField,
} from "../../../Share/General";
import { Row } from "react-grid-system";
import styled from "styled-components";
import i18n from "../../../../utils/i18n";

const CopyButton = styled(CustomQuickButton)`
  margin-top: 10px;
  height: 46px;
`;

const OverrideCustomTextField = styled(CustomTextField)`
  min-width: 75% !important;
`;

const ChannelLink = ({ id, member_id, title, url }) => {
  function copyToClipboard(e) {
    var textField = document.createElement("textarea");
    textField.innerText = e;
    document.body.appendChild(textField);
    textField.select();
    document.execCommand("copy");
    textField.remove();
    enqueueSnackbar(i18n.t("account.header.copySuccess"), {
      variant: "success",
    });
  }

  const { enqueueSnackbar } = useSnackbar();
  return (
    <Row key={id}>
      <CustomInputCol>
        <OverrideCustomTextField
          disabled
          variant="outlined"
          memberid={member_id}
          title={title}
          value={url}
        />
        <CopyButton
          onClick={() => {
            copyToClipboard(url);
          }}
        >
          {i18n.t("account.channel.copy")}
        </CopyButton>
      </CustomInputCol>
    </Row>
  );
};

export default ChannelLink;
