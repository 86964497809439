import { ApiService } from "./ApiService";
import config from "../config/config";
export const GeneralService = {
  CheckIPRestrict,
  GetSiteToken,
  GetLiveChatURL,
  GetLocalization,
  GetGameProvider,
};

function GetSiteToken() {
  const result = ApiService.post("auth/jwt", {
    client: config.client,
    currency: config.currency,
  }); //done
  return result;
}

function CheckIPRestrict() {
  const result = ApiService.post("index/ipCheck", {}, true);

  return result;
}

function GetLiveChatURL() {
  const result = ApiService.get(`brandlivechat`);
  return result;
}

function GetLocalization() {
  const result = ApiService.get(`localization`);
  return result;
}

function GetGameProvider() {
  const result = ApiService.get(`gameprovider`);
  return result;
}
