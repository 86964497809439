import React, { Component } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import { withStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import Login from "./Login";
import ForgotPasswordOption from "./ForgotPasswordOption";
import ForgotPasswordEmail from "./ForgotPasswordEmail";
import ForgotPasswordPhone from "./ForgotPasswordPhone";
import ForgotPasswordSecurityQuestion from "./ForgotPasswordSecurityQuestion";
import ResetPassword from "./ResetPassword";
import ResetPasswordSuccess from "./ResetPasswordSuccess";
import { accountAction } from "../../actions/accountAction";
import Register from "./Register";
import { SHOWING_DIALOG, SHOW_LOGIN } from "../../constant/actionType";
import { AccountService } from "../../services/AccountService";
import { withSnackbar } from "notistack";
import LoginBGPJPG from "../../assets/images/login-bg.jpg";
import CustomButton from "../Share/Button";
import i18n from "../../utils/i18n";
import { affiliateAction } from "../../actions/affiliateAction";
import { walletAction } from "../../actions/walletActions";
import ErrorSwitcher from "../../utils/ErrorSwitcher";

const Container = styled.div``;
const BtnLoginRegister = styled.div`
  float: right;
  font-size: 17px;
  text-align: center;
  color: #f6f6f6;

  &:hover {
    color: rgb(253, 231, 63);
    cursor: pointer;
  }
`;

const BtnLogin = styled(BtnLoginRegister)`
  margin-right: 5px;
`;

const BtnLoginRegisterLabel = styled.span`
  text-align: center;
  display: block;
`;
const StyledDialog = withStyles({
  root: {
    boxShadow: "0 3px 5px 2px rgba(255, 105, 135, .3)",
  },
})(Dialog);

const CustomStyledDialog = styled(StyledDialog)`
  .MuiDialog-paper {
    border-radius: 12px;
  }
`;

const DialogContainer = styled.div`
  padding: 27px 32px 10px 32px;
  background: url(${LoginBGPJPG});
  display: table;
`;
const DescText = styled.div`
  color: #16242a;
  margin: 9px 0 0 6px;
  text-align: center;
`;
const FooterDescText = styled(DescText)`
  font-size: 12px;
  margin: 20px 0 0 0px;
`;
const ButtonContainer = styled.div`
  margin: 0 auto;
  width: 150px;
`;
const DialogHeader = styled.div`
  height: 50px;
  background-color: ${(props) => props.theme.secondary};
`;
const DialogHeadertitle = styled.div`
  text-align: center;

  padding-top: 12px;
  float: left;
  font-size: 18px;
  font-weight: bold;
  color: #ffffff;
  width: 100%;
`;

class GuestContainer extends Component {
  state = {
    openDialog: false,
    openDialogName: "",
    forgotToken: "",
    emailCheck: -1,
    phoneCheck: -1,
    questionCheck: -1,
    openSelfControlDialog: false,
  };
  componentWillUnmount() {
    this.setState({
      ...this.state,
      openDialog: false,
      openDialogName: "",
    });
    this.props.showLogin(false);
    this.props.showDialog(false);
  }
  handleClickOpenDialog = (dialogName) => {
    this.setState({
      ...this.state,
      openDialog: true,
      openDialogName: dialogName,
    });
    this.props.showLogin(true);
  };

  onhandleCloseDialog = () => {
    this.setState({ ...this.state, openDialog: false });
    this.props.showLogin(false);
  };

  // onhandleLoginSuccess = token => {
  //   this.props.handleLoginSuccess(token);
  //   this.setState({ ...this.state, openDialog: false });
  //   this.props.showLogin(false);
  // };
  onhandleRegister = () => {
    this.setState({
      ...this.state,
      openDialog: true,
      openDialogName: "register",
    });
    this.props.showLogin(false);
  };
  onhandleForgotPassword = () => {
    this.setState({
      ...this.state,
      openDialog: true,
      openDialogName: "forgotpassword",
    });
    this.props.showLogin(false);
  };

  onhandleForgotPasswordNext = (username, code, sessionId) => {
    AccountService.GetResetPasswordStep1(username, code, sessionId).then(
      (response) => {
        if (response.IsSuccess) {
          this.setState({
            ...this.state,
            openDialog: true,
            question: response.data.question,
            forgotToken: response.data.forget_token,
            emailCheck: response.data.email_check,
            phoneCheck: response.data.phone_check,
            questionCheck: response.data.question_check,
            openDialogName: "forgotpasswordoption",
          });
        } else {
          this.props.enqueueSnackbar(response.msg, {
            variant: "error",
          });
        }
      }
    );
  };
  onhandleOptionClick = (method) => {
    let dialogName = "";

    if (method === "question") {
      dialogName = "forgotpasswordsecurityquestion";
      if (this.state.questionCheck !== 1) {
        return;
      }
    } else if (method === "phone") {
      dialogName = "forgotpasswordphone";
      if (this.state.phoneCheck !== 1) {
        return;
      }
    } else if (method === "email") {
      dialogName = "forgotpasswordemail";
      if (this.state.emailCheck !== 1) {
        return;
      }
    }
    this.setState({
      ...this.state,
      openDialog: true,
      openDialogName: dialogName,
    });
  };
  onhandleResetPasswordSuccessClick = () => {
    this.setState({
      ...this.state,
      openDialog: false,
      openDialogName: "",
    });
  };
  onhandleResetPasswordClick = (
    code,
    phone,
    securityKey,
    password,
    confirmPassword
  ) => {
    if (password !== confirmPassword) {
      this.props.enqueueSnackbar(i18n.t("common.msgInvalidConfirmPass"), {
        variant: "error",
      });
    } else {
      AccountService.ResetPassword(securityKey, code, password).then(
        (response) => {
          if (response.IsSuccess) {
            this.setState({
              ...this.state,
              openDialog: true,
              openDialogName: "resetpasswordsuccess",
            });
          } else {
            this.props.enqueueSnackbar(
              ErrorSwitcher.getTranslation(response.info),
              {
                variant: "error",
              }
            );
          }
        }
      );
    }
  };
  onhandleForgotPasswordBackClick = () => {
    this.setState({
      ...this.state,
      openDialog: true,
      openDialogName: "forgotpasswordoption",
    });
  };
  onhandleForgotPasswordSecurityQuestionNext = (answer) => {
    if (answer !== "") {
      AccountService.GetResetPasswordSecurityQuestionVerify(
        this.state.forgotToken,
        answer
      ).then((response) => {
        if (response.IsSuccess) {
          this.setState({
            ...this.state,
            openDialog: true,
            openDialogName: "resetpassword",
          });
        } else {
          this.props.enqueueSnackbar(response.msg, {
            variant: "error",
          });
        }
      });
    }
  };
  onhandleForgotPasswordEmailClick = (code, email, securityKey) => {
    if (code !== "") {
      this.setState({
        ...this.state,
        code: code,
        email: email,
        securityKey: securityKey,
        openDialog: true,
        openDialogName: "resetpassword",
      });
    }
  };
  onhandleForgotPasswordPhoneClick = (code) => {
    if (code !== "") {
      AccountService.GetResetPasswordPhoneVerify(
        this.state.forgotToken,
        code
      ).then((response) => {
        if (response.IsSuccess) {
          this.setState({
            ...this.state,
            openDialog: true,
            openDialogName: "resetpassword",
          });
        } else {
          this.props.enqueueSnackbar(response.msg, {
            variant: "error",
          });
        }
      });
    }
  };
  onhandleResetPasswordBackClick = () => {
    this.setState({
      ...this.state,
      openDialog: true,
      openDialogName: "forgotpassword",
    });
  };
  handleLoginClick = async (username, password) => {
    if (username === "") {
      this.props.enqueueSnackbar(i18n.t("toast.usernameCantEmpty"), {
        variant: "warning",
      });
      return;
    }
    if (username === "") {
      this.props.enqueueSnackbar(i18n.t("toast.usernameCantEmpty"), {
        variant: "warning",
      });
      return;
    }
    const result = await this.props.accountLogin(username, password);
    if (result.IsSuccess) {
      this.handleLoginSuccess(result.data);
    } else {
      this.props.enqueueSnackbar(result.info, {
        variant: "warning",
      });
    }
  };
  handleCloseSelfControlDialog = (token) => {
    this.setState({ ...this.state, openSelfControlDialog: false });
  };
  handleLoginSuccess = (token) => {
    //this.props.getAccountInfo(token);
    this.props.getAccountProfile(token, true);
    this.props.getBalanceList();
    // this.props.getAffiliateChannelList(token, window.location.origin);
  };
  onhandleOnlineSupport = () => {
    this.setState({ ...this.state, openDialog: false });
    this.props.showLogin(false);
    this.props.onhandleShowCSClick();
  };
  onhandleContactUs = () => {
    this.setState({ ...this.state, openDialog: false });
    this.props.showLogin(false);
    this.props.onhandleContactUsClick();
  };
  onhandleSubmitRegister = () => {};
  render() {
    return (
      <Container>
        {/* <BtnRegister onClick={() => this.onhandleRegister()}>
          <BtnLoginRegisterLabel>
            {i18n.t("common.register")}
          </BtnLoginRegisterLabel>
        </BtnRegister>
        <BtnSpliter> | </BtnSpliter> */}
        <BtnLogin onClick={() => this.handleClickOpenDialog("login")}>
          <BtnLoginRegisterLabel>
            {i18n.t("common.login")}
          </BtnLoginRegisterLabel>
        </BtnLogin>{" "}
        <CustomStyledDialog
          open={this.state.openDialog || this.props.ui.showLogin}
          onClose={this.onhandleCloseDialog}
          onEntering={() => this.props.showDialog(true)}
          onExited={() => this.props.showDialog(false)}
        >
          {(() => {
            if (
              this.state.openDialogName === "login" ||
              this.props.ui.showLogin === true
            ) {
              return (
                <Login
                  onhandleClose={this.onhandleCloseDialog}
                  onhandleLogin={this.onhandleLogin}
                  onhandleRegister={this.onhandleRegister}
                  onhandleForgotPassword={this.onhandleForgotPassword}
                  onhandleOnlineSupport={this.onhandleOnlineSupport}
                  onhandleContactUs={this.onhandleContactUs}
                  onhandleLoginClick={this.handleLoginClick}
                ></Login>
              );
            } else {
              if (this.state.openDialogName === "register") {
                return (
                  <Register
                    onhandleClose={this.onhandleCloseDialog}
                    onhandleSubmitRegister={this.onhandleSubmitRegister}
                  ></Register>
                );
              } else if (this.state.openDialogName === "forgotpassword") {
                return (
                  <ForgotPasswordEmail
                    onhandleClose={this.onhandleCloseDialog}
                    onhandleNextClick={this.onhandleForgotPasswordEmailClick}
                    onhandleBackClick={this.onhandleForgotPasswordBackClick}
                    onhandleOnlineSupport={this.onhandleOnlineSupport}
                    code={this.state.code}
                    email={this.state.email}
                  ></ForgotPasswordEmail>
                );
              } else if (this.state.openDialogName === "forgotpasswordoption") {
                return (
                  <ForgotPasswordOption
                    onhandleClose={this.onhandleCloseDialog}
                    onNextClick={this.onhandleOptionClick}
                    onhandleOnlineSupport={this.onhandleOnlineSupport}
                    emailCheck={this.state.emailCheck}
                    phoneCheck={this.state.phoneCheck}
                    questionCheck={this.state.questionCheck}
                  ></ForgotPasswordOption>
                );
              } else if (
                this.state.openDialogName === "forgotpasswordsecurityquestion"
              ) {
                return (
                  <ForgotPasswordSecurityQuestion
                    onhandleClose={this.onhandleCloseDialog}
                    onhandleNextClick={
                      this.onhandleForgotPasswordSecurityQuestionNext
                    }
                    onhandleBackClick={this.onhandleForgotPasswordBackClick}
                    onhandleOnlineSupport={this.onhandleOnlineSupport}
                    question={this.state.question}
                  ></ForgotPasswordSecurityQuestion>
                );
              } else if (this.state.openDialogName === "forgotpasswordemail") {
                return (
                  <ForgotPasswordEmail
                    onhandleClose={this.onhandleCloseDialog}
                    onhandleNextClick={this.onhandleForgotPasswordEmailClick}
                    onhandleBackClick={this.onhandleForgotPasswordBackClick}
                    onhandleOnlineSupport={this.onhandleOnlineSupport}
                  ></ForgotPasswordEmail>
                );
              } else if (this.state.openDialogName === "forgotpasswordphone") {
                return (
                  <ForgotPasswordPhone
                    onhandleClose={this.onhandleCloseDialog}
                    onhandleNextClick={this.onhandleForgotPasswordPhoneClick}
                    onhandleBackClick={this.onhandleForgotPasswordBackClick}
                    onhandleOnlineSupport={this.onhandleOnlineSupport}
                  ></ForgotPasswordPhone>
                );
              } else if (this.state.openDialogName === "resetpassword") {
                return (
                  <ResetPassword
                    onhandleClose={this.onhandleCloseDialog}
                    onhandleSubmitClick={this.onhandleResetPasswordClick}
                    onhandleBackClick={this.onhandleResetPasswordBackClick}
                    onhandleOnlineSupport={this.onhandleOnlineSupport}
                    code={this.state.code}
                    phone={this.state.phone}
                    securityKey={this.state.securityKey}
                  ></ResetPassword>
                );
              } else if (this.state.openDialogName === "resetpasswordsuccess") {
                return (
                  <ResetPasswordSuccess
                    onhandleClose={this.onhandleCloseDialog}
                    onhandleSubmitClick={this.onhandleResetPasswordSuccessClick}
                    onhandleOnlineSupport={this.onhandleOnlineSupport}
                  ></ResetPasswordSuccess>
                );
              } else {
                return <div></div>;
              }
            }
          })()}
        </CustomStyledDialog>
        <CustomStyledDialog
          open={this.state.openSelfControlDialog}
          onClose={this.handleCloseSelfControlDialog}
          onEntering={() => this.props.showDialog(true)}
          onExited={() => this.props.showDialog(false)}
        >
          <DialogHeader>
            <DialogHeadertitle>责任博彩</DialogHeadertitle>
          </DialogHeader>
          <DialogContainer>
            <DescText>
              您已设定自我控制，账户将在{this.state.expired_time}后解锁
            </DescText>
            <ButtonContainer>
              <CustomButton
                name={"确认"}
                width={150}
                handleClick={this.handleCloseSelfControlDialog}
              ></CustomButton>
            </ButtonContainer>

            <div className="clear"></div>
            <FooterDescText>
              如您有任何问题，请与在线客服联系。
              <br />
              申诉为7*24人工处理，90%的用户都会在24小时内收到反馈。
            </FooterDescText>
          </DialogContainer>
        </CustomStyledDialog>
      </Container>
    );
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    showLogin: (payload) => {
      dispatch({ type: SHOW_LOGIN, payload });
    },
    showDialog: (payload) => {
      dispatch({ type: SHOWING_DIALOG, payload });
    },
    accountLogin: (username, password) =>
      dispatch(accountAction.accountLogin(username, password)),
    getAccountInfo: (token) => dispatch(accountAction.getAccountInfo(token)),
    getBalanceList: () => dispatch(walletAction.getBalanceList()),
    getAccountProfile: (token, showVip) =>
      dispatch(accountAction.getAccountProfile(token, showVip)),

    getAffiliateChannelList: (token, mainUrl) =>
      dispatch(affiliateAction.getAffiliateChannelList(token, mainUrl)),
  };
};
const mapStateToProps = (state) => {
  return {
    ui: state.ui,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withSnackbar(GuestContainer));
