import React, { Component } from "react";
import styled from "styled-components";
import { Select, FormControl, withStyles } from "@material-ui/core";
import CustomTextField from "../../../Share/TextField";
import Button from "../../../Share/Button";
import i18n from "../../../../utils/i18n";
import { withSnackbar } from "notistack";
import config from "../../../../config/config";

const Container = styled.div`
  width: 100%;
  padding-bottom: 20px;
`;
const SubTitle = styled.div`
  font-size: 22px;
  font-weight: bold;
  text-align: center;
  color: #16242a;
  margin-top: 0px;
`;

const TextFieldLabel = styled.div`
  font-size: 16px;
  text-align: center;

  color: #818181;
  margin: 10px 0 0 0;
`;
const TextFieldContainer = styled.div`
  margin: 0 auto;
  display: table;
  width: 395px;
`;

const MakeButtonFloatCenter = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 15px;
`;

const MakeSomeGap = styled.div`
  margin: 0px 10px;
`;

const CustomSelect = withStyles((theme) => ({
  root: {
    fontSize: "14px",
    color: "#818181",
  },
}))(Select);
const CustomFormControl = withStyles((theme) => ({
  root: {
    width: "395px",
    height: "38px",
    marginTop: "12px",
    marginRight: "5px",
    float: "left",
    backgroundColor: "#fff",
    padding: 0,
    "&:before": {
      borderRight: "1px solid #b6b6b6",
      content: '"\\00a0"',
      position: "absolute",
      top: "1px",
      right: "36px",
      zIndex: " 1",
      display: "block",
      height: "100%",
    },
    "& label.Mui-focused": {
      color: "#b6b6b6",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#b6b6b6",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#b6b6b6",
        marginTop: "1px",
      },
      "&:hover fieldset": {
        borderColor: "#b6b6b6",
        marginTop: "1px",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#b6b6b6",
        marginTop: "1px",
      },
    },
  },
}))(FormControl);

const CustomCustomFormControl = styled(CustomFormControl)`
  @media (max-width: 530px) {
    width: 100% !important;
  }
`;

const CustomCustomTextField = styled(CustomTextField)`
  @media (max-width: 530px) {
    width: 100%;
  }
`;

const CustomTextFieldContainer = styled(TextFieldContainer)`
  @media (max-width: 530px) {
    width: 100%;
  }
`;

class AddCard extends Component {
  state = {
    password: "",
    number: "",
    bankName: "",
    bankAccountName: "",
    bankBranch: "",
    selectedChannelIndex: 0,

    selectedBankTypeIndex: 0,
  };
  componentDidMount() {}
  handleBankTypeChange = (e) => {
    this.setState({ ...this.state, selectedBankTypeIndex: e.target.value });
  };
  handleChannelChange = (e) => {
    this.setState({ ...this.state, selectedChannelIndex: e.target.value });
  };
  handleNumberChange = (e) => {
    this.setState({ ...this.state, number: e.target.value });
  };
  handleBankNameChange = (e) => {
    this.setState({ ...this.state, bankName: e.target.value });
  };
  handleBankAccountNameChange = (e) => {
    this.setState({ ...this.state, bankAccountName: e.target.value });
  };
  handleBankBranchChange = (e) => {
    this.setState({ ...this.state, bankBranch: e.target.value });
  };

  handleNextClick = (e) => {
    var selected = this.props.withdrawalList[this.state.selectedBankTypeIndex];
    var detail = {};
    if (selected.banktype === "Bank") {
      if (this.state.bankName === "") {
        this.props.enqueueSnackbar(
          i18n.t("account.withdrawal.addCard.msgEnterBankName"),
          {
            variant: "error",
          }
        );
        return;
      }
      if (config.features.AddCard_BankBranch && this.state.bankBranch === "") {
        this.props.enqueueSnackbar(
          i18n.t("account.withdrawal.addCard.branchPlaceHolder"),
          {
            variant: "error",
          }
        );
        return;
      }
      if (this.state.bankAccountName === "") {
        this.props.enqueueSnackbar(
          i18n.t("account.withdrawal.addCard.msgEnterBankAccountName"),
          {
            variant: "error",
          }
        );
        return;
      }

      if (this.state.number === "") {
        this.props.enqueueSnackbar(
          i18n.t("account.withdrawal.addCard.msgEnterWithdrawalNumber"),
          {
            variant: "error",
          }
        );
        return;
      }

      detail = {
        bankAccount: this.state.number,
        bankName: this.state.bankName,
        name: this.state.bankAccountName,
        bankBranch: this.state.bankBranch,
      };
    } else {
      detail = {
        address: this.state.number,
      };
    }

    this.props.onhandleNextClick(
      selected.channel[this.state.selectedChannelIndex].channelid,
      selected.banktype,
      detail
    );
  };
  render() {
    const { withdrawalList } = this.props;

    return (
      <Container>
        <SubTitle>{i18n.t("account.withdrawal.addCard.title")}</SubTitle>

        <TextFieldLabel>
          {i18n.t("account.withdrawal.addCard.withdrawalBankType")}
        </TextFieldLabel>
        <CustomTextFieldContainer>
          <CustomCustomFormControl>
            <CustomSelect
              native
              variant="outlined"
              displayEmpty
              value={this.state.selectedBankTypeIndex}
              onChange={this.handleBankTypeChange}
            >
              {withdrawalList.map((item, index) => {
                return (
                  <option value={index} key={index}>
                    {item.banktype}
                  </option>
                );
              })}
            </CustomSelect>
          </CustomCustomFormControl>
        </CustomTextFieldContainer>

        <TextFieldLabel>
          {i18n.t("account.withdrawal.addCard.withdrawalChannel")}
        </TextFieldLabel>
        <CustomTextFieldContainer>
          <CustomCustomFormControl>
            <CustomSelect
              native
              variant="outlined"
              displayEmpty
              value={this.state.selectedChannelIndex}
              onChange={this.handleChannelChange}
            >
              {withdrawalList.length > 0 &&
                withdrawalList[this.state.selectedBankTypeIndex].channel.map(
                  (item, index) => {
                    return (
                      <option value={index} key={index}>
                        {i18n.t(item.channelcode)}
                      </option>
                    );
                  }
                )}
            </CustomSelect>
          </CustomCustomFormControl>
        </CustomTextFieldContainer>
        {(() => {
          if (
            withdrawalList.length > 0 &&
            withdrawalList[this.state.selectedBankTypeIndex].banktype === "Bank"
          ) {
            return (
              <>
                <TextFieldLabel>
                  {config.features.AddCard_DisplayFPS
                    ? i18n.t("hk28.card.addCard.BankName")
                    : i18n.t("account.withdrawal.addCard.BankName")}
                </TextFieldLabel>
                <CustomTextFieldContainer>
                  <CustomCustomTextField
                    marginTop={10}
                    placeholder={
                      config.features.AddCard_DisplayFPS
                        ? i18n.t("hk28.card.addCard.msgEnterBankName")
                        : i18n.t("account.withdrawal.addCard.msgEnterBankName")
                    }
                    width={395}
                    height={38}
                    float={"none"}
                    handleTextFieldChange={this.handleBankNameChange}
                  ></CustomCustomTextField>
                </CustomTextFieldContainer>

                {config.features.AddCard_BankBranch && (
                  <>
                    <TextFieldLabel>
                      {i18n.t("account.withdrawal.addCard.branch")}
                    </TextFieldLabel>
                    <CustomTextFieldContainer>
                      <CustomCustomTextField
                        marginTop={10}
                        placeholder={i18n.t(
                          "account.withdrawal.addCard.branchPlaceHolder"
                        )}
                        width={395}
                        height={38}
                        float={"none"}
                        handleTextFieldChange={this.handleBankBranchChange}
                      ></CustomCustomTextField>
                    </CustomTextFieldContainer>
                  </>
                )}

                <TextFieldLabel>
                  {config.features.AddCard_DisplayFPS
                    ? i18n.t("hk28.card.addCard.BankAccountName")
                    : i18n.t("account.withdrawal.addCard.BankAccountName")}
                </TextFieldLabel>
                <CustomTextFieldContainer>
                  <CustomCustomTextField
                    marginTop={10}
                    placeholder={
                      config.features.AddCard_DisplayFPS
                        ? i18n.t("hk28.card.addCard.msgEnterBankAccountName")
                        : i18n.t(
                            "account.withdrawal.addCard.msgEnterBankAccountName"
                          )
                    }
                    width={395}
                    height={38}
                    float={"none"}
                    handleTextFieldChange={this.handleBankAccountNameChange}
                  ></CustomCustomTextField>
                </CustomTextFieldContainer>
              </>
            );
          } else {
            return null;
          }
        })()}

        <TextFieldLabel>
          {config.features.AddCard_DisplayFPS
            ? i18n.t("hk28.withdrawalNumber")
            : i18n.t("account.withdrawal.addCard.withdrawalNumber")}
        </TextFieldLabel>
        <CustomTextFieldContainer>
          <CustomCustomTextField
            marginTop={10}
            placeholder={
              config.features.AddCard_DisplayFPS
                ? i18n.t("hk28.msgEnterWithdrawalNumber")
                : i18n.t("account.withdrawal.addCard.msgEnterWithdrawalNumber")
            }
            height={38}
            maxLength={50}
            float={"none"}
            handleTextFieldChange={this.handleNumberChange}
          ></CustomCustomTextField>
        </CustomTextFieldContainer>

        <MakeButtonFloatCenter>
          <MakeSomeGap>
            {(() => {
              if (this.props.showBack === true) {
                return (
                  <Button
                    grayMode={true}
                    name={i18n.t("common.back")}
                    width={257}
                    handleClick={this.props.onhandleBackClick}
                  ></Button>
                );
              } else {
                return null;
              }
            })()}
          </MakeSomeGap>
          <MakeSomeGap>
            <Button
              name={i18n.t("common.nextStep")}
              width={255}
              handleClick={this.handleNextClick}
            ></Button>
          </MakeSomeGap>
        </MakeButtonFloatCenter>
      </Container>
    );
  }
}

export default withSnackbar(AddCard);
